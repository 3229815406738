import { Form } from "@douyinfe/semi-ui"
import FlexContainer from "../../../../components/FlexContainer"
import { useEffect, useMemo, useRef, useState } from "react"
import { FormApi } from "@douyinfe/semi-ui/lib/es/form"
import './index.less'
import { IconClose } from "@douyinfe/semi-icons"

const CashbackCalculator = () => {
    const [formData, setFormData] = useState({
        amount: 100,
        rate: 10,
    })

    const formRef = useRef<FormApi>()

    useEffect(() => {
        const handleMaxCashbackRateChange = (e: any) => {
            if (e.detail?.maxCashbackRate) {
                formRef.current?.setValue('rate', e.detail.maxCashbackRate)
            }
        }

        document.addEventListener("max-cashback-rate::change", handleMaxCashbackRateChange);

        return () => {
            document.removeEventListener('max-cashback-rate::change', handleMaxCashbackRateChange)
        }
    }, [])

    const cashbackAmount = useMemo(() => {
        if (!formData || !formData.amount || !formData.rate) {
            return 0
        }

        return (formData.amount * formData.rate / 100).toFixed(2)
    }, [formData])

    return (
        <div className='cashback-calculator-wrapper'>
            <Form getFormApi={api => formRef.current = api} initValues={formData} onValueChange={v => setFormData(prev => ({ ...prev, ...v }))}>
                <Form.Input label="Amount Spent" field="amount" prefix="$" suffix={<IconClose />} />
                <Form.Input type="number" label="Cashback Rate" field="rate" suffix="%" />
            </Form>
            <FlexContainer className="font-weight-bold responsive-text" justifyContent="flex-end">
                = ${cashbackAmount}*
            </FlexContainer>

            <div className="font-weight-bold" style={{ color: 'grey', fontSize: 10, lineHeight: '12px', textAlign: 'right' }}>
                *For reference only
            </div>
        </div>
    )
}

export default CashbackCalculator
import { Button, Empty } from "@douyinfe/semi-ui"
import FlexContainer from "../../components/FlexContainer"
import { IllustrationNotFound, IllustrationNotFoundDark } from "@douyinfe/semi-illustrations"
import { useContext } from "react"
import { AppContext } from "../../App"
import { IconRedo } from "@douyinfe/semi-icons"
import { useNavigate } from "react-router-dom"

const NotFound = () => {
    const { windowHeight, navbarHeight } = useContext(AppContext)
    const navigate = useNavigate()

    const handleClick = () => {
        navigate('/')
    }

    return (
        <FlexContainer justifyContent="center" alignItems="center" style={{ height: windowHeight - navbarHeight }}>
            <Empty
                image={<IllustrationNotFound style={{ width: 150, height: 150 }} />}
                darkModeImage={<IllustrationNotFoundDark style={{ width: 150, height: 150 }} />}
                description={'Page not found'}
            // style={emptyStyle}
            >
                <Button icon={<IconRedo />} onClick={handleClick}>
                    Redirecting to the homepage
                </Button>
            </Empty>
        </FlexContainer>
    )
}

export default NotFound
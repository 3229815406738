import { Button, Spin, Toast } from "@douyinfe/semi-ui"
import FlexContainer from "../../components/FlexContainer"
import { useContext, useEffect } from "react"
import { parse } from "querystring"
import { IconAlarm, IconAlertTriangle } from "@douyinfe/semi-icons"
import { AppContext } from "../../App"
import { useNavigate } from "react-router-dom"
import { THIRD_PARTY_LOGIN_MERGE_SHOPPING_LIST_TRIGGER_LOCAL_STORAGE_KEY, THIRD_PARTY_LOGIN_PAGE_URL_LOCAL_STORAGE_KEY } from "../../constants"
import { CookieUtil } from "../../utils"
import ShoppingList from "../ShoppingList"

const LoginCallback = () => {
    const { showLoginForm, setUserInfo } = useContext(AppContext)
    const navigate = useNavigate()

    const { state, email } = parse(window.location.search.slice(1))

    useEffect(() => {
        if (state === 'success' && email) {
            Toast.success('Login successfully')
            setUserInfo({ email })

            setTimeout(() => {
                window.location.href = CookieUtil.get(THIRD_PARTY_LOGIN_PAGE_URL_LOCAL_STORAGE_KEY) as string
                CookieUtil.delete(THIRD_PARTY_LOGIN_PAGE_URL_LOCAL_STORAGE_KEY)
            }, 2000)
        }
    }, [])

    if (state === 'failed') {
        return (
            <FlexContainer alignItems="center" flexDirection="column" style={{ height: 200, padding: 24, paddingTop: 48 }}>
                <IconAlertTriangle style={{ fontSize: 48, color: 'rgb(240,177,20)' }} />

                <div className="font-weight-bold responsive-text" style={{ fontSize: 20, textAlign: 'center', marginTop: 16 }}>
                    Error occurred <br /> while we log you in
                </div>

                <div style={{ marginTop: 8 }}>
                    <Button onClick={showLoginForm}>Log in again</Button>
                </div>
            </FlexContainer>
        )
    }

return (
        <FlexContainer alignItems="center" flexDirection="column" style={{ height: 200, padding: 24, paddingTop: 48 }}>
            <Spin size="large" />

            <div className="font-weight-bold responsive-text" style={{ fontSize: 20, textAlign: 'center', marginTop: 16 }}>
                Please wait <br /> while we log you in
            </div>

            {/* <div style={{ display: 'none' }}>
                <ShoppingList />
            </div> */}
        </FlexContainer>
    )
}

export default LoginCallback
import { useAsyncEffect } from "ahooks"
import { useCallback, useContext, useRef, useState } from "react"
import { useParams } from "react-router-dom"
import { formatNumber, generateColorGradient, request } from "../../../../utils"
import { Button, Calendar, Divider, RadioGroup, Spin } from "@douyinfe/semi-ui"
import FlexContainer from "../../../../components/FlexContainer"
import { AppContext } from "../../../../App"
import dayjs from "dayjs"
import { IconChevronLeft, IconChevronRight, IconDoubleChevronLeft } from "@douyinfe/semi-icons"
import { IHotelRate } from "../../../../typings"
import './index.less'

const PriceCalendar = () => {
    const { brand: rawBrand, property_id } = useParams()
    const brand = rawBrand?.toLowerCase()

    const { isMobile, isDarkMode } = useContext(AppContext)

    const [priceList, setPriceList] = useState<IHotelRate[]>([])
    const [loading, setLoading] = useState(true)

    const [currDate, setCurrDate] = useState(dayjs())
    const [rateCategory, setRateCategory] = useState('STANDARD')

    const getStandardColorFn = useRef<any>()
    const getRedemptionColorFn = useRef<any>()

    useAsyncEffect(async () => {
        setLoading(true)
        setCurrDate(dayjs())
        setPriceList([])
        setRateCategory('STANDARD')

        const respData: IHotelRate[] = await request({
            data: {
                service: 'rds.sql',
                requestParams: [
                    `SELECT * FROM simpo_octopus.${brand}_hotel_rate WHERE property_id = "${property_id}" AND date > CURDATE() AND price > 0`
                ]
            }
        })

        const priceNumList = respData.filter(item => item.rate_category === 'STANDARD').map(item => item.price)
        if (priceNumList.length === 0) {
            priceNumList.push(0)
        }

        getStandardColorFn.current = generateColorGradient(Math.min(...priceNumList), Math.max(...priceNumList) + 1, '#198754', '#fc8800')

        const pointNumList = respData.filter(item => item.rate_category === 'REDEMPTION').map(item => item.price)
        if (pointNumList.length === 0) {
            pointNumList.push(0)
        }

        getRedemptionColorFn.current = generateColorGradient(Math.min(...pointNumList), Math.max(...pointNumList) + 1, '#198754', '#fc8800')

        setPriceList(respData)
        setLoading(false)
    }, [property_id])

    const handleOpen = useCallback((date: string) => {
        if (brand === 'hilton') {
            const queryParams = {
                "ctyhocn": property_id,
                "arrivalDate": date,
                "departureDate": dayjs(date).add(1, 'd').format('YYYY-MM-DD'),
                "room1NumAdults": '1',
                "redeemPts": rateCategory === 'REDEMPTION'
            }

            window.open(`https://www.hilton.com/en/book/reservation/rooms/?${Object.entries(queryParams).map(([k, v]) => `${k}=${v}`).join('&')}`)
            return
        } else if (brand === 'marriott') {
            const checkInDate = dayjs(date).format('MM/DD/YYYY')
            const checkoutDate = dayjs(date).add(1, 'day').format('MM/DD/YYYY')

            const queryParams = {
                "propertyCode": property_id,
                "fromToDate_submit": checkoutDate,
                "fromDate": checkInDate,
                "toDate": checkoutDate,
                "toDateDefaultFormat": checkoutDate,
                "fromDateDefaultFormat": checkInDate,
                "flexibleDateSearch": "false",
                "t-start": checkInDate,
                "t-end": checkoutDate,
                "lengthOfStay": "1",
                "childrenCountBox": "0 Children Per Room",
                "childrenCount": "0",
                "clusterCode": "none",
                "marriottBrands": "",
                "isAdvanceSearch": "false",
                "roomCountBox": "1 Rooms",
                "numberOfRooms": "1",
                "guestCountBox": "1 Adults Per Room",
                "numberOfAdults": "1",
                "useRewardsPoints": rateCategory === 'REDEMPTION'
            }

            window.open(`https://www.marriott.com/reservation/availabilitySearch.mi?${Object.entries(queryParams).map(([k, v]) => `${k}=${v}`).join('&')}`)
            return
        } else if (brand === 'ihg') {
            const checkInDate = dayjs(date)
            const checkoutDate = dayjs(date).add(1, 'day')

            const queryParams = {
                "qDest": " ",
                "qPt": rateCategory === 'REDEMPTION' ? "POINTS" : "CASH",
                "qCiD": checkInDate.format('DD'),
                "qCoD": checkoutDate.format('DD'),
                "qCiMy": checkInDate.subtract(1, 'month').format('MMYYYY'),
                "qCoMy": checkoutDate.subtract(1, 'month').format('MMYYYY'),
                "qAdlt": "1",
                "qChld": "0",
                "qRms": "1",
                "qAAR": "",
                "qRtP": rateCategory === 'REDEMPTION' ? "IVANI" : "",
                "qSlH": property_id,
                "qAkamaiCC": "",
                "srb_u": "1",
                "qExpndSrch": "false",
                "qSrt": "sAV",
                "qBrs": "6c.hi.ex.sb.ul.ic.cp.cw.in.vn.cv.rs.ki.ma.sp.va.re.vx.nd.sx.we.lx.rn.sn.nu",
                "qWch": "0",
                "qSmP": "0",
                "qRad": "30",
                "qRdU": "mi",
                "setPMCookies": "true",
                "qpMbw": "0",
                "qErm": "false",
                "qpMn": "0",
                "qLoSe": "false",
                "qChAge": "",
                "qRmFltr": ""
            }

            window.open(`https://www.ihg.com/hotels/gb/en/find-hotels/select-roomrate?${Object.entries(queryParams).map(([k, v]) => `${k}=${v}`).join('&')}`)
            return
        }
    }, [rateCategory])

    return (
        <div>
            <Spin spinning={loading} size="large">
                <FlexContainer justifyContent="space-between">
                    <div className="font-weight-black responsive-text" style={{ letterSpacing: -0.2 }}>
                        Price Calendar
                    </div>

                    <RadioGroup
                        type="button"
                        value={rateCategory}
                        buttonSize="small"
                        onChange={e => setRateCategory(e.target.value)}
                        options={[
                            { label: 'Standard Rates', value: 'STANDARD' },
                            { label: "Point Redemption", value: 'REDEMPTION' }
                        ]}
                    />
                </FlexContainer>

                <div className="hotel-price-calendar-wrapper">
                    <FlexContainer justifyContent="center" className="font-weight-bold responsive-text" style={{ fontSize: 20, letterSpacing: -0.5 }}>{currDate.format('MMMM YYYY')}</FlexContainer>
                    <Divider />
                    <Calendar
                        height={isMobile ? 400 : 500}
                        displayValue={currDate.toDate()}
                        mode="month"
                        dateGridRender={((str: string) => {
                            const targetRate = priceList?.find(item => item.date === dayjs(str).format('YYYY-MM-DD') && item.rate_category === rateCategory)

                            if (targetRate) {
                                const isRedemption = targetRate.rate_category === 'REDEMPTION'

                                return (
                                    <FlexContainer
                                        className="font-weight-bold"
                                        justifyContent="center"
                                        alignItems="center"
                                        style={{
                                            backgroundColor: (rateCategory === 'STANDARD' ? getStandardColorFn : getRedemptionColorFn).current?.(targetRate.price) + '66',
                                            position: 'absolute',
                                            left: '0',
                                            right: '0',
                                            top: '0',
                                            bottom: '0',
                                            cursor: 'pointer',
                                            paddingTop: isMobile ? 20 : 0
                                        }}
                                        onClick={() => handleOpen(targetRate.date)}
                                    >
                                        {isRedemption ? '' : targetRate.currency} {formatNumber(targetRate.price, isMobile ? '0.[0]a' : '0,0')} {isRedemption ? 'PTS' : ''}
                                    </FlexContainer>
                                )
                            }

                            return (
                                <FlexContainer
                                    justifyContent="center"
                                    alignItems="center"
                                    style={{
                                        backgroundColor: `rgba(var(--semi-grey-${isDarkMode ? 3 : 2}),1)`,
                                        position: 'absolute',
                                        left: '0',
                                        right: '0',
                                        top: '0',
                                        bottom: '0',
                                        paddingTop: isMobile ? 20 : 0
                                    }}
                                >
                                    <span className="font-weight-bold" style={{ opacity: 0.4, fontSize: 16 }}>N/A</span>
                                </FlexContainer>
                            )
                        }) as any}
                    />

                    <FlexContainer justifyContent="center" style={isMobile ? { marginTop: 16 } : { marginTop: 24, marginBottom: 8 }}>
                        <FlexContainer gap="8px">
                            <Button
                                size={isMobile ? "default" : "large"}
                                type="tertiary"
                                style={{ borderRadius: 32 }}
                                icon={<IconDoubleChevronLeft />}
                                disabled={dayjs().format('YYYY-MM-DD') === currDate.format('YYYY-MM-DD')}
                                onClick={() => setCurrDate(dayjs())}
                            />

                            <Button
                                size={isMobile ? "default" : "large"}
                                style={{ borderRadius: 32 }}
                                icon={<IconChevronLeft />}
                                disabled={dayjs().format('YYYY-MM-DD') === currDate.format('YYYY-MM-DD')}
                                onClick={() => setCurrDate(prev => prev.subtract(1, 'month'))}
                            >
                                Previous Month
                            </Button>
                            <Button
                                theme="solid"
                                size={isMobile ? "default" : "large"}
                                style={{ borderRadius: 32 }}
                                icon={<IconChevronRight />}
                                iconPosition="right"
                                onClick={() => setCurrDate(prev => prev.add(1, 'month'))}
                            >
                                Next Month
                            </Button>
                        </FlexContainer>
                    </FlexContainer>
                </div>
            </Spin>
        </div>
    )
}

export default PriceCalendar
import { useContext, useState } from "react"
import { AppContext } from "../../App"
import FlexContainer from "../../components/FlexContainer"
import ArticleRender from "../../components/ArticleRender"
import { IArticle } from "../../typings"
import { useAsyncEffect } from "ahooks"
import { request } from "../../utils"
import { Card } from "@douyinfe/semi-ui"
import { useNavigate } from "react-router-dom"


const Article = () => {
    const { windowHeight, navbarHeight } = useContext(AppContext)
    const navigate = useNavigate()
    
    const [articleList, setArticleList] = useState<IArticle[]>([])
    const [loading, setLoading] = useState(true)

    useAsyncEffect(async () => {
        const respData = await request({
            data: {
                service: 'rds.sql',
                requestParams: [
                    `SELECT * FROM simpo_octopus.au_article`
                ]
            }
        })

        setArticleList(respData)
        setLoading(false)
    }, [])

    return (
        <FlexContainer justifyContent="center" style={{ height: windowHeight - navbarHeight, boxSizing: 'border-box', overflow: 'auto' }}>
            {articleList.map(article => {
                return (
                    <div key={article.au_article_auto_increment_id} onClick={() => {
                        navigate(`/articles/${article.au_article_auto_increment_id}`)
                    }}>
                        <Card>
                            <div>{article.article_name_en}</div>
                        </Card>
                    </div>
                )
            })}
        </FlexContainer>


    )
}

export default Article
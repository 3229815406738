import { useAsyncEffect } from "ahooks"
import { renderText, request } from "../../../utils"
import { useContext, useEffect, useRef, useState } from "react"
import { Button, Divider, Form, RadioGroup, SideSheet, Table, Tabs, Tag, Toast } from "@douyinfe/semi-ui"
import dayjs from "dayjs"
import { AppContext } from "../../../App"
import FlexContainer from "../../../components/FlexContainer"
import { IconDelete, IconPlus, IconScissors, IconSetting } from "@douyinfe/semi-icons"
import { IGiftCardDetail, IGiftCardPromotion, IOzBargainDeal } from "../../../typings"
import { FormApi } from "@douyinfe/semi-ui/lib/es/form"
import GPTHelper from "../../../components/GPTHelper"
import { pick } from "lodash-es"
import GiftCardPoster from "../../GiftCards/components/GiftCardPoster"
import { SideSheetPlaceholder } from "../AdminLayout"
import useKeyValuePairs from "../../../hooks/useKeyValuePairs"

const GiftCardPromotionPosterGenerator = ({ giftCardPromotion }: { giftCardPromotion: IGiftCardPromotion }) => {
    const [giftCardList, setGiftCardList] = useState<IGiftCardDetail[]>([])

    useAsyncEffect(async () => {
        const respData = await request({
            data: {
                service: 'au.hotaudeals.getGiftCardPromotionDetail',
                requestParams: [
                    giftCardPromotion.offer_text
                ]
            }
        })

        setGiftCardList(respData)
    }, [giftCardPromotion])

    return (
        <div>
            <div className="font-weight-black responsive-text" style={{ fontSize: 20, letterSpacing: -0.5 }}>Poster</div>
            <GiftCardPoster giftCardPromotion={giftCardPromotion} giftCardList={giftCardList} />
            <Divider />
        </div>
    )
}

const GiftCardPromotionManagement = () => {
    const { windowHeight, windowWidth, navbarHeight } = useContext(AppContext)

    const { kvPairs: platformColorMap } = useKeyValuePairs('color')

    const [pageLoading, setPageLoading] = useState(true)

    const [mode, setMode] = useState<string>('')

    const [dealList, setDealList] = useState<IOzBargainDeal[]>([])
    const [promotionList, setPromotionList] = useState<IGiftCardPromotion[]>([])

    const [selectedDeal, setSelectedDeal] = useState<IOzBargainDeal>()
    const [selectedPromotion, setSelectedPromotion] = useState<IGiftCardPromotion>()

    const [selectedTab, setSelectedTab] = useState('all_gift_card_promotions')

    const [deleteLoading, setDeleteLoading] = useState(false)
    const [submitLoading, setSubmitLoading] = useState(false)

    const createFormRef = useRef<FormApi>()
    const editFormRef = useRef<FormApi>()

    useAsyncEffect(async () => {
        const giftCardPromotionList = await request({
            method: 'post',
            url: '/api/v1/service/execute',
            data: {
                service: 'rds.sql',
                requestParams: [
                    `SELECT 
                        * 
                    FROM 
                        simpo_octopus.au_gift_card_promotion ORDER BY start_date DESC`
                ]
            }
        })

        const ozBargainGiftCardList = await request({
            method: 'post',
            url: '/api/v1/service/execute',
            data: {
                service: 'rds.sql',
                requestParams: [
                    `SELECT 
                        * 
                    FROM 
                        simpo_octopus.au_ozbargain_deal
                    WHERE 
                        is_expired = 0  
                        AND is_not_gift_card_deal = 0
                        AND name LIKE "%gift%"
                    ORDER BY 
                        created_at DESC
                        `
                ]
            }
        })

        const validatedOzBargainGiftCardDealList = await request({
            method: 'post',
            url: '/api/v1/service/execute',
            data: {
                service: 'rds.sql',
                requestParams: [
                    `SELECT * FROM simpo_octopus.au_gift_card_promotion WHERE uniq_composite_key LIKE "ozbargain_%"`
                ]
            }
        })

        setDealList(ozBargainGiftCardList.filter((deal: IOzBargainDeal) => {
            return !validatedOzBargainGiftCardDealList.find((item: IGiftCardPromotion) => item.uniq_composite_key.endsWith(`${deal.deal_id}`))
        }))
        setPromotionList(giftCardPromotionList)
        setPageLoading(false)
    }, [])

    useEffect(() => {
        if (selectedDeal) {
            createFormRef.current?.setValues({
                uniq_composite_key: `ozbargain_gift_card_deal_${selectedDeal.deal_id}`,
                platform: selectedDeal.platform,
                offer_text: selectedDeal.name
            })
        }

        if (selectedPromotion) {
            editFormRef.current?.setValues({
                ...selectedPromotion,
                is_validated: !!selectedPromotion.is_validated
            })
        }
    }, [selectedDeal, selectedPromotion])

    const handleDeleteDeal = async (id: number) => {
        setDeleteLoading(true)
        await request({
            method: 'post',
            url: '/api/v1/service/execute',
            data: {
                service: 'rds.sql',
                requestParams: [
                    `UPDATE simpo_octopus.au_ozbargain_deal SET is_not_gift_card_deal = 1 WHERE ozbargain_auto_increment_id = ${id}`
                ]
            }
        })
        setDealList(arr => arr.filter(item => item.ozbargain_auto_increment_id !== id))
        setDeleteLoading(false)
    }

    const handleSubmitDeal = async () => {
        setSubmitLoading(true)
        const formData = await createFormRef.current?.getValues()

        if (formData.start_date) {
            formData.start_date = dayjs(formData.start_date).format('YYYY-MM-DD')
        }

        if (formData.end_date) {
            formData.end_date = dayjs(formData.end_date).format('YYYY-MM-DD')
        }

        formData.is_validated = 1

        await request({
            method: 'post',
            url: '/api/v1/service/execute',
            data: {
                service: 'rds.insert',
                requestParams: [
                    'simpo_octopus', 'au_gift_card_promotion',
                    formData,
                    {
                        onDuplicateKeyUpdate: {
                            platform: 'VALUES(platform)',
                            offer_text: 'VALUES(offer_text)',
                            start_date: 'VALUES(start_date)',
                            end_date: 'VALUES(end_date)'
                        }
                    }
                ]
            }
        })

        Toast.success('Submit successfully')
        setSubmitLoading(false)
    }

    const handleEditPromotion = async () => {
        setSubmitLoading(true)
        const formData = await editFormRef.current?.getValues()

        if (formData.start_date) {
            formData.start_date = dayjs(formData.start_date).format('YYYY-MM-DD')
        }

        if (formData.end_date) {
            formData.end_date = dayjs(formData.end_date).format('YYYY-MM-DD')
        }

        if (formData.is_validated) {
            formData.is_validated = 1
        } else {
            formData.is_validated = 0
        }

        await request({
            method: 'post',
            url: '/api/v1/service/execute',
            data: {
                service: 'rds.insert',
                requestParams: [
                    'simpo_octopus', 'au_gift_card_promotion',
                    formData,
                    {
                        onDuplicateKeyUpdate: {
                            platform: 'VALUES(platform)',
                            offer_text: 'VALUES(offer_text)',
                            start_date: 'VALUES(start_date)',
                            end_date: 'VALUES(end_date)',
                            is_validated: 'VALUES(is_validated)'
                        }
                    }
                ]
            }
        })

        setPromotionList(arr => arr.map(item => {
            if (item.uniq_composite_key === formData.uniq_composite_key) {
                return {
                    ...item,
                    ...formData
                }
            }

            return item
        }))
        Toast.success('Submit successfully')
        setSubmitLoading(false)
    }

    const columns = [
        {
            title: 'ID',
            width: 90,
            dataIndex: 'deal_id',
            render: (text: string) => {
                return (
                    <Button size="small" type="tertiary" theme='borderless' style={{ padding: 0 }} onClick={() => {
                        window.open(`https://www.ozbargain.com.au/node/${text}`)
                    }}>{text}</Button>
                )
            }
        },
        {
            title: 'Deal',
            render: (record: IOzBargainDeal) => {
                return (
                    <FlexContainer flexDirection="column" gap="4px">
                        <div>
                            <Tag>{record.platform}</Tag>
                        </div>

                        <div>{record.name}</div>
                    </FlexContainer>
                )
            }
        },
        {
            title: 'Created at',
            width: 160,
            render: (record: IOzBargainDeal) => {
                return dayjs(record.created_at).format('YYYY-MM-DD HH:mm')
            }
        },
        {
            title: 'Action',
            width: 110,
            render: (record: IOzBargainDeal) => {
                return (
                    <FlexContainer gap="6px">
                        <Button
                            theme="solid"
                            type="tertiary"
                            icon={<IconPlus />}
                            style={{ borderRadius: 32 }}
                            onClick={() => setSelectedDeal(record)}
                        />
                        <Button
                            theme="solid"
                            type="danger"
                            icon={<IconDelete />}
                            style={{ borderRadius: 32 }}
                            onClick={() => handleDeleteDeal(record.ozbargain_auto_increment_id)}
                        />
                    </FlexContainer>
                )
            }
        }
    ]

    return (
        <FlexContainer>
            <div style={{ flexGrow: 1, overflow: 'hidden' }}>
                <FlexContainer flexDirection="column" style={{ padding: '8px 16px 0' }} gap="4px">
                    <div className="responsive-text font-weight-black" style={{ fontSize: 20, letterSpacing: -0.5 }}>
                        Gift Card Promotion Management
                    </div>
                    {/* <div>
                        <RadioGroup
                            type="button"
                            defaultValue="all_gift_card_promotions"
                            options={[
                                { label: `All Gift Card Promotions (${promotionList.length})`, value: 'all_gift_card_promotions' },
                                { label: `OzBargain Gift Card Deals (${dealList.length})`, value: 'ozbargain_gift_card_deals' },
                            ]}
                            onChange={e => setSelectedTab(e.target.value)}
                        />
                    </div> */}

                </FlexContainer>

                <div>
                    <Tabs
                        activeKey={selectedTab}
                        tabList={[
                            { tab: `All Gift Card Promotions (${promotionList.length})`, itemKey: 'all_gift_card_promotions' },
                            { tab: `OzBargain Gift Card Deals (${dealList.length})`, itemKey: 'ozbargain_gift_card_deals' },
                        ]}
                        onChange={setSelectedTab}
                        contentStyle={{ padding: 0 }}
                    />
                </div>

                {selectedTab === 'ozbargain_gift_card_deals' && (
                    <Table
                        dataSource={dealList}
                        columns={columns}
                        pagination={false}
                        sticky
                        loading={pageLoading}
                        style={{ height: windowHeight - navbarHeight - 85, overflow: 'auto' }}
                    />
                )}

                {selectedTab === 'all_gift_card_promotions' && (
                    <Table
                        dataSource={promotionList}
                        columns={[
                            {
                                title: 'ID',
                                width: 90,
                                render: (record: IGiftCardPromotion) => {
                                    return (
                                        <Button size="small" theme="borderless" style={{ padding: 0 }} type="tertiary" onClick={() => {
                                            window.open(`/gift-cards/${record.gift_card_promotion_auto_increment_id}`)
                                        }}>{record.gift_card_promotion_auto_increment_id}</Button>
                                    )
                                }
                            },
                            {
                                title: "Promotion",
                                render: (record: IGiftCardPromotion) => {
                                    return (
                                        <FlexContainer gap="4px" flexDirection="column">
                                            <div>
                                                <Tag style={{ background: platformColorMap[record.platform.toLowerCase() || ''], color: 'white'  }}>{record.platform}</Tag>
                                            </div>
                                            <div>{renderText(record.offer_text)}</div>
                                        </FlexContainer>
                                    )
                                }
                            },
                            {
                                title: 'Status',
                                width: 110,
                                render: (record: IGiftCardPromotion) => {
                                    if (record.is_validated) {
                                        return <Tag color="green">Validated</Tag>
                                    } else {
                                        return <Tag color="red">Deactivated</Tag>
                                    }
                                }
                            },
                            {
                                title: 'Action',
                                width: 100,
                                render: (record: IGiftCardPromotion) => {
                                    return (
                                        <FlexContainer gap="6px">
                                            <Button theme='solid' type="tertiary" icon={<IconSetting />} style={{ borderRadius: 32 }} onClick={() => {
                                                setMode('edit')
                                                setSelectedPromotion(record)
                                            }} />
                                            <Button theme="solid" icon={<IconScissors />} style={{ borderRadius: 32 }} onClick={() => {
                                                setMode('post')
                                                setSelectedPromotion(record)
                                            }} />
                                        </FlexContainer>
                                    )
                                }
                            }
                        ]}
                        pagination={false}
                        sticky
                        loading={pageLoading}
                        style={{ height: windowHeight - navbarHeight - 85, overflow: 'auto' }}
                    />
                )}
            </div>

            <SideSheetPlaceholder />

            <SideSheet mask={false} title="Create gift card promotion" visible={!!selectedDeal} onCancel={() => setSelectedDeal(undefined)}>
                <Form getFormApi={api => createFormRef.current = api}>
                    <Form.Input label="ID" field="uniq_composite_key" />
                    <Form.Input label="Platform" field="platform" />
                    <Form.TextArea label="Offer text" field="offer_text" />
                    <Form.DatePicker label="Start date" field="start_date" />
                    <Form.DatePicker label="End date" field="end_date" />
                </Form>

                <FlexContainer gap="6px">
                    <Button theme="solid" onClick={handleSubmitDeal} loading={submitLoading}>Submit</Button>
                    <Button loading={deleteLoading} onClick={() => {
                        window.open(selectedDeal?.buy_url)
                    }}>View detail</Button>
                </FlexContainer>
            </SideSheet>

            <SideSheet mask={false} title="Edit gift card promotion" visible={mode === 'edit' && !!selectedPromotion} onCancel={() => setSelectedPromotion(undefined)}>
                <Form getFormApi={api => editFormRef.current = api}>
                    <Form.Input label="ID" field="uniq_composite_key" />
                    <Form.Input label="Platform" field="platform" />
                    <Form.TextArea label="Offer text" field="offer_text" />
                    <Form.DatePicker label="Start date" field="start_date" />
                    <Form.DatePicker label="End date" field="end_date" />
                    <Form.Switch label="Validated?" field="is_validated" />
                </Form>

                <FlexContainer gap="6px">
                    <Button theme="solid" onClick={handleEditPromotion} loading={submitLoading}>Submit</Button>
                </FlexContainer>
            </SideSheet>

            <SideSheet mask={false} title="Post gift card promotion" visible={mode === 'post' && !!selectedPromotion} onCancel={() => setSelectedPromotion(undefined)}>
                {!!selectedPromotion && <GiftCardPromotionPosterGenerator giftCardPromotion={selectedPromotion} />}

                <GPTHelper
                    system_prompt="请帮我撰写小红书文案，预告下期礼品卡优惠，不要有是gpt生成的痕迹"
                    user_content={JSON.stringify(pick(selectedPromotion, ['platform', 'offer_text', 'start_date', 'end_date', 'promotion_detail']))}
                />
            </SideSheet>
        </FlexContainer>
    )
}

export default GiftCardPromotionManagement
export const json = {
    "v": "5.4.4",
    "fr": 24,
    "ip": 0,
    "op": 28,
    "w": 48,
    "h": 48,
    "nm": "fire",
    "ddd": 0,
    "assets": [],
    "layers": [
        {
            "ddd": 0,
            "ind": 1,
            "ty": 4,
            "nm": "Yellow",
            "sr": 1,
            "ks": {
                "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 11
                },
                "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 10
                },
                "p": {
                    "a": 0,
                    "k": [
                        24,
                        33.5,
                        0
                    ],
                    "ix": 2
                },
                "a": {
                    "a": 0,
                    "k": [
                        0,
                        0,
                        0
                    ],
                    "ix": 1
                },
                "s": {
                    "a": 0,
                    "k": [
                        100,
                        100,
                        100
                    ],
                    "ix": 6
                }
            },
            "ao": 0,
            "shapes": [
                {
                    "ty": "gr",
                    "it": [
                        {
                            "ind": 0,
                            "ty": "sh",
                            "ix": 1,
                            "ks": {
                                "a": 0,
                                "k": {
                                    "i": [
                                        [
                                            0,
                                            0
                                        ]
                                    ],
                                    "o": [
                                        [
                                            0,
                                            0
                                        ]
                                    ],
                                    "v": [
                                        [
                                            -80.25,
                                            -5
                                        ]
                                    ],
                                    "c": false
                                },
                                "ix": 2
                            },
                            "nm": "Path 1",
                            "mn": "ADBE Vector Shape - Group",
                            "hd": false
                        },
                        {
                            "ty": "st",
                            "c": {
                                "a": 0,
                                "k": [
                                    0,
                                    0,
                                    0,
                                    1
                                ],
                                "ix": 3
                            },
                            "o": {
                                "a": 0,
                                "k": 100,
                                "ix": 4
                            },
                            "w": {
                                "a": 0,
                                "k": 3.9,
                                "ix": 5
                            },
                            "lc": 1,
                            "lj": 1,
                            "ml": 4,
                            "bm": 0,
                            "nm": "Stroke 1",
                            "mn": "ADBE Vector Graphic - Stroke",
                            "hd": false
                        },
                        {
                            "ty": "fl",
                            "c": {
                                "a": 0,
                                "k": [
                                    1,
                                    0.756862745098,
                                    0.027450980392,
                                    1
                                ],
                                "ix": 4
                            },
                            "o": {
                                "a": 0,
                                "k": 100,
                                "ix": 5
                            },
                            "r": 1,
                            "bm": 0,
                            "nm": "Fill 1",
                            "mn": "ADBE Vector Graphic - Fill",
                            "hd": false
                        },
                        {
                            "ty": "tr",
                            "p": {
                                "a": 0,
                                "k": [
                                    0,
                                    0
                                ],
                                "ix": 2
                            },
                            "a": {
                                "a": 0,
                                "k": [
                                    0,
                                    0
                                ],
                                "ix": 1
                            },
                            "s": {
                                "a": 0,
                                "k": [
                                    100,
                                    100
                                ],
                                "ix": 3
                            },
                            "r": {
                                "a": 0,
                                "k": 0,
                                "ix": 6
                            },
                            "o": {
                                "a": 0,
                                "k": 100,
                                "ix": 7
                            },
                            "sk": {
                                "a": 0,
                                "k": 0,
                                "ix": 4
                            },
                            "sa": {
                                "a": 0,
                                "k": 0,
                                "ix": 5
                            },
                            "nm": "Transform"
                        }
                    ],
                    "nm": "Shape 1",
                    "np": 3,
                    "cix": 2,
                    "bm": 0,
                    "ix": 1,
                    "mn": "ADBE Vector Group",
                    "hd": false
                },
                {
                    "ty": "gr",
                    "it": [
                        {
                            "ind": 0,
                            "ty": "sh",
                            "ix": 1,
                            "ks": {
                                "a": 1,
                                "k": [
                                    {
                                        "i": {
                                            "x": 0.667,
                                            "y": 1
                                        },
                                        "o": {
                                            "x": 0.333,
                                            "y": 0
                                        },
                                        "t": 0,
                                        "s": [
                                            {
                                                "i": [
                                                    [
                                                        0,
                                                        2.5
                                                    ],
                                                    [
                                                        0,
                                                        3
                                                    ],
                                                    [
                                                        0,
                                                        -4.6
                                                    ],
                                                    [
                                                        2.8,
                                                        0
                                                    ]
                                                ],
                                                "o": [
                                                    [
                                                        0,
                                                        -3.4
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        2.5
                                                    ],
                                                    [
                                                        -2.8,
                                                        0
                                                    ]
                                                ],
                                                "v": [
                                                    [
                                                        -5,
                                                        1.9
                                                    ],
                                                    [
                                                        0,
                                                        -6.5
                                                    ],
                                                    [
                                                        5,
                                                        1.9
                                                    ],
                                                    [
                                                        0,
                                                        6.5
                                                    ]
                                                ],
                                                "c": true
                                            }
                                        ],
                                        "e": [
                                            {
                                                "i": [
                                                    [
                                                        0,
                                                        2.5
                                                    ],
                                                    [
                                                        -2.313,
                                                        0.938
                                                    ],
                                                    [
                                                        0,
                                                        -3.244
                                                    ],
                                                    [
                                                        2.8,
                                                        0
                                                    ]
                                                ],
                                                "o": [
                                                    [
                                                        0,
                                                        -4.556
                                                    ],
                                                    [
                                                        0.313,
                                                        3.313
                                                    ],
                                                    [
                                                        0,
                                                        2.5
                                                    ],
                                                    [
                                                        -2.8,
                                                        0
                                                    ]
                                                ],
                                                "v": [
                                                    [
                                                        -5,
                                                        1.9
                                                    ],
                                                    [
                                                        -0.375,
                                                        -6.5
                                                    ],
                                                    [
                                                        5,
                                                        1.9
                                                    ],
                                                    [
                                                        0,
                                                        6.5
                                                    ]
                                                ],
                                                "c": true
                                            }
                                        ]
                                    },
                                    {
                                        "i": {
                                            "x": 0.667,
                                            "y": 1
                                        },
                                        "o": {
                                            "x": 0.333,
                                            "y": 0
                                        },
                                        "t": 7,
                                        "s": [
                                            {
                                                "i": [
                                                    [
                                                        0,
                                                        2.5
                                                    ],
                                                    [
                                                        -2.313,
                                                        0.938
                                                    ],
                                                    [
                                                        0,
                                                        -3.244
                                                    ],
                                                    [
                                                        2.8,
                                                        0
                                                    ]
                                                ],
                                                "o": [
                                                    [
                                                        0,
                                                        -4.556
                                                    ],
                                                    [
                                                        0.313,
                                                        3.313
                                                    ],
                                                    [
                                                        0,
                                                        2.5
                                                    ],
                                                    [
                                                        -2.8,
                                                        0
                                                    ]
                                                ],
                                                "v": [
                                                    [
                                                        -5,
                                                        1.9
                                                    ],
                                                    [
                                                        -0.375,
                                                        -6.5
                                                    ],
                                                    [
                                                        5,
                                                        1.9
                                                    ],
                                                    [
                                                        0,
                                                        6.5
                                                    ]
                                                ],
                                                "c": true
                                            }
                                        ],
                                        "e": [
                                            {
                                                "i": [
                                                    [
                                                        0,
                                                        2.5
                                                    ],
                                                    [
                                                        0,
                                                        3
                                                    ],
                                                    [
                                                        0,
                                                        -4.6
                                                    ],
                                                    [
                                                        2.8,
                                                        0
                                                    ]
                                                ],
                                                "o": [
                                                    [
                                                        0,
                                                        -3.4
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        2.5
                                                    ],
                                                    [
                                                        -2.8,
                                                        0
                                                    ]
                                                ],
                                                "v": [
                                                    [
                                                        -5,
                                                        1.9
                                                    ],
                                                    [
                                                        0,
                                                        -6.5
                                                    ],
                                                    [
                                                        5,
                                                        1.9
                                                    ],
                                                    [
                                                        0,
                                                        6.5
                                                    ]
                                                ],
                                                "c": true
                                            }
                                        ]
                                    },
                                    {
                                        "i": {
                                            "x": 0.667,
                                            "y": 1
                                        },
                                        "o": {
                                            "x": 0.333,
                                            "y": 0
                                        },
                                        "t": 14,
                                        "s": [
                                            {
                                                "i": [
                                                    [
                                                        0,
                                                        2.5
                                                    ],
                                                    [
                                                        0,
                                                        3
                                                    ],
                                                    [
                                                        0,
                                                        -4.6
                                                    ],
                                                    [
                                                        2.8,
                                                        0
                                                    ]
                                                ],
                                                "o": [
                                                    [
                                                        0,
                                                        -3.4
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        2.5
                                                    ],
                                                    [
                                                        -2.8,
                                                        0
                                                    ]
                                                ],
                                                "v": [
                                                    [
                                                        -5,
                                                        1.9
                                                    ],
                                                    [
                                                        0,
                                                        -6.5
                                                    ],
                                                    [
                                                        5,
                                                        1.9
                                                    ],
                                                    [
                                                        0,
                                                        6.5
                                                    ]
                                                ],
                                                "c": true
                                            }
                                        ],
                                        "e": [
                                            {
                                                "i": [
                                                    [
                                                        0,
                                                        2.5
                                                    ],
                                                    [
                                                        -2.313,
                                                        0.938
                                                    ],
                                                    [
                                                        0,
                                                        -3.244
                                                    ],
                                                    [
                                                        2.8,
                                                        0
                                                    ]
                                                ],
                                                "o": [
                                                    [
                                                        0,
                                                        -4.556
                                                    ],
                                                    [
                                                        0.313,
                                                        3.313
                                                    ],
                                                    [
                                                        0,
                                                        2.5
                                                    ],
                                                    [
                                                        -2.8,
                                                        0
                                                    ]
                                                ],
                                                "v": [
                                                    [
                                                        -5,
                                                        1.9
                                                    ],
                                                    [
                                                        -0.375,
                                                        -6.5
                                                    ],
                                                    [
                                                        5,
                                                        1.9
                                                    ],
                                                    [
                                                        0,
                                                        6.5
                                                    ]
                                                ],
                                                "c": true
                                            }
                                        ]
                                    },
                                    {
                                        "i": {
                                            "x": 0.667,
                                            "y": 1
                                        },
                                        "o": {
                                            "x": 0.333,
                                            "y": 0
                                        },
                                        "t": 21,
                                        "s": [
                                            {
                                                "i": [
                                                    [
                                                        0,
                                                        2.5
                                                    ],
                                                    [
                                                        -2.313,
                                                        0.938
                                                    ],
                                                    [
                                                        0,
                                                        -3.244
                                                    ],
                                                    [
                                                        2.8,
                                                        0
                                                    ]
                                                ],
                                                "o": [
                                                    [
                                                        0,
                                                        -4.556
                                                    ],
                                                    [
                                                        0.313,
                                                        3.313
                                                    ],
                                                    [
                                                        0,
                                                        2.5
                                                    ],
                                                    [
                                                        -2.8,
                                                        0
                                                    ]
                                                ],
                                                "v": [
                                                    [
                                                        -5,
                                                        1.9
                                                    ],
                                                    [
                                                        -0.375,
                                                        -6.5
                                                    ],
                                                    [
                                                        5,
                                                        1.9
                                                    ],
                                                    [
                                                        0,
                                                        6.5
                                                    ]
                                                ],
                                                "c": true
                                            }
                                        ],
                                        "e": [
                                            {
                                                "i": [
                                                    [
                                                        0,
                                                        2.5
                                                    ],
                                                    [
                                                        0,
                                                        3
                                                    ],
                                                    [
                                                        0,
                                                        -4.6
                                                    ],
                                                    [
                                                        2.8,
                                                        0
                                                    ]
                                                ],
                                                "o": [
                                                    [
                                                        0,
                                                        -3.4
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        2.5
                                                    ],
                                                    [
                                                        -2.8,
                                                        0
                                                    ]
                                                ],
                                                "v": [
                                                    [
                                                        -5,
                                                        1.9
                                                    ],
                                                    [
                                                        0,
                                                        -6.5
                                                    ],
                                                    [
                                                        5,
                                                        1.9
                                                    ],
                                                    [
                                                        0,
                                                        6.5
                                                    ]
                                                ],
                                                "c": true
                                            }
                                        ]
                                    },
                                    {
                                        "t": 28
                                    }
                                ],
                                "ix": 2
                            },
                            "nm": "Path 1",
                            "mn": "ADBE Vector Shape - Group",
                            "hd": false
                        },
                        {
                            "ty": "fl",
                            "c": {
                                "a": 0,
                                "k": [
                                    1,
                                    0.75686275959,
                                    0.027450980619,
                                    1
                                ],
                                "ix": 4
                            },
                            "o": {
                                "a": 0,
                                "k": 100,
                                "ix": 5
                            },
                            "r": 1,
                            "bm": 0,
                            "nm": "Fill 1",
                            "mn": "ADBE Vector Graphic - Fill",
                            "hd": false
                        },
                        {
                            "ty": "tr",
                            "p": {
                                "a": 0,
                                "k": [
                                    0,
                                    0
                                ],
                                "ix": 2
                            },
                            "a": {
                                "a": 0,
                                "k": [
                                    0,
                                    0
                                ],
                                "ix": 1
                            },
                            "s": {
                                "a": 0,
                                "k": [
                                    100,
                                    100
                                ],
                                "ix": 3
                            },
                            "r": {
                                "a": 0,
                                "k": 0,
                                "ix": 6
                            },
                            "o": {
                                "a": 0,
                                "k": 100,
                                "ix": 7
                            },
                            "sk": {
                                "a": 0,
                                "k": 0,
                                "ix": 4
                            },
                            "sa": {
                                "a": 0,
                                "k": 0,
                                "ix": 5
                            },
                            "nm": "Transform"
                        }
                    ],
                    "nm": "Group 1",
                    "np": 2,
                    "cix": 2,
                    "bm": 0,
                    "ix": 2,
                    "mn": "ADBE Vector Group",
                    "hd": false
                }
            ],
            "ip": 0,
            "op": 28,
            "st": 0,
            "bm": 0
        },
        {
            "ddd": 0,
            "ind": 2,
            "ty": 4,
            "nm": "Orange",
            "sr": 1,
            "ks": {
                "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 11
                },
                "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 10
                },
                "p": {
                    "a": 0,
                    "k": [
                        24,
                        29,
                        0
                    ],
                    "ix": 2
                },
                "a": {
                    "a": 0,
                    "k": [
                        0,
                        0,
                        0
                    ],
                    "ix": 1
                },
                "s": {
                    "a": 0,
                    "k": [
                        100,
                        100,
                        100
                    ],
                    "ix": 6
                }
            },
            "ao": 0,
            "shapes": [
                {
                    "ty": "gr",
                    "it": [
                        {
                            "ind": 0,
                            "ty": "sh",
                            "ix": 1,
                            "ks": {
                                "a": 1,
                                "k": [
                                    {
                                        "i": {
                                            "x": 0.667,
                                            "y": 1
                                        },
                                        "o": {
                                            "x": 0.333,
                                            "y": 0
                                        },
                                        "t": 0,
                                        "s": [
                                            {
                                                "i": [
                                                    [
                                                        0,
                                                        5
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        -6.1
                                                    ],
                                                    [
                                                        -5,
                                                        0
                                                    ]
                                                ],
                                                "o": [
                                                    [
                                                        0,
                                                        -7.6
                                                    ],
                                                    [
                                                        0,
                                                        6.1
                                                    ],
                                                    [
                                                        0,
                                                        5
                                                    ],
                                                    [
                                                        5,
                                                        0
                                                    ]
                                                ],
                                                "v": [
                                                    [
                                                        9,
                                                        3
                                                    ],
                                                    [
                                                        0,
                                                        -12
                                                    ],
                                                    [
                                                        -9,
                                                        3
                                                    ],
                                                    [
                                                        0,
                                                        12
                                                    ]
                                                ],
                                                "c": true
                                            }
                                        ],
                                        "e": [
                                            {
                                                "i": [
                                                    [
                                                        0,
                                                        5
                                                    ],
                                                    [
                                                        -0.969,
                                                        5.156
                                                    ],
                                                    [
                                                        0,
                                                        -8.906
                                                    ],
                                                    [
                                                        -5,
                                                        0
                                                    ]
                                                ],
                                                "o": [
                                                    [
                                                        0,
                                                        -5.625
                                                    ],
                                                    [
                                                        -2.25,
                                                        1.406
                                                    ],
                                                    [
                                                        0,
                                                        5
                                                    ],
                                                    [
                                                        5,
                                                        0
                                                    ]
                                                ],
                                                "v": [
                                                    [
                                                        9,
                                                        3
                                                    ],
                                                    [
                                                        -0.688,
                                                        -12
                                                    ],
                                                    [
                                                        -9,
                                                        3
                                                    ],
                                                    [
                                                        0,
                                                        12
                                                    ]
                                                ],
                                                "c": true
                                            }
                                        ]
                                    },
                                    {
                                        "i": {
                                            "x": 0.667,
                                            "y": 1
                                        },
                                        "o": {
                                            "x": 0.333,
                                            "y": 0
                                        },
                                        "t": 7,
                                        "s": [
                                            {
                                                "i": [
                                                    [
                                                        0,
                                                        5
                                                    ],
                                                    [
                                                        -0.969,
                                                        5.156
                                                    ],
                                                    [
                                                        0,
                                                        -8.906
                                                    ],
                                                    [
                                                        -5,
                                                        0
                                                    ]
                                                ],
                                                "o": [
                                                    [
                                                        0,
                                                        -5.625
                                                    ],
                                                    [
                                                        -2.25,
                                                        1.406
                                                    ],
                                                    [
                                                        0,
                                                        5
                                                    ],
                                                    [
                                                        5,
                                                        0
                                                    ]
                                                ],
                                                "v": [
                                                    [
                                                        9,
                                                        3
                                                    ],
                                                    [
                                                        -0.688,
                                                        -12
                                                    ],
                                                    [
                                                        -9,
                                                        3
                                                    ],
                                                    [
                                                        0,
                                                        12
                                                    ]
                                                ],
                                                "c": true
                                            }
                                        ],
                                        "e": [
                                            {
                                                "i": [
                                                    [
                                                        0,
                                                        5
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        -6.1
                                                    ],
                                                    [
                                                        -5,
                                                        0
                                                    ]
                                                ],
                                                "o": [
                                                    [
                                                        0,
                                                        -7.6
                                                    ],
                                                    [
                                                        0,
                                                        6.1
                                                    ],
                                                    [
                                                        0,
                                                        5
                                                    ],
                                                    [
                                                        5,
                                                        0
                                                    ]
                                                ],
                                                "v": [
                                                    [
                                                        9,
                                                        3
                                                    ],
                                                    [
                                                        0,
                                                        -12
                                                    ],
                                                    [
                                                        -9,
                                                        3
                                                    ],
                                                    [
                                                        0,
                                                        12
                                                    ]
                                                ],
                                                "c": true
                                            }
                                        ]
                                    },
                                    {
                                        "i": {
                                            "x": 0.667,
                                            "y": 1
                                        },
                                        "o": {
                                            "x": 0.333,
                                            "y": 0
                                        },
                                        "t": 14,
                                        "s": [
                                            {
                                                "i": [
                                                    [
                                                        0,
                                                        5
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        -6.1
                                                    ],
                                                    [
                                                        -5,
                                                        0
                                                    ]
                                                ],
                                                "o": [
                                                    [
                                                        0,
                                                        -7.6
                                                    ],
                                                    [
                                                        0,
                                                        6.1
                                                    ],
                                                    [
                                                        0,
                                                        5
                                                    ],
                                                    [
                                                        5,
                                                        0
                                                    ]
                                                ],
                                                "v": [
                                                    [
                                                        9,
                                                        3
                                                    ],
                                                    [
                                                        0,
                                                        -12
                                                    ],
                                                    [
                                                        -9,
                                                        3
                                                    ],
                                                    [
                                                        0,
                                                        12
                                                    ]
                                                ],
                                                "c": true
                                            }
                                        ],
                                        "e": [
                                            {
                                                "i": [
                                                    [
                                                        0,
                                                        5
                                                    ],
                                                    [
                                                        -0.969,
                                                        5.156
                                                    ],
                                                    [
                                                        0,
                                                        -8.906
                                                    ],
                                                    [
                                                        -5,
                                                        0
                                                    ]
                                                ],
                                                "o": [
                                                    [
                                                        0,
                                                        -5.625
                                                    ],
                                                    [
                                                        -2.25,
                                                        1.406
                                                    ],
                                                    [
                                                        0,
                                                        5
                                                    ],
                                                    [
                                                        5,
                                                        0
                                                    ]
                                                ],
                                                "v": [
                                                    [
                                                        9,
                                                        3
                                                    ],
                                                    [
                                                        -0.688,
                                                        -12
                                                    ],
                                                    [
                                                        -9,
                                                        3
                                                    ],
                                                    [
                                                        0,
                                                        12
                                                    ]
                                                ],
                                                "c": true
                                            }
                                        ]
                                    },
                                    {
                                        "i": {
                                            "x": 0.667,
                                            "y": 1
                                        },
                                        "o": {
                                            "x": 0.333,
                                            "y": 0
                                        },
                                        "t": 21,
                                        "s": [
                                            {
                                                "i": [
                                                    [
                                                        0,
                                                        5
                                                    ],
                                                    [
                                                        -0.969,
                                                        5.156
                                                    ],
                                                    [
                                                        0,
                                                        -8.906
                                                    ],
                                                    [
                                                        -5,
                                                        0
                                                    ]
                                                ],
                                                "o": [
                                                    [
                                                        0,
                                                        -5.625
                                                    ],
                                                    [
                                                        -2.25,
                                                        1.406
                                                    ],
                                                    [
                                                        0,
                                                        5
                                                    ],
                                                    [
                                                        5,
                                                        0
                                                    ]
                                                ],
                                                "v": [
                                                    [
                                                        9,
                                                        3
                                                    ],
                                                    [
                                                        -0.688,
                                                        -12
                                                    ],
                                                    [
                                                        -9,
                                                        3
                                                    ],
                                                    [
                                                        0,
                                                        12
                                                    ]
                                                ],
                                                "c": true
                                            }
                                        ],
                                        "e": [
                                            {
                                                "i": [
                                                    [
                                                        0,
                                                        5
                                                    ],
                                                    [
                                                        0,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        -6.1
                                                    ],
                                                    [
                                                        -5,
                                                        0
                                                    ]
                                                ],
                                                "o": [
                                                    [
                                                        0,
                                                        -7.6
                                                    ],
                                                    [
                                                        0,
                                                        6.1
                                                    ],
                                                    [
                                                        0,
                                                        5
                                                    ],
                                                    [
                                                        5,
                                                        0
                                                    ]
                                                ],
                                                "v": [
                                                    [
                                                        9,
                                                        3
                                                    ],
                                                    [
                                                        0,
                                                        -12
                                                    ],
                                                    [
                                                        -9,
                                                        3
                                                    ],
                                                    [
                                                        0,
                                                        12
                                                    ]
                                                ],
                                                "c": true
                                            }
                                        ]
                                    },
                                    {
                                        "t": 28
                                    }
                                ],
                                "ix": 2
                            },
                            "nm": "Path 1",
                            "mn": "ADBE Vector Shape - Group",
                            "hd": false
                        },
                        {
                            "ty": "fl",
                            "c": {
                                "a": 0,
                                "k": [
                                    1,
                                    0.341176480055,
                                    0.133333340287,
                                    1
                                ],
                                "ix": 4
                            },
                            "o": {
                                "a": 0,
                                "k": 100,
                                "ix": 5
                            },
                            "r": 1,
                            "bm": 0,
                            "nm": "Fill 1",
                            "mn": "ADBE Vector Graphic - Fill",
                            "hd": false
                        },
                        {
                            "ty": "tr",
                            "p": {
                                "a": 0,
                                "k": [
                                    0,
                                    0
                                ],
                                "ix": 2
                            },
                            "a": {
                                "a": 0,
                                "k": [
                                    0,
                                    0
                                ],
                                "ix": 1
                            },
                            "s": {
                                "a": 0,
                                "k": [
                                    100,
                                    100
                                ],
                                "ix": 3
                            },
                            "r": {
                                "a": 0,
                                "k": 0,
                                "ix": 6
                            },
                            "o": {
                                "a": 0,
                                "k": 100,
                                "ix": 7
                            },
                            "sk": {
                                "a": 0,
                                "k": 0,
                                "ix": 4
                            },
                            "sa": {
                                "a": 0,
                                "k": 0,
                                "ix": 5
                            },
                            "nm": "Transform"
                        }
                    ],
                    "nm": "Group 1",
                    "np": 2,
                    "cix": 2,
                    "bm": 0,
                    "ix": 1,
                    "mn": "ADBE Vector Group",
                    "hd": false
                }
            ],
            "ip": 0,
            "op": 28,
            "st": 0,
            "bm": 0
        },
        {
            "ddd": 0,
            "ind": 3,
            "ty": 4,
            "nm": "Red",
            "sr": 1,
            "ks": {
                "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 11
                },
                "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 10
                },
                "p": {
                    "a": 0,
                    "k": [
                        24,
                        24,
                        0
                    ],
                    "ix": 2
                },
                "a": {
                    "a": 0,
                    "k": [
                        0,
                        0,
                        0
                    ],
                    "ix": 1
                },
                "s": {
                    "a": 0,
                    "k": [
                        100,
                        100,
                        100
                    ],
                    "ix": 6
                }
            },
            "ao": 0,
            "shapes": [
                {
                    "ty": "gr",
                    "it": [
                        {
                            "ind": 0,
                            "ty": "sh",
                            "ix": 1,
                            "ks": {
                                "a": 1,
                                "k": [
                                    {
                                        "i": {
                                            "x": 0.667,
                                            "y": 1
                                        },
                                        "o": {
                                            "x": 0.333,
                                            "y": 0
                                        },
                                        "t": 0,
                                        "s": [
                                            {
                                                "i": [
                                                    [
                                                        0,
                                                        -12.2
                                                    ],
                                                    [
                                                        8.4,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        8.4
                                                    ],
                                                    [
                                                        1.5,
                                                        7.6
                                                    ]
                                                ],
                                                "o": [
                                                    [
                                                        0,
                                                        8.4
                                                    ],
                                                    [
                                                        -8.4,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        -8.4
                                                    ],
                                                    [
                                                        3,
                                                        0
                                                    ]
                                                ],
                                                "v": [
                                                    [
                                                        15,
                                                        4
                                                    ],
                                                    [
                                                        0,
                                                        19
                                                    ],
                                                    [
                                                        -15,
                                                        4
                                                    ],
                                                    [
                                                        -3,
                                                        -19
                                                    ]
                                                ],
                                                "c": true
                                            }
                                        ],
                                        "e": [
                                            {
                                                "i": [
                                                    [
                                                        0,
                                                        -9.5
                                                    ],
                                                    [
                                                        8.4,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        8.4
                                                    ],
                                                    [
                                                        -2.375,
                                                        0
                                                    ]
                                                ],
                                                "o": [
                                                    [
                                                        0,
                                                        8.4
                                                    ],
                                                    [
                                                        -8.4,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        -14.875
                                                    ],
                                                    [
                                                        -1.25,
                                                        11.625
                                                    ]
                                                ],
                                                "v": [
                                                    [
                                                        15,
                                                        4
                                                    ],
                                                    [
                                                        0,
                                                        19
                                                    ],
                                                    [
                                                        -15,
                                                        4
                                                    ],
                                                    [
                                                        2.75,
                                                        -19
                                                    ]
                                                ],
                                                "c": true
                                            }
                                        ]
                                    },
                                    {
                                        "i": {
                                            "x": 0.667,
                                            "y": 1
                                        },
                                        "o": {
                                            "x": 0.333,
                                            "y": 0
                                        },
                                        "t": 7,
                                        "s": [
                                            {
                                                "i": [
                                                    [
                                                        0,
                                                        -9.5
                                                    ],
                                                    [
                                                        8.4,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        8.4
                                                    ],
                                                    [
                                                        -2.375,
                                                        0
                                                    ]
                                                ],
                                                "o": [
                                                    [
                                                        0,
                                                        8.4
                                                    ],
                                                    [
                                                        -8.4,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        -14.875
                                                    ],
                                                    [
                                                        -1.25,
                                                        11.625
                                                    ]
                                                ],
                                                "v": [
                                                    [
                                                        15,
                                                        4
                                                    ],
                                                    [
                                                        0,
                                                        19
                                                    ],
                                                    [
                                                        -15,
                                                        4
                                                    ],
                                                    [
                                                        2.75,
                                                        -19
                                                    ]
                                                ],
                                                "c": true
                                            }
                                        ],
                                        "e": [
                                            {
                                                "i": [
                                                    [
                                                        0,
                                                        -12.2
                                                    ],
                                                    [
                                                        8.4,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        8.4
                                                    ],
                                                    [
                                                        1.5,
                                                        7.6
                                                    ]
                                                ],
                                                "o": [
                                                    [
                                                        0,
                                                        8.4
                                                    ],
                                                    [
                                                        -8.4,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        -8.4
                                                    ],
                                                    [
                                                        3,
                                                        0
                                                    ]
                                                ],
                                                "v": [
                                                    [
                                                        15,
                                                        4
                                                    ],
                                                    [
                                                        0,
                                                        19
                                                    ],
                                                    [
                                                        -15,
                                                        4
                                                    ],
                                                    [
                                                        -3,
                                                        -19
                                                    ]
                                                ],
                                                "c": true
                                            }
                                        ]
                                    },
                                    {
                                        "i": {
                                            "x": 0.667,
                                            "y": 1
                                        },
                                        "o": {
                                            "x": 0.333,
                                            "y": 0
                                        },
                                        "t": 14,
                                        "s": [
                                            {
                                                "i": [
                                                    [
                                                        0,
                                                        -12.2
                                                    ],
                                                    [
                                                        8.4,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        8.4
                                                    ],
                                                    [
                                                        1.5,
                                                        7.6
                                                    ]
                                                ],
                                                "o": [
                                                    [
                                                        0,
                                                        8.4
                                                    ],
                                                    [
                                                        -8.4,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        -8.4
                                                    ],
                                                    [
                                                        3,
                                                        0
                                                    ]
                                                ],
                                                "v": [
                                                    [
                                                        15,
                                                        4
                                                    ],
                                                    [
                                                        0,
                                                        19
                                                    ],
                                                    [
                                                        -15,
                                                        4
                                                    ],
                                                    [
                                                        -3,
                                                        -19
                                                    ]
                                                ],
                                                "c": true
                                            }
                                        ],
                                        "e": [
                                            {
                                                "i": [
                                                    [
                                                        0,
                                                        -9.5
                                                    ],
                                                    [
                                                        8.4,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        8.4
                                                    ],
                                                    [
                                                        -2.375,
                                                        0
                                                    ]
                                                ],
                                                "o": [
                                                    [
                                                        0,
                                                        8.4
                                                    ],
                                                    [
                                                        -8.4,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        -14.875
                                                    ],
                                                    [
                                                        -1.25,
                                                        11.625
                                                    ]
                                                ],
                                                "v": [
                                                    [
                                                        15,
                                                        4
                                                    ],
                                                    [
                                                        0,
                                                        19
                                                    ],
                                                    [
                                                        -15,
                                                        4
                                                    ],
                                                    [
                                                        2.75,
                                                        -19
                                                    ]
                                                ],
                                                "c": true
                                            }
                                        ]
                                    },
                                    {
                                        "i": {
                                            "x": 0.667,
                                            "y": 1
                                        },
                                        "o": {
                                            "x": 0.333,
                                            "y": 0
                                        },
                                        "t": 21,
                                        "s": [
                                            {
                                                "i": [
                                                    [
                                                        0,
                                                        -9.5
                                                    ],
                                                    [
                                                        8.4,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        8.4
                                                    ],
                                                    [
                                                        -2.375,
                                                        0
                                                    ]
                                                ],
                                                "o": [
                                                    [
                                                        0,
                                                        8.4
                                                    ],
                                                    [
                                                        -8.4,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        -14.875
                                                    ],
                                                    [
                                                        -1.25,
                                                        11.625
                                                    ]
                                                ],
                                                "v": [
                                                    [
                                                        15,
                                                        4
                                                    ],
                                                    [
                                                        0,
                                                        19
                                                    ],
                                                    [
                                                        -15,
                                                        4
                                                    ],
                                                    [
                                                        2.75,
                                                        -19
                                                    ]
                                                ],
                                                "c": true
                                            }
                                        ],
                                        "e": [
                                            {
                                                "i": [
                                                    [
                                                        0,
                                                        -12.2
                                                    ],
                                                    [
                                                        8.4,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        8.4
                                                    ],
                                                    [
                                                        1.5,
                                                        7.6
                                                    ]
                                                ],
                                                "o": [
                                                    [
                                                        0,
                                                        8.4
                                                    ],
                                                    [
                                                        -8.4,
                                                        0
                                                    ],
                                                    [
                                                        0,
                                                        -8.4
                                                    ],
                                                    [
                                                        3,
                                                        0
                                                    ]
                                                ],
                                                "v": [
                                                    [
                                                        15,
                                                        4
                                                    ],
                                                    [
                                                        0,
                                                        19
                                                    ],
                                                    [
                                                        -15,
                                                        4
                                                    ],
                                                    [
                                                        -3,
                                                        -19
                                                    ]
                                                ],
                                                "c": true
                                            }
                                        ]
                                    },
                                    {
                                        "t": 28
                                    }
                                ],
                                "ix": 2
                            },
                            "nm": "Path 1",
                            "mn": "ADBE Vector Shape - Group",
                            "hd": false
                        },
                        {
                            "ty": "fl",
                            "c": {
                                "a": 0,
                                "k": [
                                    0.866666674614,
                                    0.172549024224,
                                    0,
                                    1
                                ],
                                "ix": 4
                            },
                            "o": {
                                "a": 0,
                                "k": 100,
                                "ix": 5
                            },
                            "r": 1,
                            "bm": 0,
                            "nm": "Fill 1",
                            "mn": "ADBE Vector Graphic - Fill",
                            "hd": false
                        },
                        {
                            "ty": "tr",
                            "p": {
                                "a": 0,
                                "k": [
                                    0,
                                    0
                                ],
                                "ix": 2
                            },
                            "a": {
                                "a": 0,
                                "k": [
                                    0,
                                    0
                                ],
                                "ix": 1
                            },
                            "s": {
                                "a": 0,
                                "k": [
                                    100,
                                    100
                                ],
                                "ix": 3
                            },
                            "r": {
                                "a": 0,
                                "k": 0,
                                "ix": 6
                            },
                            "o": {
                                "a": 0,
                                "k": 100,
                                "ix": 7
                            },
                            "sk": {
                                "a": 0,
                                "k": 0,
                                "ix": 4
                            },
                            "sa": {
                                "a": 0,
                                "k": 0,
                                "ix": 5
                            },
                            "nm": "Transform"
                        }
                    ],
                    "nm": "Group 1",
                    "np": 2,
                    "cix": 2,
                    "bm": 0,
                    "ix": 1,
                    "mn": "ADBE Vector Group",
                    "hd": false
                }
            ],
            "ip": 0,
            "op": 28,
            "st": 0,
            "bm": 0
        }
    ],
    "markers": []
}
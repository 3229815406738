import { Editor } from "@monaco-editor/react"
import { useContext, useMemo, useState } from "react"
import CodeEditor from "../../../components/CodeEditor"
import { AppContext } from "../../../App"
import { useAsyncEffect } from "ahooks"
import { request } from "../../../utils"
import { Input, Tabs } from "@douyinfe/semi-ui"
import FlexContainer from "../../../components/FlexContainer"
import './index.less'
import { IconSearch } from "@douyinfe/semi-icons"

const DatabaseSchema = () => {
    const [schema, setSchema] = useState('')
    const [loading, setLoading] = useState(true)
    const [keyword, setKeyword] = useState('')
    const [activeTab, setActiveTab] = useState('')

    const { windowHeight, navbarHeight } = useContext(AppContext)

    useAsyncEffect(async () => {
        const respData = await request({
            data: {
                service: 'rds.schema'
            }
        })

        setSchema(respData?.schema || '')
        setLoading(false)
    }, [])

    const tableList = useMemo(() => {
        const arr = schema.split('Table structure for').slice(1).map(item => item.trim())

        const tableStructureList = arr.map(item => {
            const lines = item.split('\n')

            const startIdx = lines.findIndex(item => item.includes('CREATE'))
            const endIdx = lines.findIndex(item => item.includes('ENGINE'))

            return {
                tableName: lines[0],
                schema: lines.slice(startIdx, endIdx+1).join('\n')
            }
        })

        setActiveTab(tableStructureList?.[0]?.tableName)
        return tableStructureList
    }, [schema])

    return (
        <div className='database-schema-wrapper' style={{ width: '100%' }}>
            <div style={{ fontSize: 20, letterSpacing: -0.5, padding: '8px 16px' }}>
                Database Schema
            </div>

            <FlexContainer style={{ width: '100%' }}>
                <div style={{ padding: '0 16px', width: 220 }}>
                    <Input prefix={<IconSearch />} placeholder="Search for table" style={{ marginBottom: 6 }} onChange={setKeyword} />
                    <Tabs
                        activeKey={activeTab}
                        onChange={setActiveTab}
                        type="button"
                        tabPosition="left"
                        tabBarStyle={{ padding: 0 }}
                        contentStyle={{ padding: 0 }}
                        style={{ height: windowHeight - navbarHeight - 46 - 36, overflow: 'auto' }}
                        tabList={tableList.filter(tab => !keyword || tab.tableName.includes(keyword.toLowerCase())).map(item => {
                            return {
                                tab: <span style={{ paddingLeft: 8, paddingRight: 8, fontSize: 12 }}>{item.tableName}</span>,
                                itemKey: item.tableName,
                            }
                        })}
                    />
                </div>
                <CodeEditor 
                    loading={loading}
                    language="mysql"
                    width="100%"
                    height={windowHeight - navbarHeight - 46}
                    value={tableList.find(table => table.tableName === activeTab)?.schema || ''}
                />
            </FlexContainer>
        </div>
    )
}

export default DatabaseSchema
import type { CSSProperties } from 'react';
import React from 'react';

interface FlexCompProps {
  justifyContent?: string;
  flex?: string;
  alignItems?: string;
  alignContent?: string;
  gap?: string;
  flexDirection?: 'row' | 'row-reverse' | 'column' | 'column-reverse';
  alignSelf?: string;
  flexBasis?: string;
  flexWrap?: any;

  className?: string;
  children: any;
  style?: CSSProperties;
  [handler: string]: any;
}

const flexStyleKeys = [
  'justifyContent',
  'flex',
  'alignItems',
  'alignContent',
  'gap',
  'flexDirection',
  'alignSelf',
  'flexBasis',
  'flexWrap',
];

const FlexContainer = ({ className, children, style, ...props }: FlexCompProps) => {
  const flexStyle: any = {};
  const otherProps: any = {};

  Object.entries(props).forEach(([k, v]) => {
    if (flexStyleKeys.includes(k)) {
      flexStyle[k] = v;
    } else {
      otherProps[k] = v;
    }
  });

  return (
    <div
      className={className}
      {...otherProps}
      style={{
        display: 'flex',
        ...flexStyle,
        ...style,
      }}
    >
      {children}
    </div>
  );
};

export default FlexContainer
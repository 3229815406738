import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import Map, { FullscreenControl, GeolocateControl, Layer, MapRef, Marker, NavigationControl, Popup, ScaleControl, Source } from 'react-map-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import { IAirport, IRewardSeatSummary } from '../../../../typings';
import { RewardFlightContext } from '../..';
import './index.less'
import { AppContext } from '../../../../App';
import * as turf from '@turf/turf'; // Import Turf.js for geospatial calculations
import { Button, Checkbox, Divider, Notification, SideSheet, Tag, Toast, Tooltip } from '@douyinfe/semi-ui';
import FlexContainer from '../../../../components/FlexContainer';
import { IconArrowRight, IconChevronRight, IconClose, IconCoinMoneyStroked, IconCrossStroked, IconExternalOpen } from '@douyinfe/semi-icons';
import AirportNameRender from '../AirportNameRender';
import { calculateBounds, calculateSteps, GeoJSONPoint } from '../../../RewardFlightsDetail/components/FlightRouteRender';
import { formatNumber, generateColorGradient } from '../../../../utils';
import { DescriptionItem } from '../../../RewardFlightsDetail';
import { calcHaversineDistance, estimateFlightDuration, estimateTimezoneDifference } from '../../../RewardFlightsDetail/utils';
import LazyMap from '../../../../components/LazyMap';
import AirportRoutePanel from '../AirportRoutePanel'
import classNames from 'classnames';

export const MAPBOX_TOKEN = 'pk.eyJ1IjoienM1MjAiLCJhIjoiY2xsd2J4a2s5MHNxcjNqb2JuODl2YWwzciJ9.tJUFy6j43vQyKm8QpiDPlw';

export const adjustForIDL = (coords: [number, number][]) => {
    const adjustedCoords: [number, number][] = [];
    let prevLon = coords[0][0];

    for (const [lon, lat] of coords) {
        // Check if crossing the IDL
        if (Math.abs(lon - prevLon) > 180) {
            // Adjust for crossing IDL
            if (lon > prevLon) {
                adjustedCoords.push([lon - 360, lat]);
            } else {
                adjustedCoords.push([lon + 360, lat]);
            }
        } else {
            adjustedCoords.push([lon, lat]);
        }
        prevLon = lon;
    }
    return adjustedCoords;
};

function checkIsEast(longitude1: number, longitude2: number) {
    // Normalize the longitudes to the range [0, 360)
    const normLong1 = (longitude1 + 360) % 360;
    const normLong2 = (longitude2 + 360) % 360;

    // Check if longitude2 is east of longitude1
    return normLong2 > normLong1;
}

interface IAirportMapProps {
    departureAirport: string;
    airportList: IAirport[];
    viewState: any;
}

const AirportMap = ({ departureAirport, airportList, viewState }: IAirportMapProps) => {

    const { windowHeight, isDarkMode, isMobile } = useContext(AppContext)
    const { airportDict, cabin } = useContext(RewardFlightContext)

    const [hoveredDestiantionAirportInfo, setHoveredDestiantionAirportInfo] = useState<IAirport>()
    const [selectedDestiantionAirportInfo, setSelectedDestiantionAirportInfo] = useState<IAirport>()

    const [airportListInViewpoint, setAirportListInViewpoint] = useState<IAirport[]>([])

    const [mapSetting, setMapSetting] = useState({
        displayRouteConnection: true,
        displayCityName: true
    })

    const [pointData, setPointData] = useState<GeoJSONPoint>();
    const [colorVersion, setColorVersion] = useState(1)

    const [mobileSidesheetHeight, setMobileSidesheetHeight] = useState(300)

    const isAnimatingRef = useRef(false)
    const isNotifiedRef = useRef(false)
    const mapRef = useRef<MapRef>()
    const getColorFn = useRef<any>()

    const tableClickLoadingRef = useRef(false)
    const tableClickTimeoutRef = useRef<any>()

    let counter = 0
    let steps = 500

    const createRouteData = (airport1: IAirport, airport2: IAirport, overrideSteps = false) => {
        const startCoords: [number, number] = [parseFloat(airport1.longitude), parseFloat(airport1.latitude)];
        const endCoords: [number, number] = [parseFloat(airport2.longitude), parseFloat(airport2.latitude)];

        const lineDistance = turf.length({
            type: 'Feature',
            geometry: {
                type: 'LineString',
                coordinates: [startCoords, endCoords],
            },
        } as any);

        steps = calculateSteps(lineDistance);


        // Generate points along the great-circle path
        const arc = turf.greatCircle(startCoords, endCoords, { npoints: steps });

        const route: any = {
            type: 'FeatureCollection',
            features: arc.geometry.coordinates.length === 2 ? [
                {
                    type: 'Feature',
                    geometry: {
                        type: 'LineString',
                        coordinates: arc.geometry.coordinates[0],
                    },
                },
                {
                    type: 'Feature',
                    geometry: {
                        type: 'LineString',
                        coordinates: arc.geometry.coordinates[1],
                    },
                },
            ] : [
                {
                    type: 'Feature',
                    geometry: {
                        type: 'LineString',
                        coordinates: arc.geometry.coordinates,
                    },
                },
            ],
        };

        return route;
    }

    const hideRouteConnection = useMemo(() => {
        if (airportList.length > 100) {
            // if (!isNotifiedRef.current) {
            //     Notification.info({
            //         title: <span className="font-weight-bold">Reminder</span>,
            //         duration: 5,
            //         content: <span className="font-weight-bold">Route connection is hidden to optimise web performance</span>,
            //         position: isMobile ? 'bottom' : 'bottomRight'
            //     })
            //     isNotifiedRef.current = true
            // }

            return true
        }

        setSelectedDestiantionAirportInfo(undefined)
        return false
    }, [airportList])

    // Function to animate the point
    const animatePoint = useCallback(() => {
        if (!pointData || !selectedDestiantionAirportInfo) return;
        const routeData = createRouteData(airportDict[departureAirport], selectedDestiantionAirportInfo, true)

        let counterOffset = 0
        let coordinatesIdx = 0

        if (counter >= routeData.features[0].geometry.coordinates.length) {
            coordinatesIdx = 1
            counterOffset = routeData.features[0].geometry.coordinates.length
        }

        const start = routeData.features[coordinatesIdx].geometry.coordinates[counter - counterOffset];
        const end = routeData.features[coordinatesIdx].geometry.coordinates[counter + 1 - counterOffset] || start;

        const bearing = turf.bearing(turf.point(start), turf.point(end));

        // Update point with new coordinates and bearing
        const newPointData = {
            ...pointData,
            features: [
                {
                    ...pointData.features[0],
                    geometry: {
                        ...pointData.features[0].geometry,
                        coordinates: start,
                    },
                    properties: {
                        bearing,
                    },
                },
            ],
        };

        setPointData(newPointData as any);

        if (counter + 1 >= steps) {
            counter = 0
        } else {
            counter += 1
        }

        requestAnimationFrame(animatePoint);
    }, [pointData, selectedDestiantionAirportInfo])


    useEffect(() => {
        isAnimatingRef.current = false
        if (selectedDestiantionAirportInfo) {
            setPointData({
                type: 'FeatureCollection',
                features: [
                    {
                        type: 'Feature',
                        geometry: {
                            type: 'Point',
                            coordinates: [parseFloat(selectedDestiantionAirportInfo.longitude), parseFloat(selectedDestiantionAirportInfo.latitude)],
                        },
                        properties: {},
                    },
                ],
            });
        }
    }, [selectedDestiantionAirportInfo])

    useEffect(() => {
        if (pointData && !isAnimatingRef.current) {
            animatePoint()
            isAnimatingRef.current = true
        }
    }, [pointData])

    useEffect(() => {
        const map = mapRef.current?.getMap()

        if (map) {
            const { longitude, latitude, zoom, bearing, pitch } = viewState

            map.flyTo({
                center: [longitude, latitude],
                zoom,
                bearing,
                pitch,
                speed: 2.5,
            })
        }
    }, [viewState])

    const handleOnMoveEnd = useCallback(() => {
        if (tableClickLoadingRef.current) {
            return
        }

        const map = mapRef.current?.getMap()
        const bounds = map?.getBounds()

        if (!map || !bounds) {
            return
        }

        const filteredAirportList = airportList.filter(airport => {
            return bounds.contains([parseFloat(`${airport.longitude}`), parseFloat(`${airport.latitude}`)])
        }).sort((a, b) => {
            if (cabin === 'ECO') {
                return (a.min_economy_fare_pts_price || 0) - (b.min_economy_fare_pts_price || 0)
            } else {
                return (a.min_business_fare_pts_price || 0) - (b.min_business_fare_pts_price || 0)
            }
        })

        setAirportListInViewpoint(filteredAirportList)
    }, [airportList, cabin])

    useEffect(() => {
        const priceList = airportListInViewpoint.map(airport => {
            if (cabin === 'ECO') {
                return airport.min_economy_fare_pts_price
            } else {
                return airport.min_business_fare_pts_price
            }
        }).filter(Boolean)

        if (priceList.length) {
            getColorFn.current = generateColorGradient(Math.min(...priceList as any), Math.max(...priceList as any) + 1, '#198754', '#fc8800')
        }

        setColorVersion(prev => prev + 1)
    }, [cabin, airportListInViewpoint])

    useEffect(() => {
        handleOnMoveEnd()
    }, [airportList])

    const handleCenter = useCallback((airport: IAirport) => {
        const map = mapRef.current?.getMap();
        if (!map) return;

        tableClickLoadingRef.current = true

        const originLatLng = [airportDict[departureAirport].longitude, airportDict[departureAirport].latitude].map(parseFloat)
        const destinationLatLng = [airport.longitude, airport.latitude].map(parseFloat)

        const distance = (calcHaversineDistance(airportDict[departureAirport], airport, true)) as number

        if (distance > 5000) {
            map.flyTo({
                center: [parseFloat(airport.longitude), parseFloat(airport.latitude)],
                zoom: 4,
                bearing: map.getBearing(),
                pitch: map.getPitch(),
                duration: 600
            })
        } else {
            if (isMobile) {
                const bounds = calculateBounds([originLatLng, destinationLatLng]) as any
                map.fitBounds(bounds, {
                    padding: {
                        top: 100,
                        bottom: 100 + mobileSidesheetHeight,
                        left: 100,
                        right: 100,
                    },
                    duration: 600
                })
            } else {
                const isNorth = parseFloat(airport.latitude) < parseFloat(airportDict[departureAirport].latitude)
                const isEast = checkIsEast(originLatLng[0], destinationLatLng[0])
    
                const bounds = calculateBounds([originLatLng, destinationLatLng]) as any
                map.fitBounds(bounds, {
                    padding: {
                        top: 100,
                        bottom: isNorth ? 300 : 100,
                        left: isEast ? 100 : 300,
                        right: isEast ? 400 : 100,
                    },
                    duration: 600
                })
            }
        }

        setSelectedDestiantionAirportInfo(airport)

        if (tableClickTimeoutRef.current) {
            clearTimeout(tableClickTimeoutRef.current)
            tableClickTimeoutRef.current = undefined
        }

        tableClickTimeoutRef.current = setTimeout(() => {
            tableClickLoadingRef.current = false
        }, 1000)
    }, [airportDict, isMobile, mobileSidesheetHeight])

    useEffect(() => {
        if (selectedDestiantionAirportInfo) {
            const el = document.querySelector(`#airport-marker-${selectedDestiantionAirportInfo.airport_code}`)

            if (el && el.parentElement) {
                el.parentElement.style.zIndex = '100'

                return () => {
                    if (el && el.parentElement) {
                        el.parentElement.style.zIndex = 'unset'
                    }
                }
            }
        }
    }, [selectedDestiantionAirportInfo])

    return (
        <div className="airport-map-wrapper" style={{ height: `calc(${windowHeight}px - 52px - 48px)`, position: 'relative', width: '100%' }}>
            <LazyMap
                onRef={mapRef}
                reuseMaps
                initialViewState={viewState}
                mapStyle={isDarkMode ? "mapbox://styles/mapbox/dark-v11" : "mapbox://styles/mapbox/light-v11"}
                mapboxAccessToken={MAPBOX_TOKEN}
                onMoveEnd={handleOnMoveEnd}
                onLoad={() => {
                    setTimeout(() => {
                        window.dispatchEvent(new Event('resize'))
                    }, 500)
                }}
            >
                {!!airportDict[departureAirport] && (
                    <Marker
                        className="airport-marker-departure"
                        longitude={parseFloat(airportDict[departureAirport].longitude)}
                        latitude={parseFloat(airportDict[departureAirport].latitude)}
                    >
                        <div style={{
                            position: 'relative',
                            cursor: 'pointer'
                        }}>
                            <Tooltip
                                position="right"
                                content={(
                                    <div className="font-weight-bold">{airportDict[departureAirport]?.city_name} ({airportDict[departureAirport]?.airport_code})</div>
                                )}
                            >
                                <div
                                    style={{
                                        background: isDarkMode ? 'rgb(84,169,255)' : 'rgb(0,100,250)',
                                        border: '3px solid #FFFFFF',
                                        borderRadius: 32,
                                        width: 12,
                                        height: 12,
                                        position: 'relative',
                                    }}
                                />
                            </Tooltip>

                            <div className="dot" />
                        </div>
                    </Marker>
                )}

                {airportList.map((airport, idx) => {
                    const destinationAirportInfo = airportDict[airport.airport_code]
                    const departureAirportInfo = airportDict[departureAirport]

                    if (!destinationAirportInfo.longitude || !departureAirportInfo.longitude) {
                        return <></>
                    }

                    const isHovered = destinationAirportInfo.airport_code === hoveredDestiantionAirportInfo?.airport_code
                    const isSelected = destinationAirportInfo.airport_code === selectedDestiantionAirportInfo?.airport_code

                    let price: any = cabin === 'ECO'
                        ? airport.min_economy_fare_pts_price
                        : airport.min_business_fare_pts_price

                    return (
                        <>
                            <Marker
                                longitude={parseFloat(destinationAirportInfo.longitude)}
                                latitude={parseFloat(destinationAirportInfo.latitude)}
                            >
                                <FlexContainer
                                    id={`airport-marker-${destinationAirportInfo.airport_code}`}
                                    style={{ position: 'relative' }}
                                    onMouseEnter={(e: any) => {
                                        e.stopPropagation()
                                        setHoveredDestiantionAirportInfo(destinationAirportInfo)
                                    }}
                                    onMouseLeave={(e: any) => {
                                        e.stopPropagation()
                                        setHoveredDestiantionAirportInfo(undefined)
                                    }}
                                >
                                    <div
                                        key={colorVersion}
                                        className="destination-airport-node-wrapper"
                                        // justifyContent='center'
                                        // alignItems='center'
                                        style={{
                                            background: !price ? 'rgb(250,102,76)' : getColorFn.current?.(price),
                                            border: '2px solid #FFFFFF',
                                            borderRadius: 32,
                                            width: 12,
                                            height: 12,
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',

                                            ...isSelected || isHovered ? {
                                                transform: 'scale(1.4)'
                                            } : {}
                                        }}
                                        onClick={(e: any) => {
                                            e.stopPropagation()
                                            if (selectedDestiantionAirportInfo && selectedDestiantionAirportInfo.airport_code === destinationAirportInfo.airport_code) {
                                                setSelectedDestiantionAirportInfo(undefined)
                                            } else {
                                                setSelectedDestiantionAirportInfo(destinationAirportInfo)
                                            }
                                        }}
                                    >
                                        {isSelected && (
                                            <IconClose style={{ fontSize: 8, color: 'white' }} />
                                        )}
                                    </div>

                                    {mapSetting.displayCityName && (!isSelected || isMobile) && (
                                        <div
                                            className="font-weight-bold responsive-background-secondary"
                                            style={{
                                                position: 'absolute',
                                                background: 'white',
                                                padding: '0px 6px',
                                                borderRadius: 4,
                                                top: -4,
                                                left: 20,
                                                whiteSpace: 'nowrap',
                                                border: '1px solid var(--semi-color-border)'
                                            }}
                                        >
                                            <div>
                                                {airport.city_name}
                                            </div>
                                            <div style={{ marginTop: -6, color: 'grey', fontSize: 10 }}>
                                                {price ? formatNumber(price) : '-'}
                                            </div>
                                        </div>
                                    )}


                                    {isSelected && !isMobile && (
                                        <FlexContainer
                                            flexDirection='column'
                                            gap="8px"
                                            className={classNames({
                                                "reward-flight-info-card-wrapper": true,
                                                "reward-flight-info-card-wrapper__left": !checkIsEast(parseFloat(departureAirportInfo.longitude), parseFloat(destinationAirportInfo.longitude)),
                                                "responsive-background-secondary": true,
                                                "responsive-text": true,
                                                "font-weight-bold": true
                                            })}
                                            style={{
                                                border: `1px solid ${getColorFn.current?.(price)}`,
                                                boxShadow: 'var(--semi-shadow-elevated)'
                                            }}
                                        >
                                            <FlexContainer justifyContent='space-between' alignItems='center'>
                                                <FlexContainer alignItems='center' style={{ fontWeight: 700, fontSize: 16, letterSpacing: -0.5 }} gap="4px">
                                                    <img width="20" height="20" src={`https://img.icons8.com/ios-filled/50/${isDarkMode ? "FFFFFF" : "000000"}/airplane-take-off.png`} alt="airplane-take-off" />
                                                    Reward Flights
                                                </FlexContainer>
                                            </FlexContainer>

                                            <FlexContainer alignItems='center' gap="4px" style={{ fontWeight: 700, fontSize: 16, whiteSpace: 'nowrap' }}>
                                                <AirportNameRender airport={departureAirportInfo} hideAirportCode />

                                                <IconArrowRight style={{ fontSize: 14 }} />

                                                <AirportNameRender airport={destinationAirportInfo} hideAirportCode />
                                            </FlexContainer>

                                            <FlexContainer flexDirection='column' gap="8px">

                                                <DescriptionItem label="Distance*" value={calcHaversineDistance(departureAirportInfo, destinationAirportInfo)} />
                                                <DescriptionItem label="Duration*" value={estimateFlightDuration(departureAirportInfo, destinationAirportInfo)} />
                                                <DescriptionItem
                                                    label="Timezone difference*"
                                                    value={(
                                                        <FlexContainer alignItems="center" gap="6px">
                                                            <span>{estimateTimezoneDifference(departureAirportInfo, destinationAirportInfo)}</span>
                                                            <IconExternalOpen style={{ cursor: 'pointer' }} onClick={() => {
                                                                window.open(`https://www.google.com/search?btnI=1&q=timezone difference between ${departureAirportInfo?.city_name} and ${destinationAirportInfo?.city_name}`)
                                                            }} />
                                                        </FlexContainer>
                                                    )}
                                                    hidden={estimateTimezoneDifference(departureAirportInfo, destinationAirportInfo, true) === 0}
                                                />

                                                <div style={{ width: '100%' }}>
                                                    <Button
                                                        theme="solid"
                                                        block
                                                        style={{ height: 36, background: getColorFn.current?.(price) }}
                                                        onClick={() => {
                                                            window.open(`/reward-flights/${departureAirportInfo.airport_code}/${destinationAirportInfo.airport_code}`)
                                                        }}
                                                    >
                                                        {cabin === 'ECO' ? 'Economy' : 'Business'} from {formatNumber(price)} PTS
                                                    </Button>
                                                </div>
                                            </FlexContainer>
                                        </FlexContainer>
                                    )}
                                </FlexContainer>
                            </Marker>

                            {((!hideRouteConnection && mapSetting.displayRouteConnection) || isSelected) && (
                                <Source type="geojson" data={createRouteData(departureAirportInfo, destinationAirportInfo)}>
                                    <Layer
                                        type="line"
                                        paint={{
                                            'line-width': 1,
                                            'line-color': '#d995ac',

                                            ...isHovered ? {
                                                'line-width': 3,
                                                'line-color': 'red',
                                            } : {},

                                            ...isSelected ? {
                                                'line-width': 5,
                                                'line-color': isDarkMode ? 'rgb(84,169,255)' : 'rgb(0,100,250)',
                                            } : {}
                                        }}
                                    />
                                </Source>
                            )}
                        </>

                    )
                })}

                {!!selectedDestiantionAirportInfo && pointData && (
                    <Source id="point" type="geojson" data={pointData}>
                        <Layer
                            id="point"
                            type="symbol"
                            layout={{
                                'icon-image': 'airport', // Use an icon available in Mapbox or add a custom one
                                'icon-size': 1.5,
                                'icon-rotate': ['get', 'bearing'],
                                'icon-rotation-alignment': 'map',
                                'icon-allow-overlap': true,
                                'icon-ignore-placement': true,
                            }}
                        />
                    </Source>
                )}
            </LazyMap>

            <AirportRoutePanel airportList={airportListInViewpoint} onCenter={handleCenter} onHeightChange={setMobileSidesheetHeight} />

            {!isMobile && (
                <div
                    style={{
                        position: 'absolute',
                        bottom: 34,
                        left: 8,
                        zIndex: 4,
                        borderRadius: 8,
                        border: '1px solid var(--semi-color-border)',
                        WebkitBackdropFilter: 'blur(10px)',
                        backdropFilter: "blur(10px)",
                        background: 'rgba(255,255,255,0.6)'
                    }}
                    className="responsive-background-secondary font-weight-bold"
                >
                    <FlexContainer flexDirection='column' gap="4px" style={{ padding: '12px 16px' }}>
                        <div style={{ fontSize: 14, fontWeight: 700 }}>
                            Display settings
                        </div>

                        <Checkbox
                            defaultChecked
                            onChange={e => {
                                setMapSetting(prev => ({ ...prev, displayRouteConnection: e.target.checked || false }))
                            }}
                        >
                            Route connection
                        </Checkbox>

                        <Checkbox
                            defaultChecked
                            onChange={e => {
                                setMapSetting(prev => ({ ...prev, displayCityName: e.target.checked || false }))
                            }}
                        >
                            City name
                        </Checkbox>

                        <Divider style={{ margin: '4px 0' }} />
                        
                        <div style={{ fontSize: 14, fontWeight: 700 }}>
                            Price legand
                        </div>


                        <div
                            style={{ 
                                background: 'linear-gradient(to right, #198754, #fc8800)',
                                height: 6,
                                width: '100%',
                                borderRadius: 32
                            }}
                        >
                        </div>

                        <FlexContainer justifyContent='space-between' style={{ fontSize: 10, marginTop: -4 }}>
                            <div>Low</div>

                            <div>High</div>
                        </FlexContainer>
                    </FlexContainer>
                </div>
            )}
        </div>
    );
};

export default AirportMap;
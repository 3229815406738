import { useCallback, useEffect, useState } from "react"
import { VISITED_TOUR_ID } from "../../constants"

const useTour = (tourId?: string) => {
    const [visitedTourId, setVisitedTourId] = useState(JSON.parse(localStorage.getItem(VISITED_TOUR_ID) || '[]'))

    const handleAddToVisitedTourId = useCallback((tourId: string) => {
        const idList = JSON.parse(localStorage.getItem(VISITED_TOUR_ID) || '[]')

        if (!idList.includes(tourId)) {
            const output = [...idList, tourId]
            localStorage.setItem(VISITED_TOUR_ID, JSON.stringify(output))
            handleRefreshVisitedTourId()
        }
    }, [visitedTourId])

    const handleRefreshVisitedTourId = () => {
        setVisitedTourId(JSON.parse(localStorage.getItem(VISITED_TOUR_ID) || '[]'))
    }

    return {
        visitedTourId,
        isVisited: visitedTourId.includes(tourId),
        handleAddToVisitedTourId,
        handleRefreshVisitedTourId
    }
}

export default useTour
import { useContext, useEffect, useRef, useState } from "react"
import { AppContext } from '../../../../App';
import React from "react";
import { Collapse, Input, SideSheet, Table, Tag } from "@douyinfe/semi-ui";
import FlexContainer from "../../../../components/FlexContainer";
import { IAirport } from "../../../../typings";
import './index.less'
import AirportNameRender from "../AirportNameRender";
import { RewardFlightContext } from "../..";
import { formatNumber, generateColorGradient } from "../../../../utils";
import { QFLogo, VALogo } from "./logo";
import Draggable from "react-draggable";
import { IconSearch } from "@douyinfe/semi-icons";

interface IAirportRoutePanel {
    airportList: IAirport[];
    onCenter: (airport: IAirport) => void;
    onHeightChange: (num: number) => void
}

const AirportRoutePanel = ({ airportList, onCenter, onHeightChange }: IAirportRoutePanel) => {
    const { isMobile, windowHeight, navbarHeight, isDarkMode } = useContext(AppContext)
    const { cabin } = useContext(RewardFlightContext)

    const [isExpand, setIsExpand] = useState(true)
    const [sidesheetHeight, setSidesheetHeight] = useState(300)
    const [searchKeyword, setSearchKeyword] = useState('')

    const getEcoColorFn = useRef<any>()
    const getBusColorFn = useRef<any>()

    const heightRef = useRef(300)

    useEffect(() => {
        const ecoPriceList = airportList?.map(airport => airport.min_economy_fare_pts_price)?.filter(Boolean) || []
        const busPriceList = airportList?.map(airport => airport.min_business_fare_pts_price)?.filter(Boolean) || []

        if (ecoPriceList.length) {
            getEcoColorFn.current = generateColorGradient(Math.min(...ecoPriceList as any), Math.max(...ecoPriceList as any) + 1, '#198754', '#fc8800')
        }

        if (busPriceList.length) {
            getBusColorFn.current = generateColorGradient(Math.min(...busPriceList as any), Math.max(...busPriceList as any) + 1, '#198754', '#fc8800')
        }
    }, [airportList])

    const bodyWrapper = (
        <Collapse activeKey={isExpand ? ['route-panel'] : []} style={{ position: 'relative' }} onChange={(activeKey) => {
            if (!isMobile) {
                setIsExpand(activeKey?.length === 1)
            }
        }}>
            <Collapse.Panel
                itemKey="route-panel"
                style={{ position: 'relative' }}
                header={(
                    <FlexContainer justifyContent="space-between" style={{ padding: '4px 8px 4px 12px', borderBottom: '1px solid var(--semi-color-border)', width: '100%', height: 48, boxSizing: 'border-box' }} alignItems="center">
                        <div style={{ fontSize: 14, letterSpacing: -0.3 }}>
                            <FlexContainer alignItems="center" gap="8px">
                                <div style={{ fontSize: 28, letterSpacing: -2 }}>{airportList.length}</div>

                                <div style={{ lineHeight: '16px' }}>
                                    <div>{airportList.length <= 1 ? 'Route' : 'Routes'} Found</div>
                                    <div>In Viewpoint</div>
                                </div>
                            </FlexContainer>
                        </div>

                        {/* {isExpand && (
                            <>
                                {!!userCurrency && userCurrency !== 'hotel_currency' && !!currency && userCurrency !== currency ? (
                                    <FlexContainer flexDirection="column" alignItems="flex-end">
                                        <div className="font-weight-bold" style={{ fontSize: 12 }}>
                                            {currency} 1 = {userCurrency} {exchangeRate[userCurrency] || '-'}
                                        </div>
                                        <div style={{ color: 'grey', fontSize: 8, textAlign: 'right', lineHeight: '10px', whiteSpace: 'nowrap' }}>
                                            *All prices are estimated based on real-time exchange rates,
                                            <br />which may vary from hotel websites.
                                        </div>
                                    </FlexContainer>
                                ) : (
                                    <FlexContainer flexDirection="column" alignItems="flex-end">
                                        <div className="font-weight-bold" style={{ fontSize: 12 }}>
                                            Price displayed in {currency}
                                        </div>
                                        <div style={{ color: 'grey', fontSize: 8, textAlign: 'right', lineHeight: '10px', whiteSpace: 'nowrap' }}>
                                            *All prices are estimated based on real-time exchange rates,
                                            <br />which may vary from hotel websites.
                                        </div>
                                    </FlexContainer>
                                )}
                            </>
                        )} */}


                    </FlexContainer>
                )}
            >
                <Table
                    dataSource={airportList.filter(item => !searchKeyword || item.city_name.toLowerCase().includes(searchKeyword.toLowerCase()) || item.airport_code.toLowerCase().includes(searchKeyword.toLowerCase()))}
                    rowKey="property_id"
                    pagination={false}
                    size="small"
                    sticky
                    style={{ maxHeight: isMobile ? sidesheetHeight - 48 : windowHeight - navbarHeight - 48 - 32 - 48, overflow: 'auto' }}
                    // onRow={(record: any) => {
                    //     return {
                    //         className: 'cursor-pointer',
                    //         onClick: () => {
                    //             window.open(`/reward-flights/SYD/${record.airport_code}`)
                    //         }
                    //     }
                    // }}                    
                    columns={[
                        {
                            title: (
                                <FlexContainer>
                                    <Input placeholder="City" borderless prefix={<IconSearch />} onChange={setSearchKeyword} showClear />
                                </FlexContainer>
                            ),
                            // width: 160,
                            render: (record: IAirport) => {
                                return (
                                    <FlexContainer justifyContent="space-between" alignItems="center" gap="6px" style={{ padding: '4px 8px 4px 16px', cursor: 'pointer' }} onClick={() => {
                                        onCenter(record)
                                    }}>
                                        <div style={{ width: 144 }}>
                                            <div className="text-overflow">{record.city_name}</div>
                                            <div className="text-overflow" style={{ color: 'grey', marginTop: -4, fontSize: 12 }}>{record.country_name || '-'}</div>
                                        </div>

                                        <FlexContainer gap="4px">
                                            <VALogo style={{ width: 16, height: 16, visibility: record.operating_airlines?.includes('VA') ? 'visible' : 'hidden' }} />
                                            <QFLogo style={{ width: 16, height: 16, visibility: record.operating_airlines?.includes('QF') ? 'visible' : 'hidden' }} />
                                        </FlexContainer>
                                    </FlexContainer>
                                )
                            }
                        },
                        {
                            title: 'Min(ECO)',
                            width: 100,
                            dataIndex: 'min_economy_fare_pts_price',
                            sorter: (a: any, b: any) => {
                                return (a.min_economy_fare_pts_price || 0) - (b.min_economy_fare_pts_price || 0)
                            },
                            render: (_: any, record: IAirport) => {
                                if (!record.min_economy_fare_pts_price) {
                                    return '-'
                                }

                                return (
                                    <FlexContainer alignItems="center" style={{ background: getEcoColorFn.current?.(record.min_economy_fare_pts_price) + '66', height: 44, paddingLeft: 8 }}>
                                        {formatNumber(record.min_economy_fare_pts_price)}
                                    </FlexContainer>
                                )
                            },
                            hidden: cabin !== 'ECO'
                        },
                        {
                            title: 'Min(BUS)',
                            width: 100,
                            dataIndex: 'min_business_fare_pts_price',
                            sorter: (a: any, b: any) => {
                                return (a.min_business_fare_pts_price || 0) - (b.min_business_fare_pts_price || 0)
                            },
                            render: (_: any, record: IAirport) => {
                                if (!record.min_business_fare_pts_price) {
                                    return '-'
                                }

                                return (
                                    <FlexContainer alignItems="center" style={{ background: getBusColorFn.current?.(record.min_business_fare_pts_price) + '66', height: 44, paddingLeft: 6 }}>
                                        {formatNumber(record.min_business_fare_pts_price)}
                                    </FlexContainer>
                                )
                            },
                            hidden: cabin !== 'BUS'
                        }

                        // {
                        //     title: 'Count',
                        //     dataIndex: 'total_count'
                        // }
                    ].filter(item => !item.hidden)}
                // style={{ maxHeight: isMobile ? sidesheetHeight - 49 : windowHeight - navbarHeight - 48 - 32 - 49, overflow: 'auto' }}
                // loading={loading}
                />
            </Collapse.Panel>
        </Collapse>
    )

    if (airportList.length === 0) {
        return <></>
    }

    if (isMobile) {
        return (
            <>
                <FlexContainer id="dragger" justifyContent="center" style={{ position: 'fixed', bottom: heightRef.current - 2, width: '100%' }}>
                    <Draggable
                        axis="y"
                        bounds={{
                            top: -(windowHeight - heightRef.current - navbarHeight - 48 - 31 - 32),
                            bottom: heightRef.current - 48
                        }}
                        onDrag={(e, data) => {
                            setSidesheetHeight(heightRef.current - data.lastY)
                            onHeightChange(heightRef.current - data.lastY)
                        }}
                    >
                        <div style={{ padding: 8 }}>
                            <div
                                style={{
                                    width: 80,
                                    height: 6,
                                    borderRadius: 32,
                                    background: isDarkMode ? '#f2f3f599' : `#00000099`,
                                    zIndex: 100,
                                    WebkitBackdropFilter: 'blur(20px)',
                                    backdropFilter: "blur(20px)",
                                }}
                            >
                                <></>
                            </div>
                        </div>
                    </Draggable>
                </FlexContainer>
                <SideSheet
                    className="airport-route-wrapper"
                    placement="bottom"
                    visible={true}
                    height={sidesheetHeight}
                    mask={false}
                    style={{ borderRadius: '12px 12px 0 0', position: 'relative' }}
                    bodyStyle={{ padding: 0 }}
                >
                    {bodyWrapper}
                </SideSheet>
            </>

        )
    }

    return (
        <div
            className="responsive-background-secondary airport-route-wrapper"
            style={{
                position: 'fixed',
                right: 16,
                top: navbarHeight + 48 + 16,
                width: 320,
                borderRadius: 8,
                zIndex: 4,
                background: 'white',
                overflow: 'hidden',
                boxShadow: 'var(--semi-shadow-elevated)',
            }}
        >
            {bodyWrapper}
        </div>
    )
}

export default AirportRoutePanel
import { Avatar, Button, Collapse, Dropdown, List, SideSheet, Spin, Table, Tag, Toast } from "@douyinfe/semi-ui";
import { useAsyncEffect } from "ahooks";
import { ICashbackPromotion } from "../../../../typings";
import { useContext, useEffect, useMemo, useState } from "react";
import { renderText, request } from "../../../../utils";
import { groupBy, maxBy } from "lodash-es";
import { IconBell, IconBellStroked, IconClose, IconExternalOpen, IconExternalOpenStroked, IconHistory, IconSetting } from "@douyinfe/semi-icons";
import FlexContainer from "../../../../components/FlexContainer";
import classNames from "classnames";
import { PLATFORM_NAME_MAP } from "../../../GroceriesByPlatform";
import { AppContext } from "../../../../App";
import dayjs from "dayjs";
import './index.less'

export const CASHBACK_PLATFORM_NAME_MAP: Record<string, string> = {
    'shopback': 'Shopback',
    'cashrewards': 'Cashrewards',
    'topcashback': 'TopCashback'
}

interface IMerchantCashbackSidesheetProps {
    merchant?: ICashbackPromotion;
    visible: boolean;
    onCancel: () => void;
}

interface IMerchantCashbackBodyProps {
    merchant?: ICashbackPromotion;
    hideHeader?: boolean;
    disableSticky?: boolean;
}

export const CashbackPlatformBadge = ({ platform }: { platform: string }) => {
    const { COLOR_MAP } = useContext(AppContext)

    return (
        <div
            className="font-weight-bold"
            style={{
                background: COLOR_MAP[platform],
                color: 'white',
                width: 24,
                height: 24,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: 4,
            }}
        >
            {CASHBACK_PLATFORM_NAME_MAP[platform][0]}
        </div>
    )
}


export const MerchantCashbackBody = ({ merchant, hideHeader, disableSticky }: IMerchantCashbackBodyProps) => {

    const { isMobile, isEmbedded } = useContext(AppContext)

    const [activeKey, setActiveKey] = useState('')
    const [loading, setLoading] = useState(false)
    const [promotionListByPlatform, setPromotionListByPlatform] = useState<Record<string, ICashbackPromotion[]>>({})

    useAsyncEffect(async () => {
        if (!merchant) {
            setPromotionListByPlatform({})
            return
        }

        setLoading(true)
        const respData = await request({
            method: 'post',
            url: '/api/v1/service/execute',
            data: {
                service: 'rds.sql',
                requestParams: [
                    `SELECT * FROM simpo_octopus.au_cashback_promotion WHERE merchant_name = "${merchant.merchant_name}"`
                ]
            }
        })

        const tmp = groupBy(respData, 'platform')

        if (Object.keys(tmp).length === 1) {
            setActiveKey(respData[0].platform)
        } else {
            const maxCashbackRateItem = maxBy(respData, (promotion: any) => {
                return parseFloat(promotion.cashback_rate.replace('%', '').replace('$', ''))
            })

            setActiveKey(maxCashbackRateItem.platform)
        }

        setPromotionListByPlatform(tmp)
        setLoading(false)
    }, [merchant])

    useEffect(() => {
        const promotionList = promotionListByPlatform?.[activeKey] || []
        const maxCashbackRate = Math.max(...promotionList.map(promotion => parseFloat(promotion.cashback_rate.replace('%', '').replace('$', ''))), 0)

        document.dispatchEvent(new CustomEvent('max-cashback-rate::change', {
            detail: { maxCashbackRate }
        }))
    }, [activeKey, promotionListByPlatform])

    const handleClose = (hideUntil?: string, hideAll?: boolean) => {
        window.parent && window.parent.postMessage({ action: 'close_cashback', hideUntil, hideAll: !!hideAll }, '*')
    }

    return (
        <div className={classNames({
            "merchant-cashback-wrapper": true,
            'merchant-cashback-wrapper__disable-sticky': disableSticky
        })}>
            {!!merchant && (
                <div style={{ marginTop: 24 }}>
                    {!hideHeader && (
                        <FlexContainer justifyContent="space-between" style={{ padding: '0 16px', marginBottom: 16 }}>
                            <FlexContainer gap="12px" alignItems="center">
                                {/* <img src={logoUrl} style={{ maxWidth: 200, maxHeight: 40, objectFit: 'contain', borderRadius: 4, padding: 8, background: 'white' }} />
                                <FlexContainer justifyContent="space-between" alignItems="center" style={{ marginTop: 12 }}>
                                    <div className="font-weight-bold responsive-text" style={{ fontSize: 24, letterSpacing: -0.5 }}>Cashback Detail</div>
                                    <Button theme="outline" style={{ borderRadius: 16 }} icon={<IconBellStroked />} type="tertiary" onClick={() => {
                                        if (!isLogin) {
                                            showLoginForm()
                                        }
                                    }}>Subscribe</Button>
                                </FlexContainer> */}

                                <div style={{ padding: 4 }}>
                                    <Avatar
                                        className="merchant-logo-wrapper"
                                        src={merchant?.merchant_logo}
                                        style={{ objectFit: 'cover' }}
                                        // size="large"
                                        shape="square"
                                        {...merchant?.is_upsized ? {
                                            border: { motion: true, color: "rgb(254, 220, 2)" },
                                            bottomSlot: {
                                                text: "Upsized",
                                                textColor: 'black',
                                                className: 'font-weight-bold',
                                                style: { fontSize: 12, marginBottom: '0px', padding: "0 3px", background: 'rgb(254, 220, 2)', height: 16, borderRadius: '0 0 2px 2px' }
                                            } as any
                                        } : {
                                            border: true
                                        }}
                                    />
                                </div>

                                <FlexContainer flexDirection="column" justifyContent="space-between">
                                    <div style={{ fontWeight: 700, fontSize: 20, letterSpacing: -0.5 }}>{merchant.merchant_name}</div>

                                    <div style={{ color: 'grey' }}>
                                        <div style={{ fontSize: 14, whiteSpace: 'nowrap' }}>{merchant.max_cashback_rate}</div>
                                        {!!merchant.prev_max_cashback_rate && <div style={{ fontSize: 12, fontWeight: 400, textDecoration: 'line-through', marginTop: -5, whiteSpace: 'nowrap' }}>{renderText(merchant.prev_max_cashback_rate)}</div>}
                                    </div>
                                </FlexContainer>
                            </FlexContainer>

                            {isEmbedded && (
                                <FlexContainer gap="6px">
                                    <Button type="tertiary" theme="outline" style={{ borderRadius: 32 }} icon={<IconExternalOpen />} onClick={() => {
                                        window.open(`https://www.hotaudeals.com/cashback/${btoa(merchant.merchant_name)}`)
                                    }} />

                                    <Dropdown
                                        menu={[
                                            { node: 'title', name: <span className="font-weight-bold">For this merchant ({merchant.merchant_name})</span> as any },
                                            { node: 'item', name: <span className="font-weight-bold">Mute today</span> as any, onClick: () => handleClose(dayjs().format('YYYY-MM-DD')) },
                                            { node: 'item', name: <span className="font-weight-bold">Mute indefinitely</span> as any, onClick: () => handleClose('2099-12-31') },

                                            { node: 'title', name: <span className="font-weight-bold">For ALL websites</span> as any },
                                            { node: 'item', name: <span className="font-weight-bold">Mute today</span> as any, onClick: () => handleClose(dayjs().format('YYYY-MM-DD'), true) },
                                            { node: 'item', name: <span className="font-weight-bold">Mute indefinitely</span> as any, onClick: () => handleClose('2099-12-31', true) },

                                            { node: 'title', name: <span className="font-weight-bold">Extension settings</span> as any },
                                            { node: 'item', name: <FlexContainer className="font-weight-bold" alignItems="center" gap="4px">Settings <IconExternalOpen /></FlexContainer> as any, onClick: () => {
                                                window.parent && window.parent.postMessage({ action: 'open_extension_options_page' }, '*')
                                            } },
                                        ]}
                                    >
                                        <Button icon={<IconClose />} type="tertiary" theme="outline" style={{ borderRadius: 30, height: 32, width: 32 }} onClick={() => handleClose()} />
                                    </Dropdown>
                                </FlexContainer>
                            )}
                        </FlexContainer>
                    )}

                    <Spin spinning={loading} size="large" style={{ minHeight: 100 }}>
                        <Collapse activeKey={activeKey} style={{ marginTop: 12 }} accordion onChange={(v: any) => setActiveKey(v[0])}>
                            {Object.keys(promotionListByPlatform).sort((a, b) => {
                                const aMaxCashbackRate = Math.max(...promotionListByPlatform[a].map(promotion => parseFloat(promotion.cashback_rate.replace('%', '').replace('$', ''))))
                                const bMaxCashbackRate = Math.max(...promotionListByPlatform[b].map(promotion => parseFloat(promotion.cashback_rate.replace('%', '').replace('$', ''))))

                                return bMaxCashbackRate - aMaxCashbackRate
                            }).map(platform => {
                                const promotionList = promotionListByPlatform[platform]

                                const notPercentage = promotionList.some(item => item.cashback_rate.includes('$'))
                                const maxCashbackRate = Math.max(...promotionList.map(promotion => parseFloat(promotion.cashback_rate.replace('%', '').replace('$', ''))))

                                return (
                                    <Collapse.Panel
                                        className={classNames({
                                            [`collapse-panel-wrapper__${platform}`]: true
                                        })}
                                        itemKey={platform}
                                        key={platform}
                                        header={(
                                            <FlexContainer className="font-weight-bold" alignItems="center" justifyContent="space-between" style={{ paddingLeft: 8, width: '100%', paddingRight: 8 }}>
                                                <FlexContainer alignItems="center" gap="8px">
                                                    <CashbackPlatformBadge platform={platform} />

                                                    <div>{CASHBACK_PLATFORM_NAME_MAP[platform]}</div>
                                                </FlexContainer>

                                                <div className="font-weight-bold" style={{ fontSize: 16 }}>
                                                    {notPercentage ? `$${maxCashbackRate}` : `${maxCashbackRate}%`}
                                                </div>
                                            </FlexContainer>
                                        )}
                                    >
                                        <Table
                                            dataSource={promotionList}
                                            showHeader={false}
                                            size="small"
                                            pagination={false}
                                            columns={[
                                                {
                                                    title: 'Cashback Category',
                                                    render: (record: ICashbackPromotion) => {
                                                        return <div className="font-weight-bold" dangerouslySetInnerHTML={{ __html: record.cashback_category }}></div>
                                                    }
                                                },
                                                {
                                                    title: 'Cashback Rate',
                                                    width: 40,
                                                    render: (record: ICashbackPromotion) => {
                                                        return (
                                                            <FlexContainer alignItems="center" gap="8px" style={{ marginRight: -16 }}>
                                                                <div>
                                                                    {record.cashback_rate}
                                                                </div>
                                                            </FlexContainer>
                                                        )
                                                    }
                                                }
                                            ]}
                                        />

                                        <FlexContainer justifyContent="space-between" alignItems="center" style={{ marginTop: 8, paddingLeft: 16 }}>
                                            <FlexContainer alignItems="center" gap="4px" className="font-weight-bold" style={{ color: 'grey', fontSize: 12 }}>
                                                Updated {dayjs(promotionList[0].created_at).fromNow()}
                                            </FlexContainer>
                                            <Button style={{ borderRadius: 16 }} className="open-in-btn" icon={<IconExternalOpen />} iconPosition="right" type="tertiary" onClick={() => {
                                                const url = promotionList[0].detail_url

                                                if (url.startsWith('http')) {
                                                    window.open(url)
                                                    return
                                                }

                                                if (isMobile) {
                                                    window.open(url)
                                                    return
                                                }

                                                Toast.warning("This link can only be opened in a mobile browser.")
                                            }}>
                                                Open in {CASHBACK_PLATFORM_NAME_MAP[platform]}
                                            </Button>
                                        </FlexContainer>
                                    </Collapse.Panel>
                                )
                            })}
                        </Collapse>
                    </Spin>

                    <FlexContainer className='font-weight-bold' flexDirection="column" gap="8px" style={{ color: 'grey', textAlign: 'center', padding: 24, fontSize: 13 }}>
                        <div>
                            All cashback rates are updated daily.
                        </div>
                        <div>
                            In the event of any discrepancies between the rates displayed here and those on the platform, please refer to the official platform for the most accurate information.
                        </div>

                        <div>
                            <Button theme="borderless" style={{ fontSize: 13 }} onClick={() => {
                                const recipient = "hotaudeals@icloud.com";
                                const subject = `cashback issue: ${merchant.merchant_name}`
                                const body = ''

                                const mailtoLink = "mailto:" + recipient + "?subject=" + encodeURIComponent(subject) + "&body=" + encodeURIComponent(body);

                                window.open(mailtoLink);
                            }}>Report an issue</Button>
                        </div>
                    </FlexContainer>

                </div>
            )}
        </div>
    )
}

const MerchantCashbackSidesheet = ({ merchant, visible, onCancel }: IMerchantCashbackSidesheetProps) => {
    const { isMobile } = useContext(AppContext)

    return (
        <SideSheet
            visible={visible}
            closable={false}
            headerStyle={{ padding: 0 }}
            bodyStyle={{ padding: 0, position: 'relative', paddingBottom: 24 }}
            maskClosable
            onCancel={onCancel}
            {...isMobile ? {
                placement: 'bottom',
                style: { borderRadius: '12px 12px 0 0' },
                height: '70vh',
            } : {}}
        >
            <div>
                <Button theme="borderless" type="tertiary" icon={<IconClose />} style={{ position: 'absolute', top: 24, right: 24 }} onClick={onCancel}></Button>
            </div>
            <MerchantCashbackBody merchant={merchant} />
        </SideSheet>
    )
}

export default MerchantCashbackSidesheet
import { Button, Form, Input, Modal, Toast, Typography } from "@douyinfe/semi-ui"
import FlexContainer from "../../components/FlexContainer"
import { useContext, useEffect, useState } from "react"
import { AppContext } from "../../App"
import LoginForm from "./components/LoginForm"
import { Cell, CellGroup } from "tdesign-mobile-react"
import { IconDelete, IconExit, IconList, IconMail, IconMailStroked, IconMapPin } from "@douyinfe/semi-icons"
import { CART_ITEM_LIST_LOCAL_STORAGE_KEY } from "../../constants"

const My = () => {
    const { isLogin, isInApp, userInfo, setUserInfo } = useContext(AppContext)
    const [deleteAccountInput, setDeleteAccountInput] = useState('')

    // useEffect(() => {
    //     if (!isLogin) {
    //         showLoginForm()
    //     }
    // }, [isLogin])

    const handleLogout = () => {
        const instance = Modal.info({
            footer: null,
            closable: false,
            width: '300px',
            centered: true,
            icon: false,
            content: (
                <FlexContainer flexDirection="column" alignItems="center" gap="16px">
                    <span style={{ fontSize: 20, textAlign: 'center', lineHeight: 1.3, fontWeight: 700 }}>Are you sure <br /> you want to logout?</span>

                    <FlexContainer gap="8px" style={{ width: '100%' }}>
                        <Button size="large" style={{ width: '50%' }} onClick={() => instance.destroy()}>Cancel</Button>
                        <Button size="large" style={{ width: '50%' }} theme="solid" onClick={() => {
                            setUserInfo(undefined)
                            localStorage.removeItem(CART_ITEM_LIST_LOCAL_STORAGE_KEY)
                            instance.destroy()
                            Toast.success("Logged out successfully")
                        }}>Confirm</Button>
                    </FlexContainer>
                </FlexContainer>
            )
        })
    }

    const handleDeleteAccount = () => {
        const instance = Modal.info({
            footer: null,
            closable: false,
            width: '300px',
            centered: true,
            icon: false,
            content: (
                <FlexContainer flexDirection="column" alignItems="center" gap="16px">
                    <span style={{ fontSize: 20, textAlign: 'center', lineHeight: 1.3, fontWeight: 700 }}>Are you sure you want to delete account?</span>

                    <div>
                        <Input size="large" placeholder={userInfo.email} />
                        <Typography.Paragraph type="tertiary" className="font-weight-bold" style={{ fontSize: 12, lineHeight: 1.2, marginTop: 4 }}>
                            <span className="font-weight-bold">Enter your email to confirm the deletion of your account.</span>
                        </Typography.Paragraph>
                    </div>

                    <FlexContainer gap="8px" style={{ width: '100%' }}>
                        <Button size="large" type="tertiary" style={{ width: '50%' }} onClick={() => instance.destroy()}>Cancel</Button>
                        <Button size="large" type="danger" style={{ width: '50%' }} theme="solid" onClick={() => {
                            setUserInfo(undefined)
                            instance.destroy()
                            Toast.success("Logged out successfully")
                        }}>Delete</Button>
                    </FlexContainer>
                </FlexContainer>
            )
        })
    }

    if (!isLogin) {
        return <LoginForm />
    }

    if (isInApp) {
        return (
            <FlexContainer flexDirection="column" gap="16px" style={{ padding: '36px 24px 24px' }}>
                <h1 style={{ letterSpacing: -1, margin: 0 }} className="responsive-text">Welcome back!</h1>
    
                {/* <div className="font-weight-regular" style={{ borderRadius: 8, overflow: 'hidden' }}>
                    <Cell leftIcon={<IconList />} title="Categories" />
                    <Cell leftIcon={<IconMapPin />} title="Location" />
                </div> */}
    
                <div className="font-weight-regular" style={{ borderRadius: 8, overflow: 'hidden' }}>
                    <Cell leftIcon={<IconMailStroked />} className="responsive-background-secondary responsive-border responsive-text" title="Contact us" onClick={() => {
                        const recipient = "hotaudeals@icloud.com";
                        const subject = `login issue`
                        const body = ''
                    
                        const mailtoLink = "mailto:" + recipient + "?subject=" + encodeURIComponent(subject) + "&body=" + encodeURIComponent(body);
                    
                        window.open(mailtoLink);
                    }} />
                    <Cell leftIcon={<IconExit />} className="responsive-background-secondary responsive-border responsive-text" title="Logout" onClick={handleLogout} />
                </div>
    
                <div className="font-weight-regular" style={{ borderRadius: 8, overflow: 'hidden' }}>
                    <Cell leftIcon={<IconDelete style={{ color: 'rgb(249,57,32)' }} />} className="responsive-background-secondary responsive-border" title={<span style={{ color: 'rgb(249,57,32)' }}>Delete account</span>} onClick={handleDeleteAccount} />
                </div>
            </FlexContainer>
        )
    }

    return (
        <FlexContainer flexDirection="column">
            <div style={{ cursor: 'pointer' }}>
                <Cell className="responsive-background-secondary responsive-border" leftIcon={<IconExit className="responsive-text" />} title={<span className="font-weight-bold responsive-text" style={{ fontSize: 14 }}>Logout</span>} onClick={handleLogout} />
            </div>
            <div style={{ cursor: 'pointer' }}>
                <Cell className="responsive-background-secondary responsive-border" leftIcon={<IconDelete style={{ color: 'rgb(249,57,32)' }} />} title={<span className="font-weight-bold" style={{ color: 'rgb(249,57,32)', fontSize: 14 }}>Delete account</span>} onClick={handleDeleteAccount} />
            </div>
        </FlexContainer>
    )
}

export default My
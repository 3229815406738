import { Button, Collapse, Input, Modal, SideSheet, Table } from "@douyinfe/semi-ui"
import { useAsyncEffect } from "ahooks"
import { useContext, useEffect, useState } from "react"
import { request } from "../../../../utils"
import { IGiftCardDetail, IGiftCardRetailerDetail } from "../../../../typings"
import { debounce, groupBy } from "lodash-es"
import { AppContext } from "../../../../App"
import FlexContainer from "../../../../components/FlexContainer"
import './index.less'
import { IconInfoCircle, IconSearch } from "@douyinfe/semi-icons"
import { ButtonProps } from "@douyinfe/semi-ui/lib/es/button"
import TourContainer, { IPopoverActionEnum } from "../../../../components/TourContainer"

interface IBrandExplorerProps {
    onGiftCardClick?: any;
    btnProps?: ButtonProps
}

const BrandExplorer = ({ onGiftCardClick, btnProps }: IBrandExplorerProps) => {
    const { isMobile, isInApp } = useContext(AppContext)

    const [visible, setVisible] = useState(false)
    const [loading, setLoading] = useState(false)

    const [giftCardList, setGiftCardList] = useState<IGiftCardDetail[]>([])
    const [retailerOptionList, setRetailerOptionList] = useState<IGiftCardRetailerDetail[]>([])
    const [searchKeyword, setSearchKeyword] = useState('')

    useEffect(() => {
        if (visible) {
            setSearchKeyword('')
        }
    }, [visible])

    useAsyncEffect(async () => {
        setLoading(true)

        const giftCardListDetailRespData = await request({
            method: 'POST',
            url: '/api/v1/service/execute',
            data: {
                service: 'rds.sql',
                requestParams: [
                    `SELECT * FROM simpo_octopus.au_gift_card`
                ]
            }
        })

        setGiftCardList(giftCardListDetailRespData)

        const respData: any[] = await request({
            method: 'POST',
            url: '/api/v1/service/execute',
            data: {
                service: 'rds.sql',
                requestParams: [
                    `SELECT * FROM simpo_octopus.au_gift_card_retailer ORDER BY name ASC`
                ]
            }
        })

        respData.forEach(item => {
            item.__tmp__ = item.name.toLowerCase()
        })

        const retailerListGroupByName = groupBy(respData, '__tmp__')

        const outputList = Object.keys(retailerListGroupByName).map(name => {
            const availableList = retailerListGroupByName[name]
            const eligibleGiftCardList = retailerListGroupByName[name].map(item => item.gift_card_id)

            return {
                ...availableList[0],
                eligibleGiftCardList,
                channelConsistency: {
                    // inStore: availableList.every
                }
            }
        }) as any

        setRetailerOptionList(outputList)
        setLoading(false)
    }, [])

    const bodyWrapper = (
        <>
            <div className="responsive-background-secondary" style={isMobile ? { padding: '0 16px 8px', position: 'sticky', top: 0, background: 'white', zIndex: 10 } : {}}>
                <Input style={{ borderRadius: 16 }} prefix={<IconSearch />} placeholder="Search for retailer" onChange={debounce(setSearchKeyword, 500)} />
            </div>

            <Table
                loading={loading}
                dataSource={retailerOptionList.filter(item => !searchKeyword || item.name.toLowerCase().includes(searchKeyword.toLowerCase()))}
                pagination={false}
                size="small"
                sticky={{ top: isMobile ? 40 : 0 }}
                style={isMobile ? {} : { maxHeight: 400, overflow: 'auto', marginBottom: 24 }}
                columns={[
                    {
                        title: 'Retailer',
                        className: 'retailer-name-wrapper',
                        width: isMobile ? 100 : 140,
                        fixed: true,
                        render: (record: IGiftCardRetailerDetail) => {
                            return (
                                <FlexContainer alignItems="center" style={{ height: '100%' }}>
                                    <img src={record.logo} height="40" width={isMobile ? 60 : 100} style={{
                                        objectFit: 'contain',
                                    }} />
                                </FlexContainer>
                            )
                        }
                    },
                    {
                        title: 'Gift Card Buying Options',
                        render: (record: IGiftCardRetailerDetail) => {
                            const tcnList = record.eligibleGiftCardList?.filter(item => item.startsWith('tcn'))
                            const ultimateList = record.eligibleGiftCardList?.filter(item => item.startsWith('ultimate'))

                            return (
                                <Collapse>
                                    <Collapse.Panel header={(
                                        <div className="font-weight-bold">
                                            <div>
                                                {record.eligibleGiftCardList?.length || 0} Available
                                            </div>
                                        </div>
                                    )} itemKey="1">
                                        <FlexContainer flexDirection="column" gap="12px" style={{ paddingLeft: 12 }}>
                                            {ultimateList && ultimateList.length > 0 && (
                                                <div>
                                                    <div className="font-weight-bold" style={{ marginBottom: 6 }}>
                                                        Ultimate Gift Cards - {ultimateList.length} Buying Options
                                                    </div>
                                                    <FlexContainer flexWrap="wrap" gap="6px 8px">
                                                        {ultimateList?.map(gc => {
                                                            const gcDetail = giftCardList.find(item => item.gift_card_id === gc)

                                                            return (
                                                                <div key={gc} style={{ cursor: 'pointer' }} onClick={() => onGiftCardClick?.('Ultimate ' + gcDetail?.name)}>
                                                                    <img height={isMobile ? 42 : 58} src={gcDetail?.pic_url} />
                                                                </div>
                                                            )
                                                        })}
                                                    </FlexContainer>
                                                </div>
                                            )}

                                            {tcnList && tcnList.length > 0 && (
                                                <div>
                                                    <div className="font-weight-bold" style={{ marginBottom: 6 }}>TCN Gift Cards - {tcnList.length} Buying Options</div>
                                                    <FlexContainer flexWrap="wrap" gap="6px 8px">
                                                        {tcnList?.map(gc => {
                                                            const gcDetail = giftCardList.find(item => item.gift_card_id === gc)

                                                            return (
                                                                <div key={gc} style={{ cursor: 'pointer' }} onClick={() => onGiftCardClick?.('TCN ' + gcDetail?.name)}>
                                                                    <img height={isMobile ? 42 : 58} src={gcDetail?.pic_url} />
                                                                </div>
                                                            )
                                                        })}
                                                    </FlexContainer>
                                                </div>
                                            )}
                                        </FlexContainer>

                                    </Collapse.Panel>
                                </Collapse>

                            )
                        }
                    }
                ]}
            />
        </>
    )

    const withTourContainer = (comp: any) => {
        return (
            <TourContainer
                tourId="introducing_retailer_catalog_btn"
                popover={{
                    title: 'Not sure which gift card to buy?',
                    description: "We've organized a comprehensive list of gift cards available for most Australian retailers. Check it out now!",
                    showButtons: ['next'],
                    doneBtnText: 'Check out now!',
                    action: IPopoverActionEnum.CLICK_FIRST_CHILD,
                    ...isMobile ? {
                        align: 'center'
                    } : {}
                }}
                order={9999}
            >
                {comp}
            </TourContainer>
        )
    }

    if (isMobile) {
        return (
            <>
                {btnProps ? (
                    withTourContainer(<Button icon={<IconInfoCircle />} type="tertiary" onClick={() => setVisible(true)}  {...btnProps || {}}>
                        {btnProps?.children || "Retailer Catalog"}
                    </Button>)
                ) : (
                    <div
                        className="responsive-background"
                        style={{
                            position: 'fixed',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            bottom: isInApp ? 64 : 24,
                            background: 'white',
                            borderRadius: 16,
                            zIndex: 2,
                            border: '1px solid rgb(224, 224, 224)',
                            boxShadow: 'rgba(33, 33, 33, 0.12) 0px 8px 16px 0px',
                            overflow: 'hidden'
                        }}
                    >
                        {withTourContainer(<Button theme="borderless" icon={<IconInfoCircle />} type="tertiary" onClick={() => setVisible(true)} {...btnProps || {}}>Retailer Catalog</Button>)}
                    </div >
                )}


                <SideSheet
                    className="retailer-explorer-wrapper"
                    title={<span className="font-weight-bold">Retailer Catalog</span>}
                    height="80vh"
                    visible={visible}
                    placement="bottom"
                    bodyStyle={{ padding: 0 }}
                    onCancel={() => setVisible(false)}
                    style={{ borderRadius: '12px 12px 0 0' }}
                >
                    {bodyWrapper}
                </SideSheet>
            </>
        )
    }

    return (
        <>
            {withTourContainer((
                <Button icon={<IconInfoCircle />} type="tertiary" onClick={() => setVisible(true)}  {...btnProps || {}}>
                    {btnProps?.children || "Retailer Catalog"}
                </Button>
            ))}
            <Modal
                className="retailer-explorer-wrapper"
                title={<span className="font-weight-bold">Retailer catalog</span>}
                width="760px"
                visible={visible}
                footer={null}
                onCancel={() => setVisible(false)}
            >
                {bodyWrapper}
            </Modal>
        </>

    )
}

export default BrandExplorer
import { Banner, Button, Descriptions, Form, Input, SideSheet, Table, Tabs, Tag, Toast } from "@douyinfe/semi-ui"
import { useContext, useEffect, useRef, useState } from "react"
import { IAirport, IFlightRoute } from "../../../typings"
import { useAsyncEffect } from "ahooks"
import { formatNumber, request } from "../../../utils"
import FlexContainer from "../../../components/FlexContainer"
import { IconArrowRight, IconClock, IconExternalOpen, IconSearch, IconSetting, IconSync } from "@douyinfe/semi-icons"
import copy from "copy-to-clipboard"
import { calcHaversineDistance, estimateFlightDuration } from "../../RewardFlightsDetail/utils"
import { FormApi } from "@douyinfe/semi-ui/lib/es/form"
import { AppContext } from "../../../App"
import dayjs from "dayjs"
import './index.less'
import { SideSheetPlaceholder } from "../AdminLayout"
import LoadingButton from "../../../components/LoadingButton"

const FlightRouteManagement = () => {

    const tableRef = useRef<any>()

    const { windowHeight, navbarHeight } = useContext(AppContext)

    const [pageLoading, setPageLoading] = useState(true)

    const [routeList, setRouteList] = useState<IFlightRoute[]>([])
    const [airportDict, setAirportDict] = useState<Record<string, IAirport>>({})

    const [selectedRoute, setSelectedRoute] = useState<IFlightRoute>()
    const [count, setCount] = useState(0)
    const [selectedRouteKeyList, setSelectedRouteKeyList] = useState<string[]>([])

    const [activeTab, setActiveTab] = useState('VA')
    const [searchKeyword, setSearchKeyword] = useState('')

    const formRef = useRef<FormApi>()

    useAsyncEffect(async () => {
        const airportListRespData = await request({
            data: {
                service: 'rds.sql',
                requestParams: [
                    `SELECT * FROM simpo_octopus.au_airport`
                ]
            }
        })

        const routeListRespData = await request({
            data: {
                service: 'rds.sql',
                requestParams: [
                    `SELECT a.*, economy_last_sync_at, business_last_sync_at, economy_count, business_count, min_economy_price, min_business_price
                    FROM simpo_octopus.au_airport_route a
                    LEFT JOIN (
                        SELECT route_key, MAX(created_at) AS economy_last_sync_at, COUNT(*) AS economy_count, MIN(fare_pts_price) AS min_economy_price
                        FROM simpo_octopus.au_reward_seat
                        WHERE cabin_class = "economy"
                        GROUP BY route_key
                    ) b ON a.uniq_composite_key = b.route_key
                    LEFT JOIN (
                        SELECT route_key, MAX(created_at) AS business_last_sync_at, COUNT(*) AS business_count, MIN(fare_pts_price) AS min_business_price
                        FROM simpo_octopus.au_reward_seat
                        WHERE cabin_class = "business"
                        GROUP BY route_key
                    ) c ON a.uniq_composite_key = c.route_key
                    WHERE a.enabled IN (0, 1)`
                ]
            }
        })

        setRouteList(routeListRespData)
        setAirportDict(Object.assign({}, ...airportListRespData.map((item: IAirport) => {
            return {
                [item.airport_code]: item
            }
        })))
        setCount(routeListRespData.length)
        setPageLoading(false)
    }, [])

    useEffect(() => {
        if (selectedRoute) {
            formRef.current?.setValues(selectedRoute)
        }
    }, [selectedRoute])

    const handleSync = async (routeKeys: string[]) => {
        await request({
            baseURL: 'http://124.71.152.240:7001',
            data: {
                service: 'au.qantas.syncRewardSeatByRouteKeys',
                requestParams: [routeKeys]
            }
        })

        await request({
            data: {
                service: 'rds.pullData',
                requestParams: [
                    `SELECT * FROM simpo_octopus.au_reward_seat WHERE route_key IN (${routeKeys.map(id => `"${id}"`).join(', ')})`,
                    `DELETE FROM simpo_octopus.au_reward_seat WHERE route_key IN (${routeKeys.map(id => `"${id}"`).join(', ')})`,
                    "simpo_octopus",
                    "au_reward_seat"
                ]
            }
        })

        new Promise(async () => {
            setPageLoading(true)

            const routeListRespData = await request({
                data: {
                    service: 'rds.sql',
                    requestParams: [
                        `SELECT a.*, economy_last_sync_at, business_last_sync_at, economy_count, business_count, min_economy_price, min_business_price
                        FROM simpo_octopus.au_airport_route a
                        LEFT JOIN (
                            SELECT route_key, MAX(created_at) AS economy_last_sync_at, COUNT(*) AS economy_count, MIN(fare_pts_price) AS min_economy_price
                            FROM simpo_octopus.au_reward_seat
                            WHERE cabin_class = "economy"
                            GROUP BY route_key
                        ) b ON a.uniq_composite_key = b.route_key
                        LEFT JOIN (
                            SELECT route_key, MAX(created_at) AS business_last_sync_at, COUNT(*) AS business_count, MIN(fare_pts_price) AS min_business_price
                            FROM simpo_octopus.au_reward_seat
                            WHERE cabin_class = "business"
                            GROUP BY route_key
                        ) c ON a.uniq_composite_key = c.route_key
                        WHERE a.enabled IN (0, 1)`
                    ]
                }
            })

            setRouteList(routeListRespData)
            setPageLoading(false)
        })

        Toast.success('Sync successfully')
    }

    const columns = [
        {
            title: 'Route key',
            dataIndex: 'uniq_composite_key',
            filterMultiple: false,
            filters: activeTab === 'VA' ? [
                {
                    text: 'Valid VA Routes',
                    value: 'valid_va'
                },
                {
                    text: 'Invalid VA Routes',
                    value: 'invalid_va'
                },
                {
                    text: 'Disabled VA Routes',
                    value: 'disabled_va'
                },
            ] : [
                {
                    text: 'Valid QF Routes',
                    value: 'valid_qf'
                },

                {
                    text: 'Invalid QF Routes',
                    value: 'invalid_qf'
                },
                {
                    text: 'Disabled QF Routes',
                    value: 'disabled_qf'
                },
            ],
            onFilter: (value: string, record: any) => {
                // valid
                if (value === 'valid_va') {
                    return (record.business_count || 0) + (record.economy_count || 0) > 0 && record.uniq_composite_key.startsWith('VA')
                }

                if (value === 'valid_qf') {
                    return (record.business_count || 0) + (record.economy_count || 0) > 0 && record.uniq_composite_key.startsWith('QF')
                }

                // invalid
                if (value === 'invalid_va') {
                    return (record.business_count || 0) + (record.economy_count || 0) === 0 && record.uniq_composite_key.startsWith('VA') && record.enabled === 1
                }

                if (value === 'invalid_qf') {
                    return (record.business_count || 0) + (record.economy_count || 0) === 0 && record.uniq_composite_key.startsWith('QF') && record.enabled === 1
                }

                // disabled
                if (value === 'disabled_va') {
                    return record.enabled === 0 && record.uniq_composite_key.startsWith('VA')
                }

                if (value === 'disabled_qf') {
                    return record.enabled === 0 && record.uniq_composite_key.startsWith('QF')
                }

                return record.uniq_composite_key.startsWith(value)
            },
            width: 130,
            render: (text: string, record: IFlightRoute) => {
                return (
                    <FlexContainer alignItems="center" gap="4px">
                        <div
                            style={{
                                width: 10,
                                height: 10,
                                borderRadius: 32,
                                background: record.enabled
                                    ? ((record?.business_count || 0) + (record?.economy_count || 0) === 0 ? 'rgb(var(--semi-orange-5))' : 'rgb(var(--semi-green-5))')
                                    : 'rgb(var(--semi-red-5))'
                            }}
                        />

                        <code style={{ fontSize: 12, cursor: 'pointer' }} onClick={() => {
                            copy(text)
                            Toast.success('Copied to clipboard')
                        }}>{text}</code>
                    </FlexContainer>
                )
            },
        },
        {
            title: 'Route detail',
            dataIndex: 'departure_airport_code',
            render: (text: string, record: IFlightRoute) => {
                const departureAirportDetail = airportDict[record.departure_airport_code]
                const destinationAirportDetail = airportDict[record.destination_airport_code]

                return (
                    <FlexContainer gap="16px" alignItems="center">
                        <FlexContainer flexDirection="column">
                            <span>{departureAirportDetail.airport_code}</span>
                            <div style={{ fontSize: 12, color: 'grey' }}>
                                <div>{departureAirportDetail.city_name || '-'}</div>
                                <div style={{ marginTop: -6 }}>{departureAirportDetail.country_name || '-'}</div>
                            </div>
                        </FlexContainer>

                        <IconArrowRight />

                        <FlexContainer flexDirection="column">
                            <span>{destinationAirportDetail.airport_code}</span>
                            <div style={{ fontSize: 12, color: 'grey' }}>
                                <div>{destinationAirportDetail.city_name || '-'}</div>
                                <div style={{ marginTop: -6 }}>{destinationAirportDetail.country_name || '-'}</div>
                            </div>
                        </FlexContainer>
                    </FlexContainer>
                )
            }
        },
        // {
        //     title: 'Destination',
        //     dataIndex: 'destination_airport_code',
        //     width: 180,
        //     filterMultiple: false,
        //     filters: Object.keys(airportDict).sort((a, b) => a.localeCompare(b)).map(code => ({ text: code, value: code })),
        //     onFilter: (value: string, record: any) => {
        //         return record.destination_airport_code === value
        //     },
        //     render: (text: string, record: IFlightRoute) => {
        //         const airportDetail = airportDict[record.destination_airport_code]

        //         return (
        //             <FlexContainer flexDirection="column">
        //                 <span>{airportDetail.airport_code}</span>
        //                 <span style={{ fontSize: 12, color: 'grey', marginTop: -4 }}>{airportDetail.city_name}, {airportDetail.country_name}</span>
        //             </FlexContainer>
        //         )
        //     }
        // },
        {
            title: 'Trip detail',
            width: 160,
            render: (record: IFlightRoute) => {
                const departureAirportDetail = airportDict[record.departure_airport_code]
                const destinationAirportDetail = airportDict[record.destination_airport_code]

                return (
                    <FlexContainer flexDirection="column">
                        <span>{calcHaversineDistance(departureAirportDetail, destinationAirportDetail)}</span>
                        <span style={{ fontSize: 12, color: 'grey', marginTop: -4 }}>{estimateFlightDuration(departureAirportDetail, destinationAirportDetail)}</span>
                    </FlexContainer>
                )
            }
        },
        {
            title: 'MIN(Economy)',
            dataIndex: 'min_economy_price',
            sorter: (a: any, b: any) => {
                if (!a.min_economy_price) {
                    return 1
                }

                if (!b.min_economy_price) {
                    return -1
                }

                return (a.min_economy_price || 0) - (b.min_economy_price || 0)
            },
            width: 160,
            render: (_: number, record: IFlightRoute) => {
                return (
                    <div>
                        <div>
                            {record.min_economy_price ? formatNumber(record.min_economy_price) : '-'} ({record.economy_count || 0})
                        </div>
                        <FlexContainer alignItems="center" gap="4px" style={{ fontSize: 12, color: 'grey' }}>
                            <IconClock style={{ fontSize: 12 }} />
                            <span>{dayjs(record.economy_last_sync_at).fromNow()}</span>
                        </FlexContainer>
                    </div>
                )
            }
        },
        {
            title: 'MIN(Business)',
            width: 160,
            dataIndex: 'min_business_price',
            sorter: (a: any, b: any) => {
                if (!a.min_business_price) {
                    return 1
                }

                if (!b.min_business_price) {
                    return -1
                }

                return (a.min_business_price || 0) - (b.min_business_price || 0)
            },
            render: (_: number, record: IFlightRoute) => {
                return (
                    <div>
                        <div>
                            {record.min_business_price ? formatNumber(record.min_business_price) : '-'} ({record.business_count || 0})
                        </div>
                        <FlexContainer alignItems="center" gap="4px" style={{ fontSize: 12, color: 'grey' }}>
                            <IconClock style={{ fontSize: 12 }} />
                            <span>{dayjs(record.business_last_sync_at).fromNow()}</span>
                        </FlexContainer>
                    </div>
                )
            }
        },
        // {
        //     title: 'Last sync at',
        //     width: 300,
        //     dataIndex: 'economy_last_sync_at',
        //     sorter: (a: any, b: any) => {
        //         return dayjs(a.economy_last_sync_at || a.business_last_sync_at).unix() - dayjs(b.economy_last_sync_at || b.business_last_sync_at).unix()
        //     },
        //     render: (text: string, record: IFlightRoute) => {
        //         return (
        //             <FlexContainer flexDirection="column" style={{ fontSize: 12 }} gap="6px">
        //                 <FlexContainer alignItems="center" gap="8px">
        //                     <div style={{ width: 120 }}>
        //                         <Tag color="blue">Economy: {record.min_economy_price ? formatNumber(record.min_economy_price) : '-'}</Tag>
        //                     </div>

        //                     {dayjs(record.economy_last_sync_at).fromNow()} ({record.economy_count || 0})
        //                 </FlexContainer>
        //                 <FlexContainer alignItems="center" gap="8px">
        //                     <div style={{ width: 120 }}>
        //                         <Tag color="green">Business: {record.min_business_price ? formatNumber(record.min_business_price) : '-'}</Tag> 
        //                     </div>
        //                     {dayjs(record.economy_last_sync_at).fromNow()} ({record.business_count || 0})
        //                 </FlexContainer>
        //             </FlexContainer>
        //         )
        //     }
        // },
        {
            title: 'Action',
            width: 140,
            render: (record: IFlightRoute) => {
                return (
                    <FlexContainer gap="6px">
                        <LoadingButton theme='solid' style={{ borderRadius: 32 }} icon={<IconSync />} onClick={() => handleSync([record.uniq_composite_key])} />

                        <Button type="tertiary" theme="solid" style={{ borderRadius: 32 }} icon={<IconSetting />} onClick={() => {
                            setSelectedRoute(record)
                        }} />

                        <Button type="tertiary" style={{ borderRadius: 32 }} icon={<IconExternalOpen />} onClick={() => {
                            window.open(`/reward-flights/${record.departure_airport_code}/${record.destination_airport_code}`)
                        }} />
                    </FlexContainer>
                )
            }
        }
    ]

    return (
        <FlexContainer style={{ width: '100%' }} className="flight-route-management-wrapper">
            <div style={{ flexGrow: 1, width: '100%', overflow: 'auto' }}>
                <div>
                    <div className="font-weight-black responsive-text" style={{ fontSize: 20, letterSpacing: -0.5, padding: '8px 16px 0' }}>
                        Route Management
                    </div>

                    <Tabs
                        activeKey={activeTab}
                        onChange={setActiveTab}
                        contentStyle={{ padding: 0 }}
                        tabList={[
                            { tab: `Virgin Australia (${routeList.filter(item => item.carrier === 'VA' && item.enabled).length})`, itemKey: 'VA' },
                            { tab: `Qantas (${routeList.filter(item => item.carrier === 'QF' && item.enabled).length})`, itemKey: 'QF' }
                        ]}
                        tabBarExtraContent={(
                            <Input prefix={<IconSearch />} placeholder="Search for route key" style={{ width: 230, paddingRight: 16 }} onChange={setSearchKeyword} />
                        )}
                    />
                </div>

                <Table
                    size="small"
                    key={activeTab}
                    columns={columns}
                    dataSource={routeList.filter(item => item.carrier === activeTab && (!searchKeyword || item.uniq_composite_key.toLowerCase().includes(searchKeyword.toLowerCase())))}
                    pagination={{ pageSize: 100 }}
                    sticky
                    rowKey="uniq_composite_key"
                    loading={pageLoading}
                    style={{ height: windowHeight - navbarHeight - 85, overflow: 'auto' }}
                    ref={tableRef}
                    rowSelection={{
                        onChange: (v: any) => setSelectedRouteKeyList(v)
                    }}
                    onChange={() => {
                        setTimeout(() => {
                            const el: any = document.querySelector('.semi-table-pagination-info')
                            if (el) {
                                const length = el.innerText?.split('of')?.slice(-1)?.[0]?.trim()
                                setCount(length)
                            }
                        }, 100)
                    }}
                />

                {!!selectedRouteKeyList.length && (
                    <div style={{ background: 'white', position: 'sticky', bottom: 48 * 2 }} className="responsive-background">
                        <Banner
                            fullMode={false}
                            closeIcon={null}
                            icon={null}
                            style={{ borderRadius: 0 }}
                            description={(
                                <FlexContainer justifyContent="space-between" alignItems="center">
                                    <div>
                                        {selectedRouteKeyList.length} routes selected.
                                    </div>

                                    <LoadingButton size="small" theme="borderless" icon={<IconSync />} onClick={() => handleSync(selectedRouteKeyList)}>Sync</LoadingButton>
                                </FlexContainer>
                            )}

                        />
                    </div>
                )}
            </div>

            <SideSheetPlaceholder />

            <SideSheet visible={!!selectedRoute} title="Flight route detail" onCancel={() => setSelectedRoute(undefined)} mask={false}>
                <Form getFormApi={api => formRef.current = api}>
                    <Form.Input field="uniq_composite_key" label="Route key" />
                    <Form.Switch field="enabled" label="Enabled?" />
                </Form>
                <Button theme="solid" onClick={() => {

                }}>Submit</Button>
            </SideSheet>
        </FlexContainer>
    )
}

export default FlightRouteManagement
// @ts-nocheck
import React, { useRef, useEffect, useState, useCallback, useContext, useMemo } from 'react';
import Map, { Source, Layer, MapRef, ViewState, Marker } from 'react-map-gl';
import * as turf from '@turf/turf'; // Import Turf.js for geospatial calculations
import { MAPBOX_TOKEN } from '../../../RewardFlightsByPlatform/components/AirportMap';
import { IAirport, IHotel } from '../../../../typings';
import { AppContext } from '../../../../App';
import { Tag, Tooltip } from '@douyinfe/semi-ui';
import './index.less'
import FlexContainer from '../../../../components/FlexContainer';
import { IconMapPin, IconStar } from '@douyinfe/semi-icons';
import LazyMap from '../../../../components/LazyMap';

const LATITUDE_DEGREE_KM = 111

const getBoundsFromCenter = (center: IHotel, distanceKm = 10) => {
    const longitude = parseFloat(center.longitude)
    const latitude = parseFloat(center.latitude)

    // Calculate degree difference for latitude and longitude
    const deltaLat = distanceKm / LATITUDE_DEGREE_KM;

    // Longitude degree depends on the latitude
    const deltaLng = distanceKm / (LATITUDE_DEGREE_KM * Math.cos(center.latitude * (Math.PI / 180)));

    // Return the bounds as southwest and northeast corners
    const southwest = [longitude - deltaLng, latitude - deltaLat];
    const northeast = [longitude + deltaLng, latitude + deltaLat];

    return [southwest, northeast];
};

const HotelMap = ({ hotelList, pinnedHotel }: { hotelList: IHotel[], pinnedHotel: IHotel }) => {
    const { isDarkMode } = useContext(AppContext)
    const mapRef = useRef<MapRef>(null);

    const [tooltipVisible, setTooltipVisible] = useState(false)

    const timeoutRef = useRef<any>()

    const getTooltipContent = (hotel: IHotel) => {
        return (
            <FlexContainer 
                flexDirection='column' 
                onMouseEnter={() => {
                    if (timeoutRef.current) {
                        clearTimeout(timeoutRef.current)
                    }
                }} 
                onMouseLeave={() => {
                    setTooltipVisible(false)
                }}
            >
                <div className="font-weight-bold">
                    {hotel.name}
                </div>
    
                <FlexContainer style={{ marginTop: 2 }} gap="4px"> 
                    {!!hotel.distance && (
                        <Tag>
                            <FlexContainer alignItems='center' style={{ color: 'black' }} gap="2px">
                                <IconMapPin style={{ fontSize: 12 }} />
                                {hotel.distance?.toFixed(1)}km
                            </FlexContainer>
                        </Tag>
                    )}
    
                    {!!hotel.stars && (
                        <Tag style={{ background: 'rgba(250, 200, 0, 0.15)' }}>
                            <FlexContainer alignItems='center' style={{ color: isDarkMode ? 'rgb(125, 106, 0)' : 'rgb(254,251,208)' }} gap="2px">
                                <IconStar style={{ fontSize: 12 }} />
                                {hotel.stars} ({hotel.number_of_reviews})
                            </FlexContainer>
                        </Tag>
                    )}
                </FlexContainer>
            </FlexContainer>
        )
    }
    

    return (
        <div className="hotel-map-wrapper" style={{ height: '100%', width: '100%', position: 'relative' }}>
            <LazyMap
                onRef={mapRef}
                style={{ width: '100%', height: '100%' }}
                mapStyle={isDarkMode ? "mapbox://styles/mapbox/dark-v11" : "mapbox://styles/mapbox/light-v11"}
                mapboxAccessToken={MAPBOX_TOKEN}
                onLoad={() => {
                    setTimeout(() => {
                        window.dispatchEvent(new Event('resize'))
                    }, 500)

                    if (mapRef.current) {
                        const bounds = getBoundsFromCenter(pinnedHotel, 2)
                        mapRef.current.getMap().fitBounds(bounds, {
                            // padding: { top: 100 / Math.pow(paddingOffset, 2), bottom: 100 / Math.pow(paddingOffset, 2), left: 100 / paddingOffset, right: 100 / paddingOffset },
                            duration: 1500
                        })

                        setTimeout(() => {
                            setTooltipVisible(true)
                        }, 1600)
                    }
                }}
            >
                {hotelList.map(hotel => {
                    return (
                        <Marker
                            longitude={hotel.longitude}
                            latitude={hotel.latitude}
                        >
                            <div style={{
                                position: 'relative',
                                cursor: 'pointer'
                            }}>
                                <Tooltip
                                    position="right"
                                    content={getTooltipContent(hotel)}
                                >
                                    <div
                                        style={{
                                            background: 'rgb(250,102,76)',
                                            border: '3px solid #FFFFFF',
                                            borderRadius: 32,
                                            width: 12,
                                            height: 12,
                                            zIndex: 2,
                                            position: 'relative'
                                        }}
                                    />
                                </Tooltip>
                            </div>
                        </Marker>
                    )
                })}

                <Marker
                    longitude={pinnedHotel.longitude}
                    latitude={pinnedHotel.latitude}
                >
                    <div style={{
                        position: 'relative',
                        cursor: 'pointer'
                    }}>
                        <Tooltip
                            visible={tooltipVisible}
                            trigger="custom"
                            position="right"
                            onClickOutSide={() => setTooltipVisible(false)}
                            content={getTooltipContent(pinnedHotel)}
                            
                        >
                            <div
                                onMouseEnter={() => {
                                    setTooltipVisible(true)
                                }}
                                onMouseLeave={() => {
                                    timeoutRef.current = setTimeout(() => setTooltipVisible(false), 1000)
                                }}
                                style={{
                                    background: isDarkMode ? 'rgb(84,169,255)' : 'rgb(0,100,250)',
                                    border: '3px solid #FFFFFF',
                                    borderRadius: 32,
                                    width: 12,
                                    height: 12,
                                    zIndex: 2,
                                    position: 'relative'
                                }}
                            />
                        </Tooltip>

                        <div className="dot" />
                    </div>
                </Marker>

            </LazyMap>
        </div>
    );
};

export default HotelMap;
import { Carousel, Modal } from "@douyinfe/semi-ui"
import { useContext, useState } from "react"
import { AppContext } from "../../../App"
import { isMobileDevice } from "../../../utils"

export const IMG_LIST = [
    'https://lh3.googleusercontent.com/Qvnp2wb6I3IcMD6AqJlew9MvaYvWgQfxYHC3SC0X5u7aBqCt-3H0zxeDCs_f2CW3S1L8cWLqTtlal8FbwgdyRlJEcg=s1280-w1280-h800',
    'https://lh3.googleusercontent.com/uUsht6tTgey09MiEbIdxXQxT5CUb2aIeR4Vnc5u2AGly2kPNnTSsC1S5vUNLYdJEmHB4Uurzcw3LPOptNueO_0YcQuU=s1280-w1280-h800',
    'https://lh3.googleusercontent.com/NTeNO6tjOeyTsQKBG09jQY2voR14bORPVdJBOSpUNSawioly3EDY_fKLskjxFtAM9tjyt6T5V74VqIagljnb8StbVg=s1280-w1280-h800',
    'https://lh3.googleusercontent.com/DjBD2FnVgaVl0MmSZHfq3CZBDQqky1DW6vHsxjDEVlgRDOAXQ2bCDu0XoxNe6PPBP-SUeVYP5LUvX39Hqgrpmeyzkw=s1280-w1280-h800',
]

const WIDTH = isMobileDevice() ? window.innerWidth : 500
const RATIO = 1280 / 800

const ChromeExtensionModal = () => {
    const { isMobile, isInApp } = useContext(AppContext) 
    const [visible, setVisible] = useState(false)

    return (
        <Modal 
            visible={visible} 
            width={isMobile ? '100%' : 500}
            title={<span className="font-weight-bold">Introducing HotAuDeals Helper</span>}
            cancelButtonProps={{ style: { display: 'none' } }}
            okText="Checkout Now"
            onOk={() => {
                window.open("https://chromewebstore.google.com/detail/hotaudeals-helper/hhpnflehmikmmkneofkchgjgjbmmbalg")
            }}
            onCancel={() => setVisible(false)}
        >
            <div>
                <Carousel theme='dark' style={{ height: (WIDTH - 48) / RATIO }}>
                    {IMG_LIST.map(url => {
                        return (
                            <div key={url}>
                                <img src={url} width="100%" />
                            </div>
                        )
                    })}
                </Carousel>
            </div>

            <div className="font-weight-regular" style={{ marginTop: 8 }}>
                <b>HotAuDeals Helper</b> is a Chrome extension designed to enhance your online shopping experience by <b>providing instant access to historical price data on popular retail websites </b> like Coles, Woolworths, and Chemist Warehouse.
            </div>
        </Modal>
    )
}

export default ChromeExtensionModal
function safeBtoa(str: string) {
    return btoa(unescape(encodeURIComponent(str)));
}

function safeAtob(str: string) {
    return decodeURIComponent(escape(atob(str)));
}

export class JSONCipher {
    private shift: number;

    constructor(initialShift: number) {
        this.shift = initialShift;
    }

    private shiftEncrypt(text: string, shift: number): string {
        const normalizedShift = shift % 26;
        const normalizedShiftNumbers = shift % 10;

        return text.split('').map(char => {
            const charCode = char.charCodeAt(0);
            if (charCode >= 65 && charCode <= 90) {
                return String.fromCharCode(((charCode - 65 + normalizedShift) % 26) + 65);
            }
            if (charCode >= 97 && charCode <= 122) {
                return String.fromCharCode(((charCode - 97 + normalizedShift) % 26) + 97);
            }
            if (charCode >= 48 && charCode <= 57) {
                return String.fromCharCode(((charCode - 48 + normalizedShiftNumbers) % 10) + 48);
            }
            return char;
        }).join('');
    }

    private shiftDecrypt(text: string, shift: number): string {
        const normalizedShift = shift % 26;
        const normalizedShiftNumbers = shift % 10;

        return text.split('').map(char => {
            const charCode = char.charCodeAt(0);
            if (charCode >= 65 && charCode <= 90) {
                return String.fromCharCode(((charCode - 65 - normalizedShift + 26) % 26) + 65);
            }
            if (charCode >= 97 && charCode <= 122) {
                return String.fromCharCode(((charCode - 97 - normalizedShift + 26) % 26) + 97);
            }
            if (charCode >= 48 && charCode <= 57) {
                return String.fromCharCode(((charCode - 48 - normalizedShiftNumbers + 10) % 10) + 48);
            }
            return char;
        }).join('');
    }

    public encrypt(jsonObject: Record<string, unknown>, shift?: number): string {
        const jsonString = JSON.stringify(jsonObject);
        const encryptionShift = shift !== undefined ? shift : this.shift;
        const encryptedString = this.shiftEncrypt(jsonString, encryptionShift);
        return safeBtoa(encryptedString); // Use safeBtoa
    }

    public decrypt(encodedString: string, shift?: number): Record<string, unknown> {
        const decryptedBase64 = safeAtob(encodedString); // Use safeAtob
        const decryptionShift = shift !== undefined ? shift : this.shift;
        const decryptedString = this.shiftDecrypt(decryptedBase64, decryptionShift);

        return JSON.parse(decryptedString);
    }
}
import { useContext } from "react"
import { AppContext } from "../../App"
import { Button } from "@douyinfe/semi-ui"
import FlexContainer from "../../components/FlexContainer"
import './index.less'

const PrivacyPolicy = () => {
    const { isMobile } = useContext(AppContext)

    return (
        <FlexContainer className="privacy-policy-wrapper" justifyContent="center" style={{ width: '100%', height: window.innerHeight - 52, overflow: 'auto' }}>
            <div style={{ padding: 24, maxWidth: 1000 }}>
                <div className="responsive-text" style={{ fontWeight: 700, fontSize: 28, paddingBottom: 12, letterSpacing: -1 }}>Data Protection and Privacy Policy</div>

                <FlexContainer className="font-weight-bold responsive-text" flexDirection="column" gap="8px" style={{ padding: '0 0 24px' }}>
                    <div>
                        <p style={{ fontWeight: 600 }}>Effective Date: 13/07/2024</p>

                        <p>
                            HotAuDeals ("we," "our," or "us") is dedicated to safeguarding your privacy. This Privacy Policy outlines how we collect, use, and protect your information when you use our iOS app. By using HotAuDeals, you agree to the terms of this Privacy Policy.
                        </p>

                        <h3>1. Information We Collect</h3>
                        <p>
                            We use your email to log in for authentication purposes and gather tracking data to enhance our services. The information we collect includes:
                        </p>
                        <ul>
                            <li><strong>Tracking Data:</strong> We collect data related to your interactions with the app, such as usage patterns and feature usage. This information helps us improve the app's functionality and user experience.</li>
                        </ul>

                        <h3>2. How We Use Your Information</h3>
                        <p>The information we collect is used for the following purposes:</p>
                        <ul>
                            <li><strong>To Provide and Maintain Our Services:</strong> Ensuring that the app functions as intended and providing support when needed.</li>
                            <li><strong>To Improve Our Services:</strong> Analyzing usage data to understand how users interact with the app, identifying areas for improvement, and developing new features.</li>
                            <li><strong>To Ensure Security:</strong> Monitoring and enhancing the security of our app.</li>
                        </ul>

                        <h3>3. Sharing Your Information</h3>
                        <p>We do not sell, trade, or transfer your information to outside parties, except in the following circumstances:</p>
                        <ul>
                            <li><strong>With Service Providers:</strong> We may share your information with third-party service providers, such as Firebase, who perform services on our behalf. These providers are required to maintain the confidentiality and security of your information.</li>
                            <li><strong>For Legal Reasons:</strong> We may disclose your information if required by law or in response to valid requests by public authorities (e.g., a court or government agency).</li>
                        </ul>

                        <h3>4. Data Security</h3>
                        <p>
                            We implement a variety of security measures to protect your information. While we strive to use commercially acceptable means to safeguard your information, we cannot guarantee its absolute security.
                        </p>

                        <h3>5. Your Privacy Rights</h3>
                        <p>
                            Since we collect only anonymous and non-personal information, specific privacy rights, such as data access or deletion requests, do not apply. However, if you have any concerns about your privacy, please contact us using the information provided below.
                        </p>

                        <h3>6. Changes to This Privacy Policy</h3>
                        <p>
                            We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page. You are advised to review this Privacy Policy periodically for any changes.
                        </p>

                        <h3>7. Contact Us</h3>
                        <p>
                            If you have any questions about this Privacy Policy, please contact us at: <a href="mailto:hotaudeals@icloud.com">hotaudeals@icloud.com</a>
                        </p>
                    </div>

                    <div>
                        Thank you for using HotAuDeals.
                    </div>
                </FlexContainer>
            </div>
        </FlexContainer>
    )
}

export default PrivacyPolicy
import { useContext } from "react";
import FlexContainer from "../../../../components/FlexContainer";
import { AppContext } from "../../../../App";

interface IProductBadgeProps {
    platform: string;
    discountRate: number;
    displayInSimpleMode?: boolean;
    style?: any;
}

const ProductBadge = ({ platform, discountRate, displayInSimpleMode, style }: IProductBadgeProps) => {

    const { isMobile, COLOR_MAP } = useContext(AppContext)
    
    const isPosterPage = window.location.pathname.includes('poster')

    if (Math.abs(discountRate).toFixed(0) === '0') {
        return <></>
    }

    return (
        <div
            className="responsive-text__opposite discount-rate-badge-wrapper"
            style={{
                width: 66,
                height: 66,
                borderRadius: '50%',
                border: '1px solid white',
                position: 'absolute',
                background: 'white',
                top: displayInSimpleMode ? -14 : (isMobile ? 0 : 6),
                left: displayInSimpleMode ? -14 : (isMobile ? 0 : 6),
                transform: `scale(${displayInSimpleMode ? 0.4 : (isMobile ? 0.6 : 0.8)})`,
                ...style
            }}
        >
            <FlexContainer
                flexDirection="column"
                className="font-weight-bold"
                justifyContent="center"
                alignItems="center"
                style={{
                    letterSpacing: -1,
                    fontWeight: 700,
                    fontSize: 18,
                    width: 54,
                    height: 54,
                    borderRadius: '50%',
                    border: `6px solid ${COLOR_MAP[platform]}`
                }}
            >
                <span className="font-weight-bold">{Math.abs(discountRate).toFixed(0)}%</span>
                <div style={{ fontWeight: 700, marginTop: isPosterPage ? -8 : -4 }}>OFF</div>
            </FlexContainer>
        </div>
    )



    return <></>
}

export default ProductBadge
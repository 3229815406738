import { Button, Calendar, Card, Cascader, DatePicker, Divider, Dropdown, Form, Input, Popover, Radio, RadioGroup, Select, SideSheet, Slider, Table, Tag, Toast, Tooltip, Typography } from "@douyinfe/semi-ui"
import SubHeader from "../../components/SubHeader"
import './index.less'
import { createContext, memo, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react"
import { useAsyncEffect } from "ahooks"
import { formatNumber, generateColorGradient, getUserFriendlyTextColor, renderPageOnReady, request } from "../../utils"
import { IAirport, IMultiCityAirport, IRewardSeatSummary } from '../../typings'
import { ColumnProps } from "@douyinfe/semi-ui/lib/es/table"
import FlexContainer from "../../components/FlexContainer"
import AirportRouteSidesheet from "./components/AirportRouteSidesheet"
import _, { debounce, groupBy, isEmpty, pick, uniq, uniqBy } from "lodash-es"
import AirportMap from "./components/AirportMap"
import dayjs from "dayjs"
import { IconArrowRight, IconChevronLeft, IconChevronRight, IconDelete, IconFilter, IconListView, IconMapPin, IconRedo, IconSearch } from "@douyinfe/semi-icons"
import { AppContext } from "../../App"
import { useNavigate } from "react-router-dom"
import { FormApi } from "@douyinfe/semi-ui/lib/es/form"
import useKeyValuePairs from "../../hooks/useKeyValuePairs"

const VIEW_STATE_DICT: Record<string, any> = {
    'Anywhere': {
        "longitude": 133.37703424621412,
        "latitude": -26.26276741950391,
        "zoom": 3.6294619164445927,
        "pitch": 0,
        "bearing": 0,
        "padding": {
            "top": 0,
            "bottom": 0,
            "left": 0,
            "right": 0
        }
    },
    'Australia': {
        "longitude": 133.37703424621412,
        "latitude": -26.26276741950391,
        "zoom": 3.6294619164445927,
        "pitch": 0,
        "bearing": 0,
        "padding": {
            "top": 0,
            "bottom": 0,
            "left": 0,
            "right": 0
        }
    },
    'UK & Europe': {
        "longitude": 10.505601881758736,
        "latitude": 48.86913480020897,
        "zoom": 3.4228287349514424,
        "pitch": 0,
        "bearing": 0,
        "padding": {
            "top": 0,
            "bottom": 0,
            "left": 0,
            "right": 0
        }
    },
    'North America': {
        "longitude": -112.96065632334941,
        "latitude": 40.591222008804436,
        "zoom": 2.8698919250975354,
        "pitch": 0,
        "bearing": 0,
        "padding": {
            "top": 0,
            "bottom": 0,
            "left": 0,
            "right": 0
        }
    },
    "Asia Pacific": {
        "longitude": 97.49571471636511,
        "latitude": 23.757988374146464,
        "zoom": 2.858673656188374,
        "pitch": 0,
        "bearing": 0,
        "padding": {
            "top": 0,
            "bottom": 0,
            "left": 0,
            "right": 0
        }
    },
    "Middle East": {
        "longitude": 44.37652821474242,
        "latitude": 30.14921038756067,
        "zoom": 3.4316898390551445,
        "pitch": 0,
        "bearing": 0,
        "padding": {
            "top": 0,
            "bottom": 0,
            "left": 0,
            "right": 0
        }
    }
}

export const RewardFlightContext = createContext<{
    airportDict: Record<string, IAirport>;
    showRouteDetail: (route: IRewardSeatSummary) => void;
    cabin: string;
}>({
    airportDict: {},
    showRouteDetail: () => { },
    cabin: 'ECO'
})

export const NotAvailableTag = () => (
    <Tag style={{ background: "rgb(108,117,125)", color: 'white' }}>
        <span className="font-weight-bold">Not available</span>
    </Tag>
)

const REWARD_FLIGHTS_SEARCH_HISTORY_LOCAL_STORAGE_KEY = '__reward_flights_search_history__'

const RewardFlightsByPlatform = () => {

    const { isMobile, windowHeight, navbarHeight, isDarkMode } = useContext(AppContext)
    const navigate = useNavigate()

    const { raw: multiCityAirportListRespData } = useKeyValuePairs('generic', 'multi_city_airport_codes')
    const multiCityAirportList = (multiCityAirportListRespData || []) as IMultiCityAirport[]

    const [loading, setLoading] = useState(true)
    const [departureAirport, setDepartureAirport] = useState('SYD')

    const [airportDict, setAirportDict] = useState<Record<string, IAirport>>({})
    const [airportList, setAirportList] = useState<IAirport[]>([])

    const [pcFilterSectionExpand, setPcFilterSectionExpand] = useState(true)

    const [filterSectionSidesheetVisible, setFilterSectionSidesheetVisible] = useState(false)
    const [filter, setFilter] = useState<{
        from: string;
        to: string;
        dates: [string, string];
        ff: string;
        cabin: string;
        cities: string[],
        point_limit: number,
        airports: string[]
    }>({
        from: 'SYD',
        to: 'Anywhere',
        dates: [dayjs().format('YYYY-MM-DD'), dayjs().add(30, 'day').format('YYYY-MM-DD')],

        ff: 'QF',
        cabin: 'ECO',
        cities: [],
        point_limit: 0,
        airports: []
    })

    const [searchHistoryList, setSearchHistoryList] = useState<{
        from: string;
        to: string;
        dates: string[];
    }[]>(JSON.parse(localStorage.getItem(REWARD_FLIGHTS_SEARCH_HISTORY_LOCAL_STORAGE_KEY) || '[]'))

    const [viewState, setViewState] = useState(VIEW_STATE_DICT.Australia)

    const formRef = useRef<FormApi>()

    const cityOptionList = useMemo(() => {
        return Object.entries(groupBy(airportList.map(item => ({ ...item, country_name: item.country_name || 'Other' })), 'country_name')).map(([country, arr]) => {
            return {
                label: `${country} (${arr.length})`,
                value: country,
                key: country,
                children: arr.map(item => {
                    return {
                        label: item.city_name,
                        value: item.city_name
                    }
                }).sort((a, b) => a.label.localeCompare(b.label))
            }
        }).sort((a, b) => a.label.localeCompare(b.label))
    }, [airportList])

    const filteredAirportList = useMemo(() => {
        return airportList.filter(airport => {
            if (filter.ff && !airport.operating_airlines?.includes(filter.ff)) {
                return false
            }

            if (filter.cabin === 'ECO' && !airport.min_economy_fare_pts_price) {
                return false
            }

            if (filter.cabin === 'BUS' && !airport.min_business_fare_pts_price) {
                return false
            }

            if (filter.point_limit) {
                if (filter.cabin === 'ECO' && airport.min_economy_fare_pts_price && airport.min_economy_fare_pts_price > filter.point_limit) {
                    return false
                }

                if (filter.cabin === 'BUS' && airport.min_business_fare_pts_price && airport.min_business_fare_pts_price > filter.point_limit) {
                    return false
                }
            }

            if (filter.airports?.length) {
                let airportCodeList: string[] = []

                filter.airports.forEach(code => {
                    const multiCityAirport = multiCityAirportList.find(item => item.airport_code === code)

                    if (multiCityAirport) {
                        airportCodeList = airportCodeList.concat(multiCityAirport.corresponding_airport_codes)
                    } else {
                        airportCodeList.push(code)
                    }
                })

                if (!airportCodeList.includes(airport.airport_code)) {
                    return false
                }
            }

            if (Array.isArray(filter.cities) && filter.cities.length) {
                if (!filter.cities.includes(airport.city_name || '')) {
                    return false
                }
            }

            return true
        })
    }, [airportList, filter, multiCityAirportList])

    useAsyncEffect(async () => {
        setLoading(true)

        const airportListRespData = await request({
            url: '/api/v1/service/execute',
            method: 'POST',
            data: {
                service: 'rds.sql',
                requestParams: [
                    "SELECT * FROM simpo_octopus.au_airport"
                ]
            }
        })

        setAirportDict(Object.assign({}, ...airportListRespData.map((airport: IAirport) => ({
            [airport.airport_code]: airport
        }))))
    }, [])

    useEffect(() => {
        if (isEmpty(airportDict)) return
        handleFetchRewardSeatList({
            from: 'SYD',
            to: 'Anywhere',
            dates: [dayjs().format('YYYY-MM-DD'), dayjs().add(30, 'day').format('YYYY-MM-DD')],
        }, true)
    }, [airportDict])

    const handleFetchRewardSeatList = useCallback(async (filter: any, disableToast = false) => {
        setLoading(true)

        if (VIEW_STATE_DICT[filter.to || '']) {
            setViewState(VIEW_STATE_DICT[filter.to || ''])
        }

        setDepartureAirport(filter.from)

        const airportList = Object.values(airportDict)
        const filteredAirportCodeList = airportList.filter(item => item.region_name === filter.to).map(item => item.airport_code)

        if (filter.dates && filter.dates.length === 2) {
            filter.dates = filter.dates.map((item: Date) => dayjs(item).format('YYYY-MM-DD'))
        }

        const validAirportCodeListRespData = await request({
            url: '/api/v1/service/execute',
            method: 'POST',
            data: {
                service: 'rds.sql',
                requestParams: [
                    `
                        SELECT 
                            SUBSTRING(a.route_key, -3) AS airport_code,
                            COUNT(CASE WHEN a.brand_id = 'RE' THEN 1 END) AS re_count,
                            COUNT(CASE WHEN a.brand_id = 'RB' THEN 1 END) AS rb_count,
                            COUNT(CASE WHEN a.brand_id = 'RP' THEN 1 END) AS rp_count,
                            COUNT(*) AS total_count,
                            MIN(CASE WHEN a.cabin_class = 'Economy' THEN a.fare_pts_price END) AS min_economy_fare_pts_price,
                            MIN(CASE WHEN a.cabin_class = 'Business' THEN a.fare_pts_price END) AS min_business_fare_pts_price,
                            GROUP_CONCAT(DISTINCT SUBSTRING(a.route_key, 1, 2)) AS operating_airlines
                        FROM
                            simpo_octopus.au_reward_seat a
                            JOIN simpo_octopus.au_airport_route b ON a.route_key = b.uniq_composite_key
                        WHERE
                            a.brand_id IN ('RE', 'RP', 'RB')
                            AND departure_airport_code = "${filter.from}"
                            ${filter.dates && filter.dates.length === 2 ? `AND departure_date BETWEEN "${filter.dates[0]}" AND "${filter.dates[1]}"` : ''}
                            ${filteredAirportCodeList.length ? `AND destination_airport_code IN (${filteredAirportCodeList.map(item => `"${item}"`).join(', ')})` : ''}
                        GROUP BY 
                            SUBSTRING(a.route_key, -3)  
                        HAVING 
                            total_count > 5
                        ORDER BY 
                            total_count DESC;                  
                    `
                ]
            }
        })

        const output = uniqBy([
            ...JSON.parse(localStorage.getItem(REWARD_FLIGHTS_SEARCH_HISTORY_LOCAL_STORAGE_KEY) || '[]'),
            pick(filter, ['from', 'to', 'dates'])
        ], item => `${item.from}_${item.to}`)

        localStorage.setItem(REWARD_FLIGHTS_SEARCH_HISTORY_LOCAL_STORAGE_KEY, JSON.stringify(output))
        setSearchHistoryList(output)

        setAirportList(validAirportCodeListRespData.map((item: any) => {
            return {
                ...airportDict[item.airport_code],
                ...item
            }
        }))
        !disableToast && Toast.success('Reward seat updated')
        setLoading(false)
    }, [airportDict])

    const departureAirportOptionList = useMemo(() => {
        const codeArr = ["SYD", 'MEL', 'ADL', 'BNE', 'CNS', 'PER']
        const nameArr = ['Sydney', 'Melbourne', 'Adelaide', 'Brisbane', 'Cairns', 'Perth']

        return codeArr.map((item, idx) => ({
            label: <span className="font-weight-bold">{nameArr[idx]}</span>,
            value: item,
            extra: nameArr[idx]
        }))
    }, [])

    const regionOptionList = useMemo(() => {
        const uniqRegionList = uniq(Object.values(airportDict).map(item => item.region_name))

        return [
            {
                label: <span className="font-weight-bold">Anywhere</span>,
                value: 'Anywhere',
                extra: 'Anywhere'
            },
            ...uniqRegionList.map(item => ({
                label: <span className="font-weight-bold">{item}</span>,
                value: item,
                extra: item
            }))
        ]
    }, [airportDict])

    const ffOptionList = useMemo(() => {
        return [
            {
                label: <span className="font-weight-bold">Virgin Australia</span>,
                value: 'VA'
            },
            {
                label: <span className="font-weight-bold">Qantas</span>,
                value: 'QF'
            }
        ]
    }, [])

    const filterSection = (
        <div>
            <FlexContainer
                className="responsive-background-secondary"
                flexDirection="column"
                style={{
                    width: '100%',
                    height: 'fit-content',
                    padding: isMobile ? 24 : 16,
                    boxSizing: 'border-box',
                    borderBottom: '1px solid var(--semi-color-border)'
                }}
            >
                <div className="font-weight-black" style={{ fontSize: 20, letterSpacing: -0.5 }}>Reward Flights Explorer</div>

                <Form
                    onSubmit={(v) => {
                        setFilter(prev => ({ ...prev, ...v }))
                        handleFetchRewardSeatList(v)
                    }}
                    getFormApi={api => formRef.current = api}
                    initValues={filter}
                >
                    <Form.Select
                        label="Departure"
                        field='from'
                        size="large"
                        optionList={departureAirportOptionList}
                        style={{ width: '100%' }}
                        filter={(v, option) => {
                            return !v || option.extra.toLowerCase().includes(v.toLowerCase())
                        }}

                    />

                    <Form.Select
                        label="Destination"
                        field='to'
                        size="large"
                        optionList={regionOptionList}
                        style={{ width: '100%' }}
                        filter={(v, option) => {
                            return !v || option.extra.toLowerCase().includes(v.toLowerCase())
                        }}
                    />

                    <Form.DatePicker
                        label="Travel Dates"
                        field="dates"
                        size="large"
                        type="dateRange"
                        className="custom-selector-wrapper responsive-border"
                        dropdownClassName="reward-hotels-filter-date-picker-wrapper"
                        format="dd/MM/yyyy"
                        disabledDate={date => dayjs().isSameOrAfter(date)} style={{ width: '100%' }}
                        presetPosition="left"
                        presets={Array.from({ length: 12 }, (_, i) => {
                            let start, end, text;

                            if (i === 0) {
                                start = dayjs().add(1, 'day'); // Tomorrow
                                end = dayjs().endOf('month'); // End of the current month
                            } else {
                                start = dayjs().add(i, 'month').startOf('month');
                                end = dayjs().add(i, 'month').endOf('month');
                            }

                            text = start.format('MMM YYYY');

                            return {
                                text,
                                start: start.toDate(),
                                end: end.toDate(),
                            };
                        })}
                    />

                    <Button loading={loading} block size="large" theme="solid" htmlType="submit">Update search</Button>
                </Form>


            </FlexContainer>

            <Table
                dataSource={searchHistoryList}
                pagination={false}
                size="small"
                sticky
                style={{ maxHeight: windowHeight - navbarHeight - 368, overflow: 'auto' }}
                columns={[
                    {
                        title: 'Recent search',
                        render: (record: any) => {
                            const isFlexibleTravelDate = !record.dates || record.dates.length !== 2

                            return (
                                <FlexContainer alignItems="center" justifyContent="space-between" style={{ cursor: 'pointer' }} onClick={() => {
                                    formRef.current?.setValues(record)
                                }}>
                                    <div>
                                        <FlexContainer gap="4px" alignItems="center">
                                            {record.from}
                                            <IconArrowRight style={{ fontSize: 12 }} />
                                            {record.to}
                                        </FlexContainer>

                                        <div style={{ fontSize: 12, color: 'grey', marginTop: -4 }}>
                                            {isFlexibleTravelDate ? 'Flexible travel dates' : `${dayjs(record.dates[0]).format('MMM YYYY')} - ${dayjs(record.dates[1]).format('MMM YYYY')}`}
                                        </div>
                                    </div>

                                    <Button theme="borderless" size="small" type="danger" icon={<IconDelete />} onClick={(e) => {
                                        e.stopPropagation()
                                        const arr = searchHistoryList.filter(item => JSON.stringify(item) !== JSON.stringify(record))
                                        localStorage.setItem(REWARD_FLIGHTS_SEARCH_HISTORY_LOCAL_STORAGE_KEY, JSON.stringify(arr))
                                        setSearchHistoryList(arr)
                                    }} />
                                </FlexContainer>
                            )
                        }
                    }
                ]}
            />
        </div>
    )

    return (
        <RewardFlightContext.Provider value={{
            airportDict,
            showRouteDetail: (v) => {
                // setAirportRoutesSidesheetProps({ visible: true, routeInfo: v })}
                navigate(`/reward-flights/${v.departure_airport_code}/${v.destination_airport_code}`)
            },
            cabin: filter.cabin
        }}>
            <div className="reward-flight-wrapper" style={{ height: '100%', overflow: 'hidden', position: 'relative' }}>

                <FlexContainer style={{ width: '100%' }}>
                    {!isMobile && (
                        <FlexContainer
                            className="responsive-background responsive-border filter-bar-animation-wrapper"
                            flexDirection="column"
                            style={{ width: pcFilterSectionExpand ? 320 : 0, boxSizing: 'border-box', flexShrink: 0, background: 'white', position: 'relative', borderRight: '1px solid var(--semi-color-border)' }}
                            gap="20px"
                            justifyContent="space-between"
                        >
                            {pcFilterSectionExpand && filterSection}

                            <FlexContainer
                                className="responsive-background-secondary"
                                alignItems="center"
                                style={{
                                    position: 'absolute',
                                    top: 'calc(50% - 50px)',
                                    background: 'white',
                                    right: -15,
                                    zIndex: 1,
                                    height: 100,
                                    boxSizing: 'border-box',
                                    border: '1px solid var(--semi-color-border)',
                                    borderLeft: 'none',
                                    width: 14,
                                    borderRadius: '0 50px 50px 0',
                                    cursor: 'pointer'
                                }}
                                onClick={() => {
                                    setPcFilterSectionExpand(prev => !prev)
                                    setTimeout(() => {
                                        window.dispatchEvent(new Event('resize'))
                                    }, 0)
                                }}
                            >
                                {pcFilterSectionExpand
                                    ? <IconChevronLeft style={{ color: isDarkMode ? 'lightgrey' : 'grey', fontSize: 14 }} />
                                    : <IconChevronRight style={{ color: isDarkMode ? 'lightgrey' : 'grey', fontSize: 14 }} />}
                            </FlexContainer>

                        </FlexContainer>
                    )}

                    <FlexContainer style={{ width: '100%' }} flexDirection="column">

                        <FlexContainer style={{ padding: '8px 16px', borderBottom: '1px solid var(--semi-color-border)', overflowX: 'auto', overflowY: 'hidden' }} gap="8px">
                            <Select
                                prefix={<span className="font-weight-bold" style={{ fontSize: 14, paddingLeft: 12, paddingRight: 8, color: 'var(--semi-color-text-2)' }}>Airline</span>}
                                placeholder="All"
                                style={{ flexShrink: 0, height: 30 }}
                                optionList={ffOptionList}
                                onChange={v => {
                                    setFilter(prev => ({
                                        ...prev,
                                        ff: v as any
                                    }))
                                }}
                            />

                            <Select
                                prefix={<span className="font-weight-bold" style={{ fontSize: 14, paddingLeft: 12, paddingRight: 8, color: 'var(--semi-color-text-2)' }}>Cabin</span>}
                                value={filter.cabin}
                                style={{ flexShrink: 0, height: 30 }}
                                optionList={[
                                    { label: 'Economy', value: 'ECO' },
                                    { label: 'Business', value: 'BUS' }
                                ]}
                                onChange={v => {
                                    setFilter(prev => ({
                                        ...prev,
                                        cabin: v as string
                                    }))
                                }}
                            />

                            <Select
                                key={multiCityAirportList.length + airportList.length}
                                dropdownClassName="reward-flight-filter-airport-wrapper"
                                prefix={<span className="font-weight-bold" style={{ fontSize: 14, paddingLeft: 12, paddingRight: 8, color: 'var(--semi-color-text-2)' }}>Airports</span>}
                                multiple
                                maxTagCount={2}
                                showClear
                                placeholder="All"
                                style={{ minWidth: 200 }}
                                filter={(keyword, option) => {
                                    return (option.value as string).toLowerCase().includes(keyword.toLowerCase())
                                }}
                                onChange={(v: any) => {
                                    setFilter(prev => ({
                                        ...prev,
                                        airports: v as string[]
                                    }))
                                }}
                                renderSelectedItem={(option, { onClose }) => {
                                    const content = (
                                        <Tag closable onClose={onClose}>
                                            <span>{option.value}</span>
                                        </Tag>
                                    );

                                    return {
                                        isRenderInTag: false,
                                        content,
                                    };
                                }}
                            >
                                <Select.OptGroup label="Multi-city codes" key={`multi-city-codes`}>
                                    {multiCityAirportList?.map(airport => {
                                        return (
                                            <Select.Option value={airport.airport_code} key={airport.airport_code}>
                                                <Tag style={{ marginRight: 6, width: 48, textAlign: 'center' }}>{airport.airport_code}</Tag>
                                                {airport.airport_name}
                                            </Select.Option>
                                        )
                                    })}
                                </Select.OptGroup>

                                <Select.OptGroup label="Airports" key={`multi-city-codes`}>
                                    {airportList.map(airport => {
                                        return (
                                            <Select.Option value={airport.airport_code} key={airport.airport_code}>
                                                <Tag style={{ marginRight: 6, width: 48, textAlign: 'center' }}>{airport.airport_code}</Tag>
                                                {airport.airport_name}
                                            </Select.Option>
                                        )
                                    })}
                                </Select.OptGroup>
                            </Select>

                            <Cascader
                                dropdownClassName="reward-flight-filter-city-wrapper"
                                multiple
                                prefix={<span className="font-weight-bold" style={{ fontSize: 14, paddingLeft: 12, paddingRight: 8, color: 'var(--semi-color-text-2)' }}>Cities</span>}
                                style={{ flexShrink: 0, height: 30, minWidth: 200 }}
                                maxTagCount={2}
                                filterTreeNode
                                treeNodeFilterProp='value'
                                showClear
                                leafOnly
                                treeData={cityOptionList}
                                placeholder="All"
                                onChange={(v: any) => {
                                    setFilter(prev => ({
                                        ...prev,
                                        cities: v?.map((item: any) => item[1]) || []
                                    }))
                                }}
                            />

                            <Popover
                                position="bottomLeft"
                                contentClassName="reward-flight-filter-point-wrapper"
                                content={
                                    <div style={{ width: 220, padding: '2px 16px 6px' }}>
                                        <Slider
                                            min={5000}
                                            max={200000}
                                            step={1000}
                                            value={filter.point_limit || 10000}
                                            tipFormatter={v => formatNumber(v as string)}
                                            onChange={v => setFilter(prev => ({ ...prev, point_limit: v as number }))}
                                        />

                                        <FlexContainer alignItems="center" justifyContent="space-between" style={{ marginTop: -6 }}>
                                            {!!filter.point_limit ? <span>Max {formatNumber(filter.point_limit)} points</span> : 'Unlimited'}
                                            <Button icon={<IconRedo />} theme="borderless" style={{ padding: 0 }} size="small" onClick={() => {
                                                setFilter(prev => ({ ...prev, point_limit: 0 }))
                                            }}>
                                                Reset
                                            </Button>
                                        </FlexContainer>
                                    </div>
                                }
                            >
                                <Button>
                                    <span className="font-weight-bold" style={{ fontSize: 14, color: 'var(--semi-color-text-2)' }}>
                                        Points: {!!filter.point_limit ? formatNumber(filter.point_limit, '0.[0]a') : 'unlimited'}
                                    </span>
                                </Button>
                            </Popover>
                        </FlexContainer>

                        <FlexContainer style={{ position: 'relative' }}>
                            <AirportMap departureAirport={departureAirport} viewState={viewState} airportList={filteredAirportList} />

                            {isMobile && (
                                <FlexContainer style={{ position: 'absolute', top: 8, zIndex: 1, width: '100%', pointerEvents: 'none' }} justifyContent="center">
                                    <FlexContainer
                                        className="responsive-background"
                                        alignItems="center"
                                        style={{
                                            pointerEvents: 'auto',
                                            borderRadius: 32,
                                            padding: '4px 12px 4px 12px',
                                            border: '1px solid var(--semi-color-border)',
                                            background: 'white'
                                        }}
                                        gap="8px"
                                        onClick={() => {
                                            setFilterSectionSidesheetVisible(true)
                                        }}
                                    >
                                        <div>
                                            <FlexContainer style={{ fontSize: 14 }} alignItems="center" gap="4px">
                                                {filter.from}
                                                <IconArrowRight style={{ fontSize: 12 }} />
                                                {filter.to}
                                            </FlexContainer>
                                        </div>
                                    </FlexContainer>
                                </FlexContainer>
                            )}
                        </FlexContainer>
                    </FlexContainer>
                </FlexContainer>
            </div>

            <SideSheet
                className="reward-flights-filter-sidesheet-wrapper"
                placement="bottom"
                style={{ borderRadius: '12px 12px 0 0' }}
                visible={filterSectionSidesheetVisible}
                onCancel={() => setFilterSectionSidesheetVisible(false)}
                mask={false}
                bodyStyle={{ padding: 0 }}
            >
                {filterSection}
            </SideSheet>
        </RewardFlightContext.Provider>
    )
}

export default RewardFlightsByPlatform
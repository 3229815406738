import { useAsyncEffect } from "ahooks"
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react"
import { request } from "../../../utils"
import { ICashbackPromotion } from "../../../typings"
import FlexContainer from "../../../components/FlexContainer"
import { CashbackPlatformBadge } from "../../Cashback/components/MerchantCashbackSidesheet"
import { AppContext } from "../../../App"
import { Button, Form, Input, SideSheet } from "@douyinfe/semi-ui"
import { FormApi } from "@douyinfe/semi-ui/lib/es/form"
import useKeyValuePairs from "../../../hooks/useKeyValuePairs"
import { IconSearch } from "@douyinfe/semi-icons"
import { SideSheetPlaceholder } from "../AdminLayout"

let popupWindow: any = undefined

const CashbackMerchantManagement = () => {
    const { navbarHeight, windowHeight } = useContext(AppContext)
    const { kvPairs: websiteDict, handleRefresh } = useKeyValuePairs('website')

    const [merchantList, setMerchantList] = useState<ICashbackPromotion[]>([])
    const [selectedMerchant, setSelectedMerchant] = useState<ICashbackPromotion>()

    const [searchKeyword, setSearchKeyword] = useState('')
    const [formData, setFormData] = useState<Record<string, string>>({})
    const [loading, setLoading] = useState(false)

    const formRef = useRef<FormApi>()

    const filteredMerchantList = useMemo(() => {
        return merchantList.filter(merchant => !searchKeyword || merchant.merchant_name.toLowerCase().includes(searchKeyword.toLowerCase())).sort((a, b) => a.merchant_name.localeCompare(b.merchant_name, undefined, { sensitivity: 'base' }))
    }, [searchKeyword, merchantList])

    useAsyncEffect(async () => {
        const respData = await request({
            method: 'post',
            url: '/api/v1/service/execute',
            data: {
                service: 'rds.sql',
                requestParams: [
                    `SELECT * FROM simpo_octopus.au_cashback_promotion_tmp LIMIT 1000`
                ]
            }
        })

        setMerchantList(respData)
    }, [])

    useEffect(() => {
        if (selectedMerchant) {
            formRef.current?.setValues({
                ...selectedMerchant,
                website: websiteDict[selectedMerchant.merchant_name] || ''
            })
        }
    }, [selectedMerchant, websiteDict])

    const handleSave = useCallback(async () => {
        setLoading(true)

        if (formData.website && typeof formData.website === 'string') {
            const url = new URL(formData.website);
            await request({
                url: '/api/v1/service/execute',
                method: 'post',
                data: {
                    service: 'rds.insert',
                    requestParams: [
                        'simpo_octopus', 'key_value_pair', 
                        {
                            type: 'website',
                            key: formData.merchant_name,
                            value: url.origin,
                            remark: 'cashback',
                        },
                        {
                            onDuplicateKeyUpdate: {
                                'value': 'VALUES(value)'
                            }
                        }
                    ]
                }
            })
        } else {
            await request({
                url: '/api/v1/service/execute',
                method: 'post',
                data: {
                    service: 'rds.delete',
                    requestParams: [
                        'simpo_octopus', 'key_value_pair', 
                        {
                            where: {
                                type: 'website',
                                key: formData.merchant_name
                            }
                        }
                    ]
                }
            })
        }

        if (formData.popularity) {
            await request({
                url: '/api/v1/service/execute',
                method: 'post',
                data: {
                    service: 'rds.insert',
                    requestParams: [
                        'simpo_octopus', 'key_value_pair', 
                        {
                            type: 'popularity',
                            key: formData.merchant_name,
                            value: formData.popularity,
                            remark: 'cashback',
                        },
                        {
                            onDuplicateKeyUpdate: {
                                'value': 'VALUES(value)'
                            }
                        }
                    ]
                }
            })
        }

        handleRefresh()
        setLoading(false)
    }, [formData, selectedMerchant])

    return (
        <FlexContainer>
            <div style={{ padding: '0 16px 16px' }}>
                <div>
                    <div className="font-weight-black responsive-text" style={{ padding: '8px 0 0', fontSize: 20, letterSpacing: -0.5 }}>
                        Cashback Merchant Management
                    </div>
                </div>

                <div style={{ paddingBottom: 8, paddingTop: 4 }}>
                    <Input prefix={<IconSearch />} onChange={setSearchKeyword} placeholder="Search merchant" />
                </div>

                <FlexContainer gap="8px" flexWrap="wrap" style={{ height: windowHeight - navbarHeight - 86, overflow: 'auto', boxSizing: 'border-box' }}>
                    {filteredMerchantList?.map(merchant => {
                        let background = 'rgba(var(--semi-red-5), 0.12)'
                        if (websiteDict[merchant.merchant_name]) {
                            background = 'rgba(var(--semi-light-blue-5), 0.12)'
                        }

                        return (
                            <div key={merchant.merchant_name} style={{ padding: '12px 24px', borderRadius: 8, background, cursor: 'pointer' }} onClick={() => setSelectedMerchant(merchant)}>
                                <div>{merchant.merchant_name}</div>
                                {!!merchant.merchant_name && (
                                    <Button size="small" theme="borderless" style={{ padding: 0, fontSize: 14, height: 'fit-content' }} onClick={() => {
                                        window.open(websiteDict[merchant.merchant_name])
                                    }}>{websiteDict[merchant.merchant_name]}</Button>
                                )}
                            </div>
                        )
                    })}
                </FlexContainer>

                <SideSheet visible={!!selectedMerchant} title="Edit merchat meta data" mask={false} onCancel={() => setSelectedMerchant(undefined)}>
                    <Form getFormApi={api => formRef.current = api} onValueChange={v => setFormData(prev => ({ ...prev, ...v }))} style={{ marginBottom: 12 }}>
                        <Form.Input label="Logo" field="merchant_logo" disabled />
                        <Form.Input label="Name" field="merchant_name" disabled />
                        <Form.Input label="Website" field="website" />
                        <Form.Input label="Popularity" field="popularity" />
                    </Form>

                    <Button theme="solid" type="primary" loading={loading} onClick={handleSave}>Save</Button>
                    <Button style={{ marginLeft: 6 }} onClick={() => {
                        const url = `https://www.google.com/search?q=${selectedMerchant?.merchant_name}&btnI=I%27m+Feeling+Lucky&sca_esv=2be68b492be70465&sca_upv=1&sxsrf=ADLYWILMaEmNM8y0Xna22RLGAeaD4AFuew%3A1725515249701&source=hp&ei=8UXZZpPMKNiRvr0PuczgmAw&iflsig=AL9hbdgAAAAAZtlUASVH-EhNQmWh7RzvuGlvVvdpQFTM`
                        popupWindow = window.open(url, "newWebWindow", "status=1,left=100,top=100,width=400,height=400") as any
                    }}>Search in Google</Button>

                    {/* <div>
                        <img src={selectedMerchant?.merchant_logo} height="60px" />
                        {selectedMerchant && websiteDict[selectedMerchant.merchant_name] && <iframe src={websiteDict[selectedMerchant.merchant_name]} />}
                    </div> */}
                </SideSheet>
            </div>

            <SideSheetPlaceholder />
        </FlexContainer>
    )
}

export default CashbackMerchantManagement
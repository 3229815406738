import { InputProps } from "@douyinfe/semi-ui/lib/es/input"
import FlexContainer from "../../../../components/FlexContainer"
import { Input } from "@douyinfe/semi-ui"
import { useContext, useMemo, useState } from "react";
import { AppContext } from "../../../../App";

interface IFormItemProps extends InputProps {
    label: string;
}

const FormItem = ({ label, ...props }: IFormItemProps ) => {
    return (
        <div style={{ width: '100%' }}>
            <div className="font-weight-bold responsive-text" style={{ marginBottom: 2, fontSize: 14 }}>{label}</div>
            <Input {...props} style={props.readOnly ? { pointerEvents: 'none' } : {}} />
        </div>
    )
}

interface IPointsCalculatorProps {
    platform: string;
    multiplier?: number;
}

export const REWARD_PROGRAM_MAP: Record<string, any> = {
    coles: {
        name: 'Flybuys',
        pointValue: 10 / 2000,
        ffName: 'Velocity',
        ffExchangeRate: 1 / 2
    },
    woolworths: {
        name: 'Everyday Rewards',
        pointValue: 10 / 2000,
        ffName: 'Qantas',
        ffExchangeRate: 1 / 2
    },
    'big w': {
        name: 'Everyday Rewards',
        pointValue: 10 / 2000,
        ffName: 'Qantas',
        ffExchangeRate: 1 / 2
    },
}

const PointsCalculator = ({ platform, multiplier: defaultMultiplier }: IPointsCalculatorProps) => {
    const [value, setValue] = useState(500)
    const [multiplier, setMultiplier] = useState(defaultMultiplier || 20)

    const { isMobile } = useContext(AppContext)

    const totalPoints = useMemo(() => {
        return value * multiplier
    }, [value, multiplier])

    const targetRewardProgram = REWARD_PROGRAM_MAP[platform.toLowerCase()]
    if (!targetRewardProgram) {
        return <></>
    }

    return (
        <div>
            <div className="font-weight-bold responsive-text section-header responsive-background-secondary" style={{ fontWeight: 700, letterSpacing: -1, fontSize: 22 }}>Points Calculator</div>

            <FlexContainer gap="12px" justifyContent="space-evenly" style={{ marginBottom: 8 }} flexDirection={isMobile ? 'column' : 'row'}>
                <FormItem label="Gift Card Amount" defaultValue={value} onChange={v => setValue(parseInt(v))} type="number" prefix="$" />
                <FormItem label="Reward Program" value={targetRewardProgram.name} readOnly />
                <FormItem label="Points Multiplier" defaultValue={multiplier} onChange={v => setMultiplier(parseInt(v))} type="number" suffix="x points" min={0} />
            </FlexContainer>

            <FlexContainer gap="12px" justifyContent="space-evenly" flexDirection={isMobile ? 'column' : 'row'}>
                <FormItem label={`${targetRewardProgram.name} Points`} value={totalPoints} type="number" readOnly />
                <FormItem label="Points Value" value={totalPoints * targetRewardProgram.pointValue} readOnly prefix="$" />
                <FormItem label={`Equivalent ${targetRewardProgram.ffName} Points`} value={totalPoints * targetRewardProgram.ffExchangeRate} readOnly />
            </FlexContainer>
        </div>
    )
}

export default PointsCalculator
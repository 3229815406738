import { useAsyncEffect } from "ahooks";
import { useContext, useEffect, useRef, useState } from "react";
import { request } from "../../utils";
import { times, uniqueId } from "lodash-es";
import { AppContext } from "../../App";
import { Spin } from "@douyinfe/semi-ui";

export function loadScript(url: string) {
    return new Promise((resolve, reject) => {
        const script = document.createElement('script');
        script.src = url;
        script.async = true; // Optional: Load script asynchronously

        script.onload = () => {
            resolve(null);
        };

        script.onerror = (error) => {
            console.error(`Error loading script: ${url}`, error);
            reject(error);
        };

        document.head.appendChild(script);
    });
}

interface IArticleRenderProps {
    src: string
}

const ArticleRender = ({ src }: IArticleRenderProps) => {
    const [loading, setLoading] = useState(true)

    const { isDarkMode, isWindowLoaded } = useContext(AppContext)

    const isReadyRef = useRef(false)
    const larkRef = useRef<any>()

    useEffect(() => {
        if (!larkRef.current) {
            return
        }

        window.location.reload()
    }, [isDarkMode, isWindowLoaded])

    useAsyncEffect(async () => {
        if (larkRef.current) {
            return
        }

        if (!window.webComponent) {
            await loadScript('https://lf3-cdn-tos.bytegoofy.com/obj/goofy/locl/lark/external_js_sdk/h5-js-sdk-1.2.17.js');
        }

        const noncestr = uniqueId()
        const timestamp = Date.now()

        const respData = await request({
            data: {
                "service": "bot.larkV2.getJsApiTicket",
                "requestParams": [
                    {
                        "noncestr": noncestr,
                        "timestamp": `${timestamp}`,
                        "url": window.location.origin
                    }
                ]
            }
        })

        await window.webComponent.config({
            signature: respData?.signature,
            appId: 'cli_a453473632ab9013',
            timestamp,
            nonceStr: noncestr,
            url: window.location.origin,
            jsApiList: ['DocsComponent']
        })

        const theme = document.body.getAttribute('theme-mode') || 'light'

        larkRef.current = window.webComponent.render(
            'DocsComponent',
            {
                featureConfig: JSON.stringify({
                    LIKE: { enable: false },
                    SHARE: { enable: false },
                    HEADER: { enable: false },
                    FOOTER: { enable: false },
                    CONTENT: { readonly: true, toolbox: { enable: false }, titleVisible: false },
                    COMMENT: { partial: { enable: false }, global: { enable: false } },
                    DIRECTORY: { enable: false }
                }),
                src,
                minHeight: '500px',
                width: '100%',
                theme,
                lang: 'en'
            },
            document.querySelector('#article-render-wrapper'),
        )
        setLoading(false)
    }, [])

    useEffect(() => {
        return () => {
            if (larkRef.current) {
                larkRef.current.unmount()
            }
        }
    }, [])

    return (
        <Spin spinning={loading} size="large">
            <div id="article-render-wrapper" style={{ width: '100%', minHeight: 200, overflow: 'hidden' }}></div>
        </Spin>
    )
}

export default ArticleRender
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react"
import FlexContainer from "../../../components/FlexContainer"
import { useAsyncEffect } from "ahooks"
import { request } from "../../../utils"
import { capitalize, groupBy, isEmpty, omit, pick, uniq } from "lodash-es"
import { IKeyValuePair } from "../../../typings"
import { Button, Form, Input, Popconfirm, RadioGroup, SideSheet, Table, Tabs, Tag, Toast } from "@douyinfe/semi-ui"
import { AppContext } from "../../../App"
import { IconDelete, IconPlus, IconSearch, IconSetting } from "@douyinfe/semi-icons"
import { SideSheetPlaceholder } from "../AdminLayout"
import { FormApi, withField } from "@douyinfe/semi-ui/lib/es/form"
import copy from "copy-to-clipboard"
import { Editor } from "@monaco-editor/react"
import { FormCodeEditor } from "../../../components/CodeEditor"
import './index.less'
import useKeyValuePairs from "../../../hooks/useKeyValuePairs"

const KeyValueManagement = () => {

    const { windowHeight, navbarHeight } = useContext(AppContext)

    const [loading, setLoading] = useState(true)

    const [keyValuePairList, setKeyValuePairList] = useState<IKeyValuePair[]>([])
    const [activeTab, setActiveTab] = useState('color')

    const [searchKeyword, setSearchKeyword] = useState('')
    const [selectedKeyValuePair, setSelectedKeyValuePair] = useState<IKeyValuePair>()

    const formRef = useRef<FormApi>()

    useAsyncEffect(async () => {
        const respData = await request({
            data: {
                service: 'rds.sql',
                requestParams: [
                    `SELECT * FROM simpo_octopus.key_value_pair`
                ]
            }
        })

        const respData2 = await request({
            data: {
                service: 'rds.sql',
                requestParams: [
                    `SELECT CONCAT("config_", config_id) AS key_value_pair_auto_increment_id, "generic" AS type, "config" AS remark, a.* FROM simpo_octopus.config a`
                ]
            }
        })

        setKeyValuePairList([...respData, ...respData2])
        setLoading(false)
    }, [])

    const keyValuePairsGroupByType = useMemo(() => {
        return groupBy(keyValuePairList, 'type')
    }, [keyValuePairList])

    useEffect(() => {
        if (selectedKeyValuePair) {
            const formData = {
                type: activeTab,
                ...selectedKeyValuePair as any,
            }

            if (formData.value && typeof formData.value === 'object') {
                formData.value = JSON.stringify(formData.value, null, 4)
            }

            formRef.current?.setValues(formData)
        }
    }, [selectedKeyValuePair, activeTab])

    const handleSubmit = useCallback(async (formData: any) => {
        if (activeTab === 'generic') {
            try {
                let isJson = false

                if (formData.value.trim().startsWith('[') || formData.value.trim().startsWith('{')) {
                    formData.value = encodeURIComponent(JSON.stringify(JSON.parse(formData.value)))
                    isJson = true
                }

                await request({
                    data: {
                        __skip_decode__: true,
                        service: 'rds.insert',
                        requestParams: [
                            'simpo_octopus',
                            'config',
                            pick(formData, ['key', 'value']),
                            {
                                onDuplicateKeyUpdate: {
                                    value: 'VALUES(value)',
                                }
                            }
                        ]
                    }
                })

                formData.type = 'generic'
                formData.remark = 'config'

                if (isJson) {
                    formData.value = JSON.parse(decodeURIComponent(formData.value))
                }
            } catch (err) {
                Toast.error('Invalid JSON format')
                return
            }
        } else {
            await request({
                data: {
                    service: 'rds.insert',
                    requestParams: [
                        'simpo_octopus',
                        'key_value_pair',
                        {
                            ...omit(selectedKeyValuePair, ['created_at', 'updated_at']) || {},
                            ...formData
                        },
                        {
                            onDuplicateKeyUpdate: {
                                value: 'VALUES(value)',
                                remark: 'VALUES(remark)'
                            }
                        }
                    ]
                }
            })
        }

        Toast.success('Submit successfully')

        if (isEmpty(selectedKeyValuePair)) {
            setKeyValuePairList(arr => [...arr, formData])
        } else {
            setKeyValuePairList(arr => arr.map(item => {
                if (item.type === formData.type && item.key === formData.key) {
                    return formData
                } else {
                    return item
                }
            }))
        }
    }, [selectedKeyValuePair, activeTab])

    return (
        <FlexContainer className="key-value-management-wrapper" style={{ width: '100%' }}>
            <div style={{ flexGrow: 1 }}>
                <div className="font-weight-black" style={{ fontSize: 20, letterSpacing: -.5, padding: '8px 16px' }}>
                    Key Value Management
                </div>

                <Tabs
                    collapsible
                    activeKey={activeTab}
                    onChange={setActiveTab}
                    tabList={Object.keys(keyValuePairsGroupByType).map(type => {
                        return {
                            tab: capitalize(`${type} (${keyValuePairsGroupByType[type].length})`),
                            itemKey: type
                        }
                    })}
                    contentStyle={{ padding: 0 }}
                    tabBarExtraContent={(
                        <Button theme="solid" icon={<IconPlus />} style={{ position: 'relative', top: -2 }} onClick={() => {
                            setSelectedKeyValuePair({} as any)
                        }}>KV Pair</Button>
                    )}
                />

                <Table
                    pagination={['website', 'category'].includes(activeTab) ? { pageSize: 100 } : false}
                    dataSource={keyValuePairsGroupByType[activeTab]?.filter(item => !searchKeyword || item.key.toLowerCase().includes(searchKeyword.toLowerCase()))}
                    sticky
                    loading={loading}
                    rowKey="key_value_pair_auto_increment_id"
                    style={{ height: windowHeight - navbarHeight - 82, overflow: 'auto' }}
                    columns={[
                        {
                            title: 'Scene',
                            filterMultiple: false,
                            filters: uniq(keyValuePairsGroupByType[activeTab]?.map(item => item.remark)).map(scene => {
                                return {
                                    text: scene,
                                    value: scene
                                }
                            }),
                            onFilter: (value: string, record: any) => {
                                return record.remark === value
                            },
                            dataIndex: 'remark',
                            width: 120,
                        },
                        {
                            title: (
                                <FlexContainer alignItems="center" gap="6px">
                                    <div>Key</div>
                                    <Input size="small" prefix={<IconSearch />} style={{ width: 150 }} onChange={setSearchKeyword} />
                                </FlexContainer>
                            ),
                            dataIndex: 'key'
                        },
                        {
                            title: 'Value',
                            dataIndex: 'value',
                            render: (text: string) => {
                                let el: any

                                if (text && typeof text === 'object') {
                                    text = JSON.stringify(text, null, 4)
                                }

                                switch (activeTab) {
                                    case 'color':
                                        el = (
                                            <div style={{ color: 'white', backgroundColor: text, padding: '0 6px', width: 'fit-content', borderRadius: 2 }}>
                                                {text}
                                            </div>
                                        )
                                        break
                                    case 'category':
                                        el = (
                                            <FlexContainer gap="4px" flexWrap="wrap">
                                                {text?.split('||').filter(Boolean).map(item => <Tag>{item}</Tag>)}
                                            </FlexContainer>
                                        )
                                        break
                                    case 'generic':
                                        el = (
                                            <div className="responsive-background" style={{ padding: 12, borderRadius: 4, background: '#f2f3f5' }}>
                                                <pre style={{ maxHeight: 100, overflow: 'auto', margin: 0, fontSize: 12 }}>
                                                    {text}
                                                </pre>
                                            </div>
                                        )
                                        break
                                    case 'logo':
                                        el = (
                                            <img src={text} width="60px" height="60px" style={{ objectFit: 'contain', borderRadius: 4 }} />
                                        )
                                        break
                                    default:
                                        el = text
                                }

                                return (
                                    <div style={{ cursor: 'pointer' }} onClick={() => {
                                        copy(text)
                                        Toast.success("Copied to clipboard")
                                    }}>
                                        {el}
                                    </div>
                                )
                            }
                        },
                        {
                            title: 'Action',
                            width: 110,
                            render: (record: IKeyValuePair) => {
                                return (
                                    <FlexContainer gap="6px">
                                        <Button icon={<IconSetting />} type="tertiary" theme="solid" style={{ borderRadius: 32 }} onClick={() => {
                                            setSelectedKeyValuePair(record)
                                        }} />

                                        <Popconfirm
                                            title="Delete reminder"
                                            content="Are you sure to delete this key value pair?"
                                            okType="danger"
                                            onConfirm={async () => {
                                                if (record.type === 'generic') {
                                                    await request({
                                                        data: {
                                                            service: 'rds.delete',
                                                            requestParams: [
                                                                'simpo_octopus',
                                                                'config',
                                                                { where: { key: record.key } }
                                                            ]
                                                        }
                                                    })
                                                } else {
                                                    await request({
                                                        data: {
                                                            service: 'rds.delete',
                                                            requestParams: [
                                                                'simpo_octopus',
                                                                'key_value_pair',
                                                                { where: { type: record.type, key: record.key } }
                                                            ]
                                                        }
                                                    })
                                                }

                                                Toast.success("Delete successfully")
                                                setKeyValuePairList(arr => arr.filter(item => !(item.key === record.key && item.type === record.type)))
                                            }}
                                        >
                                            <Button icon={<IconDelete />} type="danger" theme="solid" style={{ borderRadius: 32 }} />
                                        </Popconfirm>
                                    </FlexContainer>
                                )
                            }
                        }
                    ]}
                />
            </div>

            <SideSheetPlaceholder />

            <SideSheet title={`${isEmpty(selectedKeyValuePair) ? 'Create' : 'Edit'} key value pair`} visible={!!selectedKeyValuePair} onCancel={() => setSelectedKeyValuePair(undefined)} mask={false}>
                <Form getFormApi={api => formRef.current = api} onSubmit={handleSubmit}>
                    <Form.Input field="type" label="Type" />
                    {activeTab !== 'generic' && <Form.Input field="remark" label="Scene" />}

                    <Form.Input field="key" label="Key" disabled={!isEmpty(selectedKeyValuePair)} />
                    {activeTab !== 'generic' && <Form.Input field="value" label="Value" />}
                    {activeTab === 'generic' && <FormCodeEditor field="value" label="Value" height="300px" language="json" />}

                    <Button htmlType="submit" theme="solid">Submit</Button>
                </Form>
            </SideSheet>
        </FlexContainer>
    )
}

export default KeyValueManagement
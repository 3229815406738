import { useCallback, useContext, useEffect, useRef, useState } from "react"
import FlexContainer from "../../../components/FlexContainer"
import { Banner, Button, Form, Input, RadioGroup, SideSheet, Table, Tabs, Tag, Toast } from "@douyinfe/semi-ui"
import { useAsyncEffect } from "ahooks"
import { request } from "../../../utils"
import { AppContext } from "../../../App"
import { IconExternalOpen, IconRefresh, IconSearch, IconSetting, IconSync } from "@douyinfe/semi-icons"
import uniq from "lodash-es/uniq"
import { IHotel } from "../../../typings"
import dayjs from "dayjs"
import copy from "copy-to-clipboard"
import { IconCheckbox, IconNotification } from "@douyinfe/semi-icons-lab"
import LoadingButton from "../../../components/LoadingButton"
import './index.less'
import useKeyValuePairs from "../../../hooks/useKeyValuePairs"
import { SideSheetPlaceholder } from "../AdminLayout"
import { FormCodeEditor } from "../../../components/CodeEditor"
import { FormApi } from "@douyinfe/semi-ui/lib/es/form"
import { pick } from "lodash-es"

const HotelManagement = () => {
    const { windowHeight, navbarHeight } = useContext(AppContext)

    const { kvPairs: hiltonBrandNameDict } = useKeyValuePairs('name', 'hilton-brand')

    const [hotelList, setHotelList] = useState<IHotel[]>([])
    const [loading, setLoading] = useState(true)

    const [activeTab, setActiveTab] = useState('marriott')
    const [activeHotel, setActiveHotel] = useState<IHotel>()
    const [searchKeyword, setSearchKeyword] = useState('')

    const [selectedPropertyIdList, setSelectedPropertyIdList] = useState<string[]>([])
    const [v, setV] = useState(1)

    const formRef = useRef<FormApi>()

    useAsyncEffect(async () => {
        setLoading(true)
        const respData = await request({
            data: {
                service: 'rds.sql',
                requestParams: [
                    `SELECT 
                        a.property_id,
                        a.nick_name,
                        a.brand,
                        a.name,
                        a.latitude,
                        a.longitude,
                        a.currency,
                        a.opening_date,
                        a.stars,
                        a.number_of_reviews,
                        a.country,
                        a.location, 
                        standard_last_sync_at, 
                        redemption_last_sync_at
                    FROM simpo_octopus.${activeTab}_hotel a
                    LEFT JOIN (
                        SELECT property_id, MAX(created_at) AS standard_last_sync_at
                        FROM simpo_octopus.${activeTab}_hotel_rate
                        WHERE rate_category = "STANDARD"
                        GROUP BY rate_category, property_id
                    ) b ON a.property_id = b.property_id
                    LEFT JOIN (
                        SELECT property_id, MAX(created_at) AS redemption_last_sync_at
                        FROM simpo_octopus.${activeTab}_hotel_rate
                        WHERE rate_category = "REDEMPTION"
                        GROUP BY rate_category, property_id
                    ) c ON a.property_id = c.property_id
                    `
                ]
            }
        })

        setHotelList(respData)
        setLoading(false)
    }, [v, activeTab])

    const handleSync = useCallback(async (propertyIdList: string[]) => {
        if (activeTab === 'marriott') {
            await request({
                baseURL: 'http://124.71.152.240:7001',
                data: {
                    service: 'marriott.syncHotelRatesByPropertyIds',
                    requestParams: [
                        propertyIdList
                    ]
                }
            })

            await request({
                data: {
                    service: 'rds.pullData',
                    requestParams: [
                        `SELECT * FROM simpo_octopus.marriott_hotel_rate WHERE property_id IN (${propertyIdList.map(id => `"${id}"`).join(', ')})`,
                        `DELETE FROM simpo_octopus.marriott_hotel_rate WHERE property_id IN (${propertyIdList.map(id => `"${id}"`).join(', ')})`,
                        "simpo_octopus",
                        "marriott_hotel_rate"
                    ]
                }
            })
        } else if (activeTab === 'ihg') {
            await request({
                baseURL: 'http://124.71.152.240:7001',
                data: {
                    service: 'ihg.syncHotelRateCalendarByPropertyIds',
                    requestParams: [
                        propertyIdList
                    ]
                }
            })

            await request({
                data: {
                    service: 'rds.pullData',
                    requestParams: [
                        `SELECT * FROM simpo_octopus.ihg_hotel_rate WHERE property_id IN (${propertyIdList.map(id => `"${id}"`).join(', ')})`,
                        `DELETE FROM simpo_octopus.ihg_hotel_rate WHERE property_id IN (${propertyIdList.map(id => `"${id}"`).join(', ')})`,
                        "simpo_octopus",
                        "ihg_hotel_rate"
                    ]
                }
            })
        } else if (activeTab === 'hilton') {
            await request({
                baseURL: 'http://124.71.152.240:7001',
                data: {
                    service: 'hilton.syncHotelRateCalendarByPropertyIds',
                    requestParams: [
                        propertyIdList
                    ]
                }
            })

            await request({
                data: {
                    service: 'rds.pullData',
                    requestParams: [
                        `SELECT * FROM simpo_octopus.hilton_hotel_rate WHERE property_id IN (${propertyIdList.map(id => `"${id}"`).join(', ')})`,
                        `DELETE FROM simpo_octopus.hilton_hotel_rate WHERE property_id IN (${propertyIdList.map(id => `"${id}"`).join(', ')})`,
                        "simpo_octopus",
                        "hilton_hotel_rate"
                    ]
                }
            })
        }

        Toast.success("Sync successfully")
        setV(prev => prev + 1)
    }, [activeTab])

    useEffect(() => {
        if (activeHotel) {
            formRef.current?.setValues({
                json: JSON.stringify(
                    pick(activeHotel, ['property_id', 'nick_name', 'brand', 'name', 'latitude', 'longitude', 'currency', 'opening_date', 'stars', 'number_of_reviews', 'country', 'location', 'address_line_one', 'address_line_two']), 
                    null, 
                    4
                )
            })
        }
    }, [activeHotel])

    return (
        <FlexContainer className="hotel-management-wrapper" style={{ width: '100%' }}>
            <div style={{ flexGrow: 1 }}>
                <div style={{ padding: '8px 16px 0' }}>
                    <div className="font-weight-black" style={{ fontSize: 20, letterSpacing: -0.5 }}>
                        Hotel Management
                    </div>
                </div>
                <div>
                    <Tabs
                        tabList={[
                            { tab: `Marriott`, itemKey: 'marriott' },
                            { tab: 'IHG', itemKey: 'ihg' },
                            { tab: 'Hilton', itemKey: 'hilton' }
                        ]}
                        onChange={(v) => {
                            setActiveTab(v)
                            setSelectedPropertyIdList([])
                        }}
                        contentStyle={{ padding: 0 }}
                        tabBarExtraContent={(
                            <Button icon={<IconRefresh />} loading={loading} onClick={() => setV(prev => prev + 1)}>
                                Refresh
                            </Button>
                        )}
                    />
                </div>

                <Table
                    loading={loading}
                    dataSource={hotelList.filter(hotel => !searchKeyword || hotel.name.toLowerCase().includes(searchKeyword.toLowerCase()))}
                    pagination={{ pageSize: 100 }}
                    sticky
                    rowKey="property_id"
                    rowSelection={{
                        selectedRowKeys: selectedPropertyIdList,
                        onChange: (v: any) => setSelectedPropertyIdList(v)
                    }}
                    style={{ height: windowHeight - navbarHeight - 85, overflow: 'auto' }}
                    columns={[
                        {
                            title: 'ID',
                            width: 90,
                            dataIndex: 'property_id',
                            render: (text: string) => {
                                return <Tag style={{ cursor: 'pointer' }} onClick={() => {
                                    copy(text)
                                    Toast.success('Copied to clipboard')
                                }}>{text}</Tag>
                            }
                        },
                        // {
                        //     title: 'Brand',
                        //     width: 160,
                        //     filterMultiple: false,
                        //     filters: uniq(hotelList.map(item => item.brand)).map(brand => {
                        //         return {
                        //             text: `${brand} (${hotelList.filter(item => item.brand === brand).length})`,
                        //             value: brand
                        //         }
                        //     }),
                        //     onFilter: (value: string, record: any) => {
                        //         return record.brand === value
                        //     },
                        //     dataIndex: 'brand',
                        //     render: (text: string) => {
                        //         if (activeTab === 'hilton') {
                        //             return hiltonBrandNameDict[text] || text || '-'
                        //         } else {
                        //             return text
                        //         }
                        //     }
                        // },
                        {
                            title: 'Country',
                            width: 100,
                            dataIndex: 'country',
                        },
                        {
                            title: (
                                <FlexContainer alignItems="center" gap="6px">
                                    <span>Name</span>
                                    <Input size="small" style={{ width: 150 }} prefix={<IconSearch />} onChange={setSearchKeyword} />
                                </FlexContainer>
                            ),
                            dataIndex: 'name',
                            filterMultiple: false,
                            filters: uniq(hotelList.map(item => item.country)).map(country => {
                                return {
                                    text: `${country} (${hotelList.filter(item => item.country === country).length})`,
                                    value: country
                                }
                            }),
                            onFilter: (value: string, record: any) => {
                                return record.country === value
                            },
                            render: (name: string, record: IHotel) => {
                                return (
                                    <FlexContainer flexDirection="column" gap="4px">
                                        <div>
                                            <Tag>{record.brand}</Tag>
                                        </div>
                                        <div style={{ paddingLeft: 8 }}>{name}</div>
                                    </FlexContainer>
                                )
                            }
                        },
                        {
                            title: 'Last sync at',
                            render: (record: IHotel) => {
                                if (!record.standard_last_sync_at && !record.redemption_last_sync_at) {
                                    return '-'
                                }

                                return (
                                    <FlexContainer flexDirection="column" gap="4px">
                                        {!!record.standard_last_sync_at && (
                                            <div>
                                                <Tag color="blue">Standard: {dayjs(record.standard_last_sync_at).fromNow()}</Tag>
                                            </div>
                                        )}
                                        {!!record.redemption_last_sync_at && (
                                            <div>
                                                <Tag color="green">Redemption: {dayjs(record.redemption_last_sync_at).fromNow()}</Tag>
                                            </div>
                                        )}
                                    </FlexContainer>
                                )
                            }
                        },
                        {
                            title: 'Action',
                            width: 140,
                            render: (record: IHotel) => {
                                return (
                                    <FlexContainer gap="6px">
                                        <Button type="tertiary" icon={<IconExternalOpen />} style={{ borderRadius: 32 }} onClick={() => {
                                            window.open(`/reward-hotels/${activeTab}/${record.property_id}`)
                                        }} />
                                        <Button theme="solid" type="tertiary" style={{ borderRadius: 32 }} icon={<IconSetting />} onClick={() => setActiveHotel(record)}></Button>
                                        <LoadingButton theme="solid" type="primary" icon={<IconSync />} style={{ borderRadius: 32 }} onClick={() => handleSync([record.property_id])} />
                                    </FlexContainer>
                                )
                            }
                        }
                    ]}
                />

                {!!selectedPropertyIdList.length && (
                    <div style={{ background: 'white', position: 'sticky', bottom: 48 }} className="responsive-background">
                        <Banner
                            fullMode={false}
                            closeIcon={null}
                            icon={null}
                            style={{ borderRadius: 0 }}
                            description={(
                                <FlexContainer justifyContent="space-between" alignItems="center">
                                    <div>
                                        {selectedPropertyIdList.length} properties selected.
                                    </div>

                                    <LoadingButton size="small" theme="borderless" icon={<IconSync />} onClick={() => handleSync(selectedPropertyIdList)}>Sync</LoadingButton>
                                </FlexContainer>
                            )}

                        />
                    </div>
                )}

                <SideSheet title="Edit property" visible={!!activeHotel} onCancel={() => setActiveHotel(undefined)} mask={false}>
                    <Form getFormApi={api => formRef.current = api}>
                        <FormCodeEditor
                            label="Hotel detail"
                            field="json"
                            height="300px"
                            language="json"
                        />

                        <Button theme="solid" htmlType="submit">Submit</Button>
                    </Form>
                </SideSheet>
            </div>

            <SideSheetPlaceholder />
        </FlexContainer>
    )
}

export default HotelManagement
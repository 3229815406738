import React, { lazy, Suspense } from 'react';
import { json } from "./lottie"

// Lazy load the Lottie component
const LazyLottie = lazy(() =>
  import('react-lottie-player').then(module => ({ default: module.default }))
);

const LoadingSpinner = () => {
  return (
    <Suspense fallback={<div>Loading Spinner...</div>}>
      <LazyLottie
        loop
        animationData={json}
        play
        // style={{ width: 150, height: 150 }} // Specify size
      />
    </Suspense>
  );
};

export default LoadingSpinner;
import { Button, Form, Modal, Table, Toast } from "@douyinfe/semi-ui"
import FlexContainer from "../../../components/FlexContainer"
import { IconArrowRight, IconSync } from "@douyinfe/semi-icons"
import { useEffect, useRef, useState } from "react"
import { FormCodeEditor } from "../../../components/CodeEditor"
import { FormApi } from "@douyinfe/semi-ui/lib/es/form"
import dayjs from "dayjs"
import { request } from "../../../utils"

const DatabaseSynchronization = () => {

    const [database, setDatabase] = useState('')
    const [confirmLoading, setConfirmLoading] = useState(false)

    const formRef = useRef<FormApi>()

    const dataSource = [
        {
            database: 'marriott_hotel_rate',
            primary_key: 'property_id',
        },
        {
            database: 'ihg_hotel_rate',
            primary_key: 'property_id',
        },
        {
            database: 'hilton_hotel_rate',
            primary_key: 'property_id',
        }
    ]

    const handleSubmit = async (v: any) => {
        try {
            setConfirmLoading(true)
            const customFilter = JSON.parse(v.custom_filter)

            const whereCommand = [
                `created_at >= "${dayjs(v.created_at).format('YYYY-MM-DD HH:mm:ss')}"`,
                ...Object.entries(customFilter).map(([k, v]) => {
                    return `${k} = "${v}"`
                })
            ].join(' AND ')

            const respData = await request({
                data: {
                    service: 'rds.pullData',
                    requestParams: [
                        v.database, v.primary_key, whereCommand
                    ]
                }
            })

            Toast.success(`Sync task created successfully [ID: ${respData}]`)
            setDatabase('')
        } catch (err) {
            Toast.error('Invalid custom filter')
            return
        } finally {
            setConfirmLoading(false)
        }
    }

    useEffect(() => {
        if (database) {
            formRef.current?.setValues({
                custom_filter: '{}',
                ...dataSource.find(item => item.database === database) || {}
            })
        }
    }, [database])

    return (
        <div style={{ width: '100%' }}>
            <div className='font-weight-black responsive-text' style={{ fontSize: 20, letterSpacing: -0.5, padding: '8px 0 8px 16px' }}>
                Database Synchronization
            </div>

            <Table
                size="small"
                columns={[
                    {
                        title: 'Database',
                        dataIndex: 'database',
                    },
                    {
                        title: 'Action',
                        width: 130,
                        render: (record: any) => {
                            return (
                                <FlexContainer>
                                    <Button theme="solid" style={{ borderRadius: 32 }} icon={<IconSync />} onClick={() => setDatabase(record.database)}>
                                        <FlexContainer alignItems="center" gap="4px">
                                            CN <IconArrowRight style={{ fontSize: 12 }} /> AU
                                        </FlexContainer>
                                    </Button>
                                </FlexContainer>
                            )
                        }
                    }
                ]}
                pagination={false}
                dataSource={dataSource}
            />

            <Modal confirmLoading={confirmLoading} visible={!!database} title="Create synchronization task" onOk={() => formRef.current?.submitForm()} onCancel={() => setDatabase('')}>
                <Form getFormApi={api => formRef.current = api} onSubmit={handleSubmit}>
                    <Form.Input
                        label="Database"
                        field="database"
                        style={{ width: '100%' }}
                        disabled
                        rules={[{ required: true }]}
                    />
                    <Form.Input
                        label="Primary key"
                        field="primary_key"
                        rules={[{ required: true }]}
                    />
                    <Form.DatePicker
                        type="dateTime"
                        label="Created at"
                        field="created_at"
                        style={{ width: '100%' }}
                        rules={[{ required: true }]}
                    />
                    <FormCodeEditor
                        label="Custom filter"
                        field="custom_filter"
                        height="200px"
                    />
                </Form>
            </Modal>
        </div>
    )
}

export default DatabaseSynchronization
export const initSeo = ({ title, description }: { title: string, description?: string }) => {
    document.title = title

    if (description) {
        // Select the existing meta description tag
        let metaDescription = document.querySelector('meta[name="description"]');

        // If it exists, update its content. Otherwise, create a new one
        if (metaDescription) {
            metaDescription.setAttribute("content", description);
        } else {
            metaDescription = document.createElement('meta');
            metaDescription.setAttribute("name", "description");
            metaDescription.setAttribute("content", description);
            document.head.appendChild(metaDescription);
        }
    }
}

export const resetSeo = () => {
    initSeo({
        title: 'Your Ultimate Deals & Discount Community | HotAuDeals',
        description: 'Discover your ultimate savings community at HotAuDeals! Access exclusive gift card promotions, cashback offers, catalogue previews, and grocery items. Join us to maximize your savings effortlessly and stay updated on the best deals available.'
    })
}
import FlexContainer from "../../../../components/FlexContainer"

export const VALogo = (props: any) => {
    return (
        <div style={{ fill: '#e10a0a', background: 'white', borderRadius: 300, ...props.style || {} }}>
            <svg xmlns="http://www.w3.org/2000/svg" height="100%" stroke-miterlimit="10" version="1.1" viewBox="0 0 16 16" width="100%"><g><path d="M8 1.77636e-15C3.58172 1.77636e-15 0 3.58172 0 8C0 12.4183 3.58172 16 8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 1.77636e-15 8 1.77636e-15ZM3.6875 4.46875L5.75 4.46875L7.09375 8.125L8.9375 8.09375L10.25 4.46875L12.2812 4.46875L10.8125 8.03125L11.9375 8L11.9375 8.4375L10.6562 8.40625L8.84375 12.875L7.125 12.875L5.25 8.3125L2.46875 8.25L5.21875 8.1875L3.6875 4.46875ZM13.0938 7.125C13.2389 7.1368 13.2813 7.21875 13.2812 7.21875L13.4062 7.40625L13.6875 7.8125L13.6875 7.84375L13.8438 8.09375L14.25 8.09375C14.5628 8.09572 14.625 8.25 14.625 8.25C14.625 8.25 14.5629 8.37675 14.25 8.375L13.8438 8.375L13.8125 8.4375L13.25 9.25C13.2269 9.27186 13.1627 9.30594 13.0625 9.3125L13.3438 8.34375L13.25 8.34375L12.7188 8.34375L12.375 8.5625C12.375 8.5625 12.3644 8.55092 12.3438 8.5625C12.3229 8.57453 12.2997 8.55137 12.3125 8.53125L12.5 8.25L12.5 8.1875L12.3125 7.90625C12.3001 7.88592 12.3231 7.86254 12.3438 7.875C12.3637 7.8868 12.375 7.90625 12.375 7.90625L12.7188 8.125L13.25 8.09375L13.3438 8.09375L13.0938 7.125ZM7.1875 8.34375L8 10.5938L8.8125 8.375L7.1875 8.34375Z" fill-rule="evenodd"></path></g></svg>
        </div>
    )
}

export const QFLogo = (props: any) => {
    return (
        <FlexContainer justifyContent="center" alignItems="center" style={{ ...props.style || {}, borderRadius: 300, background: 'rgb(228, 0, 0)', color: 'white' }}>
            <div className="font-weight-bold">Q</div>
        </FlexContainer>
    )
}
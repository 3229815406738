import { useContext, useState } from "react"
import { IGiftCardDetail, IGiftCardPromotion, IGiftCardRetailerDetail } from "../../../../../typings"
import { useAsyncEffect } from "ahooks"
import { request } from "../../../../../utils"
import dayjs from "dayjs"
import { FOOTER_HEIGHT, HEADER_HEIGHT, RADIUS } from "../CatalogueRender"
import FlexContainer from "../../../../../components/FlexContainer"
import { Card } from "@douyinfe/semi-ui"
import { groupBy } from "lodash-es"
import { Footer } from "../SceneRender"
import { AppContext } from "../../../../../App"
import GiftCardPoster from "../../../../GiftCards/components/GiftCardPoster"
import { PosterContext } from "../.."

const GiftCardRender = () => {
    const { COLOR_MAP } = useContext(AppContext)
    const { setTotalPageNum, pageNum } = useContext(PosterContext)

    const [giftCardPromotionList, setGiftCardPromotionList] = useState<IGiftCardPromotion[]>([])
    const [giftCardListList, setGiftCardListList] = useState<IGiftCardDetail[][]>([])
    // const [retailerList, setRetailerList] = useState<IGiftCardRetailerDetail[]>([])

    useAsyncEffect(async () => {
        const todayDate = dayjs().format('YYYY-MM-DD')

        const respData = await request({
            method: 'POST',
            url: '/api/v1/service/execute',
            data: {
                service: 'rds.sql',
                requestParams: [
                    `SELECT * FROM simpo_octopus.au_gift_card_promotion WHERE end_date > "${todayDate}" AND platform IN ("coles", "woolworths")`,
                ]
            }
        })

        const detailRespData: IGiftCardDetail[][] = await Promise.all(respData.map((item: any) => {
            return request({
                method: 'POST',
                url: '/api/v1/service/execute',
                data: {
                    service: 'au.hotaudeals.getGiftCardPromotionDetail',
                    requestParams: [
                        item?.offer_text
                    ]
                }
            })
        })) 

        // const flatRetailerList = detailRespData.map(item => item.retailerList).flat()
        // const flatRetailerListGroupByName = groupBy(flatRetailerList, 'name')


        // const uniqRetailerName = Object.keys(flatRetailerListGroupByName)

        // const uniqRetailerList = uniqRetailerName.map(name => {
        //     return {
        //         ...flatRetailerList.find(item => item.name === name) || {},
        //         eligibleGiftCardList: flatRetailerListGroupByName[name].map(item => item.gift_card_id)
        //     }
        // })

        // setRetailerList(uniqRetailerList as any)

        setGiftCardListList(detailRespData)
        setGiftCardPromotionList(respData)
        setTotalPageNum(respData.length)
    }, [])

    return (
        <FlexContainer flexDirection="column" alignItems="center" style={{ height: '100%', width: '100%' }}>
            <div style={{ background: `linear-gradient(to bottom, transparent, rgb(0,100,250) calc(100% - ${HEADER_HEIGHT}px), rgba(0,100,250,0.9) 100%)`, position: 'absolute', height: RADIUS * 2, width: RADIUS * 2, top: 0 - RADIUS * 2 + HEADER_HEIGHT, borderRadius: '50%', zIndex: 0 }} />

            <FlexContainer flexDirection="column" alignItems="center" style={{ position: 'relative', zIndex: 1, height: '100%', width: '100%' }}>
                <FlexContainer flexDirection="column" alignItems="center" style={{ height: HEADER_HEIGHT, color: 'white' }}>
                    <div style={{ fontSize: 36, letterSpacing: -1, fontWeight: 700 }}>
                        Gift Cards
                    </div>

                    <div style={{ marginTop: -10 }}>
                        Valid from {dayjs(giftCardPromotionList?.[0]?.start_date).format('DD/MM')} to {dayjs(giftCardPromotionList?.[0]?.end_date).format('DD/MM')}
                    </div>
                </FlexContainer>


                <FlexContainer flexDirection="column" justifyContent="center" style={{ padding: '8px 24px', width: '100%', height: `calc(100% - ${HEADER_HEIGHT}px - ${FOOTER_HEIGHT}px)`, boxSizing: 'border-box', overflow: 'auto' }}>
                    {/* <div style={{ fontSize: 20, letterSpacing: -1 }}>Available Retailers</div> */}

                    {/* <FlexContainer flexWrap="wrap" gap="8px" >
                        {retailerList.map(item => {
                            return (
                                <Card style={{ width: `calc((100% - 24px) / 4)` }} bodyStyle={{ padding: '4px 8px', height: '100%' }}>
                                    <FlexContainer alignItems="center" style={{ height: '100%' }}>
                                        <img src={item.logo} width="100%" style={{ objectFit: 'contain', maxHeight: 30 }} />
                                    </FlexContainer>
                                </Card>
                            )
                        })}
                    </FlexContainer> */}

                    
                    <GiftCardPoster giftCardPromotion={giftCardPromotionList[pageNum - 1]} giftCardList={giftCardListList[pageNum - 1] || []} embedded />


                </FlexContainer>

                <Footer />
            </FlexContainer>

            <div style={{ background: `linear-gradient(to bottom, rgb(53, 55, 59), rgb(53, 55, 59) calc(100% - ${FOOTER_HEIGHT}px), transparent 100%)`, position: 'absolute', height: RADIUS * 2, width: RADIUS * 2, bottom: 0 - RADIUS * 2 + FOOTER_HEIGHT, borderRadius: '50%', zIndex: 0 }} />
        </FlexContainer>
    )
}

export default GiftCardRender
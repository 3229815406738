import { useNavigate, useParams } from "react-router-dom"
import FlexContainer from "../../components/FlexContainer"
import { Banner, Button, Collapse, Divider, Image, Input, Select, Skeleton, Spin, Table, Tag, Toast } from "@douyinfe/semi-ui"
import { IconArrowLeft, IconBell, IconBulb, IconChevronDown, IconChevronRight, IconExternalOpen, IconExternalOpenStroked, IconList, IconPlus, IconSearch, IconShareStroked } from "@douyinfe/semi-icons"
import { useContext, useEffect, useMemo, useState } from "react"
import { AppContext } from "../../App"
import classNames from "classnames"
import copy from "copy-to-clipboard"
import SimilarProductSidesheet from "../GroceriesByPlatform/components/SimilarProductSidesheet"
import { useAsyncEffect } from "ahooks"
import { getGroceryItemUrl, request } from "../../utils"
import { ICatalogue, IDeal } from "../../typings"
import PriceHistoryPane from "../GroceriesByPlatform/components/PriceHistoryPane"
import { capitalize, debounce } from "lodash-es"
import ProductCard from "../GroceriesByPlatform/components/ProductCard"
import { OptionProps } from "@douyinfe/semi-ui/lib/es/select"
import dayjs from "dayjs"
import { initSeo, resetSeo } from "../../utils/seo"

const GroceriesDetail = () => {
    const { platform, sku_id } = useParams()
    const { isMobile, windowHeight, navbarHeight, windowWidth, COLOR_MAP } = useContext(AppContext)

    const navigate = useNavigate()

    const [loading, setLoading] = useState(true)
    const [productDetail, setProductDetail] = useState<IDeal>()
    const [buyingOptionList, setBuyingOptionList] = useState<IDeal[]>([])

    const [searchItemList, setSearchItemList] = useState<IDeal[]>([])
    const [searchLoading, setSearchLoading] = useState(false)

    const [exactMatchProductDetail, setExactMatchProductDetail] = useState<IDeal>()
    const [catalogueItemList, setCatalogueItemList] = useState<ICatalogue[]>([])

    const [expiredSaleHistoryExpanded, setExpiredSaleHistoryExpanded] = useState(false)
    const [priceHistoryDisplayMode, setPriceHistoryDisplayMode] = useState('timeline')

    useAsyncEffect(async () => {
        setExpiredSaleHistoryExpanded(false)
        setLoading(true)
        setProductDetail(undefined)
        setBuyingOptionList([])
        setExactMatchProductDetail(undefined)

        const respData = await request({
            // baseURL: 'http://localhost:7001',
            data: {
                service: 'au.hotaudeals.getGroceryItemDetail',
                requestParams: [platform, sku_id]
            }
        })

        if (respData?.productDetail) {
            initSeo({
                title: `${respData?.productDetail?.name} @ ${capitalize(platform)} | HotAuDeals`,
                description: `Stay informed about the latest offers on your favorite grocery items at Coles and Woolworths by checking current prices, purchasing advice, price history, and promotions at HotAuDeals.`
            })
        }

        setProductDetail(respData?.productDetail)
        setExactMatchProductDetail(respData?.exactMatchItemDetail)
        setBuyingOptionList(respData?.buyingOptionList)
        setCatalogueItemList(respData?.catalogueItemList)
        setLoading(false)
    }, [sku_id])

    useEffect(() => {
        return () => {
            resetSeo()
        }
    }, [])

    const showInOneColumn = useMemo(() => {
        if (isMobile) {
            return true
        }

        return windowWidth < 600
    }, [isMobile, windowWidth])

    const handleSearch = async (v: string) => {
        if (!v || v.length < 3) {
            setSearchItemList([])
        }

        setSearchLoading(true)
        const respData = await request({
            data: {
                service: 'rds.sql',
                requestParams: [
                    `SELECT * FROM simpo_octopus.au_${platform}_product_tmp WHERE name LIKE "%${v}%" LIMIT 10`
                ]
            }
        })

        setSearchItemList(respData)
        setSearchLoading(false)
    }

    const buyingTips = useMemo(() => {
        if (loading) {
            return []
        }

        const tips = []

        if (exactMatchProductDetail?.price && productDetail?.price) {

            if (exactMatchProductDetail.price === productDetail.price) {
                tips.push((
                    <FlexContainer alignItems="center">
                        You can purchase from either supermarket since they both have the same price at the moment.
                    </FlexContainer>
                ))
            } else {
                const bestPlatform = productDetail.price > exactMatchProductDetail.price ? exactMatchProductDetail.platform : productDetail.platform
                tips.push((
                    <FlexContainer alignItems="center">
                        We recommend buying at
                        <Tag style={{ background: COLOR_MAP[bestPlatform], marginLeft: 6, marginRight: 4 }}>{capitalize(bestPlatform)}</Tag>
                        , as it's currently ${Math.abs(exactMatchProductDetail.price - productDetail.price)} cheaper.
                    </FlexContainer>
                ))
            }
        }

        if (productDetail?.extra_data?.isLowestPrice) {
            tips.push((
                <div>
                    <div style={{ marginBottom: 4 }}>
                        <Tag style={{ background: COLOR_MAP[platform as string] }}>Historical Low</Tag>
                    </div>
                    Lowest price since July 2024.
                </div>

                // <FlexContainer flexWrap="wrap">
                //     The current price is at its <Tag style={{ background: COLOR_MAP[platform as string], margin: '0 6px' }}>historical low</Tag> since July 2024.
                // </FlexContainer>
            ))
        }

        const today = dayjs().format('YYYY-MM-DD')
        const isPromotionActive = catalogueItemList.find(item => {
            return item.start_date && item.start_date <= today && item.end_date && item.end_date >= today
        })

        if (isPromotionActive) {
            tips.push((
                <div>
                    <div style={{ marginBottom: 4 }}>
                        <Tag style={{ background: 'rgb(254, 220, 2)', color: 'black' }}>{isPromotionActive.item_offer_text}</Tag>
                    </div>
                    Featured in this week's catalogue, ending Tuesday.
                </div>
            ))
        }

        const isPromotionUpcoming = catalogueItemList.find(item => {
            return item.start_date && item.start_date > today
        })

        if (isPromotionUpcoming) {
            tips.push((
                <div>
                    <div style={{ marginBottom: 4 }}>
                        <Tag style={{ background: 'rgb(254, 220, 2)', color: 'black' }}>{isPromotionUpcoming.item_offer_text}</Tag>
                    </div>
                    Featured in next week's catalogue, {isPromotionUpcoming.offer_text}.
                </div>
            ))
        }

        return tips
    }, [exactMatchProductDetail, catalogueItemList, loading, productDetail, COLOR_MAP])

    return (
        <Spin spinning={loading} size="large">
            <FlexContainer justifyContent="center" className="groceries-detail-wrapper" style={{ padding: isMobile ? 12 : 20, maxHeight: windowHeight - navbarHeight, overflow: 'auto', boxSizing: 'border-box' }}>
                <FlexContainer style={{ maxWidth: 1000, width: '100%', height: '100%' }} flexDirection="column">
                    {/* {isMobile && (
                        <div style={{ marginBottom: 4 }}>
                            <Button theme="borderless" style={{ padding: 0 }} size="small" icon={<IconArrowLeft style={{ fontSize: 14 }} />} onClick={() => navigate('/groceries')}>
                                Back to cashback promotions
                            </Button>
                        </div>
                    )} */}

                    {!isMobile && (
                        <FlexContainer style={{ marginBottom: 16 }} justifyContent="space-between" gap="24px">
                            <FlexContainer className={classNames({
                                'responsive-text': true,
                                'logo-medium-wrapper': isMobile
                            })} alignItems="center" gap="16px">
                                <div style={{ fontSize: 24, fontWeight: 700, letterSpacing: -1 }}>
                                    {productDetail?.name}
                                </div>
                            </FlexContainer>

                            <FlexContainer alignItems="center" gap="12px">
                                <Select
                                    size="large"
                                    prefix={<IconSearch />}
                                    style={{ borderRadius: 32, width: 220 }}
                                    placeholder="Search"
                                    showArrow={false}
                                    key={sku_id}
                                    remote
                                    filter
                                    onSearch={debounce(handleSearch, 500)}
                                    loading={searchLoading}
                                    onChange={v => {
                                        navigate(`/groceries/${platform}/${v}`)
                                        setSearchItemList([])
                                    }}
                                    optionList={searchItemList.map(item => {
                                        return {
                                            label: (
                                                <FlexContainer gap="6px" alignItems="center">
                                                    <Image src={item.pic_url} width={40} />
                                                    <div>
                                                        <div className="text-overflow" style={{ fontSize: 14, width: 300 }}>{item.name}</div>
                                                        <div style={{ color: COLOR_MAP[platform as string], letterSpacing: -0.2 }}>${item.price}</div>
                                                    </div>
                                                </FlexContainer>
                                            ),
                                            value: item.sku_id,
                                            showTick: false
                                        }
                                    })}
                                />

                                <Button
                                    icon={<IconShareStroked style={{ fontSize: 20 }} />}
                                    type="tertiary"
                                    style={{ borderRadius: 100, width: 40, height: 40 }}
                                    onClick={() => {
                                        copy(window.location.href)
                                        Toast.success("Link copied to clipboard")
                                    }}
                                />
                            </FlexContainer>
                        </FlexContainer>
                    )}


                    <FlexContainer gap={isMobile ? "12px" : "20px"} flexWrap={showInOneColumn ? 'wrap' : 'nowrap'}>
                        <FlexContainer flexDirection="column" gap={isMobile ? "12px" : "20px"} style={{ boxSizing: 'border-box', ...showInOneColumn ? { width: '100%' } : { width: 260, flexGrow: 0, flexShrink: 0 } }}>
                            <FlexContainer
                                flexDirection="column"
                                gap={isMobile ? "12px" : "20px"}
                                style={{
                                    position: 'sticky',
                                    top: 0
                                }}
                            >
                                <FlexContainer
                                    className="responsive-background-secondary"
                                    flexDirection="column"
                                    gap="8px"
                                    style={{
                                        borderRadius: '4px',
                                        background: 'white',
                                        width: '100%',
                                        height: 'fit-content',
                                        padding: 16,
                                        boxSizing: 'border-box',
                                        position: 'relative'
                                    }}
                                >
                                    {/* {isMobile && (
                                        <Button>

                                        </Button>
                                    )} */}

                                    {loading ? (
                                        <FlexContainer flexDirection="column" gap="12px" style={{ marginBottom: 4 }}>
                                            <Skeleton.Image style={{ height: (isMobile ? windowWidth - 28 * 2 : 228) }} />
                                            <Skeleton.Title style={{ width: 200 }} />
                                            <Skeleton.Paragraph rows={2} />
                                        </FlexContainer>
                                    ) : (
                                        <>
                                            <Image
                                                src={productDetail?.pic_url}
                                                style={platform === 'woolworths' ? { padding: 24, boxSizing: 'border-box', background: 'white' } : {}}
                                                imgStyle={{ objectFit: 'contain', height: (isMobile ? windowWidth - 28 * 2 : 228) - (platform === 'woolworths' ? 48 : 0) }}
                                                width="100%"
                                            />
                                            <div
                                                className="font-weight-black responsive-text"
                                                style={isMobile ? { fontSize: 18, letterSpacing: -0.5 } : { letterSpacing: -0.2 }}
                                            >
                                                {productDetail?.name}
                                            </div>

                                            <div>
                                                <div className="font-weight-font" style={{ fontSize: 20, letterSpacing: -1, color: COLOR_MAP[platform as string] }}>
                                                    ${productDetail?.price}
                                                </div>
                                                <div style={{ fontSize: 12, color: 'grey', marginTop: -2, marginBottom: 4 }}>
                                                    {productDetail?.unit_price}
                                                </div>
                                            </div>
                                        </>
                                    )}



                                    <Button
                                        block
                                        style={{ background: COLOR_MAP[platform as string], height: 36, borderRadius: 6, color: 'white' }}
                                        onClick={() => {
                                            console.log(productDetail)

                                            if (productDetail) {
                                                window.open(getGroceryItemUrl(productDetail))
                                            }
                                        }}
                                    >
                                        Open in {capitalize(platform)}
                                    </Button>

                                    <Button
                                        block
                                        type="tertiary"
                                        className="responsive-background"
                                        style={{ height: 36, borderRadius: 6 }}
                                        icon={<IconPlus />}
                                    >
                                        Add to Shopping List
                                    </Button>
                                </FlexContainer>

                                {!!exactMatchProductDetail && (
                                    <FlexContainer
                                        className='responsive-background-secondary'
                                        flexDirection="column"
                                        style={{
                                            width: '100%',
                                            background: 'white',
                                            borderRadius: 4,
                                            padding: 16,
                                            height: 'fit-content',
                                            boxSizing: 'border-box',
                                        }}
                                    >
                                        <div className="font-weight-black responsive-text" style={{ letterSpacing: -0.2, marginBottom: 8 }}>Exact Match at {platform === 'coles' ? "Woolworths" : 'Coles'}</div>

                                        <FlexContainer alignItems="center" gap="8px" className='cursor-pointer' onClick={() => {
                                            navigate(`/groceries/${exactMatchProductDetail?.platform}/${exactMatchProductDetail?.sku_id}`)
                                        }}>
                                            <Image
                                                src={exactMatchProductDetail?.pic_url}
                                                width={40}
                                                style={{ padding: exactMatchProductDetail?.platform === 'woolworths' ? 4 : 0, background: 'white' }}
                                            />
                                            <div>
                                                <div className="font-weight-font" style={{ fontSize: 20, letterSpacing: -1, color: COLOR_MAP[exactMatchProductDetail?.platform as string] }}>
                                                    ${exactMatchProductDetail?.price}
                                                </div>
                                                <div style={{ fontSize: 12, color: 'grey', marginTop: -2, marginBottom: 4 }}>
                                                    {exactMatchProductDetail?.unit_price}
                                                </div>
                                            </div>
                                        </FlexContainer>
                                    </FlexContainer>
                                )}
                            </FlexContainer>
                        </FlexContainer>

                        <FlexContainer
                            flexDirection="column"
                            gap={isMobile ? '12px' : "20px"}
                            style={{
                                flexGrow: 0,
                                boxSizing: 'border-box',
                                width: '100%',
                                ...showInOneColumn ? {} : { width: 720 }
                            }}
                        >
                            <FlexContainer
                                // className='responsive-background-secondary'
                                flexDirection="column"
                                style={{
                                    width: '100%',
                                    // background: 'white',
                                    borderRadius: 4,
                                    padding: '12px 16px 16px',
                                    height: 'fit-content',
                                    boxSizing: 'border-box',
                                    border: `1px solid ${COLOR_MAP[platform as string]}`,
                                    background: COLOR_MAP[platform as string] + '10'
                                }}
                            >
                                <FlexContainer alignItems="center" gap="4px" className="font-weight-black" style={{ letterSpacing: -0.2, color: COLOR_MAP[platform as string] }}>
                                    <IconBulb />
                                    Buying Tips
                                </FlexContainer>

                                {!!buyingTips.length && (
                                    <ul style={{ padding: 0, margin: 0, paddingLeft: 18, fontSize: 14, paddingTop: 8 }}>
                                        {buyingTips.map(tip => {
                                            return (
                                                <li style={{ paddingLeft: 2, paddingBottom: 6 }}>
                                                    {tip}
                                                </li>
                                            )
                                        })}
                                    </ul>
                                )}
                            </FlexContainer>

                            {!!catalogueItemList && !!catalogueItemList.length && (
                                <FlexContainer
                                    className='responsive-background-secondary'
                                    flexDirection="column"
                                    style={{
                                        width: '100%',
                                        background: 'white',
                                        borderRadius: 4,
                                        padding: 16,
                                        height: 'fit-content',
                                        boxSizing: 'border-box',
                                    }}
                                >
                                    <div className="font-weight-black responsive-text" style={{ letterSpacing: -0.2, paddingBottom: 8 }}>Promotion History</div>

                                    <div>
                                        {catalogueItemList.filter(item => (item.end_date as string) >= dayjs().format('YYYY-MM-DD')).map((item, idx) => {
                                            return (
                                                <>
                                                    {idx !== 0 && <Divider style={{ margin: '6px 0 4px' }} />}
                                                    <FlexContainer justifyContent="space-between" alignItems="center">
                                                        <FlexContainer flexDirection="column">
                                                            <div style={{ letterSpacing: -0.2 }}>{item.name}</div>
                                                            <div style={{ fontSize: 12, color: 'grey' }}>{item.offer_text}</div>
                                                        </FlexContainer>

                                                        <div style={{ color: COLOR_MAP[platform as string], fontSize: 18, letterSpacing: -0.5 }}>
                                                            ${item.new_price}
                                                        </div>
                                                    </FlexContainer>
                                                </>

                                            )
                                        })}

                                        {catalogueItemList.filter(item => (item.end_date as string) >= dayjs().format('YYYY-MM-DD')).length === 0 && (
                                            <FlexContainer style={{ color: 'grey', fontSize: 14 }}>
                                                No active or upcoming promotions yet.
                                            </FlexContainer>
                                        )}
                                    </div>

                                    {!!catalogueItemList.filter(item => (item.end_date as string) < dayjs().format('YYYY-MM-DD')).length && (
                                        <div style={{ paddingTop: 8 }}>
                                            <Button theme="borderless" size="small" style={{ padding: 0 }} icon={expiredSaleHistoryExpanded ? <IconChevronDown /> : <IconChevronRight />} iconPosition="right" onClick={() => {
                                                setExpiredSaleHistoryExpanded(prev => !prev)
                                            }}>
                                                {expiredSaleHistoryExpanded ? 'Hide' : 'View'} expired promotions ({catalogueItemList.filter(item => (item.end_date as string) < dayjs().format('YYYY-MM-DD')).length})
                                            </Button>
                                        </div>
                                    )}



                                    {expiredSaleHistoryExpanded && catalogueItemList.filter(item => (item.end_date as string) < dayjs().format('YYYY-MM-DD')).map((item, idx) => {
                                        return (
                                            <>

                                                {idx !== 0 && <Divider style={{ margin: '6px 0 4px' }} />}
                                                <FlexContainer justifyContent="space-between" alignItems="center" style={{ paddingTop: idx === 0 ? 4 : 0 }}>
                                                    <FlexContainer flexDirection="column">
                                                        <div style={{ letterSpacing: -0.2 }}>{item.name}</div>
                                                        <div style={{ fontSize: 12, color: 'grey' }}>{item.offer_text}</div>
                                                    </FlexContainer>

                                                    <div style={{ color: COLOR_MAP[platform as string], fontSize: 18, letterSpacing: -0.5 }}>
                                                        ${item.new_price}
                                                    </div>
                                                </FlexContainer>
                                            </>

                                        )
                                    })}
                                </FlexContainer>
                            )}

                            <FlexContainer
                                className='responsive-background-secondary'
                                flexDirection="column"
                                style={{
                                    width: '100%',
                                    background: 'white',
                                    borderRadius: 4,
                                    padding: '16px 8px',
                                    height: 'fit-content',
                                    boxSizing: 'border-box',
                                }}
                            >
                                <div className="font-weight-black responsive-text" style={{ letterSpacing: -0.2, padding: '0 8px' }}>Price History</div>

                                <div>
                                    {!!productDetail && (
                                        <PriceHistoryPane platform={platform as string} sku_id={sku_id as string} />
                                    )}
                                </div>
                            </FlexContainer>

                            {!!buyingOptionList && !!buyingOptionList.length && (
                                <FlexContainer
                                    className='responsive-background-secondary'
                                    flexDirection="column"
                                    style={{
                                        width: '100%',
                                        background: 'white',
                                        borderRadius: 4,
                                        padding: '16px',
                                        height: 'fit-content',
                                        boxSizing: 'border-box',
                                    }}
                                >
                                    <div className="font-weight-black responsive-text" style={{ letterSpacing: -0.2, paddingBottom: 8 }}>You May Also Like</div>
                                    {buyingOptionList.map(item => {
                                        return (
                                            <div style={{ cursor: 'pointer' }} onClick={() => {
                                                navigate(`/groceries/${platform}/${item.sku_id}`)
                                            }}>
                                                <ProductCard
                                                    product={item}
                                                    cardProps={{
                                                        className: 'responsive-border',
                                                        style: { width: '100%', borderRadius: 0, borderBottom: `1px solid rgba(28,31,35,.08)` },
                                                        bordered: false,
                                                    }}
                                                    platform={platform as string}
                                                />
                                            </div>
                                        )
                                    })}
                                </FlexContainer>
                            )}
                        </FlexContainer>
                    </FlexContainer>


                </FlexContainer>
            </FlexContainer>
        </Spin>
    )
}

export default GroceriesDetail
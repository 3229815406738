import { Card, Collapse, SideSheet, Skeleton, Table, Tag, Tooltip } from "@douyinfe/semi-ui"
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react"
import './index.less'
import { IHotel, IHotelPriceSummary } from "../../../../typings"
import FlexContainer from "../../../../components/FlexContainer";
import useKeyValuePairs from "../../../../hooks/useKeyValuePairs";
import { relative } from "path";
import { AppContext } from "../../../../App";
import { useAsyncEffect } from "ahooks";
import { generateColorGradient, request } from "../../../../utils";
import { brandList } from "../HotelMap";
import { groupBy } from "lodash-es";
import Draggable from 'react-draggable';
import { RewardHotelsContext } from "../..";

interface IHotelMatchSidesheetProps {
    hotelList: IHotel[];
    dragInProgress: boolean;
    onCenter: (hotel: IHotel) => void;
    onReady: (arr: IHotelPriceSummary[]) => void;
}

const OPACITY = '66'

const HotelMatchSidesheet = ({ hotelList, dragInProgress, onCenter, onReady }: IHotelMatchSidesheetProps) => {

    const { kvPairs: hotelBrandChainColorMap } = useKeyValuePairs('color', 'hotels')

    const { navbarHeight, windowHeight, isMobile, isDarkMode } = useContext(AppContext)
    const { currency: userCurrency, date_range, exchangeRate, setExchangeRate } = useContext(RewardHotelsContext)

    const [visible, setVisible] = useState(false)
    const [loading, setLoading] = useState(true)

    const [hotelPriceList, setHotelPriceList] = useState<IHotelPriceSummary[]>([])
    const [isExpand, setIsExpand] = useState(true)
    const [sidesheetHeight, setSidesheetHeight] = useState(300)

    const standardPriceColorFn = useRef<any>(() => { })
    const redemptionPriceColorFn = useRef<any>(() => { })
    const valuePriceColorFn = useRef<any>(() => { })
    const heightRef = useRef<number>(300)

    useEffect(() => {
        if (!visible) {
            heightRef.current = sidesheetHeight
        }
    }, [visible])

    useAsyncEffect(async () => {
        if (!hotelList.length && !dragInProgress) {
            setVisible(false)
            return
        }

        if (dragInProgress) {
            return
        }

        setVisible(true)
        const sqlList: string[] = []

        brandList.forEach(brand => {
            const validHotelList = hotelList.filter(item => item.chain === brand)

            if (validHotelList.length) {
                sqlList.push(
                    `
                        SELECT 
                            property_id, rate_category, MIN(price) AS min_price, currency 
                        FROM 
                            simpo_octopus.${brand}_hotel_rate 
                        WHERE 
                            property_id IN (${validHotelList.map(item => `"${item.property_id}"`).join(', ')})
                            ${Array.isArray(date_range) && date_range.length ? `AND date BETWEEN '${date_range[0]}' AND '${date_range[1]}'` : ''}
                        GROUP BY 
                            property_id, rate_category, currency
                    `
                )
            }
        })

        if (sqlList.length) {
            setLoading(true)

            const respData: IHotelPriceSummary[] = await request({
                data: {
                    service: 'rds.sql',
                    requestParams: [
                        sqlList.join(`\nUNION\n`)
                    ]
                }
            })

            const redemptionList: number[] = respData.filter(item => item.rate_category === 'REDEMPTION').map(item => item.min_price)
            const standardList = respData.filter(item => item.rate_category === 'STANDARD').map(item => item.min_price * 100)

            if (redemptionList && redemptionList.length) {
                redemptionPriceColorFn.current = generateColorGradient(Math.min(...redemptionList), Math.max(...redemptionList) + 1, '#198754', '#fc8800')
            }

            if (standardList && standardList.length) {
                standardPriceColorFn.current = generateColorGradient(Math.min(...standardList), Math.max(...standardList) + 1, '#198754', '#fc8800')
            }

            const priceListByPropertyId = groupBy(respData, 'property_id')
            const pointValueList = Object.entries(priceListByPropertyId).map(([property_id, priceList]) => {
                const minStandardPrice = priceList.find(item => item.rate_category === 'STANDARD')?.min_price
                const minRedemptionPrice = priceList.find(item => item.rate_category === 'REDEMPTION')?.min_price

                if (!minStandardPrice || !minRedemptionPrice) {
                    return 0
                }

                return minStandardPrice / minRedemptionPrice * 10000
            }).filter(Boolean).map((v: number) => parseInt(`${v}`, 10))

            if (pointValueList && pointValueList.length) {
                valuePriceColorFn.current = generateColorGradient(Math.max(...pointValueList) + 1, Math.min(...pointValueList), '#198754', '#fc8800')
            }

            respData.forEach((item: IHotelPriceSummary) => {
                if (item.rate_category === 'STANDARD') {
                    item.color = standardPriceColorFn.current(item.min_price * 100)
                } else if (item.rate_category === 'REDEMPTION') {
                    item.color = redemptionPriceColorFn.current(item.min_price)

                    const standardPriceItem = respData.find(data => data.rate_category === 'STANDARD' && data.property_id === item.property_id)
                    if (standardPriceItem) {
                        const pointValue = parseInt(`${standardPriceItem.min_price / item.min_price * 10000}`)

                        item.hotelCurrency = standardPriceItem.currency
                        item.pointValue = pointValue
                        item.pointValueColor = valuePriceColorFn.current(pointValue)
                    }
                }
            })

            onReady(respData)
            setHotelPriceList(respData)
            setLoading(false)
        }
    }, [hotelList, dragInProgress])

    const currency = useMemo(() => {
        return hotelPriceList.find(item => item.rate_category === 'STANDARD')?.currency
    }, [hotelPriceList])

    useAsyncEffect(async () => {
        if (userCurrency && userCurrency !== 'hotel_currency' && currency && userCurrency !== currency) {

            console.log(userCurrency, currency)

            const respData = await request({
                data: {
                    service: 'au.hotaudeals.getExchangeRate',
                    requestParams: [
                        currency, userCurrency
                    ]
                }
            })

            if (respData) {
                setExchangeRate({
                    [respData.target]: parseFloat(respData.value.toFixed(2))
                })
            }
        }
    }, [userCurrency, currency])

    const calculatePriceInAnotherCurrency = useCallback((value: number) => {
        if (!userCurrency || userCurrency === 'hotel_currency' || userCurrency === currency) {
            return {
                value,
                currency,
                isEstimated: false
            }
        }

        try {
            const price = value * exchangeRate[userCurrency]

            return {
                value: parseFloat(price.toFixed(0)),
                currency: userCurrency,
                isEstimated: true
            }
        } catch (err) {
            return {
                value,
                currency,
                isEstimated: false
            }
        }
    }, [currency, userCurrency, exchangeRate])

    if (!visible) {
        return <></>
    }

    const bodyWrapper = (
        <Collapse activeKey={isExpand ? ['property-panel'] : []} style={{ position: 'relative' }} onChange={(activeKey) => {
            if (!isMobile) {
                setIsExpand(activeKey?.length === 1)
            }
        }}>
            <Collapse.Panel
                itemKey="property-panel"
                style={{ position: 'relative' }}
                header={(
                    <FlexContainer justifyContent="space-between" style={{ padding: '4px 8px 4px 12px', borderBottom: '1px solid var(--semi-color-border)', width: '100%', height: 48, boxSizing: 'border-box' }} alignItems="center">
                        <div style={{ fontSize: 14, letterSpacing: -0.3 }}>
                            <FlexContainer alignItems="center" gap="8px">
                                <div style={{ fontSize: 28, letterSpacing: -2 }}>{hotelList.length}</div>

                                <div style={{ lineHeight: '16px' }}>
                                    <div>{hotelList.length <= 1 ? 'Property' : 'Properties'} Found</div>
                                    <div>In Viewpoint</div>
                                </div>
                            </FlexContainer>
                        </div>

                        {isExpand && (
                            <>
                                {!!userCurrency && userCurrency !== 'hotel_currency' && !!currency && userCurrency !== currency ? (
                                    <FlexContainer flexDirection="column" alignItems="flex-end">
                                        <div className="font-weight-bold" style={{ fontSize: 12 }}>
                                            {currency} 1 = {userCurrency} {exchangeRate[userCurrency] || '-'}
                                        </div>
                                        <div style={{ color: 'grey', fontSize: 8, textAlign: 'right', lineHeight: '10px', whiteSpace: 'nowrap' }}>
                                            *All prices are estimated based on real-time exchange rates,
                                            <br />which may vary from hotel websites.
                                        </div>
                                    </FlexContainer>
                                ) : (
                                    <FlexContainer flexDirection="column" alignItems="flex-end">
                                        <div className="font-weight-bold" style={{ fontSize: 12 }}>
                                            Price displayed in {currency}
                                        </div>
                                        <div style={{ color: 'grey', fontSize: 8, textAlign: 'right', lineHeight: '10px', whiteSpace: 'nowrap' }}>
                                            *All prices are estimated based on real-time exchange rates,
                                            <br />which may vary from hotel websites.
                                        </div>
                                    </FlexContainer>
                                )}
                            </>
                        )}


                    </FlexContainer>
                )}
            >

                <Table
                    dataSource={hotelList.map(hotel => ({ ...hotel, propert_id_2: hotel.property_id, property_id_3: hotel.property_id }))}
                    rowKey="property_id"
                    pagination={false}
                    size="small"
                    sticky
                    style={{ maxHeight: isMobile ? sidesheetHeight - 49 : windowHeight - navbarHeight - 48 - 32 - 49, overflow: 'auto' }}
                    loading={loading}
                    columns={[
                        {
                            title: '',
                            width: 12,
                            dataIndex: 'idx',
                            fixed: true,
                            render: (idx: number, record: IHotel) => {
                                return (
                                    <FlexContainer
                                        alignItems="center"
                                        justifyContent="center"
                                        style={{
                                            borderLeft: `6px solid ${hotelBrandChainColorMap[record.chain || ''] || 'transparent'}`,
                                            height: 42,
                                            paddingRight: 2,
                                        }}
                                    >
                                        <div></div>
                                    </FlexContainer>
                                )
                            }
                        },
                        {
                            title: 'Hotel',
                            dataIndex: 'name',
                            width: 120,
                            fixed: true,
                            render: (text: string, hotel: IHotel) => {
                                return (
                                    <div style={{ padding: '6px 8px 6px 0' }}>
                                        <div className="text-overflow-max-two-lines" style={{ fontSize: 12, lineHeight: '14px', cursor: 'pointer' }} onClick={() => onCenter(hotel)}>
                                            {text}
                                        </div>
                                    </div>
                                )
                            }
                        },
                        {
                            title: 'Money',
                            dataIndex: 'property_id',
                            width: 96,
                            sorter: (hotelA: any, hotelB: any) => {
                                const hotelAMinStandardPrice = hotelPriceList.find(item => item.property_id === hotelA.property_id && item.rate_category === 'STANDARD')?.min_price
                                const hotelBMinStandardPrice = hotelPriceList.find(item => item.property_id === hotelB.property_id && item.rate_category === 'STANDARD')?.min_price

                                if (hotelAMinStandardPrice && hotelBMinStandardPrice) {
                                    return hotelAMinStandardPrice - hotelBMinStandardPrice
                                } else if (hotelAMinStandardPrice) {
                                    return -1
                                } else if (hotelBMinStandardPrice) {
                                    return 1
                                } else {
                                    return 0
                                }
                            },
                            render: (property_id: string, hotel: IHotel) => {
                                const minStandardPrice = hotelPriceList.find(item => item.property_id === property_id && item.rate_category === 'STANDARD')?.min_price

                                if (!minStandardPrice) {
                                    return <div style={{ paddingLeft: 8 }}>-</div>
                                }

                                const { value, currency, isEstimated } = calculatePriceInAnotherCurrency(minStandardPrice)

                                return (
                                    <div style={{ padding: '10px 8px', background: standardPriceColorFn.current((minStandardPrice) * 100) + OPACITY, cursor: 'pointer' }} onClick={() => {
                                        window.open(`/reward-hotels/${hotel.chain}/${hotel.property_id}`)
                                    }}>
                                        {value}
                                        <span className='price-legend-wrapper' style={{ color: 'grey', fontSize: 8 }}>{currency}{isEstimated && '*'}</span>
                                    </div>
                                )
                            }
                        },
                        {
                            title: 'Points',
                            width: 96,
                            dataIndex: 'property_id_2',
                            sorter: (hotelA: any, hotelB: any) => {
                                const hotelAMinRedemptionPrice = hotelPriceList.find(item => item.property_id === hotelA.property_id && item.rate_category === 'REDEMPTION')?.min_price
                                const hotelBMinRedemptionPrice = hotelPriceList.find(item => item.property_id === hotelB.property_id && item.rate_category === 'REDEMPTION')?.min_price

                                if (hotelAMinRedemptionPrice && hotelBMinRedemptionPrice) {
                                    return hotelAMinRedemptionPrice - hotelBMinRedemptionPrice
                                } else if (hotelAMinRedemptionPrice) {
                                    return -1
                                } else if (hotelBMinRedemptionPrice) {
                                    return 1
                                } else {
                                    return 0
                                }
                            },
                            render: (property_id: string, hotel: IHotel) => {
                                const minRedemptionPrice = hotelPriceList.find(item => item.property_id === hotel.property_id && item.rate_category === 'REDEMPTION')?.min_price

                                if (!minRedemptionPrice) {
                                    return <div style={{ paddingLeft: 8 }}>-</div>
                                }

                                return (
                                    <div style={{ padding: '10px 8px', background: redemptionPriceColorFn.current(minRedemptionPrice) + OPACITY, cursor: 'pointer' }} onClick={() => {
                                        window.open(`/reward-hotels/${hotel.chain}/${hotel.property_id}`)
                                    }}>
                                        {minRedemptionPrice}
                                        <span className="price-legend-wrapper" style={{ color: 'grey', fontSize: 8 }}>PTS</span>
                                    </div>
                                )
                            }
                        },
                        {
                            title: 'Worths',
                            width: 96,
                            dataIndex: 'property_id_3',
                            sorter: (hotelA: any, hotelB: any) => {
                                const minStandardPriceA = hotelPriceList.find(item => item.property_id === hotelA.property_id && item.rate_category === 'STANDARD')?.min_price;
                                const minRedemptionPriceA = hotelPriceList.find(item => item.property_id === hotelA.property_id && item.rate_category === 'REDEMPTION')?.min_price;
                                const minStandardPriceB = hotelPriceList.find(item => item.property_id === hotelB.property_id && item.rate_category === 'STANDARD')?.min_price;
                                const minRedemptionPriceB = hotelPriceList.find(item => item.property_id === hotelB.property_id && item.rate_category === 'REDEMPTION')?.min_price;


                                if ((!minStandardPriceA || !minRedemptionPriceA) && minStandardPriceB && minRedemptionPriceB) {
                                    return 1
                                }

                                if ((!minStandardPriceB || !minRedemptionPriceB) && minStandardPriceA && minRedemptionPriceA) {
                                    return -1
                                }

                                if (!minStandardPriceA || !minRedemptionPriceA || !minStandardPriceB || !minRedemptionPriceB) {
                                    return 0
                                }

                                const pointValueA = minStandardPriceA / minRedemptionPriceA * 10000;
                                const pointValueB = minStandardPriceB / minRedemptionPriceB * 10000;

                                return pointValueA - pointValueB
                            },
                            render: (property_id: string, hotel: IHotel) => {
                                const minStandardPrice = hotelPriceList.find(item => item.property_id === hotel.property_id && item.rate_category === 'STANDARD')?.min_price
                                const minRedemptionPrice = hotelPriceList.find(item => item.property_id === hotel.property_id && item.rate_category === 'REDEMPTION')?.min_price

                                if (!minStandardPrice || !minRedemptionPrice) {
                                    return <div style={{ paddingLeft: 8 }}>-</div>
                                }

                                const { value, currency, isEstimated } = calculatePriceInAnotherCurrency(minStandardPrice)
                                const pointValue = (value / minRedemptionPrice * 10000).toFixed(0)

                                return (
                                    <div style={{ padding: '10px 8px', background: valuePriceColorFn.current(parseInt(`${minStandardPrice / minRedemptionPrice * 10000}`)) + OPACITY, cursor: 'pointer' }} onClick={() => {
                                        window.open(`/reward-hotels/${hotel.chain}/${hotel.property_id}`)
                                    }}>
                                        {pointValue}
                                        <span className="price-legend-wrapper" style={{ color: 'grey', fontSize: 8 }}>{currency}/10k{isEstimated && '*'}</span>
                                    </div>
                                )
                            }
                        }
                    ]}
                />
            </Collapse.Panel>
        </Collapse>
    )

    if (isMobile) {
        return (
            <>
                <FlexContainer id="dragger" justifyContent="center" style={{ position: 'fixed', bottom: heightRef.current - 2, width: '100%' }}>
                    <Draggable
                        axis="y"
                        bounds={{
                            top: -(windowHeight - heightRef.current - navbarHeight - 48 - 31 - 32),
                            bottom: heightRef.current - 48
                        }}
                        onDrag={(e, data) => {
                            setSidesheetHeight(heightRef.current - data.lastY)
                        }}
                    >
                        <div style={{ padding: 8 }}>
                            <div
                                style={{
                                    width: 80,
                                    height: 6,
                                    borderRadius: 32,
                                    background: isDarkMode ? '#f2f3f599' : `#00000099`,
                                    zIndex: 100,
                                    WebkitBackdropFilter: 'blur(20px)',
                                    backdropFilter: "blur(20px)",
                                }}
                            >
                                <></>
                            </div>
                        </div>
                    </Draggable>
                </FlexContainer>

                <SideSheet
                    className="hotel-match-sidesheet-wrapper"
                    placement="bottom"
                    height={isExpand ? sidesheetHeight : 49}
                    visible={visible}
                    style={{ borderRadius: '12px 12px 0 0', position: 'relative' }}
                    bodyStyle={{ padding: 0 }}
                    onCancel={() => setVisible(false)}
                    mask={false}
                >


                    {bodyWrapper}
                </SideSheet>
            </>
        )
    }

    return (
        <div
            className="responsive-background-secondary hotel-match-sidesheet-wrapper"
            style={{
                position: 'fixed',
                top: navbarHeight + 16 + 48,
                maxHeight: windowHeight - navbarHeight - 48 - 32,
                overflow: 'auto',
                right: 16,
                width: 450,
                borderRadius: 8,
                zIndex: 4,
                background: 'white',
                boxShadow: 'var(--semi-shadow-elevated)'
            }}
        >
            {bodyWrapper}
        </div>
    )
}

export default HotelMatchSidesheet
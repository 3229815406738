import { IconArrowUpRight } from "@douyinfe/semi-icons";
import { Button } from "@douyinfe/semi-ui";
import FlexContainer from "../../../../components/FlexContainer";
import { ButtonProps } from "@douyinfe/semi-ui/lib/es/button";

interface IStoreLocatorProps {
    platform: string;
    btnProps?: ButtonProps
}

const STORE_LOCATOR_URL_MAP: Record<string, string> = {
    woolworths: 'https://www.woolworths.com.au/shop/storelocator',
    coles: 'https://www.coles.com.au/find-stores',
    'big w': 'https://www.bigw.com.au/store-finder'
}

const StoreLocator = ({ platform, btnProps }: IStoreLocatorProps) => {
    const targetPlatformUrl = STORE_LOCATOR_URL_MAP[platform.toLowerCase()]

    if (!targetPlatformUrl) {
        return <></>
    }

    if (btnProps) {
        return (
            <Button {...btnProps || {}} onClick={() => {
                window.open(targetPlatformUrl)
            }}>
                {btnProps?.children || "Store locator"}
            </Button>
        )
    }

    return (
        <FlexContainer alignItems="center" gap="4px" style={{ cursor: 'pointer' }} onClick={() => {
            window.open(targetPlatformUrl)
        }}>
            Store locator
            <IconArrowUpRight style={{ fontSize: 12, position: 'relative', top: 0 }} />
        </FlexContainer>
    )
}

export default StoreLocator
// @ts-nocheck
import React, { useRef, useEffect, useState, useCallback, useContext, useMemo } from 'react';
import Map, { Source, Layer, MapRef, ViewState } from 'react-map-gl';
import * as turf from '@turf/turf'; // Import Turf.js for geospatial calculations
import { MAPBOX_TOKEN } from '../../../RewardFlightsByPlatform/components/AirportMap';
import { IAirport } from '../../../../typings';
import { AppContext } from '../../../../App';
import LazyMap from '../../../../components/LazyMap';

export interface GeoJSONPoint {
    type: 'FeatureCollection';
    features: [
        {
            type: 'Feature';
            geometry: {
                type: 'Point';
                coordinates: [number, number];
            };
            properties: {
                bearing?: number;
            };
        }
    ];
}

export interface GeoJSONLine {
    type: 'FeatureCollection';
    features: [
        {
            type: 'Feature';
            geometry: {
                type: 'LineString';
                coordinates: [number, number][];
            };
        }
    ];
}

// Calculate the bounding box
export const calculateBounds = (coords) => {
    let [minLng, minLat, maxLng, maxLat] = [Infinity, Infinity, -Infinity, -Infinity];

    coords.forEach(([lng, lat]) => {
        if (lng < minLng) minLng = lng;
        if (lat < minLat) minLat = lat;
        if (lng > maxLng) maxLng = lng;
        if (lat > maxLat) maxLat = lat;
    });

    return [[minLng, minLat], [maxLng, maxLat]];
};

// Function to calculate the number of steps
export const calculateSteps = (distance: number) => {
    const minDistance = 100; // Distance in meters
    return Math.max(500, Math.ceil(distance / minDistance))
};

const FlightRouteRender = ({ departureAirportInfo, destinationAirportInfo, paddingOffset = 1 }: { departureAirportInfo: IAirport, destinationAirportInfo: IAirport, paddingOffset?: number }) => {
    const { isDarkMode } = useContext(AppContext)

    const mapRef = useRef<MapRef>(null);

    const [pointData, setPointData] = useState<GeoJSONPoint | null>(null);
    const [routeData, setRouteData] = useState<GeoJSONLine | null>(null);

    const origin: [number, number] = [parseFloat(departureAirportInfo.longitude), parseFloat(departureAirportInfo.latitude)]; // San Francisco
    const destination: [number, number] = [parseFloat(destinationAirportInfo.longitude), parseFloat(destinationAirportInfo.latitude)]; // Washington DC

    const isInitRef = useRef(false)

    let counter = 0
    let steps = 500

    // Function to animate the point
    const animatePoint = useCallback(() => {
        if (!routeData || !pointData) return;

        let counterOffset = 0
        let coordinatesIdx = 0

        if (counter >= routeData.features[0].geometry.coordinates.length) {
            coordinatesIdx = 1
            counterOffset = routeData.features[0].geometry.coordinates.length
        }

        const start = routeData.features[coordinatesIdx].geometry.coordinates[counter - counterOffset];
        const end = routeData.features[coordinatesIdx].geometry.coordinates[counter + 1 - counterOffset] || start;

        const bearing = turf.bearing(turf.point(start), turf.point(end));

        // Update point with new coordinates and bearing
        const newPointData = {
            ...pointData,
            features: [
                {
                    ...pointData.features[0],
                    geometry: {
                        ...pointData.features[0].geometry,
                        coordinates: start,
                    },
                    properties: {
                        bearing,
                    },
                },
            ],
        };

        setPointData(newPointData);

        if (counter + 1 >= steps) {
            counter = 0
        } else {
            counter += 1
        }


        // Continue the animation loop
        requestAnimationFrame(animatePoint);
    }, [pointData, routeData])

    useEffect(() => {
        let route: GeoJSONLine = {
            type: 'FeatureCollection',
            features: [
                {
                    type: 'Feature',
                    geometry: {
                        type: 'LineString',
                        coordinates: [origin, destination],
                    },
                },
            ],
        };

        const lineDistance = turf.length(route.features[0]);

        steps = calculateSteps(lineDistance);
        const arc = turf.greatCircle(origin, destination, { npoints: steps });

        route = {
            type: 'FeatureCollection',
            features: arc.geometry.coordinates.length === 2 ? [
                {
                    type: 'Feature',
                    geometry: {
                        type: 'LineString',
                        coordinates: arc.geometry.coordinates[0],
                    },
                },
                {
                    type: 'Feature',
                    geometry: {
                        type: 'LineString',
                        coordinates: arc.geometry.coordinates[1],
                    },
                },
            ] : [
                {
                    type: 'Feature',
                    geometry: {
                        type: 'LineString',
                        coordinates: arc.geometry.coordinates,
                    },
                },
            ],
        };

        setRouteData(route);
        setPointData({
            type: 'FeatureCollection',
            features: [
                {
                    type: 'Feature',
                    geometry: {
                        type: 'Point',
                        coordinates: origin,
                    },
                    properties: {},
                },
            ],
        });
    }, []);

    useEffect(() => {
        // Automatically start the animation when the route is created
        if (routeData && pointData && !isInitRef.current) {
            isInitRef.current = true
            animatePoint();
        }
    }, [routeData, pointData]);

    const airportPoints = useMemo(() => {
        // GeoJSON for origin and destination dots
        return {
            type: 'FeatureCollection',
            features: [
                {
                    type: 'Feature',
                    geometry: {
                        type: 'Point',
                        coordinates: origin,
                    },
                },
                {
                    type: 'Feature',
                    geometry: {
                        type: 'Point',
                        coordinates: destination,
                    },
                },
            ],
        };
    }, [origin, destination])

    return (
        <div style={{ height: '100%', width: '100%', position: 'relative' }}>
            <LazyMap
                onRef={mapRef}
                style={{ width: '100%', height: '100%' }}
                mapStyle={isDarkMode ? "mapbox://styles/mapbox/dark-v11" : "mapbox://styles/mapbox/light-v11"}
                mapboxAccessToken={MAPBOX_TOKEN}
                onLoad={() => {
                    if (mapRef.current) {
                        const bounds = calculateBounds([origin, destination])
                        mapRef.current.getMap().fitBounds(bounds, {
                            padding: { top: 100 / Math.pow(paddingOffset, 2), bottom: 100 / Math.pow(paddingOffset, 2), left: 100 / paddingOffset, right: 100 / paddingOffset },
                            duration: 1500
                        })
                    }
                }}
            >
                {routeData && (
                    <Source id="route" type="geojson" data={routeData}>
                        <Layer
                            id="route"
                            type="line"
                            paint={{
                                'line-width': 2,
                                'line-color': isDarkMode ? 'rgb(84,169,255)' : 'rgb(0,100,250)',
                            }}
                        />
                    </Source>
                )}

                {pointData && (
                    <Source id="point" type="geojson" data={pointData}>
                        <Layer
                            id="point"
                            type="symbol"
                            layout={{
                                'icon-image': 'airport', // Use an icon available in Mapbox or add a custom one
                                'icon-size': 1.5,
                                'icon-rotate': ['get', 'bearing'],
                                'icon-rotation-alignment': 'map',
                                'icon-allow-overlap': true,
                                'icon-ignore-placement': true,
                            }}
                        />
                    </Source>
                )}

                {/* Dots for origin and destination airports */}
                <Source id="airports" type="geojson" data={airportPoints}>
                    <Layer
                        id="airport-points"
                        type="circle"
                        paint={{
                            'circle-radius': 6,
                            'circle-color': 'rgb(250,102,76)', // Red color for the airport dots
                            'circle-stroke-width': 2,
                            'circle-stroke-color': '#FFFFFF', // White border around the dots
                        }}
                    />
                </Source>
            </LazyMap>
        </div>
    );
};

export default FlightRouteRender;

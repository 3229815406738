import { useCallback, useContext, useEffect, useMemo, useState } from "react"
import { ICashbackPromotion } from "../../typings"
import FlexContainer from "../../components/FlexContainer"
import { AppContext } from "../../App"
import './index.less'
import { useAsyncEffect } from "ahooks"
import { renderText, request } from "../../utils"
import { Avatar, Button, Card, Checkbox, Input, Spin, Table, Tag } from "@douyinfe/semi-ui"
import { IconSearch } from "@douyinfe/semi-icons"
import MerchantCashbackSidesheet, { CashbackPlatformBadge } from "./components/MerchantCashbackSidesheet"
import { debounce } from "lodash-es"
import { useNavigate } from "react-router-dom"
import { initSeo, resetSeo } from "../../utils/seo"

const Cashback = () => {
    const { isMobile } = useContext(AppContext)
    const navigate = useNavigate()

    const [loading, setLoading] = useState(false)

    const [cashbackList, setCashbackList] = useState<ICashbackPromotion[]>([])
    const [selectedMerchant, setSelectedMerchant] = useState<ICashbackPromotion>()

    const [filter, setFilter] = useState({
        showUpsizedOnly: false,
        showShopOnly: true,
        showGiftCardsOnly: false,
        searchKeyword: ''
    })

    const filteredCashbackList = useMemo(() => {
        return cashbackList.filter(item => {
            if (filter.showUpsizedOnly && !item.is_upsized) {
                return false
            }

            if (filter.showShopOnly && (item.merchant_name.toLowerCase().includes('gift card') || item.merchant_category === 'Gift Cards')) {
                return false
            }

            if (filter.showGiftCardsOnly && (!item.merchant_name.toLowerCase().includes('gift card') && item.merchant_category !== 'Gift Cards')) {
                return false
            }

            return !filter.searchKeyword || item.merchant_name.toLowerCase().includes(filter.searchKeyword.toLowerCase())
        })

    }, [cashbackList, filter])

    useEffect(() => {
        initSeo({
            title: 'Cashback Promotions | HotAuDeals',
            description: 'Discover the latest cashback promotions from ShopBack, Cashrewards, and TopCashback. We compare rates across these platforms and show you the best buying options, helping you make informed decisions. Subscribe to your favorite brands to stay updated on new deals and exclusive promotions.'
        })

        return () => {
            resetSeo()
        }
    }, [])

    useAsyncEffect(async () => {
        setLoading(true)

        const cashbackListRespData = await request({
            method: 'post',
            url: '/api/v1/service/execute',
            data: {
                service: 'rds.sql',
                requestParams: [`
                    SELECT * FROM simpo_octopus.au_cashback_promotion_tmp ORDER BY is_upsized DESC, -max_cashback_rate_num ASC
                `]
            }
        })

        setLoading(false)
        setCashbackList(cashbackListRespData.map((item: any) => {
            item.available_platforms = item.available_platforms?.split?.(',') || []
            return item
        }))
    }, [])

    useEffect(() => {
        const pageWrapperEl: any = document.querySelector('.page-wrapper')

        if (pageWrapperEl) {
            pageWrapperEl.style.overflow = 'auto'

            return () => {
                pageWrapperEl.style.overflow = 'hidden'
            }
        }
    }, [])

    const columns = [
        {
            title: 'Merchant',
            render: (record: ICashbackPromotion) => {
                return (
                    <FlexContainer gap="16px" alignItems="center" style={{ padding: '4px 0' }}>
                        <Avatar
                            className="merchant-logo-wrapper"
                            src={record?.merchant_logo}
                            style={{ objectFit: 'cover', marginLeft: isMobile ? 2 : 0 }}
                            // size={isMobile ? "medium" : "large"}
                            size="medium"
                            shape="square"
                            {...record?.is_upsized ? {
                                border: { motion: true, color: "rgb(254, 220, 2)" },
                                bottomSlot: {
                                    text: "Upsized",
                                    textColor: 'black',
                                    style: { fontSize: 12, marginBottom: '1px', padding: "0 3px", background: 'rgb(254, 220, 2)', height: 16, borderRadius: '0 0 2px 2px', position: 'absolute' }
                                } as any
                            } : {
                                border: true
                            }}
                        />

                        <FlexContainer flexDirection="column" gap="4px">
                            <div style={{ fontSize: 16 }}>{record.merchant_name}</div>
                            <div>
                                <FlexContainer gap="4px">
                                    {record.available_platforms?.sort((a, b) => a.localeCompare(b))?.map(item => <CashbackPlatformBadge platform={item} />)}
                                </FlexContainer>
                            </div>
                        </FlexContainer>

                    </FlexContainer>

                    // <FlexContainer alignItems="center" gap="12px">
                    //     <img src={record.merchant_logo} width={100} height={40} style={{ objectFit: 'contain' }} />

                    //     <div>
                    //         {!!record.is_upsized && <Tag style={{ background: 'rgb(254, 220, 2)', marginBottom: 4 }} shape="circle"><span className='font-weight-bold responsive-text__opposite'>Upsized</span></Tag>}
                    //         <div style={{ fontSize: 16 }}>{record.merchant_name}</div>
                    //     </div>

                    // </FlexContainer>
                )
            }
        },
        // {
        //     title: 'Platform',
        //     width: 100,
        //     render: (record: ICashbackPromotion) => {
        //         return (
        //             <div>
        //                 <FlexContainer gap="4px">
        //                     {record.available_platforms?.map(item => <CashbackPlatformBadge platform={item} />)}
        //                 </FlexContainer>
        //             </div>
        //         )
        //     }
        // },
        {
            title: 'Cashback Rate',
            width: 200,
            render: (record: ICashbackPromotion) => {
                return (
                    <div>
                        <div style={{ fontSize: 11, color: 'grey', marginBottom: -2 }}>Up to</div>
                        <div style={{ fontSize: 16 }}>
                            <span style={{ fontWeight: 600 }}>{record.max_cashback_rate.replace(/up to/gi, "").trim()}</span>
                        </div>

                        {!!record.prev_max_cashback_rate && <div style={{ fontSize: 11, textDecoration: 'line-through', fontWeight: 400, marginTop: -4, color: 'grey' }}>{renderText(record?.prev_max_cashback_rate)}</div>}
                    </div>
                )
            }
        },
        {
            title: 'Detail',
            width: 60,
            render: (record: ICashbackPromotion) => {
                return (
                    <Button type="tertiary" theme="solid" style={{ borderRadius: 16 }} icon={<IconSearch />} onClick={() => {
                        // setSelectedMerchant(record)
                        navigate(`/cashback/${btoa(record.merchant_name)}`)
                    }}></Button>
                )
            }
        }
    ]

    return (
        <div className="cashback-wrapper">
            <div style={{ width: isMobile ? '100%' : '1000px' }}>
                <FlexContainer justifyContent="space-between" alignItems="center" style={{ padding: '12px 0 0 0' }}>
                    <div className="responsive-text" style={{ fontWeight: 700, fontSize: 28, letterSpacing: -1 }}>Cashback Promotions</div>
                </FlexContainer>

                <FlexContainer className="cashback-filter-wrapper responsive-background" justifyContent="space-between" alignItems="center" flexWrap="wrap" gap="8px">
                    <FlexContainer gap="8px" style={isMobile ? { width: '100%' } : {}}>
                        <div className="checkbox-filter-wrapper responsive-background">
                            <Checkbox checked={filter.showUpsizedOnly} onChange={e => setFilter(prev => ({ ...prev, showUpsizedOnly: e.target.checked as boolean }))}>
                                <span className="font-weight-bold">Upsized</span>
                            </Checkbox>
                        </div>

                        <div className="checkbox-filter-wrapper responsive-background">
                            <Checkbox checked={filter.showShopOnly} onChange={e => setFilter(prev => ({ ...prev, showShopOnly: e.target.checked as boolean }))}>
                                <span className="font-weight-bold">Shop</span>
                            </Checkbox>
                        </div>

                        <div className="checkbox-filter-wrapper responsive-background">
                            <Checkbox checked={filter.showGiftCardsOnly} onChange={e => setFilter(prev => ({ ...prev, showGiftCardsOnly: e.target.checked as boolean }))}>
                                <span className="font-weight-bold">Gift Cards</span>
                            </Checkbox>
                        </div>
                    </FlexContainer>


                    <Input showClear style={{ width: isMobile ? '100%' : 200, borderRadius: 16 }} prefix={<IconSearch />} onChange={debounce(v => setFilter(prev => ({ ...prev, searchKeyword: v })), 500)} placeholder="Search for merchant" />
                </FlexContainer>

                {!isMobile && (
                    <Table
                        dataSource={filteredCashbackList}
                        loading={loading}
                        columns={columns}
                        size="small"
                        pagination={{
                            pageSize: 20
                        }}
                    />
                )}

                {isMobile && (
                    <Spin spinning={loading} style={{ minHeight: 200 }} size="large">
                        <FlexContainer flexDirection="column" gap="8px" style={{ padding: '0 0 12px' }}>

                            {filteredCashbackList.map(promotion => {
                                return (
                                    <div key={promotion.merchant_name} style={{ cursor: 'pointer' }} onClick={() => {
                                        navigate(`/cashback/${btoa(promotion.merchant_name)}`)
                                    }}>
                                        <Card className="mobile-cashback-wrapper" bodyStyle={{ padding: 0 }}>
                                            {!!promotion.is_upsized && (
                                                <FlexContainer
                                                    className="font-weight-bold responsive-text__opposite"
                                                    justifyContent="space-between"
                                                    style={{
                                                        width: '100%',
                                                        boxSizing: 'border-box',
                                                        height: '20px',
                                                        padding: '0 16px',
                                                        lineHeight: '20px',
                                                        fontSize: 12,
                                                        background: 'rgb(254, 220, 2)'
                                                    }}
                                                >
                                                    Upsized
                                                </FlexContainer>
                                            )}


                                            <div style={{ padding: '12px 16px 8px', position: 'relative', height: 110 }}>
                                                <img
                                                    className="merchant-logo-img-wrapper"
                                                    src={promotion.merchant_logo}
                                                    style={{
                                                        objectFit: 'contain',
                                                        maxWidth: '30%',
                                                        maxHeight: 60,
                                                        position: 'absolute',
                                                        right: 8,
                                                        bottom: 8,
                                                        opacity: 0.2,
                                                        borderRadius: 4,
                                                        background: 'white',
                                                        padding: 8
                                                    }}
                                                />

                                                <FlexContainer flexDirection="column" justifyContent="space-between" style={{ height: '100%' }}>
                                                    <div>
                                                        <div className='font-weight-bold' style={{ fontSize: 18, letterSpacing: -0.5 }}>{promotion.merchant_name}</div>
                                                        <div style={{ marginTop: 6 }}>
                                                            <FlexContainer gap="4px">
                                                                {promotion.available_platforms?.map(item => <CashbackPlatformBadge key={item} platform={item} />)}
                                                            </FlexContainer>
                                                        </div>
                                                    </div>

                                                    <div className="font-weight-bold">
                                                        {columns.find(item => item.title === 'Cashback Rate')?.render(promotion)}
                                                    </div>
                                                </FlexContainer>
                                            </div>

                                        </Card>
                                    </div>
                                )
                            })}

                            {!loading && filteredCashbackList.length === 0 && (
                                <FlexContainer style={{ width: '100%', padding: '32px 0 16px', color: 'grey' }} justifyContent="center">
                                    <div className="font-weight-bold">Oops! No records found.</div>
                                </FlexContainer>
                            )}
                        </FlexContainer>
                    </Spin>
                )}
            </div>

            <MerchantCashbackSidesheet visible={!!selectedMerchant} merchant={selectedMerchant} onCancel={() => setSelectedMerchant(undefined)} />
        </div>
    )
}

export default Cashback
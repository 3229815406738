import { useAsyncEffect } from "ahooks"
import { Footer, ISceneRenderProps } from "../SceneRender"
import { renderText, request } from "../../../../../utils"
import { useContext, useMemo, useState } from "react"
import { ICatalogueItem, IDeal } from "../../../../../typings"
import ProductCard from "../../../../GroceriesByPlatform/components/ProductCard"
import { PosterContext } from "../.."
import FlexContainer from "../../../../../components/FlexContainer"
import dayjs from "dayjs"
import { render } from "@testing-library/react"
import ProductBadge from "../../../../GroceriesByPlatform/components/ProductBadge"
import { List, Tag } from "@douyinfe/semi-ui"
import { groupBy, uniqueId } from "lodash-es"
import { AppContext } from "../../../../../App"
import './index.less'

const SCENE_TO_PLATFORM_DICT: Record<string, string> = {
    'woolworths-catalogue': 'woolworths (sneak peek)',
    'coles-catalogue': 'coles (sneak peek)',

    // 'woolworths-catalogue': 'woolworths',
    // 'coles-catalogue': 'coles',
    'costco-catalogue': 'costco'
}

const SCENE_TO_TITLE_DICT: Record<string, string> = {
    'woolworths-catalogue': 'Woolworths Catalogue',
    'coles-catalogue': 'Coles Catalogue',
    'costco-catalogue': 'Costco Warehouse Savings'
}

export const RADIUS = 1500
export const HEADER_HEIGHT = 80
export const FOOTER_HEIGHT = 50

const CatalogueRender = ({ scene }: ISceneRenderProps) => {

    const { COLOR_MAP } = useContext(AppContext)
    const { width, pageNum, setTotalPageNum } = useContext(PosterContext)

    const [catalogueItemList, setCatalogueItemList] = useState<IDeal[]>([])
    const isColesOrWws = scene.toLowerCase().includes('coles') || scene.toLowerCase().includes('woolworths')

    const PAGE_SIZE = useMemo(() => {
        if (isColesOrWws) {
            return 12
        } else {
            return 16
        }
    }, [])

    const catalogueItemGroupByCategory = useMemo(() => {
        const flattenedItems = catalogueItemList.map(item => {
            const tmp = (item.category || 'Others').split('||')

            return {
                ...item,
                category: tmp.length === 1 ? tmp : tmp.filter(tmp2 => tmp2 !== 'Others')
            }
        }).flatMap(item =>
            item.category.map(category => ({ ...item, category }))
        );

        const entries = (Object.entries(groupBy(flattenedItems, 'category') || {}) as any)

        return entries.sort((a: any, b: any) => {
            return b[1].length - a[1].length
        })
    }, [catalogueItemList])

    const currentProductList = useMemo(() => {
        const valueList = catalogueItemGroupByCategory.map((arr: any) => arr[1]).flat() as IDeal[]

        const outputList = valueList.slice(PAGE_SIZE * (pageNum - (isColesOrWws ? 2 : 1)), PAGE_SIZE * (pageNum - (isColesOrWws ? 1 : 0)))

        while (outputList.length < PAGE_SIZE) {
            outputList.push({
                ...outputList?.[0] || {},
                hidden: true
            } as any)
        }

        return outputList
    }, [catalogueItemGroupByCategory, pageNum])

    useAsyncEffect(async () => {
        const platform = SCENE_TO_PLATFORM_DICT[scene]

        let sortByCommand = 'ORDER BY category DESC, -discount_rate DESC'
        let whereCommand = ['category != ""']
        if (platform === 'costco') {
            sortByCommand = 'ORDER BY new_price ASC'
            whereCommand = []
        }

        const respData = await request({
            method: 'POST',
            url: '/api/v1/service/execute',
            data: {
                service: 'au.hotaudeals.getCatalogueItemList',
                requestParams: [
                    platform, 1, 1000, whereCommand, sortByCommand
                ]
            }
        })

        const filteredProductList = (respData?.productList?.filter((item: any) => {
            return item.discount_rate && item.discount_rate <= -20
        }) || []).sort((a: any, b: any) => {
            const aNum = a.lowest_price >= a.new_price 
                ? 1 
                : a.lowest_price 
                    ? 0.5
                    : 0
            const bNum = b.lowest_price >= b.new_price 
                ? 1 
                : b.lowest_price 
                    ? 0.5
                    : 0

            return bNum - aNum
        })

        setTotalPageNum(Math.ceil(filteredProductList.length / PAGE_SIZE) + (isColesOrWws ? 1 : 0))
        setCatalogueItemList(filteredProductList)
    }, [scene])

    return (
        <FlexContainer className="catalogue-render-wrapper" alignItems="center" flexDirection="column" style={{ width: '100%', height: '100%', background: `${COLOR_MAP[scene.split('-')[0]]}16` }}>
            <div
                style={{
                    background: `linear-gradient(to bottom, transparent, ${COLOR_MAP[scene.split('-')[0]]} calc(100% - ${HEADER_HEIGHT}px), ${COLOR_MAP[scene.split('-')[0]]}dd 100%)`,
                    position: 'absolute',
                    height: RADIUS * 2,
                    width: RADIUS * 2,
                    top: 0 - RADIUS * 2 + HEADER_HEIGHT + (isColesOrWws && pageNum === 1 ? 550 : 0),
                    borderRadius: '50%',
                    zIndex: 0
                }}
            />

            <FlexContainer flexDirection="column" alignItems="center" style={{ position: 'relative', zIndex: 1, height: '100%', width: '100%' }}>
                <FlexContainer flexDirection="column" alignItems="center" style={{ height: HEADER_HEIGHT, color: 'white' }}>
                    <div style={{ fontSize: 30, letterSpacing: -1, fontWeight: 700, paddingTop: 8, }}>
                        {SCENE_TO_TITLE_DICT[scene]}
                    </div>

                    <div style={{ marginTop: -8 }}>
                        Valid from {dayjs(catalogueItemList?.[0]?.start_date).format('DD/MM')} to {dayjs(catalogueItemList?.[0]?.end_date).format('DD/MM')}
                    </div>

                    {/* <div style={{ fontSize: 36, letterSpacing: -1, fontWeight: 700 }}>
                        Gift Cards
                    </div>

                    <div style={{ marginTop: -10 }}>
                        Valid from {dayjs(giftCardPromotionList?.[0]?.start_date).format('DD/MM')} to {dayjs(giftCardPromotionList?.[0]?.end_date).format('DD/MM')}
                    </div> */}

                    {isColesOrWws && pageNum === 1 && (
                        <div style={{ marginTop: 16, fontSize: 16 }}>本期目录共包含 {catalogueItemList.length} 件商品优惠</div>
                    )}

                </FlexContainer>

                {isColesOrWws && pageNum === 1 ? (
                    <FlexContainer alignItems="center" style={{ height: `calc(100% - ${HEADER_HEIGHT}px - ${FOOTER_HEIGHT}px)`, boxSizing: 'border-box', overflow: 'hidden' }}>
                        <div style={{ background: 'white', borderRadius: 16, overflow: 'hidden' }}>

                            <List
                                header={(
                                    <FlexContainer justifyContent="space-between" style={{ width: '100%' }}>
                                        <span className="font-weight-black" style={{ width: 160 }}>Category</span>
                                        <span className="font-weight-black">#items</span>
                                        {/* <span className="font-weight-black">Highlights</span> */}
                                    </FlexContainer>
                                )}
                                // footer={<div>Footer</div>}
                                // bordered
                                style={{ width: 320, background: `${COLOR_MAP[scene.split('-')[0]]}32`, padding: 24 }}
                                size="small"
                                dataSource={catalogueItemGroupByCategory}
                                renderItem={([category, itemList]: any) => {
                                    return (
                                        <List.Item style={{ padding: '2px 4px' }}>
                                            <FlexContainer justifyContent="space-between" style={{ width: '100%' }}>
                                                <span className="font-weight-bold" style={{ width: 160 }}>{category}</span>
                                                <span className="font-weight-bold">{itemList.length}</span>

                                            </FlexContainer>
                                        </List.Item>
                                    )
                                }}
                            />
                        </div>
                    </FlexContainer>
                ) : (
                    <div className="catalogue-item-list-wrapper" style={{ height: `calc(100% - ${HEADER_HEIGHT}px - ${FOOTER_HEIGHT}px)`, boxSizing: 'border-box', overflow: 'hidden' }}>
                        {currentProductList.map((item, idx) => {
                            return (
                                <div key={`catalogue-item-${idx+1}`} style={{ ...(item as any).hidden ? { visibility: 'hidden', border: 'none' } : {} }}>
                                    <div style={{ width: '100%' }}>
                                        <FlexContainer flexDirection="column" alignItems="center" style={{ height: item.platform === 'coles' ? 32 : 30, display: isColesOrWws ? 'flex' : 'none' }}>
                                            {['coles', 'woolworths'].includes(item.platform) && <div className="font-weight-bold" style={{ fontSize: 9, letterSpacing: -0.2, whiteSpace: 'nowrap' }}>{item.category?.replaceAll('||', ', ') || 'Other'}</div>}

                                            {!!item.lowest_price && item.lowest_price >= item.new_price && (
                                                <Tag shape="circle" style={{ background: COLOR_MAP[item.platform], color: 'white', padding: '0px 6px 0 4px', height: '14px' }}>
                                                    <span className="font-weight-bold" style={{ fontSize: 8, letterSpacing: -0.2 }}>
                                                        <FlexContainer alignItems="center">
                                                            <img width="10" height="10" src="https://img.icons8.com/glyph-neue/64/FFFFFF/double-tick.png" alt="double-tick" style={{ marginRight: 2 }} />
                                                            历史最低
                                                        </FlexContainer>
                                                    </span>
                                                </Tag>
                                            )}

                                            {!!item.lowest_price && item.lowest_price < item.new_price && (
                                                <Tag shape="circle" style={{ background: 'rgb(230,232,234)', color: 'black', padding: '0px 6px 0 6px', height: '14px' }}>
                                                    <span className="font-weight-bold" style={{ fontSize: 8, letterSpacing: -0.2 }}>
                                                        <FlexContainer alignItems="center">
                                                            历史最低 ${item.lowest_price}
                                                        </FlexContainer>
                                                    </span>
                                                </Tag>
                                            )}
                                        </FlexContainer>

                                        <FlexContainer justifyContent="center" style={{ position: 'relative', padding: item.platform === 'coles' ? '4px 8px' : 0 }} >
                                            <img src={`https://www.hotaudeals.com/api/v1/redirect/img/${uniqueId()}?imgUrl=${item.pic_url}`} width={item.platform === 'coles' ? '85%' : '100%'} height="90" style={{ objectFit: 'contain' }} />

                                            {(item.platform === 'coles' || (item.platform === 'woolworths' && !!item.discount_rate && !(item.discount_rate > -51 && item.discount_rate < -49))) && <ProductBadge platform={item.platform} discountRate={item.discount_rate as number} style={{ transform: 'scale(0.55)', top: -16, left: -10 }} />}

                                            {!!item.offer_text && (
                                                <div style={{ textAlign: 'right', letterSpacing: -0.5, fontSize: 8, position: 'absolute', bottom: 0, right: -4, background: 'rgb(254, 220, 2)', padding: '0 4px', borderRadius: '2px 0 0 2px' }}>
                                                    {item.offer_text.replace('Down Down', '').trim()}
                                                </div>
                                            )}
                                        </FlexContainer>
                                        <FlexContainer justifyContent="space-between" alignItems="center" gap="4px">
                                            <div className="catalogue-item-name-wrapper" style={{ fontSize: 8, lineHeight: 1 }}>{renderText(item.name)}</div>
                                            <div style={{ fontWeight: 700, letterSpacing: -1, color: COLOR_MAP[item.platform] }}>
                                                ${item.new_price}
                                            </div>
                                        </FlexContainer>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                )}

                <Footer />
            </FlexContainer>

            <div style={{ background: `linear-gradient(to bottom, rgb(53, 55, 59), rgb(53, 55, 59) calc(100% - ${FOOTER_HEIGHT}px), transparent 100%)`, position: 'absolute', height: RADIUS * 2, width: RADIUS * 2, bottom: 0 - RADIUS * 2 + FOOTER_HEIGHT, borderRadius: '50%', zIndex: 0 }} />
        </FlexContainer>
    )
}

export default CatalogueRender
import { useContext } from "react"
import { AppContext } from "../../App"
import { Editor, EditorProps } from "@monaco-editor/react"
import { withField } from "@douyinfe/semi-ui"

interface ICodeEditor extends EditorProps {
    value?: string
}

const CodeEditor = ({ value, ...editorProps }: EditorProps) => {
    const { isDarkMode } = useContext(AppContext)

    return (
        <Editor 
            theme={isDarkMode ? 'vs-dark' : 'light'}
            value={value}
            onChange={editorProps.onChange}
            {...editorProps} 
        />
    )
}

export const FormCodeEditor = withField(CodeEditor)
export default CodeEditor
import { useAsyncEffect } from "ahooks"
import { useState } from "react"
import { request } from "../utils"

const useKeyValuePairs = (scope: string, scene?: string) => {
    const [kvPairs, setKvPairs] = useState<Record<string, string>>({})
    const [raw, setRaw] = useState<any>()
    const [v, setV] = useState(1)

    useAsyncEffect(async () => {
        if (scope === 'generic') {
            const respData = await request({
                method: 'post',
                url: '/api/v1/service/execute',
                data: {
                    service: 'rds.sql',
                    requestParams: [
                        `SELECT * FROM simpo_octopus.config WHERE \`key\` = "${scene}"`
                    ]
                }
            })

            setRaw(respData?.[0]?.value)
        } else {
            const respData = await request({
                method: 'post',
                url: '/api/v1/service/execute',
                data: {
                    service: 'rds.sql',
                    requestParams: [
                        `SELECT * FROM simpo_octopus.key_value_pair WHERE type = "${scope}" ${scene ? `AND remark = "${scene}"` : ''}`
                    ]
                }
            })

            setRaw(respData)
            setKvPairs(Object.assign({}, ...respData.map((item: any) => ({
                [item.key]: [item.value]
            }))))
        }
    }, [scope, scene, v])

    return { kvPairs, raw, handleRefresh: () => setV(prev => prev + 1) }
}

export default useKeyValuePairs
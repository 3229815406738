import { useAsyncEffect } from "ahooks"
import { useNavigate, useParams } from "react-router-dom"
import { request } from "../../utils"
import { useContext, useMemo, useState } from "react"
import { IArticle } from "../../typings"
import { Button, Spin, Toast } from "@douyinfe/semi-ui"
import FlexContainer from "../../components/FlexContainer"
import { AppContext } from "../../App"
import { IconArrowLeft, IconShareStroked } from "@douyinfe/semi-icons"
import copy from "copy-to-clipboard"
import ArticleRender from "../../components/ArticleRender"

const ArticleDetail = () => {
    const { article_id } = useParams()
    const navigate = useNavigate()

    const { isMobile, windowHeight, navbarHeight, windowWidth, isDarkMode } = useContext(AppContext)

    const [loading, setLoading] = useState(true)
    const [articleDetail, setArticleDetail] = useState<IArticle>()

    useAsyncEffect(async () => {
        const respData = await request({
            data: {
                service: 'rds.sql',
                requestParams: [
                    `SELECT * FROM simpo_octopus.au_article WHERE au_article_auto_increment_id = "${article_id}"`
                ]
            }
        })

        setArticleDetail(respData?.[0])
        setLoading(false)
    }, [])

    const showInOneColumn = useMemo(() => {
        if (isMobile) {
            return true
        }

        return windowWidth < 1048
    }, [isMobile, windowWidth])

    return (
        <Spin spinning={loading} size="large">
            <FlexContainer justifyContent="center" className="reward-hotels-wrapper" style={{ padding: isMobile ? 12 : 24, maxHeight: windowHeight - navbarHeight, overflow: 'auto', boxSizing: 'border-box' }}>
                <FlexContainer style={{ maxWidth: 1000, width: '100%', height: '100%' }} flexDirection="column">
                    <FlexContainer style={{ marginBottom: 16 }} justifyContent="space-between" gap="24px">
                        <FlexContainer className="responsive-text" flexDirection="column">
                            <div>
                                <Button theme="borderless" style={{ padding: 0 }} size="small" icon={<IconArrowLeft style={{ fontSize: 14 }} />} onClick={() => navigate('/articles')}>
                                    Back to all articles
                                </Button>
                            </div>
                            <div style={{ fontWeight: 700, fontSize: 24, letterSpacing: -1, lineHeight: 1.2 }}>
                                {articleDetail?.article_name_en}
                            </div>
                        </FlexContainer>
                        {!isMobile && (
                            <div>
                                <Button
                                    icon={<IconShareStroked style={{ fontSize: 20 }} />}
                                    type="tertiary"
                                    style={{ borderRadius: 100, width: 40, height: 40 }}
                                    onClick={() => {
                                        copy(window.location.href)
                                        Toast.success("Link copied to clipboard")
                                    }}
                                />
                            </div>
                        )}
                    </FlexContainer>

                    <FlexContainer gap="20px" flexWrap={showInOneColumn ? 'wrap' : 'nowrap'}>
                        <FlexContainer
                            flexDirection="column"
                            gap="20px"
                            style={{
                                flexGrow: 0,
                                boxSizing: 'border-box',
                                width: '100%',
                                // ...showInOneColumn ? {} : { width: 720 }
                            }}
                        >
                            <FlexContainer
                                flexDirection="column"
                                style={{
                                    width: '100%',
                                    background: 'white',
                                    borderRadius: 8,
                                    overflow: 'auto',
                                    border: '1px solid var(--semi-color-border)',
                                    padding: '24px 0',
                                    height: 'fit-content',
                                    boxSizing: 'border-box',
                                    ...isDarkMode ? {
                                        background: 'rgb(30,30,27)'
                                    } : {}
                                }}
                            >
                                {!!articleDetail?.article_url_en && <ArticleRender src={articleDetail?.article_url_en} />}
                            </FlexContainer>

                            {/* <FlexContainer
                                className='responsive-background-secondary'
                                flexDirection="column"
                                style={{
                                    width: '100%',
                                    background: 'white',
                                    borderRadius: 4,
                                    padding: '16px 8px 0 8px',
                                    height: 'fit-content',
                                    boxSizing: 'border-box',
                                }}
                            >
                                <div className="font-weight-black responsive-text" style={{ letterSpacing: -0.2, padding: '0 8px' }}>History Rates</div>

                                {!!activeMerchant && (
                                    <div style={{ marginTop: -12 }}>
                                        <MerchantCashbackBody merchant={activeMerchant} hideHeader disableSticky />
                                    </div>
                                )}
                            </FlexContainer> */}
                        </FlexContainer>

                        {/* <FlexContainer flexDirection="column" gap="20px" style={{ boxSizing: 'border-box', ...showInOneColumn ? { width: '100%' } : { width: 260, flexGrow: 0, flexShrink: 0 } }}>
                            <FlexContainer className="responsive-background-secondary" flexDirection="column" gap="8px" style={{ borderRadius: '4px', background: 'white', width: '100%', height: 'fit-content', padding: 16, boxSizing: 'border-box' }}>
                                <div className="font-weight-black responsive-text" style={{ letterSpacing: -0.2, }}>Hotel Summary</div>

                                <DescriptionItem label="Chain" value={capitalize(brand)} />
                                <DescriptionItem label="Brand" value={hotelMetaData?.brand} />
                                <DescriptionItem label="Opening Date" value={dayjs(hotelMetaData?.opening_date).format('MMM YYYY')} />
                                <DescriptionItem label="Rating" value={(
                                    <Tag color="yellow">
                                        <FlexContainer alignItems="center" gap="2px">
                                            <IconStar style={{ fontSize: 12 }} />
                                            {hotelMetaData?.stars} based on {hotelMetaData?.number_of_reviews} reviews
                                        </FlexContainer>
                                    </Tag>
                                )} />
                                <DescriptionItem label="Website" value={(
                                    <Button theme="borderless" size="small" style={{ padding: 0 }} icon={<IconExternalOpen />} iconPosition="right" onClick={() => {
                                        window.open(`https://www.google.com/search?btnI=1&q=${hotelMetaData?.nick_name}`)
                                    }}>
                                        Open in {capitalize(brand)}
                                    </Button>
                                )} />
                            </FlexContainer>
                        </FlexContainer> */}
                    </FlexContainer>


                </FlexContainer>
            </FlexContainer>
        </Spin>
    )
}

export default ArticleDetail
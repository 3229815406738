import { useCallback, useContext, useEffect, useMemo, useState } from "react"
import FlexContainer from "../../../components/FlexContainer"
import { AppContext, adminNavItems } from "../../../App"
import { Button, Input, Nav, Switch } from "@douyinfe/semi-ui"
import { Outlet, useNavigate } from "react-router-dom"
import { IconArrowLeft } from "@douyinfe/semi-icons"
import { groupBy } from "lodash-es"
import './index.less'

export const RESPONSE_CACHE_DISABLED_FLAG = '__response_cache_disabled__'

export const SideSheetPlaceholder = () => {
    const [hasSidesheet, setHasSidesheet] = useState(false)

    useEffect(() => {
        const interval = setInterval(() => {
            const el = document.querySelector('.semi-sidesheet')

            if (el) {
                setHasSidesheet(true)
            } else {
                setHasSidesheet(false)
            }
        }, 500)

        return () => {
            clearInterval(interval)
        }
    }, [])

    if (!hasSidesheet) {
        return <></>
    }

    return (
        <div style={{ width: 448, flexShrink: 0 }} />
    )
}

const AdminLayout = () => {
    const { windowHeight, navbarHeight, isMobile } = useContext(AppContext)

    const navigate = useNavigate()

    const [isValidated, setIsValidated] = useState(sessionStorage.getItem('__admin_is_validated__') === '1')
    const [passwordInput, setPasswordInput] = useState('')

    const [cacheEnabled, setCacheEnabled] = useState(!localStorage.getItem(RESPONSE_CACHE_DISABLED_FLAG))

    const [selectedNavItemKey, setSelectedNavItemKey] = useState('')

    const groupedAdminNavItem = groupBy(adminNavItems.filter(item => !item.hidden), 'category')

    const handleLogin = useCallback(() => {
        if (passwordInput === '5280') {
            setIsValidated(true)
            sessionStorage.setItem('__admin_is_validated__', '1')
        }
    }, [passwordInput])

    if (!isValidated) {
        return (
            <FlexContainer justifyContent="center" style={{ height: windowHeight - navbarHeight }} alignItems="center">
                <FlexContainer
                    className="responsive-background-secondary"
                    flexDirection="column"
                    gap="8px"
                    style={{ width: 250, background: 'white', padding: 24, borderRadius: 8, border: '1px solid var(--semi-color-border)' }}
                >
                    <div className="responsive-text" style={{ textAlign: 'center' }}>
                        <b style={{ fontSize: 20, letterSpacing: -0.5 }}>HotAuDeals</b><br />Admin Login
                    </div>
                    <Input placeholder="Enter password" type="password" onChange={setPasswordInput} />
                    <Button theme="solid" block onClick={handleLogin}>Login</Button>
                </FlexContainer>
            </FlexContainer>
        )
    }

    return (
        <FlexContainer className="admin-wrapper" style={{ position: 'relative' }} flexDirection={isMobile ? 'column' : 'row'}>
            <Nav
                mode={isMobile ? 'horizontal' : 'vertical'}
                style={isMobile ? { width: '100vw', overflow: 'auto', height: 48 } : { height: windowHeight - navbarHeight, flexShrink: 0, overflow: 'auto' }}
                selectedKeys={[selectedNavItemKey]}
                items={Object.entries(groupedAdminNavItem).map(([category, navItemList]) => {
                    return {
                        itemKey: category,
                        text: category,
                        icon: navItemList[0].icon,
                        items: navItemList.map(item => {
                            return {
                                itemKey: item.name,
                                text: item.name,
                                onClick: () => {
                                    setSelectedNavItemKey(item.name as string)
                                    navigate(item.path)
                                }
                            }
                        })
                    }
                })}
                footer={{
                    collapseButton: true,
                }}
            // header={{
            //     text: <span className="font-weight-bold">Admin Navigation</span>
            // }}
            />

            <FlexContainer style={{ flexGrow: 1, width: '100%' }}>
                <Outlet />
            </FlexContainer>

            <div style={{ position: 'fixed', top: navbarHeight - 1, right: 0 }}>
                <Button theme="solid" type={cacheEnabled ? 'primary' : "danger"} size="small" style={{ borderRadius: '0 0 0 4px' }} onClick={() => {
                    if (cacheEnabled) {
                        setCacheEnabled(false)
                        localStorage.setItem(RESPONSE_CACHE_DISABLED_FLAG, '1')
                    } else {
                        setCacheEnabled(true)
                        localStorage.removeItem(RESPONSE_CACHE_DISABLED_FLAG)
                    }
                }}>
                    Response cache {cacheEnabled ? 'enabled' : 'disabled'}
                </Button>
            </div>
        </FlexContainer>
    )
}

export default AdminLayout
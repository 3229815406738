import { useAsyncEffect } from "ahooks"
import { useContext, useEffect, useMemo, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { ICashbackPromotion } from "../../typings"
import MerchantCashbackSidesheet, { MerchantCashbackBody } from "../Cashback/components/MerchantCashbackSidesheet"
import { renderText, request } from "../../utils"
import FlexContainer from "../../components/FlexContainer"
import { AppContext } from "../../App"
import { Avatar, Button, Spin, Toast } from "@douyinfe/semi-ui"
import { IconArrowLeft, IconBell, IconLink, IconShareStroked } from "@douyinfe/semi-icons"
import copy from "copy-to-clipboard"
import './index.less'
import CashbackCalculator from "./components/CashbackCalculator"
import SimilarCashbackOfferList from "./components/SimilarCashbackOfferList"
import classNames from "classnames"
import HistoryCashbackRate from "./components/HistoryCashbackRate"
import { initSeo, resetSeo } from "../../utils/seo"
import { capitalize } from "lodash-es"

interface ICashBackDetailProps {
    merchantName?: string;
}

const CashbackDetail = ({ merchantName }: ICashBackDetailProps) => {
    const { merchant: encodedMerchantName } = useParams()
    const navigate = useNavigate()

    const { isMobile, isEmbedded, windowWidth, navbarHeight, windowHeight } = useContext(AppContext)

    const [activeMerchant, setActiveMerchant] = useState<ICashbackPromotion>()
    const [SimilarCashbackPromotionList, setSimilarCashbackPromotionList] = useState<ICashbackPromotion[]>([])
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        if (!activeMerchant) {
            return
        }

        initSeo({
            title: `${capitalize(activeMerchant.merchant_name)} Cashback Deals & Discount Offers | HotAuDeals`,
            description: `Discover the latest ${activeMerchant.merchant_name} cashback deals & discount offers. We compare rates from ShopBack, Cashrewards, and TopCashback to present the best buying choices. Subscribe to your preferred brands for updates on new deals and exclusive promotions.`
        })

        return () => {
            resetSeo()
        }
    }, [])

    const merchantWebsite = useMemo(() => {
        if (!activeMerchant || !activeMerchant.website) return ''

        const url = new URL(activeMerchant.website)
        return url.origin
    }, [activeMerchant])

    const showInOneColumn = useMemo(() => {
        if (isMobile) {
            return true
        }

        return windowWidth < 1048
    }, [isMobile, windowWidth])

    useAsyncEffect(async () => {
        try {
            const decodedMerchantName = encodedMerchantName ? atob(encodedMerchantName as string)?.trim() : merchantName

            if (!decodedMerchantName) {
                throw new Error("Invalid merchant name")
            }

            setLoading(true)
            setActiveMerchant(undefined)

            const respData = await request({
                method: 'post',
                url: '/api/v1/service/execute',
                data: {
                    service: 'rds.sql',
                    requestParams: [
                        `SELECT * FROM simpo_octopus.au_cashback_promotion_tmp WHERE merchant_name = "${decodedMerchantName}"`
                    ]
                }
            })

            if (!respData[0]) {
                throw new Error("Invalid merchant name")
            }

            const merchantMetaDataRespData: any[] = await request({
                method: 'post',
                url: '/api/v1/service/execute',
                data: {
                    service: 'rds.sql',
                    requestParams: [
                        `SELECT * FROM simpo_octopus.key_value_pair WHERE type IN ("website", "category") AND \`key\` = "${decodedMerchantName}"`
                    ]
                }
            })

            setActiveMerchant({
                ...respData[0],
                website: merchantMetaDataRespData?.find(item => item.type === 'website')?.value,
                category: merchantMetaDataRespData?.find(item => item.type === 'category')?.value?.split('||')?.filter(Boolean)
            })

            setLoading(false)
        } catch (err) {
            navigate('/cashback')
            return
        }
    }, [encodedMerchantName])

    return (
        <Spin spinning={loading} size="large">
            <FlexContainer justifyContent="center" className="cashback-detail-wrapper" style={{ padding: isMobile ? 12 : 24, maxHeight: windowHeight - navbarHeight, overflow: 'auto', boxSizing: 'border-box' }}>
                <FlexContainer style={{ maxWidth: 1000, width: '100%', height: '100%' }} flexDirection="column">
                    {isMobile && (
                        <div style={{ marginBottom: 4 }}>
                            <Button theme="borderless" style={{ padding: 0 }} size="small" icon={<IconArrowLeft style={{ fontSize: 14 }} />} onClick={() => navigate('/cashback')}>
                                Back to cashback promotions
                            </Button>
                        </div>
                    )}
                    <FlexContainer style={{ marginBottom: 16 }} justifyContent="space-between" gap="24px">
                        <FlexContainer className={classNames({
                            'responsive-text': true,
                            'logo-medium-wrapper': isMobile
                        })} alignItems="center" gap="16px">
                            <Avatar
                                className="merchant-logo-wrapper"
                                src={activeMerchant?.merchant_logo}
                                style={{ objectFit: 'cover', marginLeft: isMobile ? 2 : 0 }}
                                size={isMobile ? "medium" : "large"}
                                shape="square"
                                {...activeMerchant?.is_upsized ? {
                                    border: { motion: true, color: "rgb(254, 220, 2)" },
                                    bottomSlot: {
                                        text: "Upsized",
                                        textColor: 'black',
                                        style: isMobile
                                            ? { fontSize: 12, marginBottom: '1px', padding: "0 3px", background: 'rgb(254, 220, 2)', height: 16, borderRadius: '0 0 2px 2px', position: 'absolute', left: -1 }
                                            : { padding: '0 7px', marginBottom: '5px', background: 'rgb(254, 220, 2)', borderRadius: '0 0 6px 6px' }
                                    } as any
                                } : {
                                    border: true
                                }}
                            />
                            <div>
                                {!isEmbedded && !isMobile && (
                                    <div>
                                        <Button theme="borderless" style={{ padding: 0 }} size="small" icon={<IconArrowLeft style={{ fontSize: 14 }} />} onClick={() => navigate('/cashback')}>
                                            Back to cashback promotions
                                        </Button>
                                    </div>
                                )}
                                <div style={{ fontWeight: 700, fontSize: 24, letterSpacing: -1, lineHeight: 1.2 }}>
                                    {renderText(activeMerchant?.merchant_name)} Cashback Detail
                                </div>
                                {!!merchantWebsite && (
                                    <FlexContainer gap="6px" alignItems="center">
                                        <FlexContainer gap="4px" alignItems="center" className="font-weight-bold" style={{ letterSpacing: -0.5, color: 'grey', cursor: 'pointer' }} onClick={() => window.open(merchantWebsite)}>
                                            <IconLink />
                                            {merchantWebsite}
                                        </FlexContainer>
                                    </FlexContainer>
                                )}
                            </div>

                        </FlexContainer>

                        {!isMobile && (
                            <div>
                                <Button
                                    icon={<IconShareStroked style={{ fontSize: 20 }} />}
                                    type="tertiary"
                                    style={{ borderRadius: 100, width: 40, height: 40 }}
                                    onClick={() => {
                                        copy(window.location.href)
                                        Toast.success("Link copied to clipboard")
                                    }}
                                />
                            </div>
                        )}
                    </FlexContainer>

                    <FlexContainer gap="20px" flexWrap={showInOneColumn ? 'wrap' : 'nowrap'}>
                        {isMobile && (
                            <style>{`
                                    .gift-card-promotion-detail-body-wrapper .section-header {
                                        top: -12px !important;
                                    }
                                `}</style>
                        )}

                        <FlexContainer
                            flexDirection="column"
                            gap="20px"
                            style={{
                                flexGrow: 0,
                                boxSizing: 'border-box',
                                width: '100%',
                                ...showInOneColumn ? {} : { width: 720 }
                            }}
                        >

                            <FlexContainer
                                className='responsive-background-secondary'
                                flexDirection="column"
                                style={{
                                    width: '100%',
                                    background: 'white',
                                    borderRadius: 4,
                                    padding: '16px 8px 0 8px',
                                    height: 'fit-content',
                                    boxSizing: 'border-box',
                                }}
                            >
                                <div className="font-weight-black responsive-text" style={{ letterSpacing: -0.2, padding: '0 8px' }}>Cashback Rates</div>

                                {!!activeMerchant && (
                                    <div style={{ marginTop: -12 }}>
                                        <MerchantCashbackBody merchant={activeMerchant} hideHeader disableSticky />
                                    </div>
                                )}
                            </FlexContainer>

                            <FlexContainer
                                className='responsive-background-secondary'
                                flexDirection="column"
                                style={{
                                    width: '100%',
                                    background: 'white',
                                    borderRadius: 4,
                                    padding: '16px 8px 0 8px',
                                    height: 'fit-content',
                                    boxSizing: 'border-box',
                                }}
                            >
                                <HistoryCashbackRate />
                            </FlexContainer>
                        </FlexContainer>

                        <FlexContainer flexDirection="column" gap="20px" style={{ boxSizing: 'border-box', ...showInOneColumn ? { width: '100%' } : { width: 260, flexGrow: 0, flexShrink: 0 } }}>
                            <FlexContainer className="responsive-background-secondary" flexDirection="column" gap="8px" style={{ borderRadius: '4px', background: 'white', width: '100%', height: 'fit-content', padding: 16, boxSizing: 'border-box' }}>
                                <div className="font-weight-black responsive-text" style={{ letterSpacing: -0.2, }}>Getting Started</div>

                                <Button
                                    block
                                    theme="outline"
                                    type="tertiary"
                                    className="responsive-background"
                                    style={{ height: 40, borderRadius: 6 }}
                                    icon={<IconBell />}
                                >
                                    Subscribe
                                </Button>
                            </FlexContainer>

                            {activeMerchant?.max_cashback_rate.includes('%') && (
                                <FlexContainer className="responsive-background-secondary" flexDirection="column" gap="8px" style={{ borderRadius: '4px', background: 'white', width: '100%', height: 'fit-content', padding: 16, boxSizing: 'border-box' }}>
                                    <div className="font-weight-black responsive-text" style={{ letterSpacing: -0.2, }}>Cashback Calculator</div>

                                    <CashbackCalculator />
                                </FlexContainer>
                            )}

                            {!!activeMerchant?.category?.length && (
                                <FlexContainer className="responsive-background-secondary" flexDirection="column" gap="8px" style={{ borderRadius: '4px', background: 'white', width: '100%', height: 'fit-content', padding: 16, boxSizing: 'border-box' }}>
                                    <div className="font-weight-black responsive-text" style={{ letterSpacing: -0.2, }}>Trending Cashback Offer in {activeMerchant.category[0]}</div>

                                    <SimilarCashbackOfferList category={activeMerchant.category[0]} currMerchantName={activeMerchant.merchant_name} />
                                </FlexContainer>
                            )}
                        </FlexContainer>
                    </FlexContainer>


                </FlexContainer>
            </FlexContainer>
        </Spin>
    )
}

export default CashbackDetail
import { driver } from "driver.js"
import { useContext, useEffect, useRef, useState } from "react"
import { AppContext } from "../../../App"
import { waitTime } from "../../../utils"
import { Button, Carousel, Modal } from "@douyinfe/semi-ui"
import ProductCard from "../../../pages/GroceriesByPlatform/components/ProductCard"
import ReactDOM from "react-dom"
import FlexContainer from "../../FlexContainer"
import { isEmpty } from "lodash-es"
import SimilarProductSidesheet from "../../../pages/GroceriesByPlatform/components/SimilarProductSidesheet"
import { IconExternalOpen } from "@douyinfe/semi-icons"
import { IMG_LIST } from "../ChromeExtensionModal"
import useTour from "../../../hooks/useTour"
import { EXAMPLE_PRODUCT } from "./example"

const GroceriesTourModal = () => {
    const { isWindowLoaded, COLOR_MAP, isMobile } = useContext(AppContext)

    const { isVisited, handleAddToVisitedTourId } = useTour('groceries_page_tutorial')

    const [visible, setVisible] = useState(false)
    const [modalContent, setModalContent] = useState('product-card')

    const chromeExtensionIsOpened = useRef(false)

    useEffect(() => {
        if (!isWindowLoaded && isEmpty(COLOR_MAP)) {
            return
        }

        if (isVisited) {
            return
        }

        setTimeout(() => {
            const driverObj = driver({
                popoverClass: 'responsive-background-secondary',
                showProgress: true,
                allowClose: false,
                onDestroyed: () => {
                    setVisible(false)
                },
                steps: [
                    {
                        popover: {
                            title: 'Got 1 minute?',
                            description: 'Let us show you how HotAuDeals can help you save even more on your daily grocery shopping!',
                            showButtons: ["next", "close"],
                            onNextClick: async () => {
                                setVisible(true)
                                await waitTime(100)
                                driverObj.moveNext()
                            }
                        }
                    },
                    {
                        element: '.groceries-tour-modal-wrapper .product-card-wrapper',
                        popover: {
                            showButtons: ["next"],
                            title: 'Product Details at a Glance',
                            description: `We're tracking live prices for nearly all Coles and Woolworths products to help you shop smarter for groceries.`
                        }
                    },
                    {
                        element: '.groceries-tour-modal-wrapper .product-price-wrapper',
                        popover: {
                            showButtons: ["previous", "next"],
                            title: 'Price Tag Legend',
                            onPopoverRender: (dom) => {
                                dom.description.style.display = 'block'
                                ReactDOM.render((
                                    <FlexContainer flexDirection="column" gap="8px">
                                        <div>
                                            <div style={{ width: 'fit-content', marginRight: -2, fontSize: 12, letterSpacing: -0.5, fontWeight: 600, padding: '2px 8px', color: 'black', borderRadius: 16, background: 'rgb(254, 220, 2)', whiteSpace: 'nowrap' }}>
                                                Save $2.4
                                            </div>
                                            <div style={{ fontSize: 12, margin: '4px 0 8px 2px', borderLeft: '4px solid var(--semi-color-border)', paddingLeft: 8 }}>
                                                The current price is <b>$2.40 lower</b> than the previous price.
                                            </div>
                                        </div>

                                        <div>
                                            <div style={{ width: 'fit-content', marginRight: -2, fontSize: 12, letterSpacing: -0.5, fontWeight: 600, padding: '2px 8px', color: 'white', borderRadius: 16, background: COLOR_MAP.coles, whiteSpace: 'nowrap' }}>
                                                Historical low
                                            </div>
                                            <div style={{ fontSize: 12, margin: '4px 0 8px 2px', borderLeft: '4px solid var(--semi-color-border)', paddingLeft: 8 }}>
                                                The current price is at its <b>lowest</b> since July 2024
                                            </div>
                                        </div>

                                        <div>
                                            <FlexContainer gap="2px" alignItems="center" style={{ width: 'fit-content', fontSize: 12, letterSpacing: -0.5, fontWeight: 600, padding: '2px 8px 2px 6px', color: 'white', borderRadius: 16, background: COLOR_MAP.coles, whiteSpace: 'nowrap' }}>
                                                <img width="16" height="16" src="https://img.icons8.com/glyph-neue/64/FFFFFF/double-tick.png" alt="double-tick" />
                                                Cheapest
                                            </FlexContainer>
                                            <div style={{ fontSize: 12, margin: '4px 0 8px 2px', borderLeft: '4px solid var(--semi-color-border)', paddingLeft: 8 }}>
                                                The current price is <b>cheapest</b> compared to another grocery store.
                                            </div>
                                        </div>

                                        <div>
                                            <FlexContainer gap="2px" alignItems="center" style={{ width: 'fit-content', fontSize: 12, letterSpacing: -0.5, fontWeight: 600, padding: '2px 8px 2px 5px', color: 'white', borderRadius: 16, background: COLOR_MAP.coles, whiteSpace: 'nowrap' }}>
                                                <img width="16" height="16" src="https://img.icons8.com/ios-filled/50/FFFFFF/us-dollar-circled--v2.png" />
                                                Matched
                                            </FlexContainer>
                                            <div style={{ fontSize: 12, margin: '4px 0 8px 2px', borderLeft: '4px solid var(--semi-color-border)', paddingLeft: 8 }}>
                                                The current price <b>matches</b> the price of the same product from another grocery store.
                                            </div>
                                        </div>
                                    </FlexContainer>
                                ), dom.description);
                            }
                        }
                    },
                    {
                        element: '.groceries-tour-modal-wrapper .product-price-wrapper',
                        popover: {
                            showButtons: ["previous", "next"],
                            title: 'Compare the price of the exact same product at another grocery store.',
                            description: "You’ll be notified if the same product is available at a lower price at another store. \nIn this example, Pepsi is cheaper at Woolworths. Let’s go with Woolies for Pepsi!"
                        }
                    },
                    {
                        element: '.groceries-tour-modal-wrapper .catalogue-item-banner',
                        popover: {
                            showButtons: ["previous", "next"],
                            title: 'Catalogue Item Reminder',
                            description: "You'll be notified if the product appears in the special catalogue trending that week, including its expiry date.",

                        }
                    },
                    {
                        element: '.groceries-tour-modal-wrapper .product-card-wrapper',
                        popover: {
                            showButtons: ["previous", "next"],
                            title: 'Want more insights?',
                            description: 'To view more product details, such as price history, simply click on the product card.',
                            onNextClick: async () => {
                                setModalContent('product-detail')
                                await new Promise(resolve => {
                                    const interval = setInterval(() => {
                                        const isLoading = !!document.querySelector('.groceries-tour-modal-wrapper .semi-spin-wrapper')
                                        if (!isLoading) {
                                            resolve(null)
                                            clearInterval(interval)
                                        }
                                    }, 100)
                                })
                                driverObj.moveNext()
                            }
                        }
                    },
                    {
                        element: '.groceries-tour-modal-wrapper .price-history-pane-wrapper',
                        popover: {
                            showButtons: ["next"],
                            title: 'Get More Insights with Exact Matches, Price History and More',
                            description: "We’re continuously tracking live prices for nearly all Coles and Woolworths products, giving you access to price history since July 2024.",
                        }
                    },
                    {
                        element: '.groceries-tour-modal-wrapper .price-history-pane-wrapper',
                        popover: {
                            title: "One more thing...",
                            onPopoverRender: (dom) => {
                                dom.description.style.display = 'block'
                                ReactDOM.render((
                                    <FlexContainer flexDirection="column" gap="8px">
                                        <Carousel theme='dark' style={{ height: 168 }} showIndicator={false}>
                                            {IMG_LIST.map(url => {
                                                return (
                                                    <div key={url}>
                                                        <img src={url} width="100%" />
                                                    </div>
                                                )
                                            })}
                                        </Carousel>

                                        <div>We’ve also created a handy <b>Chrome Extension</b> to offer product insights while you shop online at Coles or Woolworths.</div>
                                        <div>It's completely <b>ad-free</b> and does <b>NOT</b> include any user tracking.</div>
                                        <div>HotAuDeals is developed with ❤️ by an independent developer and is not affiliated with any third-party services.</div>

                                        <div>
                                            <Button theme="borderless" style={{ padding: 0 }} icon={<IconExternalOpen />} size="small" iconPosition="right" onClick={() => {
                                                chromeExtensionIsOpened.current = true
                                                window.open("https://chromewebstore.google.com/detail/hotaudeals-helper/hhpnflehmikmmkneofkchgjgjbmmbalg")
                                            }}>Check it out</Button>
                                        </div>
                                    </FlexContainer>
                                ), dom.description);
                            },
                            onNextClick: () => {
                                if (chromeExtensionIsOpened.current) {
                                    driverObj.moveNext()
                                    setVisible(false)
                                } else {
                                    if (!isMobile) {
                                        window.open("https://chromewebstore.google.com/detail/hotaudeals-helper/hhpnflehmikmmkneofkchgjgjbmmbalg")
                                    }
                                    
                                    driverObj.moveNext()
                                    setVisible(false)
                                }
                            }
                        },
                    },
                    {
                        popover: {
                            title: "That's the End of the Tour. We Hope You Enjoy Using HotAuDeals!",
                            showButtons: ["next"],
                            onNextClick: () => {
                                driverObj.moveNext()
                                handleAddToVisitedTourId('groceries_page_tutorial')
                            }
                        }
                    }
                ]
            })

            driverObj.drive()
        }, 1000)
    }, [isWindowLoaded, COLOR_MAP, isVisited])

    return (
        <Modal className="groceries-tour-modal-wrapper" title="Introducing HotAuDeals" visible={visible} footer={null} width={isMobile ? '100%' : 560} bodyStyle={{ paddingBottom: 24 }}>
            {modalContent === 'product-card' && <ProductCard {...EXAMPLE_PRODUCT as any} showCatalogueItemLabel />}

            {modalContent === 'product-detail' && <SimilarProductSidesheet {...EXAMPLE_PRODUCT as any} mode="tour" />}
        </Modal>
    )
}

export default GroceriesTourModal
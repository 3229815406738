import { useNavigate, useParams } from "react-router-dom"
import { IHotel } from "../../typings"
import { useContext, useMemo, useState } from "react"
import { useAsyncEffect } from "ahooks"
import { request } from "../../utils"
import { Button, Card, Spin, Tag, Toast, Tooltip } from "@douyinfe/semi-ui"
import FlexContainer from "../../components/FlexContainer"
import { AppContext } from "../../App"
import classNames from "classnames"
import { IconArrowLeft, IconExternalOpen, IconMapPin, IconShareStroked, IconStar } from "@douyinfe/semi-icons"
import copy from "copy-to-clipboard"
import { DescriptionItem } from "../RewardFlightsDetail"
import { capitalize } from "lodash-es"
import dayjs from "dayjs"
import PriceCalendar from "./components/PriceCalendar"
import HotelMap from "./components/HotelMap"
import useKeyValuePairs from "../../hooks/useKeyValuePairs"
import CurrencyConverter from "./components/CurrencyConverter"

const RewardHotelDetails = () => {
    const { brand: rawBrand, property_id } = useParams()
    const brand = rawBrand?.toLowerCase()

    const { kvPairs: hotelChainDict } = useKeyValuePairs('name', 'reward-hotels')
    const { kvPairs: hiltonBrandNameDict } = useKeyValuePairs('name', 'hilton-brand	')

    const { isMobile, windowWidth, windowHeight, navbarHeight } = useContext(AppContext)

    const navigate = useNavigate()

    const [hotelMetaData, setHotelMetaData] = useState<IHotel>()
    const [nearbyHotelList, setNearbyHotelList] = useState<IHotel[]>([])

    const [loading, setLoading] = useState(true)

    useAsyncEffect(async () => {
        setLoading(true)
        setHotelMetaData(undefined)
        setNearbyHotelList([])

        const respData = await request({
            data: {
                service: 'rds.sql',
                requestParams: [
                    `SELECT * FROM simpo_octopus.${brand}_hotel WHERE property_id = "${property_id}"`
                ]
            }
        })

        const targetHotel: IHotel = respData?.[0]

        const nearbyHotelListRespData = await request({
            data: {
                service: 'rds.sql',
                requestParams: [
                    `SELECT *,
                        (6371 * ACOS(
                            COS(RADIANS(${targetHotel.latitude})) * COS(RADIANS(latitude)) *
                            COS(RADIANS(longitude) - RADIANS(${targetHotel.longitude})) +
                            SIN(RADIANS(${targetHotel.latitude})) * SIN(RADIANS(latitude))
                        )) AS distance
                    FROM simpo_octopus.${brand}_hotel
                    WHERE country = "${targetHotel.country}" AND property_id <> "${targetHotel.property_id}"
                    HAVING distance <= 50
                    ORDER BY distance`
                ]
            }
        })

        setNearbyHotelList(nearbyHotelListRespData)
        setHotelMetaData(targetHotel)
        setLoading(false)
    }, [property_id])

    const showInOneColumn = useMemo(() => {
        if (isMobile) {
            return true
        }

        return windowWidth < 1048
    }, [isMobile, windowWidth])

    return (
        <Spin spinning={loading} size="large">
            <FlexContainer justifyContent="center" className="reward-hotels-wrapper" style={{ padding: isMobile ? 12 : 24, maxHeight: windowHeight - navbarHeight, overflow: 'auto', boxSizing: 'border-box' }}>
                <FlexContainer style={{ maxWidth: 1000, width: '100%', height: '100%' }} flexDirection="column">
                    <FlexContainer style={{ marginBottom: 16 }} justifyContent="space-between" gap="24px">
                        <FlexContainer className="responsive-text" flexDirection="column">
                            <div>
                                <Button theme="borderless" style={{ padding: 0 }} size="small" icon={<IconArrowLeft style={{ fontSize: 14 }} />} onClick={() => navigate('/reward-hotels')}>
                                    Back to reward hotels explorer
                                </Button>
                            </div>
                            <div style={{ fontWeight: 700, fontSize: 24, letterSpacing: -1, lineHeight: 1.2 }}>
                                {hotelMetaData?.name}
                            </div>
                        </FlexContainer>
                        {!isMobile && (
                            <div>
                                <Button
                                    icon={<IconShareStroked style={{ fontSize: 20 }} />}
                                    type="tertiary"
                                    style={{ borderRadius: 100, width: 40, height: 40 }}
                                    onClick={() => {
                                        copy(window.location.href)
                                        Toast.success("Link copied to clipboard")
                                    }}
                                />
                            </div>
                        )}
                    </FlexContainer>

                    <FlexContainer gap="20px" flexWrap={showInOneColumn ? 'wrap' : 'nowrap'}>
                        <FlexContainer
                            flexDirection="column"
                            gap="20px"
                            style={{
                                flexGrow: 0,
                                boxSizing: 'border-box',
                                width: '100%',
                                ...showInOneColumn ? {} : { width: 720 }
                            }}
                        >

                            <div style={{ width: '100%', height: 200, borderRadius: 4, overflow: 'hidden' }}>
                                {!!hotelMetaData && <HotelMap hotelList={nearbyHotelList} pinnedHotel={hotelMetaData} />}
                            </div>

                            <FlexContainer
                                className='responsive-background-secondary'
                                flexDirection="column"
                                style={{
                                    width: '100%',
                                    background: 'white',
                                    borderRadius: 4,
                                    padding: '16px',
                                    height: 'fit-content',
                                    boxSizing: 'border-box',
                                }}
                            >
                                <PriceCalendar />
                            </FlexContainer>

                            {/* <FlexContainer
                                className='responsive-background-secondary'
                                flexDirection="column"
                                style={{
                                    width: '100%',
                                    background: 'white',
                                    borderRadius: 4,
                                    padding: '16px 8px 0 8px',
                                    height: 'fit-content',
                                    boxSizing: 'border-box',
                                }}
                            >
                                <div className="font-weight-black responsive-text" style={{ letterSpacing: -0.2, padding: '0 8px' }}>History Rates</div>

                                {!!activeMerchant && (
                                    <div style={{ marginTop: -12 }}>
                                        <MerchantCashbackBody merchant={activeMerchant} hideHeader disableSticky />
                                    </div>
                                )}
                            </FlexContainer> */}
                        </FlexContainer>

                        <FlexContainer flexDirection="column" gap="20px" style={{ boxSizing: 'border-box', ...showInOneColumn ? { width: '100%' } : { width: 260, flexGrow: 0, flexShrink: 0 } }}>
                            <FlexContainer className="responsive-background-secondary" flexDirection="column" gap="8px" style={{ borderRadius: '4px', background: 'white', width: '100%', height: 'fit-content', padding: 16, boxSizing: 'border-box' }}>
                                <div className="font-weight-black responsive-text" style={{ letterSpacing: -0.2, }}>Hotel Summary</div>

                                <DescriptionItem label="Chain" value={hotelChainDict[brand || '']} />
                                <DescriptionItem label="Brand" value={hiltonBrandNameDict[hotelMetaData?.brand || ''] || hotelMetaData?.brand} />
                                <DescriptionItem label="Opening Date" hidden={!hotelMetaData?.stars}  value={dayjs(hotelMetaData?.opening_date).format('MMM YYYY')} />
                                <DescriptionItem label="Rating" hidden={!hotelMetaData?.stars} value={(
                                    <Tag color="yellow">
                                        <FlexContainer alignItems="center" gap="2px">
                                            <IconStar style={{ fontSize: 12 }} />
                                            {hotelMetaData?.stars} based on {hotelMetaData?.number_of_reviews} reviews
                                        </FlexContainer>
                                    </Tag>
                                )} />
                                <DescriptionItem label="Website" hidden={!hotelMetaData?.nick_name} value={(
                                    <Button theme="borderless" size="small" style={{ padding: 0 }} icon={<IconExternalOpen />} iconPosition="right" onClick={() => {
                                        if (hotelMetaData?.nick_name?.startsWith('http')) {
                                            window.open(hotelMetaData.nick_name)
                                            return
                                        }

                                        if (brand === 'marriott') {
                                            window.open(`https://www.google.com/search?btnI=1&q=${hotelMetaData?.nick_name}`)
                                        } else if (brand === 'ihg') {
                                            window.open(hotelMetaData?.nick_name?.startsWith('http') ? hotelMetaData?.nick_name : `https://${hotelMetaData?.nick_name}`)
                                        }
                                    }}>
                                        Open in {hotelChainDict[brand || '']}
                                    </Button>
                                )} />
                            </FlexContainer>

                            {/* <FlexContainer className="responsive-background-secondary" flexDirection="column" gap="8px" style={{ borderRadius: '4px', background: 'white', width: '100%', height: 'fit-content', overflow: 'hidden', boxSizing: 'border-box' }}>
                                <div className="font-weight-black responsive-text" style={{ letterSpacing: -0.2, }}>Hotel Summary</div>

                                <CurrencyConverter />
                            </FlexContainer> */}

                            {!!nearbyHotelList.length && (
                                <FlexContainer className="responsive-background-secondary" flexDirection="column" gap="8px" style={{ borderRadius: '4px', background: 'white', width: '100%', height: 'fit-content', padding: 16, boxSizing: 'border-box' }}>
                                    <div className="font-weight-black responsive-text" style={{ letterSpacing: -0.2, }}>Nearby Hotels</div>

                                    {nearbyHotelList.map(hotel => {
                                        return (
                                            <div style={{ cursor: 'pointer' }} onClick={() => {
                                                navigate(`/reward-hotels/${brand}/${hotel.property_id}`)
                                            }}>
                                                <Card bodyStyle={{ padding: '8px 12px' }}>
                                                    <div className="font-weight-bold" style={{ marginBottom: 2 }}>{hotel.name}</div>

                                                    <FlexContainer gap="4px">
                                                        <Tag>
                                                            <FlexContainer gap="2px" alignItems="center">
                                                                <IconMapPin style={{ fontSize: 12 }} />
                                                                <div className="font-weight-bold">{hotel.distance?.toFixed(1)}km</div>
                                                            </FlexContainer>
                                                        </Tag>

                                                        {!!hotel.stars && (
                                                            <Tooltip content={`Based on ${hotel.number_of_reviews} reviews`}>
                                                                <Tag color="yellow">
                                                                    <FlexContainer gap="2px" alignItems="center">
                                                                        <IconStar style={{ fontSize: 12 }} />
                                                                        <div className="font-weight-bold">{hotel.stars}</div>
                                                                    </FlexContainer>
                                                                </Tag>
                                                            </Tooltip>
                                                        )}

                                                    </FlexContainer>
                                                </Card>
                                            </div>

                                        )
                                    })}
                                </FlexContainer>
                            )}
                        </FlexContainer>
                    </FlexContainer>


                </FlexContainer>
            </FlexContainer>
        </Spin>
    )
}

export default RewardHotelDetails
import { Collapse, Divider, Table, Toast, Typography, Upload } from "@douyinfe/semi-ui"
import { useState } from "react"
import FlexContainer from "../../../components/FlexContainer"

const Toolbox = () => {
    const [fileList, setFileList] = useState<{ name: string, url: string }[]>([])
    const [uploadLoading, setUploadLoading] = useState(false)

    return (
        <div style={{ width: '100%' }}>
            <div className="font-weight-black" style={{ padding: '8px 16px', fontSize: 20, letterSpacing: -0.5 }}>
                Toolbox
            </div>

            <Divider />

            <div style={{ padding: '0' }}>
                <Collapse defaultActiveKey={['upload_results']}>
                    <Collapse.Panel itemKey="upload_results" header={(
                        <div style={{ fontSize: 16 }}>Upload images</div>
                    )}>
                        <FlexContainer flexDirection="column" gap="8px">
                            <Upload
                                action="https://bg.qibaodwight.org/api/v1/service/upload"
                                draggable={true}
                                style={{ width: '100%', height: 120 }}
                                showUploadList={false}
                                beforeUpload={() => {
                                    setUploadLoading(true)
                                    return true
                                }}
                                onChange={(info: any) => {
                                    const fileInfo = info.currentFile
                                    const cdnPath = fileInfo?.response?.data?.data?.InterfaceResult?.Key

                                    if (fileInfo.status === 'success') {
                                        setUploadLoading(false)
                                        if (cdnPath) {
                                            setFileList(prev => [
                                                ...prev,
                                                {
                                                    name: fileInfo.name,
                                                    url: `https://newweb.obs.cn-east-3.myhuaweicloud.com/${cdnPath}`
                                                }
                                            ])
                                            Toast.success(`${fileInfo.name} file uploaded successfully`);
                                        } else {
                                            Toast.error(`${fileInfo.name} file upload failed.`);
                                        }
                                    } else if (fileInfo.status === 'error') {
                                        setUploadLoading(false)
                                        Toast.error(`${fileInfo.name} file upload failed.`);
                                    }
                                }}
                            ></Upload>

                            <Table
                                dataSource={fileList}
                                pagination={false}
                                loading={uploadLoading}
                                columns={[
                                    {
                                        title: 'File name',
                                        dataIndex: 'name'
                                    },
                                    {
                                        title: 'URL',
                                        dataIndex: 'url',
                                        render: (text: string) => {
                                            return (
                                                <Typography.Paragraph copyable>{text}</Typography.Paragraph>
                                            )
                                        }
                                    }
                                ]}
                            />
                        </FlexContainer>

                    </Collapse.Panel>
                </Collapse>
            </div>
        </div >
    )
}

export default Toolbox
export const EVENTS = {
    API_REQUEST: 'api_request'
}

export const slardarReport = (name: string, metrics?: Record<string, number>, categories?: Record<string, string>) => {
    if (!window.location.hostname.includes('hotaudeals.com')) {
        return
    }

    window.Slardar('sendEvent', {
        name,
        metrics,
        categories
    })
}
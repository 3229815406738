import { Button, Card, Divider, TextArea } from "@douyinfe/semi-ui"
import { useCallback, useContext, useState } from "react"
import FlexContainer from "../../../components/FlexContainer"
import './index.less'
import { request } from "../../../utils"
import { AppContext } from "../../../App"
import { IconDelete } from "@douyinfe/semi-icons"
import { Editor } from "@monaco-editor/react"

const QUERY_HISTORY_LOCAL_STORAGE_KEY = '__query_history__'
const SQL_RUNNER_CODE_EDITOR_CACHE = '__sql_runner_code_editor_cache__'


const SqlRunner = () => {
    const { windowHeight, navbarHeight, isDarkMode } = useContext(AppContext)

    const [historyList, setHistoryList] = useState<{ name?: string, sql: string }[]>(JSON.parse(localStorage.getItem(QUERY_HISTORY_LOCAL_STORAGE_KEY) || '[]'))

    const [loading, setLoading] = useState(false)
    const [sql, setSql] = useState(localStorage.getItem(SQL_RUNNER_CODE_EDITOR_CACHE) || '')
    const [response, setResponse] = useState('')

    const handleSql = useCallback(async () => {
        setLoading(true)

        localStorage.setItem(SQL_RUNNER_CODE_EDITOR_CACHE, sql)
        const isNewSql = !historyList.find(item => item.sql.toLowerCase() === sql.toLowerCase())

        if (isNewSql) {
            const arr = [
                { sql },
                ...historyList,
            ]
            localStorage.setItem(QUERY_HISTORY_LOCAL_STORAGE_KEY, JSON.stringify(arr))
            setHistoryList(arr)
        }

        const respData = await request({
            method: 'post',
            url: '/api/v1/service/execute',
            data: {
                service: 'rds.sql',
                requestParams: [sql]
            }
        })

        if (respData && typeof respData === 'object') {
            setResponse(JSON.stringify(respData, null, 4))
        } else if (typeof respData === 'string') {
            setResponse(respData)
        } else {
            setResponse('')
        }

        setLoading(false)
    }, [sql, historyList])

    const handleDeleteSql = useCallback((sql: string) => {
        const arr = historyList.filter(item => item.sql !== sql)

        localStorage.setItem(QUERY_HISTORY_LOCAL_STORAGE_KEY, JSON.stringify(arr))
        setHistoryList(arr)
    }, [historyList])

    return (
        <FlexContainer className="sql-runner-wrapper" gap="16px" style={{ padding: '8px 16px', width: '100%' }}>
            <FlexContainer gap="8px" flexDirection="column" style={{ width: '100%', height: windowHeight - navbarHeight, overflow: 'auto' }}>
                <div className='font-weight-black responsive-text' style={{ fontSize: 20, letterSpacing: -0.5 }}>SQL Runner</div>
                <FlexContainer flexDirection="column" gap="12px">
                    <div style={{ borderRadius: 8, overflow: 'hidden', resize: 'vertical', height: 100 }}>
                        <Editor theme={isDarkMode ? "vs-dark" : 'light'} value={sql || localStorage.getItem(SQL_RUNNER_CODE_EDITOR_CACHE) || ''} onChange={v => setSql(v as string)} height="100%" language="sql" options={{ minimap: { enabled: false } }} />
                    </div>

                    <FlexContainer gap="6px">
                        <Button loading={loading} theme="solid" onClick={handleSql}>Execute</Button>
                        {loading && <Button theme="outline" type="danger" onClick={() => setLoading(false)}>Stop</Button>}
                    </FlexContainer>
                </FlexContainer>

                {response && (
                    <>
                        <Divider style={{ margin: '8px 0 0' }} />

                        <div className="responsive-text">{JSON.parse(response || '[]')?.length} records found:</div>

                        <div className="responsive-background-secondary responsive-text" style={{ borderRadius: 8, overflow: 'auto' }}>
                            <Editor theme={isDarkMode ? "vs-dark" : 'light'} height={`${windowHeight - navbarHeight - 213 - 16 - 32}px`} defaultLanguage="json" value={response} />
                        </div>
                    </>
                )}
            </FlexContainer>

            <FlexContainer flexDirection="column" gap="4px" style={{ width: 250, flexShrink: 0, borderLeft: '1px solid var(--semi-color-border)', paddingLeft: 16 }}>
                <div>
                    <div className="font-weight-black responsive-text" style={{ fontSize: 16, letterSpacing: -0.5 }}>History queries</div>
                </div>

                <FlexContainer flexDirection="column" gap="8px">
                    {historyList.map(item => {
                        return (
                            <div key={item.sql} style={{ cursor: 'pointer' }} onClick={() => setSql(item.sql)}>
                                <Card className="responsive-background-secondary" bodyStyle={{ padding: '8px 12px', position: 'relative' }}>
                                    <div style={{ fontFamily: 'monospace', fontWeight: 600, fontSize: 12, lineHeight: '16px', wordBreak: 'break-word' }}>
                                        <Button icon={<IconDelete style={{ fontSize: 12 }} />} theme="borderless" type="danger" size="small" style={{ position: 'absolute', top: 2, right: 4 }} onClick={() => handleDeleteSql(item.sql)} />
                                        {item.sql}
                                    </div>
                                </Card>
                            </div>
                        )
                    })}
                </FlexContainer>
            </FlexContainer>
        </FlexContainer>
    )
}

export default SqlRunner
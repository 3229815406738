import { act, useCallback, useContext, useEffect, useMemo, useState } from "react"
import { ICatalogue, ICatalogueItem, IDeal } from "../../../typings"
import { useAsyncEffect } from "ahooks"
import FlexContainer from "../../../components/FlexContainer"
import { request } from "../../../utils"
import { Button, Descriptions, Image, Input, MarkdownRender, RadioGroup, SideSheet, Table, Tabs, Tag, Toast, Tooltip, Typography } from "@douyinfe/semi-ui"
import { capitalize, groupBy, pick, uniq } from "lodash-es"
import { AppContext } from "../../../App"
import { IconArrowLeft, IconArrowRight, IconIdentity, IconSearch, IconSetting } from "@douyinfe/semi-icons"
import GPTHelper from "../../../components/GPTHelper"
import copy from "copy-to-clipboard"
import dayjs from "dayjs"
import './index.less'
import { SideSheetPlaceholder } from "../AdminLayout"
import EmbeddedWrapper, { EmbeddedProductDetail } from "../../Embedded"

function jsonToMarkdownTable(jsonArray: any[]) {
    if (!Array.isArray(jsonArray) || jsonArray.length === 0) {
        return '';
    }

    // Get table headers from the keys of the first object
    const headers = Object.keys(jsonArray[0]);

    // Create the header row
    let markdownTable = `| ${headers.join(' | ')} |\n`;

    // Create the separator row
    markdownTable += `| ${headers.map(() => '---').join(' | ')} |\n`;

    // Create the data rows
    jsonArray.forEach(obj => {
        const row = headers.map(header => obj[header] || '').join(' | ');
        markdownTable += `| ${row} |\n`;
    });

    return markdownTable;
}

const CatalogueManagement = () => {
    const { windowHeight, windowWidth, navbarHeight } = useContext(AppContext)

    const [loading, setLoading] = useState(false)

    const [catalogueList, setCatalogueList] = useState<ICatalogue[]>([])
    const [itemList, setItemList] = useState<ICatalogueItem[]>([])

    const [activeTab, setActiveTab] = useState('coles')
    const [selectedItem, setSelectedItem] = useState<ICatalogueItem>()

    const [salefinderUrl, setSalefinderUrl] = useState('')
    const [exportMdSidesheetVisible, setExportMdSidesheetVisible] = useState(false)
    const [searchKeyword, setSearchKeyword] = useState('')

    useAsyncEffect(async () => {
        const respData = await request({
            data: {
                service: 'rds.sql',
                requestParams: [
                    `SELECT * FROM simpo_octopus.au_salefinder_catalogue WHERE end_date >= CURDATE()`
                ]
            }
        })

        setCatalogueList(respData)
    }, [])

    useAsyncEffect(async () => {
        setItemList([])

        if (!activeTab) {
            return
        }

        setLoading(true)

        const respData = await request({
            data: {
                service: 'au.hotaudeals.getCatalogueItemList',
                requestParams: [
                    activeTab.toLowerCase(), 1, 500, [], 'ORDER BY -discount_rate DESC'
                ]
            }
        })

        setItemList(respData.productList)
        setLoading(false)
    }, [activeTab])

    const md = useMemo(() => {
        const itemListGroupByCategory = groupBy(itemList, el => el?.category?.split('||')[0])

        const targetCatalogue = catalogueList.find(catalogue => {
            if (catalogue.platform === activeTab) {
                return true
            }

            if (activeTab === 'coles (sneak peek)') {
                return catalogue.platform === 'coles' && dayjs(catalogue.start_date).isAfter(dayjs())
            } else if (activeTab === 'woolworths (sneak peek)') {
                return catalogue.platform === 'woolworths' && dayjs(catalogue.start_date).isAfter(dayjs())
            }

            return false
        })

        let output: string = `
### Key summary: 
**${itemList.length} Items** on Sale in This Week's Catalogue, **${itemList.filter(item => item.discount_rate && Math.abs(item.discount_rate) >= 0.45).length}** ½ Price, **${itemList.filter(item => item.lowest_price && item.lowest_price >= item.new_price).length}** at Their Lowest since July.

--------

Here are the ${capitalize(activeTab)} half-price specials, valid from Wednesday, ${dayjs(targetCatalogue?.start_date).format('DD MMMM YYYY')}, to Tuesday, ${dayjs(targetCatalogue?.end_date).format('DD MMMM YYYY')}. These deals are from the NSW metro catalogue. You can view the full catalogue for more details [here](${activeTab.includes('coles') ? 'https://www.coles.com.au/catalogues' : 'https://www.woolworths.com.au/shop/catalogue'}).

To help you browse the entire catalogue quickly, we’ve grouped all the bargains by category. Each item includes its previous price, discount rate, and the lowest price since July. We hope you find this useful! 

Alternatively, you can view this organized catalogue [here](${activeTab.includes('coles') ? 'https://hotaudeals.com/catalogue/coles' : 'https://hotaudeals.com/catalogue/woolworths'}).

--------
        `

        Object.entries(itemListGroupByCategory).filter(([category]) => !!category).forEach(([category, arr]) => {
            output += [
                '',
                `### ${category}`,
                `- ${arr.length} items on sale`,
                `- ${arr.filter(item => item.lowest_price && item.lowest_price >= item.new_price).length} at their lowest price since July`,
                '', ''
            ].join('\n')

            output += jsonToMarkdownTable(arr.map((item, idx) => {

                let Item = item.name
                const firstSkuId = item.sku_id_list?.split(',')?.[0]

                if (firstSkuId) {
                    Item = `[${item.name}](${activeTab.toLowerCase().includes('coles') ? `https://www.coles.com.au/product/any-${firstSkuId}` : `https://www.woolworths.com.au/shop/productdetails/${firstSkuId}`})`
                }

                return {
                    "#": `${idx + 1}.`,
                    Item,
                    Was: item.origin_price ? `~~$${item.origin_price.toFixed(2)}~~` : '',
                    Discount: item.discount_rate ? `${item.discount_rate.toFixed(0)}% ` : '',
                    Now: `$${item.new_price.toFixed(2)}`,
                    Lowest: item.lowest_price
                        ? item.lowest_price >= item.new_price
                            ? '✅'
                            : `❌ - $${item.lowest_price.toFixed(2)}`
                        : 'N/A',
                }
            }))
        })

        return output
    }, [itemList, catalogueList])

    return (
        <FlexContainer className='catalogue-management-wrapper'>
            <FlexContainer flexDirection="column" style={{ flexGrow: 1 }}>
                <div style={{ padding: '8px 0 0 16px' }}>
                    <div className="font-weight-black responsive-text" style={{ fontSize: 20, letterSpacing: -0.5 }}>Catalogue Management</div>
                </div>

                <div>
                    <Tabs
                        activeKey={activeTab}
                        tabList={catalogueList.map(catalogue => {
                            const isSneakPeek = dayjs().isBefore(dayjs(catalogue.start_date))

                            return {
                                key: catalogue.catalogue_id,
                                tab: isSneakPeek ? `${capitalize(catalogue.platform)} (sneak peek)` : `${capitalize(catalogue.platform)}`,
                                itemKey: (isSneakPeek ? `${catalogue.platform} (sneak peek)` : `${catalogue.platform}`).toLowerCase()
                            }
                        })}
                        onChange={setActiveTab}
                        contentStyle={{ padding: 0 }}
                        tabBarExtraContent={(
                            <div>
                                <Button theme="solid" onClick={() => [
                                    setExportMdSidesheetVisible(true)
                                ]}>Export as Markdown</Button>
                            </div>
                        )}
                    />
                </div>

                <Table
                    dataSource={itemList.filter(item => !searchKeyword || item.name.toLowerCase().includes(searchKeyword.toLowerCase()))}
                    pagination={false}
                    rowKey="item_id"
                    style={{ height: windowHeight - navbarHeight - 85, overflow: 'auto' }}
                    sticky
                    expandedRowRender={(record: any) => {
                        return <ProductSkuRender platform={record.platform} skuIdList={record.sku_id_list?.split(',')?.filter(Boolean) || []} />
                    }}
                    loading={loading}
                    columns={[
                        {
                            title: '',
                            width: 28
                        },
                        ...activeTab.toLowerCase().includes('coles') || activeTab.toLowerCase().includes('woolworths') ? [
                            {
                                title: 'Category',
                                width: 150,
                                filterMultiple: false,
                                filters: uniq(itemList.map(item => item.category?.split('||')?.filter(Boolean)).flat()).map(category => {
                                    return {
                                        text: `${category} (${itemList.filter(item => item.category?.includes(category || '')).length})`,
                                        value: category
                                    }
                                }),
                                onFilter: (value: string, record: any) => {
                                    return record.category.includes(value)
                                },
                                dataIndex: 'category',
                                render: (text: string) => {
                                    return (
                                        <FlexContainer gap="4px" flexWrap="wrap">
                                            {text?.split('||').filter(Boolean).map(item => (
                                                <Tag>{item}</Tag>
                                            ))}

                                            {!text && '-'}
                                        </FlexContainer>
                                    )
                                }
                            },
                        ] : [],
                        {
                            title: (
                                <FlexContainer alignItems="center" gap="8px">
                                    <span>Product</span>
                                    <div>
                                        <Input size="small" prefix={<IconSearch />} onChange={setSearchKeyword} />
                                    </div>
                                </FlexContainer>
                            ),
                            render: (record: ICatalogueItem) => {
                                const skuIdCount = record.sku_id_list?.split(',')?.filter(Boolean).length || 0

                                return (
                                    <div>
                                        <div style={{ marginBottom: 4 }} onClick={() => {
                                            setSalefinderUrl(`https://salefinder.com.au/${record.catalogue_id}/-/-/-/-/${record.item_id}/`)
                                        }}>
                                            <div>{record.name}</div>
                                        </div>

                                        <FlexContainer gap="4px">
                                            <Tooltip content={record.sku_id_list}>
                                                <Tag style={{ cursor: 'pointer' }}>{skuIdCount} SKUs</Tag>
                                            </Tooltip>

                                            <Tag style={{ cursor: 'pointer' }} onClick={() => {
                                                copy(record.item_id)
                                                Toast.success("Copied to clipboard")
                                            }}>ID: {record.item_id}</Tag>

                                            {!!record.offer_text && (
                                                <Tag style={{ background: 'rgb(254, 220, 2)', color: 'black' }}>
                                                    {record.offer_text}
                                                </Tag>
                                            )}

                                            <FlexContainer gap="4px">
                                                {!!record.lowest_price && record.lowest_price >= record.new_price && <Tag color="red">Historical low</Tag>}
                                                {!!record.lowest_price && record.lowest_price < record.new_price && <Tag>Lowest: ${record.lowest_price}</Tag>}
                                            </FlexContainer>
                                        </FlexContainer>

                                    </div>
                                )
                            }
                        },
                        // {
                        //     title: 'Offer text',
                        //     width: 200,
                        //     dataIndex: 'offer_text'
                        // },
                        // {
                        //     title: 'Price',
                        //     render: (record: ICatalogueItem) => {
                        //         return (
                        //             <FlexContainer alignItems="center" gap="4px">
                        //                 {!!record.origin_price && (
                        //                     <>
                        //                         {record.origin_price}
                        //                         <IconArrowRight style={{ fontSize: 12 }} />
                        //                     </>
                        //                 )}
                        //                 {record.new_price} ({Math.abs(record.discount_rate || 0)}% off)
                        //             </FlexContainer>
                        //         )
                        //     }
                        // },
                        // {
                        //     title: 'Tags',
                        //     render: (record: ICatalogueItem) => {
                        //         return (
                        //             <FlexContainer gap="4px">
                        //                 {!!record.lowest_price && record.lowest_price >= record.new_price && <Tag color="red">Historical low</Tag>}

                        //                 {!!record.lowest_price && record.lowest_price < record.new_price && <Tag>Lowest: ${record.lowest_price}</Tag>}
                        //             </FlexContainer>
                        //         )
                        //     }
                        // },
                        {
                            title: 'Was',
                            width: 80,
                            dataIndex: 'origin_price',
                            sorter: (a: any, b: any) => {
                                if (!a.origin_price) return 1
                                if (!b.origin_price) return -1

                                return a.origin_price - b.origin_price
                            },
                            render: (text: string) => {
                                return text ? `$${text}` : ''
                            },
                        },
                        {
                            title: 'New',
                            width: 80,
                            dataIndex: 'new_price',
                            sorter: (a: any, b: any) => {
                                if (!a.new_price) return 1
                                if (!b.new_price) return -1

                                return a.new_price - b.new_price
                            },
                            render: (text: string) => {
                                return text ? `$${text}` : ''
                            }
                        },
                        {
                            title: 'Action',
                            width: 80,
                            render: (record: ICatalogueItem) => {
                                return (
                                    <Button theme="solid" type="tertiary" icon={<IconSetting />} style={{ borderRadius: 32 }} onClick={() => {
                                        setSelectedItem(record)
                                    }} />
                                )
                            }
                        }
                    ]}
                />
            </FlexContainer>

            <SideSheetPlaceholder />

            <SideSheet visible={!!selectedItem} title="Post catalogue item" onCancel={() => setSelectedItem(undefined)} mask={false}>
                <GPTHelper system_prompt="" user_content="" />
            </SideSheet>

            <SideSheet
                visible={exportMdSidesheetVisible}
                title="Export as Markdown"
                onCancel={() => setExportMdSidesheetVisible(false)}
                mask={false}
                footer={(
                    <FlexContainer justifyContent="flex-end">
                        <Button theme="solid" onClick={() => {
                            copy(md)
                            Toast.success('Copied to clipboard')
                        }}>Copy Markdown</Button>
                    </FlexContainer>
                )}
            >
                <Descriptions
                    data={[
                        { key: 'Title', value: `½ Price: Fantastic Instant Noodles 700g $2.45, Viva Paper Towel 2-Pack $2.25, Powerade Sports Drink 600ml $2.10 & More @ ${activeTab.includes('coles') ? 'Coles' : 'Woolworths'}` },
                        // { key: 'Start date', value: `${catalogueList.find(item => item.)}` }

                    ]}
                />

                <MarkdownRender raw={md} />
            </SideSheet>

            <SideSheet visible={!!salefinderUrl} onCancel={() => setSalefinderUrl('')} title="View catalogue detail" bodyStyle={{ padding: 0, overflow: 'hidden' }} mask={false}>
                <iframe
                    key={salefinderUrl}
                    src={salefinderUrl}
                    width="100%"
                    style={{ height: windowHeight - 68 }}
                    frameBorder="none"
                />
            </SideSheet>
        </FlexContainer>
    )
}

interface IProdcutSkuRenderProps {
    skuIdList: string[];
    platform: string;
}

const ProductSkuRender = ({ platform, skuIdList }: IProdcutSkuRenderProps) => {
    const [productList, setProductList] = useState<IDeal[]>([])
    const [loading, setLoading] = useState(false)

    const [selectedProduct, setSelectedProduct] = useState<IDeal>()

    useAsyncEffect(async () => {
        if (!platform || !skuIdList.length || !['coles', 'woolworths'].includes(platform)) {
            return
        }

        setLoading(true)
        const respData = await request({
            data: {
                service: 'rds.sql',
                requestParams: [
                    `SELECT * FROM simpo_octopus.au_${platform}_product_tmp WHERE sku_id IN (${skuIdList.map(item => `"${item}"`).join(', ')})`
                ]
            }
        })

        setProductList(respData)
        setLoading(false)
    }, [platform, skuIdList])

    if (!skuIdList.length || !['coles', 'woolworths'].includes(platform)) {
        return (
            <div style={{ padding: '12px 0' }}>No product SKUs found</div>
        )
    }

    return (
        <div style={{ width: '100%' }}>
            <Table
                dataSource={productList}
                pagination={false}
                size="small"
                showHeader={false}
                loading={loading}
                style={{ width: '100%' }}
                columns={[
                    {
                        title: '#',
                        width: 20,
                        dataIndex: 'sku_id',
                        render: (text: string, record: IDeal, idx: number) => {
                            return `${idx + 1}.`
                        }
                    },
                    {
                        title: 'SKU',
                        width: 100,
                        dataIndex: 'sku_id',
                        render: (text: string) => {
                            return (
                                <div style={{ cursor: 'pointer' }} onClick={() => {
                                    copy(text)
                                    Toast.success('Copied to clipboard')
                                }}>
                                    {text}
                                </div>
                            )
                        }
                    },
                    {
                        title: 'Product',
                        render: ((record: IDeal) => {
                            return (
                                <FlexContainer alignItems="center" gap="8px">
                                    <Image src={record.pic_url} style={{ flexShrink: 0 }} imgStyle={{ objectFit: 'contain', width: 60, height: 60 }} />

                                    <div>{record.name}</div>
                                </FlexContainer>
                            )
                        })
                    },
                    {
                        title: 'Was',
                        width: 80,
                        dataIndex: 'origin_price',
                        render: (text: string) => {
                            return text ? `$${text}` : ''
                        }
                    },
                    {
                        title: 'New',
                        width: 80,
                        dataIndex: 'new_price',
                        render: (text: string) => {
                            return text ? `$${text}` : ''
                        }
                    },
                    {
                        title: 'Action',
                        width: 80,
                        render: (record: IDeal) => {
                            return (
                                <Button
                                    type="tertiary"
                                    style={{ borderRadius: 32 }}
                                    icon={<IconSearch />}
                                    onClick={() => setSelectedProduct(record)}
                                />
                            )
                        }
                    }
                ]}
            />

            <SideSheet title="Product detail" visible={!!selectedProduct} onCancel={() => setSelectedProduct(undefined)} mask={false}>
                <iframe frameBorder="none" src={`https://www.hotaudeals.com/embedded?platform=${platform}&sku_id=${selectedProduct?.sku_id}`} width="100%" height="500px" />
            </SideSheet>
        </div>
    )
}

export default CatalogueManagement  
import { Tag } from "@douyinfe/semi-ui";
import FlexContainer from "../../../../components/FlexContainer";
import { IAirport } from "../../../../typings"

interface IAirportNameRenderProps {
    airport?: IAirport;
    hideAirportCode?: boolean
}

const AirportNameRender = ({ airport, hideAirportCode }: IAirportNameRenderProps) => {
    if (!airport) {
        return <></>
    }

    return (
        <FlexContainer className="font-weight-bold text-overflow" alignItems="center" gap="4px">
            <div>{airport.city_name}</div>
            {!hideAirportCode && (
                <div>
                    <Tag>{airport.airport_code}</Tag>
                </div>
            )}
        </FlexContainer>
    )
}

export default AirportNameRender
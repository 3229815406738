import { Button, Form, Popconfirm, SideSheet, Table, Tabs, Tag, Toast } from "@douyinfe/semi-ui"
import { useCallback, useEffect, useRef, useState } from "react"
import { IArticle } from "../../../typings"
import { useAsyncEffect } from "ahooks"
import { request } from "../../../utils"
import FlexContainer from "../../../components/FlexContainer"
import { IconDelete, IconEyeOpened, IconPlus, IconSetting } from "@douyinfe/semi-icons"
import { FormApi } from "@douyinfe/semi-ui/lib/es/form"
import { SideSheetPlaceholder } from "../AdminLayout"
import { capitalize, isEmpty, omit } from "lodash-es"
import ArticleRender from "../../../components/ArticleRender"
import './index.less'

const ArticleManagement = () => {
    const [loading, setLoading] = useState(true)

    const [articleList, setArticleList] = useState<IArticle[]>([])
    const [selectedArticle, setSelectedArticle] = useState<IArticle>()

    const [mode, setMode] = useState('')

    const formRef = useRef<FormApi>()

    useAsyncEffect(async () => {
        setLoading(true)

        const respData = await request({
            data: {
                service: 'rds.sql',
                requestParams: [
                    `SELECT * FROM simpo_octopus.au_article`
                ]
            }
        })

        setArticleList(respData)
        setLoading(false)
    }, [])

    useEffect(() => {
        if (selectedArticle) {
            formRef.current?.setValues(selectedArticle)
        }
    }, [selectedArticle])

    const handleEditArticle = useCallback(async (formData: any) => {
        const newData = {
            article_type: 'lark',
            ...selectedArticle || {},
            ...formData
        }

        if (newData.status) {
            newData.status = 1
        } else {
            newData.status = 0
        }

        const respData = await request({
            data: {
                service: 'rds.insert',
                requestParams: [
                    "simpo_octopus",
                    "au_article",
                    omit(newData, ['created_at', 'updated_at']),
                    {
                        onDuplicateKeyUpdate: {
                            article_name_cn: 'VALUES(article_name_cn)',
                            article_name_en: 'VALUES(article_name_en)',
                            article_url_cn: 'VALUES(article_url_cn)',
                            article_url_en: 'VALUES(article_url_en)',
                            status: 'VALUES(status)'
                        }
                    }
                ]
            }
        })

        Toast.success('Submit successfully')

        if (isEmpty(selectedArticle)) {
            setArticleList(arr => [...arr, {
                ...newData,
                au_article_auto_increment_id: respData?.insertId
            }])
        } else {
            setArticleList(arr => arr.map(item => {
                if (item.au_article_auto_increment_id === newData.au_article_auto_increment_id) {
                    return newData
                } else {
                    return item
                }
            }))
        }
    }, [selectedArticle])

    const handleDeleteArticle = async (id: number) => {
        await request({
            data: {
                service: 'rds.delete',
                requestParams: [
                    'simpo_octopus',
                    'au_article',
                    { where: { au_article_auto_increment_id: id } }
                ]
            }
        })

        Toast.success('Delete successfully')
        setArticleList(arr => arr.filter(item => item.au_article_auto_increment_id !== id))
    }

    return (
        <FlexContainer style={{ width: '100%' }}>
            <div style={{ flexGrow: 1, width: '100%' }}>
                <FlexContainer style={{ padding: '7px 16px' }} alignItems="center" justifyContent="space-between">
                    <div className="font-weight-black" style={{ fontSize: 20, letterSpacing: -0.5 }}>
                        Article Management
                    </div>

                    <Button icon={<IconPlus />} theme="solid" onClick={() => {
                        setMode('create')
                        setSelectedArticle({} as any)
                    }}>Article</Button>
                </FlexContainer>

                <Table
                    dataSource={articleList}
                    pagination={false}
                    loading={loading}
                    columns={[
                        {
                            title: 'ID',
                            dataIndex: 'au_article_auto_increment_id'
                        },
                        {
                            title: 'Type',
                            dataIndex: 'article_type',
                            render: (text: string) => {
                                return <Tag>{text}</Tag>
                            }
                        },
                        {
                            title: 'Article',
                            render: (record: IArticle) => {
                                return (
                                    <FlexContainer flexDirection="column" gap="4px">
                                        <div>{record.article_name_en}</div>

                                        {!!record.article_url_en && (
                                            <div style={{ cursor: 'pointer' }} onClick={() => {
                                                window.open(record.article_url_en)
                                            }}>
                                                <Tag color="blue">EN: {record.article_url_en}</Tag>
                                            </div>
                                        )}

                                        {!!record.article_name_cn && <div style={{ marginTop: 8 }}>{record.article_name_cn}</div>}

                                        {!!record.article_url_cn && (
                                            <div style={{ cursor: 'pointer' }} onClick={() => {
                                                window.open(record.article_url_cn)
                                            }}>
                                                <Tag color="green">CN: {record.article_url_cn}</Tag>
                                            </div>
                                        )}
                                    </FlexContainer>
                                )
                            }
                        },
                        {
                            title: 'Status',
                            render: (record: IArticle) => {
                                return (
                                    <Tag color={['red', 'green'][record.status || 0] as any}>{['Draft', 'Released'][record.status || 0]}</Tag>
                                )
                            }
                        },
                        {
                            title: 'Action',
                            width: 110,
                            render: (record: IArticle) => {
                                return (
                                    <FlexContainer flexDirection="column" gap="6px">
                                        <FlexContainer gap="6px">
                                            <Button type="tertiary" theme="solid" style={{ borderRadius: 32 }} icon={<IconSetting />} onClick={() => {
                                                setMode('edit')
                                                setSelectedArticle(record)
                                            }} />
                                            <Button type="primary" theme="solid" style={{ borderRadius: 32 }} icon={<IconEyeOpened />} onClick={() => {
                                                setMode('preview')
                                                setSelectedArticle(record)
                                            }} />
                                        </FlexContainer>

                                        <FlexContainer gap="6px">
                                            <Popconfirm title="Delete reminder" content="Are you sure to delete this article?" okType="danger" onConfirm={() => handleDeleteArticle(record.au_article_auto_increment_id)}>
                                                <Button type="danger" theme="solid" style={{ borderRadius: 32 }} icon={<IconDelete />} />
                                            </Popconfirm>
                                        </FlexContainer>
                                    </FlexContainer>

                                )
                            }
                        }
                    ]}
                />
            </div>

            <SideSheetPlaceholder />

            <SideSheet visible={!!selectedArticle && ['edit', 'create'].includes(mode)} title={`${capitalize(mode)} article`} onCancel={() => setSelectedArticle(undefined)} mask={false}>
                <Form getFormApi={api => formRef.current = api} onSubmit={handleEditArticle}>
                    <Form.Input label="Article name (EN)" field="article_name_en" />
                    <Form.Input label="Article URL (EN)" field="article_url_en" />
                    <Form.Input label="Article name (CN)" field="article_name_cn" />
                    <Form.Input label="Article URL (CN)" field="article_url_cn" />
                    <Form.Select label="Status" field="status" style={{ width: '100%' }} optionList={[
                        { label: 'Draft', value: 0 },
                        { label: 'Released', value: 1 }
                    ]} />

                    <Button htmlType="submit" theme="solid">Submit</Button>
                </Form>
            </SideSheet>

            <SideSheet className="preview-article-sidesheet-wrapper" visible={!!selectedArticle && mode === 'preview'} title="Preview article" bodyStyle={{ padding: 0 }} onCancel={() => setSelectedArticle(undefined)} mask={false}>
                <Tabs keepDOM={false}>
                    {selectedArticle && selectedArticle.article_url_en && (
                        <Tabs.TabPane itemKey="EN" tab="EN">
                            <ArticleRender src={selectedArticle.article_url_en} />
                        </Tabs.TabPane>
                    )}

                    {selectedArticle && selectedArticle.article_url_cn && (
                        <Tabs.TabPane itemKey="CN" tab="CN">
                            <ArticleRender src={selectedArticle.article_url_cn} />
                        </Tabs.TabPane>
                    )}
                </Tabs>
            </SideSheet>
        </FlexContainer>
    )
}

export default ArticleManagement
// @ts-nocheck
import React, { useRef, useEffect, useState, useCallback, useContext, useMemo } from 'react';
import Map, { Source, Layer, MapRef, ViewState, Marker, Popup } from 'react-map-gl';
import * as turf from '@turf/turf'; // Import Turf.js for geospatial calculations
import { MAPBOX_TOKEN } from '../../../RewardFlightsByPlatform/components/AirportMap';
import { IAirport, IHotel, IHotelPriceSummary } from '../../../../typings';
import { AppContext } from '../../../../App';
import { Button, Image, Notification, Tag, Tooltip } from '@douyinfe/semi-ui';
import FlexContainer from '../../../../components/FlexContainer';
import { IconBulb, IconExternalOpen, IconMapPin, IconStar } from '@douyinfe/semi-icons';
import { FeatureSelector } from 'mapbox-gl';
import { FeatureIdentifier } from 'mapbox-gl';
import mapboxgl from 'mapbox-gl';
import './index.less'
import { uniqueId } from 'lodash-es';
import HotelMatchSidesheet from '../HotelMatchSidesheet';
import { calcHaversineDistance } from '../../../RewardFlightsDetail/utils';
import LazyMap from '../../../../components/LazyMap';
import { RewardHotelsContext } from '../..';
import { formatNumber } from '../../../../utils';

export const brandList = ['marriott', 'ihg', 'hilton']

const imgList = [
    { imgName: 'ihg', src: 'https://newweb.obs.cn-east-3.myhuaweicloud.com/public/1726743545028-ihg-logo.png' },
    { imgName: 'marriott', src: 'https://newweb.obs.cn-east-3.myhuaweicloud.com/public/1726743486718-marriott-logo.png' },
    { imgName: 'hilton', src: 'https://newweb.obs.cn-east-3.myhuaweicloud.com/public/1726743706982-hilton-logo.png' }
]

const HotelMap = ({ hotelList, viewState }: { hotelList: IHotel[], viewState?: ViewState }) => {
    const { isDarkMode, isMobile } = useContext(AppContext)
    const { display_price, currency, exchangeRate } = useContext(RewardHotelsContext)

    const mapRef = useRef<MapRef>(null);

    const [hoveredFeature, setHoveredFeature] = useState<any>();

    const [centerLngLat, setCenterLngLat] = useState<[number, number]>([])
    const [zoomLevel, setZoomLevel] = useState(13)

    const [isLoaded, setIsLoaded] = useState(false)
    const [tooltipVisible, setTooltipVisible] = useState(false)

    const [hotelListInViewpoint, setHotelListInViewpoint] = useState<IHotel[]>([])
    const [dragInProgress, setDragInProgress] = useState(false)
    const [sortedPropertyIdList, setSortedPropertyIdList] = useState<string[]>([])
    const [hotelPriceList, setHotelPriceList] = useState<IHotelPriceSummary[]>([])

    const timeoutRef = useRef<any>()
    const tableClickLoadingRef = useRef(false)
    const tableClickTimeoutRef = useRef()
    const zoomInNotificationRef = useRef<any>()

    const getTooltipContent = (hotel: IHotel) => {
        return (
            <FlexContainer
                flexDirection='column'
                onMouseEnter={() => {
                    if (timeoutRef.current) {
                        clearTimeout(timeoutRef.current)
                    }
                }}
                onMouseLeave={() => {
                    setTooltipVisible(false)
                }}
            >
                <div className="font-weight-bold">
                    {hotel.name}
                </div>

                <FlexContainer style={{ marginTop: 2 }} gap="4px">
                    {!!hotel.distance && (
                        <Tag>
                            <FlexContainer alignItems='center' style={{ color: 'black' }} gap="2px">
                                <IconMapPin style={{ fontSize: 12 }} />
                                {hotel.distance?.toFixed(1)}km
                            </FlexContainer>
                        </Tag>
                    )}

                    <Tag style={{ background: 'rgba(250, 200, 0, 0.15)' }}>
                        <FlexContainer alignItems='center' style={{ color: 'rgb(125, 106, 0)' }} gap="2px">
                            <IconStar style={{ fontSize: 12 }} />
                            {hotel.stars} ({hotel.number_of_reviews})
                        </FlexContainer>
                    </Tag>
                </FlexContainer>
            </FlexContainer>
        )
    }

    const hoveredHotelDetail = useMemo(() => {
        return hoveredFeature?.properties as IHotel
    }, [hoveredFeature])

    useEffect(() => {
        if (viewState) {
            const map = mapRef.current?.getMap();
            if (!map) return;

            const { longitude, latitude, zoom, bearing, pitch } = viewState

            map.flyTo({
                center: [longitude, latitude],
                zoom,
                bearing,
                pitch,
                // speed: 2.5,
                duration: 1200
            })
        }
    }, [viewState])

    useEffect(() => {
        if (!isLoaded) {
            return
        }

        const map = mapRef.current?.getMap();
        if (!map) return;

        const handleMouseEnter = (e) => {
            if (e.features.length > 0) {
                const feature = e.features[0];

                if (timeoutRef.current) {
                    clearTimeout(timeoutRef.current)
                    timeoutRef.current = undefined
                }

                setHoveredFeature(feature)
                map.getCanvas().style.cursor = 'pointer'; // Change cursor on hover
            }
        };

        const handleMouseLeave = () => {
            timeoutRef.current = setTimeout(() => {
                setHoveredFeature(undefined)
            }, 500)
            map.getCanvas().style.cursor = ''; // Reset cursor
        };

        const handleZoom = () => {
            setZoomLevel(map.getZoom());
        }

        brandList.forEach(brand => {
            map.on('mouseenter', `unclustered-point-${brand}`, handleMouseEnter);
            map.on('mouseleave', `unclustered-point-${brand}`, handleMouseLeave);
        })

        map.on('zoom', handleZoom);

        return () => {
            if (map) {
                brandList.forEach(brand => {
                    map.off('mouseenter', `unclustered-point-${brand}`, handleMouseEnter);
                    map.off('mouseleave', `unclustered-point-${brand}`, handleMouseLeave);
                })

                map.off('zoom', handleZoom);
            }
        };
    }, [isLoaded]);

    const geojson = useMemo(() => {
        return {
            type: 'FeatureCollection',
            features: hotelList.map(hotel => {
                return {
                    type: 'Feature',
                    geometry: {
                        type: 'Point',
                        coordinates: [hotel.longitude, hotel.latitude],
                    },
                    properties: {
                        ...hotel
                    }
                }
            }),
        }
    }, [hotelList])

    useEffect(() => {
        setTimeout(() => {
            handleMoveEnd()
        }, 500)
    }, [geojson])

    const mapLayers = useMemo(() => {
        const clusterLayer = {
            id: 'clusters',
            type: 'circle',
            source: 'markers',
            filter: ['has', 'point_count'],
            paint: {
                'circle-color': 'rgb(198,202,205)',
                'circle-radius': [
                    'step',
                    ['get', 'point_count'],
                    15, // Radius for clusters with fewer than 2 points
                    2, 18, // Radius for clusters with 2 to 10 points
                    10, 22, // Radius for clusters with 11 to 20 points
                    20, 26, // Radius for clusters with 21 to 30 points
                    30, 30, // Radius for clusters with 31 to 40 points
                    40, 34, // Radius for clusters with 41 to 50 points
                    50, 38, // Radius for clusters with 51 to 60 points
                    60, 42, // Radius for clusters with 61 to 70 points
                    70, 46, // Radius for clusters with 71 to 80 points
                    80, 50, // Radius for clusters with 81 to 90 points
                    90, 54, // Radius for clusters with 91 to 100 points
                    100, 30, // Radius for clusters with 100 to 700 points
                    700, 45, // Radius for clusters with 700 to 1000 points
                    1000, 55 // Radius for clusters with 1000+ points
                ]
            },
        };

        const clusterCountLayer = {
            id: 'cluster-count',
            type: 'symbol',
            source: 'markers',
            filter: ['has', 'point_count'],
            layout: {
                'text-field': '{point_count_abbreviated}',
                'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
                'text-size': 16,
            },
        };

        const unclusteredPointLayers = [
            {
                id: 'unclustered-point-shadow',
                type: 'circle',
                source: 'markers',
                paint: {
                    'circle-radius': 20, // Slightly larger than icon size
                    'circle-color': 'rgba(0, 0, 0, 0.3)', // Black shadow with opacity
                    'circle-blur': 0.5, // Adds blur to simulate shadow softness
                },
            },
            {
                id: 'unclustered-point-marriott',
                type: 'symbol',
                source: 'markers',
                filter: ['==', ['get', 'chain'], 'marriott'], // Filter for 'field' == 'ihg'
                layout: {
                    'icon-image': "marriott",
                    'icon-size': 0.25,
                    'icon-allow-overlap': true, // Ensure overlap is allowed
                },
            },
            {
                id: 'unclustered-point-ihg',
                type: 'symbol',
                source: 'markers',
                filter: ['==', ['get', 'chain'], 'ihg'], // Filter for 'field' == 'ihg'
                layout: {
                    'icon-image': "ihg",
                    'icon-size': 0.25,
                    'icon-allow-overlap': true, // Ensure overlap is allowed
                },
            },
            {
                id: 'unclustered-point-hilton',
                type: 'symbol',
                source: 'markers',
                filter: ['==', ['get', 'chain'], 'hilton'], // Filter for 'field' == 'ihg'
                layout: {
                    'icon-image': "hilton",
                    'icon-size': 0.25,
                    'icon-allow-overlap': true, // Ensure overlap is allowed
                },
            }
        ]

        return [
            clusterLayer,
            clusterCountLayer,
            ...unclusteredPointLayers
        ]
    })

    const handleLoad = () => {
        setIsLoaded(true)
        setTimeout(() => {
            window.dispatchEvent(new Event('resize'))
        }, 500)

        const map = mapRef.current?.getMap()

        imgList.forEach(img => {
            map.loadImage(img.src, (error, image) => {
                if (error) throw error;
                if (!map.hasImage(img.imgName)) {
                    map.addImage(img.imgName, image);
                }
            });
        })
    }

    const handleMoveEnd = () => {
        if (tableClickLoadingRef.current) {
            return
        }

        console.log('moved')

        setDragInProgress(false)
        const map = mapRef.current?.getMap();
        if (!map) return;

        const zoomIdx = map.getZoom()
        const center = map.getCenter()

        if (zoomIdx >= 11) {
            const visibleFeatures = map.queryRenderedFeatures({
                layers: brandList.map(brand => `unclustered-point-${brand}`)
            });

            const sortedHotelList = visibleFeatures.map(feature => feature.properties).sort((a: IHotel, b: IHotel) => {
                const aDistance = calcHaversineDistance(a, { latitude: center.lat, longitude: center.lng }, true)
                const bDistance = calcHaversineDistance(b, { latitude: center.lat, longitude: center.lng }, true)

                return aDistance - bDistance
            }).map((hotel, idx) => ({ ...hotel, idx }))

            setHotelListInViewpoint(sortedHotelList)
            setSortedPropertyIdList(sortedHotelList.map(hotel => hotel.property_id))
        } else {
            setHotelListInViewpoint([])
            setSortedPropertyIdList([])
        }
    }

    const handleCenter = (hotel: IHotel) => {
        const map = mapRef.current?.getMap();
        if (!map) return;

        tableClickLoadingRef.current = true

        map.flyTo({
            center: [hotel.longitude, hotel.latitude],
            zoom: map.getZoom(),
            bearing: map.getBearing(),
            pitch: map.getPitch(),
            duration: 600
        })

        setHoveredFeature({
            type: 'Feature',
            geometry: {
                type: 'Point',
                coordinates: [hotel.longitude, hotel.latitude],
            },
            properties: {
                ...hotel
            }
        })

        if (tableClickTimeoutRef.current) {
            clearTimeout(tableClickTimeoutRef.current)
            tableClickTimeoutRef.current = undefined
        }

        tableClickTimeoutRef.current = setTimeout(() => {
            tableClickLoadingRef.current = false
        }, 1000)
    }

    useEffect(() => {
        if (zoomLevel < 11) {
            if (zoomInNotificationRef.current) {
                return
            }

            zoomInNotificationRef.current = Notification.info({
                icon: <IconBulb />,
                content: <span className="font-weight-bold">Zoom in to check hotel prices</span>,
                position: 'bottom',
                duration: 0,
                showClose: false,
            })
        } else {
            if (zoomInNotificationRef.current) {
                Notification.close(zoomInNotificationRef.current)
                zoomInNotificationRef.current = undefined
            }
        }
    }, [zoomLevel])

    return (
        <div className="reward-hotel-map-wrapper" style={{ height: '100%', width: '100%', position: 'relative' }}>
            <LazyMap
                onRef={mapRef}
                style={{ width: '100%', height: '100%' }}
                mapStyle={isDarkMode ? "mapbox://styles/mapbox/dark-v11" : "mapbox://styles/mapbox/light-v11"}
                mapboxAccessToken={MAPBOX_TOKEN}
                onLoad={handleLoad}
                onMoveEnd={handleMoveEnd}
                onMoveStart={() => {
                    setDragInProgress(true)
                }}
                initialViewState={{
                    "longitude": 151.20699000000002,
                    "latitude": -33.87078,
                    "zoom": 13,
                    "bearing": 0,
                    "pitch": 0

                }}
            >
                <Source
                    id="markers"
                    type="geojson"
                    data={geojson}
                    cluster={true}
                    clusterMaxZoom={10} // Max zoom to cluster points on
                    clusterRadius={50} // Radius of each cluster when clustering points
                >
                    {mapLayers.map((layerProps, idx) => {
                        if (zoomLevel > 11 && idx < 2) {
                            return <></>
                        }

                        return (
                            <Layer {...layerProps} />
                        )
                    })}

                </Source>

                {sortedPropertyIdList.map(property_id => hotelListInViewpoint.find(item => item.property_id === property_id)).map((hotel, idx) => {
                    const targetPrice = hotelPriceList.find(item => hotel?.property_id === item.property_id && item.rate_category === (display_price === 'POINT_VALUE' ? 'REDEMPTION' : display_price) && item.min_price)
                    const isSameCurrency = !currency || targetPrice?.currency === currency

                    return (
                        <Marker
                            longitude={hotel.longitude}
                            latitude={hotel.latitude}
                            key={`${idx + 1}_${hotel?.property_id}`}
                        >
                            <FlexContainer justifyContent='center' style={{ position: 'relative' }}>
                                <div
                                    className="responsive-background"
                                    style={{
                                        position: 'absolute',
                                        background: 'white',
                                        top: 10,
                                        borderRadius: 4,
                                        boxShadow: 'var(--semi-shadow-elevated)',
                                        overflow: 'hidden'
                                    }}
                                >
                                    {!!targetPrice && display_price === 'STANDARD' && (
                                        <div className="font-weight-bold" style={{ padding: '0 4px', whiteSpace: 'nowrap', letterSpacing: -0.3, background: `${targetPrice?.color}80` }}>
                                            {isSameCurrency && `${targetPrice?.currency} ${targetPrice?.min_price}`}
                                            {!isSameCurrency && `${currency} ${(targetPrice?.min_price * exchangeRate[currency]).toFixed(0)}`}
                                        </div>
                                    )}

                                    {!!targetPrice && display_price === 'REDEMPTION' && (
                                        <div className="font-weight-bold" style={{ padding: '0 4px', whiteSpace: 'nowrap', letterSpacing: -0.3, background: `${targetPrice?.color}80` }}>
                                            {formatNumber(targetPrice?.min_price)} {targetPrice?.currency}
                                        </div>
                                    )}

                                    {!!targetPrice && display_price === 'POINT_VALUE' && (
                                        <div className="font-weight-bold" style={{ padding: '0 4px', whiteSpace: 'nowrap', letterSpacing: -0.3, background: `${targetPrice?.color}80` }}>
                                            {isSameCurrency ? (
                                                <div>
                                                    {targetPrice.hotelCurrency} {targetPrice?.pointValue}
                                                    <span style={{ fontSize: 8 }}>/10k</span>
                                                </div>
                                            ) : (
                                                <div></div>
                                            )}
                                        </div>
                                    )}
                                </div>
                            </FlexContainer>
                        </Marker>
                    )
                })}

                {hoveredFeature && (
                    <Popup
                        longitude={hoveredFeature.geometry.coordinates[0]}
                        latitude={hoveredFeature.geometry.coordinates[1]}
                        closeButton={false}
                        anchor={isMobile ? 'bottom' : "top"}
                        offset={20}
                        style={{ width: 200 }}
                    >
                        <FlexContainer
                            className="responsive-background-secondary"
                            onMouseEnter={() => {
                                if (timeoutRef.current) {
                                    clearTimeout(timeoutRef.current)
                                    timeoutRef.current = undefined
                                }
                            }}
                            flexDirection='column'
                            onMouseLeave={() => {
                                timeoutRef.current = undefined
                                setHoveredFeature(undefined)
                            }}
                        >
                            {!!hoveredHotelDetail.pic_url && (
                                <div style={{ height: 100 }}>
                                    <Image src={hoveredHotelDetail.pic_url} style={{ objectFit: 'cover' }} width="100%" height="100%" />
                                </div>
                            )}

                            <FlexContainer flexDirection='column' style={{ padding: 10 }}>
                                <div className="font-weight-bold" style={{ lineHeight: '16px' }}>{hoveredHotelDetail?.name}</div>

                                {/* {!!hoveredHotelDetail?.stars && (
                                    <FlexContainer style={{ marginTop: 4 }}>
                                        <Tag color="yellow" className="font-weight-bold" prefixIcon={<IconStar style={{ fontSize: 12 }} />}>
                                            {hoveredHotelDetail?.stars}
                                        </Tag>
                                    </FlexContainer>
                                )} */}

                                <div>
                                    <Button
                                        theme="borderless"
                                        size="small"
                                        icon={<IconExternalOpen style={{ fontSize: 12, marginLeft: -4 }} />}
                                        autoFocus={false}
                                        iconPosition='right'
                                        style={{ padding: 0, fontSize: 12, height: 'fit-content' }}
                                        onClick={() => {
                                            window.open(`/reward-hotels/${hoveredHotelDetail.chain}/${hoveredHotelDetail.property_id}`)
                                        }}
                                    >
                                        View detail
                                    </Button>
                                </div>
                            </FlexContainer>
                        </FlexContainer>
                    </Popup>
                )}
            </LazyMap>

            <div
                style={{
                    width: '100%',
                    height: '100%',
                    position: 'absolute',
                    zIndex: 1,
                    top: 0,
                    right: 0,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    pointerEvents: 'none'
                }}
            >
                <div style={{ position: 'relative' }}>
                    <div
                        style={{
                            width: 18,
                            height: 18,
                            border: '3px solid white',
                            boxSizing: 'border-box',
                            borderRadius: '50%',
                            background: 'var(--semi-color-primary)',
                            position: 'relative',
                            zIndex: 3,
                        }}
                    />

                    <div className="dot" style={{ position: 'absolute', top: 0, left: 0, zIndex: 2 }} />
                </div>

            </div>

            <HotelMatchSidesheet hotelList={hotelListInViewpoint} dragInProgress={dragInProgress} onCenter={handleCenter} onReady={setHotelPriceList} />
        </div >
    );
};

export default HotelMap;
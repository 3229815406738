import { useAsyncEffect } from "ahooks"
import { useNavigate, useParams } from "react-router-dom"
import { request } from "../../utils"
import { useContext, useEffect, useMemo, useState } from "react"
import { IAirport, IRewardSeatSummary } from "../../typings"
import { Button, Card, Descriptions, Divider, Empty, Modal, Radio, RadioGroup, Select, Spin, Tag, Toast, Tooltip } from "@douyinfe/semi-ui"
import FlexContainer from "../../components/FlexContainer"
import { AppContext } from "../../App"
import { IconArrowLeft, IconArrowUp, IconCalendar, IconClock, IconClose, IconExternalOpen, IconList, IconShareStroked } from "@douyinfe/semi-icons"
import copy from "copy-to-clipboard"
import AirportRouteSidesheet from "../RewardFlightsByPlatform/components/AirportRouteSidesheet"
import { calcHaversineDistance, estimateFlightDuration, estimateTimezoneDifference } from "./utils"
import './index.less'
import FlightRouteRender from "./components/FlightRouteRender"
import { IllustrationFailure, IllustrationFailureDark } from "@douyinfe/semi-illustrations"
import useSyncTaskStatus from "../../hooks/useSyncTaskStatus"
import dayjs from "dayjs"

export const DescriptionItem = ({ label, value, hidden }: { label: string, value?: any, hidden?: boolean }) => {
    if (hidden) {
        return <></>
    }

    return (
        <div>
            <div style={{ fontSize: 14, color: 'var(--semi-color-text-2)' }}>
                {label}
            </div>
            <div className="responsive-text description-item-value-wrapper">
                {value}
            </div>
        </div>
    )
}

const CARRIER_NAME_DICT: Record<string, string> = {
    VA: 'Virgin Australia',
    QF: 'Qantas'
}

const RewardFlightsDetail = () => {
    const { departure, destination } = useParams()
    const { syncTaskStatusDict } = useSyncTaskStatus(['au-virgin-australia', 'au-qantas'])

    const qantasSyncTask = syncTaskStatusDict?.['au-qantas']?.[0]
    const virginAustraliaSyncTask = syncTaskStatusDict?.['au-virgin-australia']?.[0]

    const { isMobile, windowHeight, navbarHeight, windowWidth, isDarkMode } = useContext(AppContext)
    const navigate = useNavigate()

    const [loading, setLoading] = useState(true)

    const [flightMetaData, setFlightMetaData] = useState<{
        departureAirportInfo?: IAirport;
        destinationAirportInfo?: IAirport;
        routeList: IRewardSeatSummary[];
        popularDestinationList: IAirport[];
    }>()

    const [flightRouteFullScreen, setFlightRouteFullScreen] = useState(false)
    const [showMode, setShowMode] = useState('calendar')
    const [cabinClass, setCabinClass] = useState('economy')
    const [selectedRoute, setSelectedRoute] = useState<IRewardSeatSummary>()
    const [minPriceInfo, setMinPriceInfo] = useState({
        minEconomyPrice: -1,
        minBusinessPrice: -1
    })

    useAsyncEffect(async () => {
        setLoading(true)
        setFlightMetaData(undefined)

        const airportList: IAirport[] = await request({
            method: 'post',
            url: '/api/v1/service/execute',
            data: {
                service: 'rds.sql',
                requestParams: [
                    `SELECT * FROM simpo_octopus.au_airport WHERE airport_code IN ("${departure}", "${destination}")`
                ]
            }
        })

        const routeListRespData = await request({
            method: 'post',
            url: '/api/v1/service/execute',
            data: {
                service: 'rds.sql',
                requestParams: [
                    `SELECT * FROM simpo_octopus.au_airport_route WHERE departure_airport_code = "${departure}" AND destination_airport_code = "${destination}"`
                ]
            }
        })

        const popularDestinationRespData = await request({
            method: 'post',
            url: '/api/v1/service/execute',
            data: {
                service: 'rds.sql',
                requestParams: [
                    `
                        SELECT
                            b.*,
                            count(*) AS num 
                        FROM
                            simpo_octopus.au_reward_seat a,
                            simpo_octopus.au_airport b 
                        WHERE
                            SUBSTRING( route_key, 4, 3 ) = '${departure}' 
                            AND SUBSTRING( route_key, 8, 3 ) <> '${destination}'
                            AND departure_date BETWEEN CURDATE() 
                            AND DATE_ADD( CURDATE(), INTERVAL 30 DAY ) 
                            AND brand_id = "RE" 
                            AND substring( route_key, 8, 3 ) = b.airport_code 
                        GROUP BY
                            substring( route_key, 8, 3 ),
                            airport_code 
                        ORDER BY
                            num DESC       
                        LIMIT 20          
                    `
                ]
            }
        })

        const tmp = {
            departureAirportInfo: airportList.find(item => item.airport_code === departure?.toUpperCase()),
            destinationAirportInfo: airportList.find(item => item.airport_code === destination?.toUpperCase()),
            routeList: routeListRespData.map((item: IRewardSeatSummary) => ({
                ...item,
                route_key: `${item.carrier}_${item.departure_airport_code}_${item.destination_airport_code}`
            })),
            popularDestinationList: popularDestinationRespData
        }

        setFlightMetaData(tmp)
        setSelectedRoute(tmp.routeList.find((item: IRewardSeatSummary) => item.carrier === 'QF') || tmp.routeList[0])

        setLoading(false)
    }, [departure, destination])

    useEffect(() => {
        const handler = (e: any) => {
            setMinPriceInfo(e.detail)
        }

        document.addEventListener('reward-flight-min-price-update', handler)

        return () => {
            document.removeEventListener('reward-flight-min-price-update', handler)
        }
    }, [])

    const showInOneColumn = useMemo(() => {
        if (isMobile) {
            return true
        }

        return windowWidth < 1048
    }, [isMobile, windowWidth])

    return (
        <Spin spinning={loading} size="large">
            <FlexContainer className="reward-flight-detail-wrapper" justifyContent="center" style={{ padding: isMobile ? 12 : 24, maxHeight: windowHeight - navbarHeight, overflow: 'auto', boxSizing: 'border-box' }}>
                <FlexContainer style={{ maxWidth: 1000, width: '100%', height: '100%' }} flexDirection="column">
                    <div>
                        <Button theme="borderless" style={{ padding: 0 }} size="small" icon={<IconArrowLeft style={{ fontSize: 14 }} />} onClick={() => navigate('/reward-flights')}>
                            Back to reward flights explorer
                        </Button>
                    </div>
                    <FlexContainer style={{ marginBottom: 16 }} justifyContent="space-between" gap="24px">
                        <FlexContainer className="responsive-text" flexDirection="column">
                            <FlexContainer style={{ fontSize: 26, fontWeight: 700, letterSpacing: -1, lineHeight: '18px' }} alignItems="center" gap="8px" flexWrap="wrap">
                                <FlexContainer alignItems="center" gap="8px">
                                    <div>{flightMetaData?.departureAirportInfo?.city_name}</div>
                                    <Tag style={{ fontSize: 20, height: 28 }}>{flightMetaData?.departureAirportInfo?.airport_code}</Tag>
                                </FlexContainer>

                                <Tooltip content={(<span className="font-weight-bold">Switch</span>)}>
                                    <div className="airport-separator-img" onClick={() => {
                                        navigate(`/reward-flights/${destination}/${departure}`)
                                    }}>
                                        <img width="32" height="32" src={`https://img.icons8.com/ios-filled/50/${isDarkMode ? "FFFFFF" : "000000"}/airplane-take-off.png`} alt="airplane-take-off" />
                                    </div>
                                </Tooltip>

                                <FlexContainer alignItems="center" gap="8px">
                                    <div>{flightMetaData?.destinationAirportInfo?.city_name}</div>
                                    <Tag style={{ fontSize: 20, height: 28 }}>{flightMetaData?.destinationAirportInfo?.airport_code}</Tag>
                                </FlexContainer>
                            </FlexContainer>

                        </FlexContainer>

                        {!isMobile && (
                            <div>
                                <Button
                                    icon={<IconShareStroked style={{ fontSize: 20 }} />}
                                    type="tertiary"
                                    style={{ borderRadius: 100, width: 40, height: 40 }}
                                    onClick={() => {
                                        copy(window.location.href)
                                        Toast.success("Link copied to clipboard")
                                    }}
                                />
                            </div>
                        )}
                    </FlexContainer>
                    <FlexContainer gap="20px" flexWrap={showInOneColumn ? 'wrap' : 'nowrap'}>
                        {isMobile && (
                            <style>{`
                            .gift-card-promotion-detail-body-wrapper .section-header {
                                top: -12px !important;
                            }
                        `}</style>
                        )}
                        <FlexContainer
                            flexDirection="column"
                            gap="20px"
                            style={{
                                width: '100%',
                                boxSizing: 'border-box',
                                height: 'fit-content',
                                flexGrow: 0,
                                ...showInOneColumn ? {} : { width: 720 }
                            }}
                        >
                            {!loading && !!flightMetaData?.routeList.length && (
                                <>
                                    <FlexContainer
                                        flexDirection="column"
                                        className='responsive-background-secondary'
                                        style={{
                                            padding: 16,
                                            borderRadius: 4,
                                            background: 'white'
                                        }}
                                    >
                                        <FlexContainer justifyContent="space-between" flexDirection={isMobile ? "column" : "row"} gap="12px">
                                            <DescriptionItem label="Airline" value={(
                                                <FlexContainer>
                                                    <Select
                                                        style={{ width: 200 }}
                                                        value={selectedRoute?.carrier}
                                                        optionList={flightMetaData?.routeList.map(route => ({
                                                            label: <span className="font-weight-bold">{CARRIER_NAME_DICT[route.carrier]}</span>,
                                                            value: route.carrier
                                                        }))}
                                                        onChange={(carrier) => {
                                                            setSelectedRoute(flightMetaData?.routeList?.find(item => item.carrier === carrier))
                                                        }}
                                                    />
                                                </FlexContainer>
                                            )} />

                                            <FlexContainer gap="12px">
                                                <DescriptionItem label="Min Economy Price" value={(
                                                    <FlexContainer alignItems="center" style={{ height: 32 }}>{minPriceInfo && minPriceInfo.minEconomyPrice !== -1 ? minPriceInfo.minEconomyPrice : 'N/A'}</FlexContainer>
                                                )} />
                                                <Divider layout="vertical" style={{ height: '100%' }} />
                                                <DescriptionItem label="Min Business Price" value={(
                                                    <FlexContainer alignItems="center" style={{ height: 32 }}>{minPriceInfo && minPriceInfo.minBusinessPrice !== -1 ? minPriceInfo.minBusinessPrice : 'N/A'}</FlexContainer>
                                                )} />
                                            </FlexContainer>
                                        </FlexContainer>
                                    </FlexContainer>

                                    <FlexContainer
                                        flexDirection="column"
                                        className='responsive-background-secondary'
                                        style={{
                                            padding: 16,
                                            borderRadius: 4,
                                            background: 'white'
                                        }}
                                    >
                                        <FlexContainer justifyContent="space-between">
                                            <div className="font-weight-black responsive-text" style={{ letterSpacing: -0.2, marginBottom: 12, }}>Points Calendar</div>
                                            <FlexContainer gap="8px" {...isMobile ? {
                                                flexDirection: "column",
                                                alignItems: 'flex-end',
                                                style: { marginBottom: 8 }
                                            } : {}}>
                                                {showMode === 'calendar' && (
                                                    <div>
                                                        <RadioGroup
                                                            defaultValue={cabinClass}
                                                            type="button"
                                                            buttonSize="small"
                                                            onChange={e => setCabinClass(e.target.value)}
                                                            options={[
                                                                { label: <FlexContainer className="font-weight-bold">Economy</FlexContainer>, value: 'economy' },
                                                                { label: <FlexContainer className="font-weight-bold">Business</FlexContainer>, value: 'business' },
                                                            ]}
                                                        />
                                                    </div>
                                                )}


                                                <div>
                                                    <RadioGroup
                                                        defaultValue={showMode}
                                                        type="button"
                                                        buttonSize="small"
                                                        onChange={e => setShowMode(e.target.value)}
                                                        options={[
                                                            { label: <FlexContainer className="font-weight-bold">Calendar</FlexContainer>, value: 'calendar' },
                                                            { label: <FlexContainer className="font-weight-bold">Table</FlexContainer>, value: 'table' },
                                                        ]}
                                                    />
                                                </div>
                                            </FlexContainer>
                                        </FlexContainer>
                                        
                                        {!!selectedRoute && <AirportRouteSidesheet routeInfo={selectedRoute} renderDirectly mode={showMode} cabinClass={cabinClass} />}
                                    </FlexContainer>

                                </>
                            )}

                            {!loading && !flightMetaData?.routeList.length && (
                                <FlexContainer
                                    flexDirection="column"
                                    className='responsive-background-secondary'
                                    style={{
                                        padding: '32px 16px',
                                        borderRadius: 4,
                                        background: 'white'
                                    }}
                                >
                                    <Empty
                                        image={<IllustrationFailure style={{ width: 150, height: 150 }} />}
                                        darkModeImage={<IllustrationFailureDark style={{ width: 150, height: 150 }} />}
                                        description={'Oops! No data yet.'}
                                    // style={emptyStyle}
                                    />
                                </FlexContainer>
                            )}



                        </FlexContainer>

                        <FlexContainer flexDirection="column" gap="20px" style={{ boxSizing: 'border-box', ...showInOneColumn ? { width: '100%' } : { width: 260, flexGrow: 0, flexShrink: 0 } }}>
                            <FlexContainer className="responsive-background-secondary" flexDirection="column" gap="8px" style={{ borderRadius: '4px', background: 'white', width: '100%', height: 'fit-content', padding: 16, boxSizing: 'border-box' }}>
                                <div className="font-weight-black responsive-text" style={{ letterSpacing: -0.2, }}>Flight Summary</div>

                                <div style={{ cursor: 'zoom-in' }} onClick={() => setFlightRouteFullScreen(true)}>
                                    <div
                                        style={flightRouteFullScreen
                                            ? {
                                                height: windowHeight,
                                                width: '100vw',
                                                position: 'fixed',
                                                top: 0,
                                                left: 0,
                                                zIndex: 10000,
                                            } : {
                                                height: 150,
                                                borderRadius: 4,
                                                overflow: 'hidden',
                                                pointerEvents: 'none',
                                                position: 'relative'
                                            }
                                        }>
                                        {flightRouteFullScreen && (
                                            <Button
                                                theme="solid"
                                                size="large"
                                                icon={<IconClose style={{ color: 'black' }} />}
                                                style={{ position: 'fixed', top: 24, right: 24, zIndex: 100000, background: 'white', borderRadius: 32 }}
                                                onClick={(e) => {
                                                    e.stopPropagation()
                                                    setFlightRouteFullScreen(false)
                                                }}
                                            />
                                        )}
                                        {flightMetaData && (
                                            <FlightRouteRender
                                                key={`${selectedRoute?.route_key}__${flightRouteFullScreen}`}
                                                departureAirportInfo={flightMetaData.departureAirportInfo as IAirport}
                                                destinationAirportInfo={flightMetaData.destinationAirportInfo as IAirport}
                                                paddingOffset={flightRouteFullScreen ? 1 : 2}
                                            />
                                        )}
                                    </div>
                                </div>

                                <DescriptionItem label="Departure" value={(
                                    <div>
                                        <FlexContainer gap="4px" alignItems="center">
                                            {flightMetaData?.departureAirportInfo?.airport_name}
                                            <Tag>{flightMetaData?.departureAirportInfo?.airport_code}</Tag>
                                        </FlexContainer>
                                        <div style={{ fontSize: 12, fontWeight: 400, color: 'var(--semi-color-text-2)', marginTop: -4 }}>
                                            {flightMetaData?.departureAirportInfo?.country_name}
                                        </div>
                                    </div>
                                )} />
                                <DescriptionItem label="Destination" value={(
                                    <div>
                                        <FlexContainer gap="4px" alignItems="center">
                                            {flightMetaData?.destinationAirportInfo?.airport_name}
                                            <Tag>{flightMetaData?.destinationAirportInfo?.airport_code}</Tag>
                                        </FlexContainer>
                                        <div style={{ fontSize: 12, fontWeight: 400, color: 'var(--semi-color-text-2)', marginTop: -4 }}>
                                            {flightMetaData?.destinationAirportInfo?.country_name}
                                        </div>
                                    </div>
                                )} />
                                <DescriptionItem label="Distance*" value={calcHaversineDistance(flightMetaData?.departureAirportInfo, flightMetaData?.destinationAirportInfo)} />
                                <DescriptionItem label="Duration*" value={estimateFlightDuration(flightMetaData?.departureAirportInfo, flightMetaData?.destinationAirportInfo)} />
                                <DescriptionItem
                                    label="Timezone difference*"
                                    value={(
                                        <FlexContainer alignItems="center" gap="6px">
                                            <span>{estimateTimezoneDifference(flightMetaData?.departureAirportInfo, flightMetaData?.destinationAirportInfo)}</span>
                                            <IconExternalOpen style={{ cursor: 'pointer' }} onClick={() => {
                                                window.open(`https://www.google.com/search?btnI=1&q=timezone difference between ${flightMetaData?.departureAirportInfo?.city_name} and ${flightMetaData?.destinationAirportInfo?.city_name}`)
                                            }} />
                                        </FlexContainer>
                                    )}
                                />

                                <div style={{ color: 'var(--semi-color-text-2)', fontSize: 12 }}>
                                    *For reference only
                                </div>
                            </FlexContainer>

                            <FlexContainer className="responsive-background-secondary" flexDirection="column" gap="8px" style={{ borderRadius: '4px', background: 'white', width: '100%', height: 'fit-content', padding: 16, boxSizing: 'border-box' }}>
                                <div className="font-weight-black responsive-text" style={{ letterSpacing: -0.2, }}>Popular Destinations</div>

                                {flightMetaData?.popularDestinationList?.map(airport => {
                                    return (
                                        <div key={airport.airport_auto_increment_id} className="popular-destination-card-wrapper" onClick={() => {
                                            navigate(`/reward-flights/${departure}/${airport.airport_code}`)
                                        }}>
                                            <Card bodyStyle={{ padding: '8px 12px' }}>
                                                <div className="font-weight-bold responsive-text">{airport.city_name}</div>
                                                <FlexContainer justifyContent="space-between" alignItems="flex-end" style={{ marginTop: -2 }}>
                                                    <div>
                                                        <div style={{ fontSize: 12, fontWeight: 400, color: 'var(--semi-color-text-2)' }}>
                                                            {airport.country_name}
                                                        </div>
                                                    </div>

                                                    <FlexContainer alignItems="center" gap="4px">
                                                        <IconClock style={{ fontSize: 12 }} />
                                                        <span className="font-weight-bold" style={{ fontSize: 12, whiteSpace: 'nowrap' }}>
                                                            {estimateFlightDuration(flightMetaData.departureAirportInfo, airport)}
                                                        </span>
                                                    </FlexContainer>
                                                </FlexContainer>
                                            </Card>
                                        </div>
                                    )
                                })}
                            </FlexContainer>
                        </FlexContainer>
                    </FlexContainer>
                </FlexContainer>
            </FlexContainer>
        </Spin>
    )
}

export default RewardFlightsDetail
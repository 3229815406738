import { Button, Divider, Modal, SideSheet, Spin, Tabs } from "@douyinfe/semi-ui"
import { SideSheetReactProps } from "@douyinfe/semi-ui/lib/es/sideSheet"
import { useAsyncEffect } from "ahooks";
import { useContext, useState } from "react";
import { IDeal } from "../../../../typings";
import algoliasearch from "algoliasearch"
import FlexContainer from "../../../../components/FlexContainer";
import { renderPageOnReady, request } from "../../../../utils";
import { AppContext } from "../../../../App";
import './index.less'
import { uniq } from "lodash-es";
import { IconDisc, IconHistory } from "@douyinfe/semi-icons";
import PriceHistoryPane from "../../../GroceriesByPlatform/components/PriceHistoryPane";
import ProductCard from "../../../GroceriesByPlatform/components/ProductCard";

const client = algoliasearch('A97US7BF85', '70a0214c44fee7a1ddda7f47b0babeaf');
const index = client.initIndex('simpo_octopus');


export interface ISimilarProductSidesheetProps extends SideSheetReactProps {
    platform?: string;
    product?: IDeal;
    mode?: string;
}

const SimilarProductSidesheet = ({ platform, product, mode, ...sidesheetProps }: ISimilarProductSidesheetProps) => {
    const { isMobile, isInApp, userInfo } = useContext(AppContext)

    const [loading, setLoading] = useState(false)

    const bodyWrapper = (
        <>
            {product && platform && (
                <div className="product-detail-wrapper">
                    {mode !== 'embedded' && (
                        <div style={{ padding: '12px 0', paddingTop: product.extra_data?.isActiveCatalogueItem ? 0 : 12,  borderBottom: '1px solid #eaeaea' }}>
                            <ProductCard
                                platform={platform}
                                product={product}
                                cardProps={{ style: { width: '100%' }, bordered: false }}
                                showSimpleOpenButton={isMobile}
                                showOpenButton={!isMobile}
                            />
                        </div>
                    )}

                    <div style={{ padding: 0 }}>
                        {loading ? (
                            <FlexContainer style={{ width: '100%', padding: '24px 0' }} justifyContent="center">
                                <Spin spinning size="large" />
                            </FlexContainer>
                        ) : (
                            <Tabs>
                                <Tabs.TabPane tab="Price history" itemKey="price-history">
                                    <PriceHistoryPane platform={platform} sku_id={product.sku_id as any} />
                                </Tabs.TabPane>
                            </Tabs>
                        )}

                    </div>

                    <FlexContainer
                        gap="16px"
                        flexDirection="column"
                        className="font-weight-bold"
                        style={{ fontSize: 13, padding: 24, textAlign: 'center', color: 'rgba(28, 31, 35, 0.6)' }}
                    >
                        <div>
                            All product names, product images, retailer names, trademarks, and registered trademarks are the property of their respective owners.
                        </div>
                        <div>
                            Prices may differ from those found in-store. In case of any errors, the in-store prices shall prevail.
                        </div>
                        <div>
                            <Button theme="borderless" style={{ fontSize: 13 }} onClick={() => {
                                const recipient = "hotaudeals@icloud.com";
                                const subject = `product issue: ${product.platform}_${product.sku_id}_${product.price}`
                                const body = ''
                            
                                const mailtoLink = "mailto:" + recipient + "?subject=" + encodeURIComponent(subject) + "&body=" + encodeURIComponent(body);
                            
                                window.open(mailtoLink);
                            }}>Report an issue</Button>
                        </div>
                    </FlexContainer>
                </div>

            )}
        </>
    )

    if (mode === 'embedded') {
        return bodyWrapper
    }

    if (isMobile) {
        return (
            <SideSheet
                {...sidesheetProps}
                placement="bottom"
                bodyStyle={{ padding: 0, paddingBottom: isInApp ? 48 : 0 }}
                closable={false}
                headerStyle={{ padding: 0 }}
                style={{ borderRadius: '12px 12px 0 0' }}
                height={600}
            >
                {bodyWrapper}
            </SideSheet>
        )
    }

    return (
        <Modal {...sidesheetProps} title="Product detail" width="700px" footer={null}>
            {bodyWrapper}
        </Modal>
    )
}

export default SimilarProductSidesheet
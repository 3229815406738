import Button, { ButtonProps } from "@douyinfe/semi-ui/lib/es/button"
import { useState } from "react"

const LoadingButton = ({ onClick, ...props }: ButtonProps) => {
    const [loading, setLoading] = useState(false)

    const handleClick = async (e: any) => {
        setLoading(true)

        try {
            await onClick?.(e)
        } catch(err) {
            console.error(err)
        } finally {
            setLoading(false)
        }
    }

    return <Button {...props} onClick={handleClick} loading={loading} />
}

export default LoadingButton
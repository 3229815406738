import { createContext } from "react"
import CatalogueRender, { FOOTER_HEIGHT } from "../CatalogueRender"
import GiftCardRender from "../GiftCardRender"
import FlexContainer from "../../../../../components/FlexContainer"
import CashbackRender from "../CashbackRender"

export const Footer = () => {
    return (
        <FlexContainer style={{ color: 'white', fontSize: 24, fontWeight: 700, letterSpacing: -1, height: FOOTER_HEIGHT }} alignItems="center">
            <img width="36" height="36" src="https://img.icons8.com/color/96/fire-element--v2.png" alt="fire-element--v2" />
            HotAuDeals
        </FlexContainer>
    )
}

export interface ISceneRenderProps {
    scene: string
}

const SceneRender = ({ scene }: ISceneRenderProps) => {

    if (scene.toLowerCase().includes('catalogue')) {
        return <CatalogueRender scene={scene} />
    }

    if (scene === 'gift-card') {
        return <GiftCardRender />
    }

    if (scene === 'cashback') {
        return <CashbackRender />
    }

    return (
        <div>1234</div>
    )
}

export default SceneRender
import { useState } from "react"
import { ISyncTask } from "../typings"
import { useAsyncEffect } from "ahooks"
import { request } from "../utils"

const useSyncTaskStatus = (sourceList: string[], count: number = 1) => {
    const [syncTaskList, setSyncTaskList] = useState<ISyncTask[][]>([])
    const [loading, setLoading] = useState(true)

    useAsyncEffect(async () => {
        const resp = await Promise.all(sourceList.map(source => {
            return request({
                method: 'post',
                url: '/api/v1/service/execute',
                data: {
                    service: 'rds.sql',
                    requestParams: [
                        `SELECT * FROM simpo_octopus.sync_task WHERE source = "${source}" ORDER BY created_at DESC LIMIT ${count}`
                    ]
                }
            })
        }))

        setSyncTaskList(resp)
        setLoading(false)
    }, [])

    return {
        syncTaskStatusDict: Object.assign({}, ...sourceList.map((source, idx) => ({
            [source]: syncTaskList[idx]
        }))),
        loading
    }
}

export default useSyncTaskStatus
import { Cell, SwipeCell, Tabs } from "tdesign-mobile-react"
import './index.less'
import { useCallback, useContext, useMemo, useRef, useState } from "react"
import { IDeal } from "../../typings"
import { useAsyncEffect } from "ahooks"
import { getGroceryItemUrl, request, toBase64Unicode } from "../../utils"
import ProductCard from "../GroceriesByPlatform/components/ProductCard"
import { Button, Checkbox, Divider, Empty, Modal, Spin, Toast, Tabs as SemiTabs, Banner, InputNumber } from "@douyinfe/semi-ui"
import FlexContainer from "../../components/FlexContainer"
import { AppContext } from "../../App"
import { IconAlignTop, IconClose } from "@douyinfe/semi-icons"
import { IllustrationNoContent } from '@douyinfe/semi-illustrations';
import { CART_ITEM_LIST_LOCAL_STORAGE_KEY } from "../../constants"
import { omit } from "lodash-es"

const ShoppingList = () => {
    const { isInApp, windowHeight, userInfo, isLogin, showLoginForm, updateCart, hideShoppingList, cartItemList: appCartItemList } = useContext(AppContext)

    const [loading, setLoading] = useState(false)
    const [cartItemList, setCartItemList] = useState<IDeal[]>([])

    const [exactMatchItemList, setExactMatchItemList] = useState<IDeal[]>([])

    const [currTab, setCurrTab] = useState('all')

    const isLoginFlag = useRef<boolean>(isLogin)
    const skuIdListRef = useRef<string[]>([])

    const filteredCartItemList = useMemo(() => {
        return cartItemList.filter(item => {
            if (currTab === 'all') {
                return true
            }

            let exactMatchItem: IDeal | undefined

            if (item.platform === 'coles') {
                exactMatchItem = exactMatchItemList.find(el => el.name && el.platform === 'woolworths' && el.criteria === `algolia_${item.sku_id}`)
            } else {
                exactMatchItem = exactMatchItemList.find(el => el.name && el.platform === 'coles' && el.buying_options === (item.sku_id as any))
            }

            if (!exactMatchItem || (exactMatchItem as any)?.price >= (item as any)?.price) {
                return item.platform === currTab
            }

            return exactMatchItem.platform === currTab
        })
    }, [currTab, cartItemList, exactMatchItemList])

    useAsyncEffect(async () => {
        setLoading(true)

        if (!isLoginFlag.current && !!userInfo) {
            // login change, merge to user cart
            isLoginFlag.current = true

            const itemStrJsonStr = localStorage.getItem(CART_ITEM_LIST_LOCAL_STORAGE_KEY)
            const itemList = JSON.parse(itemStrJsonStr || '[]').map((item: any) => {
                const [platform, sku_id] = item.split('_')
                return { platform, sku_id }
              })

            if (itemList.length) {
                await request({
                    method: 'POST',
                    url: '/api/v1/service/execute',
                    data: {
                        service: 'rds.insert',
                        requestParams: [
                            "simpo_octopus", "au_user_cart_item", itemList.map((item: any) => ({
                                email: userInfo.email,
                                platform: item.platform,
                                sku_id: item.sku_id,
                                quantity: 1,
                                checked: 1,
                            })),
                            { ignore: true }
                        ]
                    }
                })

                localStorage.removeItem(CART_ITEM_LIST_LOCAL_STORAGE_KEY)
                updateCart('refresh')
                Toast.success("Cart item synced successfully")
            }
        }

        let productList: IDeal[] = []

        if (userInfo) {
            productList = await request({
                method: 'POST',
                url: '/api/v1/service/execute',
                data: {
                    service: 'rds.sql',
                    requestParams: [`
                        SELECT
                            c.*,
                            COALESCE(a.sku_id, b.sku_id) AS sku_id,
                            COALESCE(a.name, b.name) AS name,
                            COALESCE(a.description, b.description) AS description,
                            COALESCE(a.category_level_one, b.category_level_two) AS category_level_one,
                            COALESCE(a.category_level_one, b.category_level_two) AS category_level_two,
                            COALESCE(a.pic_url, b.pic_url) AS pic_url,
                            COALESCE(a.price, b.price) AS price,
                            COALESCE(a.unit_price, b.unit_price) AS unit_price,
                            COALESCE(a.meta_data, b.meta_data) AS meta_data,
                            COALESCE(a.origin_price, b.origin_price) AS origin_price,
                            COALESCE(a.new_price, b.new_price) AS new_price,
                            COALESCE(a.discount_rate, b.discount_rate) AS discount_rate,
                            COALESCE(a.created_at, b.created_at) AS created_at,
                            COALESCE(a.updated_at, b.updated_at) AS updated_at,
                            COALESCE(a.sync_batch, b.sync_batch) AS sync_batch
                        FROM 
                            (SELECT * FROM simpo_octopus.au_user_cart_item WHERE email = "${userInfo.email}") c
                        LEFT JOIN 
                            simpo_octopus.au_coles_product_tmp a ON c.sku_id = a.sku_id AND c.platform = 'coles'
                        LEFT JOIN 
                            simpo_octopus.au_woolworths_product_tmp b ON c.sku_id = b.sku_id AND c.platform = 'woolworths';  
                    `]
                }
            })
        } else {
            isLoginFlag.current = false

            const itemStrJsonStr = localStorage.getItem(CART_ITEM_LIST_LOCAL_STORAGE_KEY)
            const itemStrList: string[] = JSON.parse(itemStrJsonStr || '[]')

            const colesSkuIdList = itemStrList.filter(item => item.startsWith('coles')).map(item => item.split('_')[1])
            const woolworthsSkuIdList = itemStrList.filter(item => item.startsWith('woolworths')).map(item => item.split('_')[1])

            if (colesSkuIdList.length) {
                const tmpList = await request({
                    method: 'POST',
                    url: '/api/v1/service/execute',
                    data: {
                        service: 'rds.sql',
                        requestParams: [`
                            SELECT *, "coles" as platform FROM simpo_octopus.au_coles_product_tmp WHERE sku_id IN (${colesSkuIdList.map(item => `"${item}"`).join(', ')})
                        `]
                    }
                })

                productList = productList.concat(tmpList)
            }

            if (woolworthsSkuIdList.length) {
                const tmpList = await request({
                    method: 'POST',
                    url: '/api/v1/service/execute',
                    data: {
                        service: 'rds.sql',
                        requestParams: [`
                            SELECT *, "woolworths" as platform FROM simpo_octopus.au_woolworths_product_tmp WHERE sku_id IN (${woolworthsSkuIdList.map(item => `"${item}"`).join(', ')})
                        `]
                    }
                })

                productList = productList.concat(tmpList)
            }
        }

        setCartItemList(productList)

        productList = productList.filter(item => !skuIdListRef.current.includes(`${item.platform}__${item.sku_id}`))

        if (productList.length) {
            skuIdListRef.current = productList.map(item => `${item.platform}__${item.sku_id}`)

            const exactMatchItemListRespData = await request({
                method: 'POST',
                url: '/api/v1/service/execute',
                data: {
                    service: 'au.hotaudeals.getGroceryExactMatchItem',
                    requestParams: [
                        productList.map(item => `${item.platform}_${item.sku_id}`)
                    ]
                }
            })

            setExactMatchItemList(prev => [...prev, ...exactMatchItemListRespData])
        }

        setLoading(false)
    }, [userInfo, appCartItemList])

    const totalCount = useMemo(() => {
        return filteredCartItemList.filter(item => !isLogin || item.checked).length
    }, [filteredCartItemList, isLogin])

    const totalPrice = useMemo(() => {
        return filteredCartItemList.filter(item => !isLogin || item.checked).reduce((acc, item) => {
            let exactMatchItem: IDeal | undefined

            if (item.platform === 'coles') {
                exactMatchItem = exactMatchItemList.find(el => el.name && el.platform === 'woolworths' && el.criteria === `algolia_${item.sku_id}`)
            } else {
                exactMatchItem = exactMatchItemList.find(el => el.name && el.platform === 'coles' && el.buying_options === (item.sku_id as any))
            }

            if (!exactMatchItem || (exactMatchItem as any)?.price >= (item as any)?.price) {
                return acc + ((item.new_price || item.price) || 0)
            }

            return acc + ((exactMatchItem.new_price || exactMatchItem.price) || 0)
        }, 0).toFixed(2)
    }, [filteredCartItemList, isLogin])

    const totalSaving = useMemo(() => {
        return filteredCartItemList.filter(item => !isLogin || item.checked).reduce((acc, item) => {
            let exactMatchItem: IDeal | undefined

            if (item.platform === 'coles') {
                exactMatchItem = exactMatchItemList.find(el => el.name && el.platform === 'woolworths' && el.criteria === `algolia_${item.sku_id}`)
            } else {
                exactMatchItem = exactMatchItemList.find(el => el.name && el.platform === 'coles' && el.buying_options === (item.sku_id as any))
            }

            if (!exactMatchItem) {
                if (item.new_price && item.origin_price && item.origin_price > item.new_price) {
                    return acc + (item.origin_price - item.new_price)
                }

                return acc
            }

            return acc + Math.abs((exactMatchItem as any).price - (item as any).price)

        }, 0).toFixed(2)
    }, [filteredCartItemList, isLogin])

    const handleDelete = useCallback(async (product: IDeal) => {
        if (isLogin) {
            setLoading(true)
            await request({
                method: 'POST',
                url: '/api/v1/service/execute',
                data: {
                    service: 'rds.sql',
                    requestParams: [
                        `DELETE FROM simpo_octopus.au_user_cart_item WHERE au_user_cart_item_id = "${product.au_user_cart_item_id}"`
                    ]
                }
            })
    
            setCartItemList(prev => prev.filter(item => item.au_user_cart_item_id !== product.au_user_cart_item_id))
            setLoading(false)
        } else {
            const itemStrJsonStr = localStorage.getItem(CART_ITEM_LIST_LOCAL_STORAGE_KEY)
            const itemStrList: string[] = JSON.parse(itemStrJsonStr || '[]')
            
            const filteredItemStrList = itemStrList.filter(item => item !== `${product.platform}_${product.sku_id}`)
            localStorage.setItem(CART_ITEM_LIST_LOCAL_STORAGE_KEY, JSON.stringify(filteredItemStrList))
        }

        updateCart('delete', product)
    }, [isLogin])

    const handleCheckAll = useCallback(async (checked: boolean) => {
        setLoading(true)

        await request({
            method: 'POST',
            url: '/api/v1/service/execute',
            data: {
                service: 'rds.sql',
                requestParams: [
                    `UPDATE simpo_octopus.au_user_cart_item SET checked = ${checked ? 1 : 0} WHERE email = "${userInfo.email}"`
                ]
            }
        })

        setCartItemList(prev => prev.map(item => {
            item.checked = checked ? 1 : 0
            return item
        }))

        setLoading(false)
    }, [userInfo])

    const handleChecked = async (product: IDeal, checked: boolean) => {
        setLoading(true)

        await request({
            method: 'POST',
            url: '/api/v1/service/execute',
            data: {
                service: 'rds.sql',
                requestParams: [
                    `UPDATE simpo_octopus.au_user_cart_item SET checked = ${checked ? 1 : 0} WHERE au_user_cart_item_id = "${product.au_user_cart_item_id}"`
                ]
            }
        })

        setCartItemList(prev => prev.map(item => {
            if (item.au_user_cart_item_id !== product.au_user_cart_item_id) {
                return item
            } else {
                item.checked = checked ? 1 : 0
                return item
            }
        }))
        setLoading(false)
    }

    const handleExport = () => {
        const instance = Modal.info({
            footer: null,
            closable: false,
            width: '300px',
            centered: true,
            icon: false,
            content: (
                <FlexContainer flexDirection="column" alignItems="center" gap="16px">
                    <span style={{ fontSize: 20, textAlign: 'center', lineHeight: 1.3 }}>Export your shopping list in 4 steps</span>

                    <div className="font-weight-bold">
                        <ol style={{ paddingLeft: 16, margin: '6px 0' }}>
                            <li style={{ marginBottom: 6 }}>
                                We will open a popup window to help you review all the grocery items you've selected.
                            </li>

                            <li style={{ marginBottom: 6 }}>
                                First, log in to your Coles or Woolworths account in the popup window.
                            </li>

                            <li style={{ marginBottom: 6 }}>
                                Next, the page will guide you through your entire shopping list. Select the quantities you need and add them to your cart.                            
                            </li>

                            <li>
                                Once you've reviewed and added all items, the page will close. You can then proceed to the Coles or Woolworths app to complete your checkout.
                            </li>
                        </ol>
                    </div>

                    <FlexContainer gap="8px" style={{ width: '100%' }}>
                        <Button size="large" style={{ width: '50%' }} onClick={() => instance.destroy()}>Cancel</Button>
                        <Button size="large" style={{ width: '50%' }} theme="solid" onClick={() => {
                            const formattedCartItemList = cartItemList.map(item => ({
                                ...omit(item, 'description'),
                                buy_url: getGroceryItemUrl(item, 'detail'),
                            }))
                    
                            const schema = `checkout:${toBase64Unicode(JSON.stringify(formattedCartItemList))}`
                    
                            if (isInApp) {
                                window.webkit?.messageHandlers?.jsHandler.postMessage(schema);
                            }
                        }}>Export</Button>
                    </FlexContainer>
                </FlexContainer>
            )
        })
    }

    // if (!isLogin && cartItemList.length === 0 && !loading) {
    //     return (
    //         <FlexContainer justifyContent="center" alignItems="center" style={{ position: 'relative', width: '100%', height: `calc(${windowHeight}px - 48px)`, background: 'white' }}>
    //             <Empty
    //                 style={{ width: 300 }}
    //                 title={<span className="font-weight-bold">You haven't logged in</span>}
    //                 description={<span className="font-weight-regular" style={{ fontSize: 14, width: 16 }}>To get started, log in to your account to add to your shopping list.</span>}
    //             >
    //                 <Button block size="large" theme="solid" onClick={showLoginForm}>Log in</Button>
    //             </Empty>
    //             {!isInApp && <Button size="small" theme="borderless" type="tertiary" icon={<IconAlignTop rotate={90} />} style={{ position: 'absolute', top: 0, right: 24 }} onClick={hideShoppingList}></Button>}
    //         </FlexContainer>
    //     )
    // }

    return (
        <div className={`shopping-list-wrapper responsive-background-secondary`} style={{ background: 'white', height: '100%', overflow: 'auto' }}>
            <div className={isInApp ? 'responsive-background' : 'responsive-background-secondary'} style={{ position: 'sticky', top: 0, background: 'white', zIndex: 10, paddingTop: isInApp ? 36 : 0 }}>

                {/* <p className="font-weight-bold">Log in to your account to get the most out of HotAuDeals.</p> */}

                {isInApp ? (
                    <>
                        <h1 style={{ paddingLeft: 24, paddingRight: 24, letterSpacing: -1, margin: 0 }} className="responsive-text">Shopping List</h1>
                        <SemiTabs onChange={setCurrTab} tabList={[
                            { itemKey: 'all', tab: 'All' },
                            { itemKey: 'coles', tab: 'Coles' },
                            { itemKey: 'woolworths', tab: 'Woolworths' },
                        ]} />
                    </>
                ) : (
                    <>
                        <h2 style={{ padding: 0, paddingLeft: 24, margin: 0 }} className='responsive-text'>Shopping List</h2>
                        <SemiTabs onChange={setCurrTab} tabList={[
                            { itemKey: 'all', tab: 'All' },
                            { itemKey: 'coles', tab: 'Coles' },
                            { itemKey: 'woolworths', tab: 'Woolworths' },
                        ]} />
                        <Button size="small" theme="borderless" type="tertiary" icon={<IconAlignTop rotate={90} />} style={{ position: 'absolute', top: 0, right: 24 }} onClick={hideShoppingList}></Button>
                    </>
                )}
            </div>

            {/* {loading && (
                <FlexContainer justifyContent="center" style={{ width: '100%', height: '100%', paddingTop: 36 }}>
                    <Spin spinning={loading} size="large" />
                </FlexContainer>
            )} */}

            {!isLogin && (
                <Banner 
                    fullMode={false}
                    description={(
                        <span className="font-weight-bold">
                            <a 
                                href="#"
                                onClick={e => {
                                    e.preventDefault()
                                    showLoginForm()
                                }}
                                style={{ marginRight: 4 }}
                            >
                                Log in
                            </a>
                            to sync your shopping list across all devices.
                        </span>
                    )}
                    closeIcon={null}
                />
            )}

            <Spin spinning={loading} size="large">
                <div style={isInApp ? { minHeight: `calc(${windowHeight}px - 48px - 128px - 58px)` } : { height: `calc(${windowHeight}px - 68px - 77px - 56px - ${!isLogin ? '66px' : '0px'})`, overflow: 'auto' }}>
                    {filteredCartItemList.map((item, idx) => {
                        let exactMatchItem: IDeal | undefined

                        if (item.platform === 'coles') {
                            exactMatchItem = exactMatchItemList.find(el => el.name && el.platform === 'woolworths' && el.criteria === `algolia_${item.sku_id}`)
                        } else {
                            exactMatchItem = exactMatchItemList.find(el => el.name && el.platform === 'coles' && el.buying_options === (item.sku_id as any))
                        }

                        return (
                            <ProductCard
                                key={item.sku_id}
                                product={item}
                                platform={item.platform}
                                cardProps={{ 
                                    bordered: false, 
                                    style: { borderRadius: 0, borderBottom: '1px solid rgba(28,31,35,.08)' },
                                    className: 'responsive-border'
                                }}
                                displayInSimpleMode
                                onDelete={() => handleDelete(item)}
                                checked={!!item.checked}
                                onChecked={(v) => handleChecked(item, v)}
                                showSimpleOpenButton
                                exactMatchProduct={exactMatchItem}
                                // extraActions={[
                                //     <InputNumber /> as any
                                // ]}

                            />
                        )
                    })}

                    {!loading && filteredCartItemList.length === 0 && (
                        <Empty
                            style={{ marginTop: 24 }}
                            image={<IllustrationNoContent style={{ width: 150, height: 150 }} />}
                            title={<span className="font-weight-bold" style={{ fontSize: 16 }}>No items in your shopping list</span>}
                        >
                        </Empty>
                    )}
                </div>

                <FlexContainer className={`shopping-list-footer-wrapper ${isInApp ? 'responsive-background' : 'responsive-background-secondary'}`} justifyContent="space-between">
                    <FlexContainer alignItems="center" gap="8px">
                        {isLogin && (
                            <Checkbox
                                indeterminate={cartItemList.some(item => item.checked) && !cartItemList.every(item => item.checked)}
                                checked={cartItemList.every(item => item.checked)}
                                onChange={e => handleCheckAll(e.target.checked as boolean)}
                            />
                        )}

                        <div className="font-weight-bold responsive-text">
                            <div>
                                <span className="font-weight-regular" style={{ marginRight: 4 }}>Total</span>
                                <span>${totalPrice}</span>
                            </div>

                            <div style={{ fontSize: 12 }}>
                                <span className="font-weight-regular" style={{ marginRight: 4 }}>Your savings</span>
                                <span style={{ letterSpacing: -0.5, fontWeight: 600, padding: '2px 8px', color: 'black', borderRadius: 16, background: 'rgb(254, 220, 2)', whiteSpace: 'nowrap' }}>
                                    ${totalSaving}
                                </span>
                            </div>
                        </div>
                    </FlexContainer>


                    {isInApp && (
                        <Button theme="solid" size="large" style={{ borderRadius: 20 }} onClick={handleExport} disabled={cartItemList.length === 0}> 
                            <span className="font-weight-bold">Export</span>
                        </Button>
                    )}
                </FlexContainer>
            </Spin>
        </div>
    )
}

export default ShoppingList
import { useState } from "react"
import { ICashbackPromotion } from "../../../../typings"
import { useAsyncEffect } from "ahooks"
import { renderText, request } from "../../../../utils"
import { Avatar, Card, Spin } from "@douyinfe/semi-ui"
import FlexContainer from "../../../../components/FlexContainer"
import './index.less'
import { useNavigate } from "react-router-dom"

interface ISimilarCashbackOfferListProps {
    category: string
    currMerchantName: string
}

const SimilarCashbackOfferList = ({ category, currMerchantName }: ISimilarCashbackOfferListProps) => {
    const [promotionList, setPromotionList] = useState<ICashbackPromotion[]>([])
    const [loading, setLoading] = useState(true)
    const navigate = useNavigate()

    useAsyncEffect(async () => {
        setLoading(true)
        setPromotionList([])

        const respData = await request({
            url: '/api/v1/service/execute',
            method: 'post',
            data: {
                service: 'rds.sql',
                requestParams: [
                    `
                        SELECT 
                            * 
                        FROM 
                            simpo_octopus.au_cashback_promotion_tmp 
                        WHERE 
                            merchant_name IN (SELECT \`key\` FROM simpo_octopus.key_value_pair WHERE type = "category" AND value LIKE "%||${category}||%")
                            AND is_upsized = 1
                            AND merchant_name != "${currMerchantName}"
                        ORDER BY
                            max_cashback_rate_num DESC
                        LIMIT 10
                    `
                ]
            }
        })

        setLoading(false)
        setPromotionList(respData)
    }, [category, currMerchantName])

    if (loading) {
        return (
            <Spin spinning={loading} />
        )
    }

    return (
        <FlexContainer className="similar-cashback-offer-wrapper" flexDirection="column" gap="8px">
            {promotionList.map(promotion => {
                return (
                    <div key={promotion.merchant_name} onClick={() => {
                        navigate(`/cashback/${btoa(promotion.merchant_name)}`)
                    }}>
                        <Card className="cashback-card-wrapper" bodyStyle={{ padding: 12 }}>
                            <FlexContainer gap="12px">
                                <div style={{ padding: 4 }} className="logo-medium-wrapper">
                                    <Avatar
                                        className="merchant-logo-wrapper"
                                        src={promotion?.merchant_logo}
                                        style={{ objectFit: 'cover' }}
                                        shape="square"
                                        {...promotion?.is_upsized ? {
                                            border: { motion: true, color: "rgb(254, 220, 2)" },
                                            bottomSlot: {
                                                text: "Upsized",
                                                textColor: 'black',
                                                className: 'font-weight-bold',
                                                style: { fontSize: 12, marginBottom: '1px', padding: "0 3px", background: 'rgb(254, 220, 2)', height: 16, borderRadius: '0 0 2px 2px' }
                                            } as any
                                        } : {
                                            border: true
                                        }}
                                    />
                                </div>
                                <FlexContainer flexDirection="column" justifyContent="space-between">
                                    <div className="responsive-text font-weight-bold">{promotion.merchant_name}</div>
                                    <div style={{ marginBottom: -4 }}>
                                        {!!promotion.prev_max_cashback_rate && <div className="font-weight-regular" style={{ textDecoration: 'line-through', fontSize: 12, marginBottom: -4, color: 'grey' }}>{renderText(promotion.prev_max_cashback_rate)}</div>}
                                        <div style={{ whiteSpace: 'nowrap' }} className="font-weight-bold">{promotion.max_cashback_rate}</div>
                                    </div>
                                </FlexContainer>
                            </FlexContainer>
                        </Card>
                    </div>
                )
            })}
        </FlexContainer>
    )
}

export default SimilarCashbackOfferList
import { useContext, useMemo, useState } from "react"
import { ICatalogueItem, IGiftCardDetail, IGiftCardPromotion } from "../../../../typings"
import { useAsyncEffect } from "ahooks"
import { request } from "../../../../utils"
import { Banner, Button, Divider, ImagePreview, Input, Spin, Table, Tabs, Typography } from "@douyinfe/semi-ui"
import FlexContainer from "../../../../components/FlexContainer"
import { IconArrowRight, IconBulb, IconCheckCircleStroked, IconChevronRight, IconSearch, IconTickCircle } from "@douyinfe/semi-icons"
import './index.less'
import { debounce, groupBy, uniqBy } from "lodash-es"
import dayjs from "dayjs"
import { AppContext } from "../../../../App"
import GiftCardPoster from "../GiftCardPoster"
import GiftCardSummary from "../GiftCardSummary"
import PointsCalculator, { REWARD_PROGRAM_MAP } from "../PointsCalculator"
import StoreLocator from "../StoreLocator"
import { GiftCardRender } from "../../../ManagementPage/GiftCardManagement"

interface IGiftCardPromotionDetailBodyProps {
    giftCardPromotion?: IGiftCardPromotion
}

const checkIsNotOtherGiftCard = (str: string) => {
    return str.startsWith('tcn') || str.startsWith('ultimate')
}

const GiftCardPromotionDetailBody = ({ giftCardPromotion }: IGiftCardPromotionDetailBodyProps) => {
    const { isMobile } = useContext(AppContext)

    const [loading, setLoading] = useState(false)
    const [giftCardList, setGiftCardList] = useState<IGiftCardDetail[]>([])

    const [catalogueItemDetail, setCatalogueItemDetail] = useState<ICatalogueItem>()

    const [selectedGiftCard, setSelectedGiftCard] = useState<IGiftCardDetail>()
    const [searchKeyword, setSearchKeyword] = useState('')

    const [previewVisible, setPreviewVisible] = useState(false)
    const [activeKey, setActiveKey] = useState('promotion-detail')

    const isGiftCardDemo = useMemo(() => {
        return !giftCardPromotion?.pic_url
    }, [giftCardPromotion])

    const filteredRetailerList = useMemo(() => {
        // all
        if (!selectedGiftCard) {
            const flatRetailerList = giftCardList.map(item => item.retailerList).flat()
            const flatRetailerListGroupByName = groupBy(flatRetailerList, 'name')


            const uniqRetailerName = Object.keys(flatRetailerListGroupByName)

            const uniqRetailerList = uniqRetailerName.map(name => {
                return {
                    ...flatRetailerList.find(item => item.name === name) || {},
                    eligibleGiftCardList: flatRetailerListGroupByName[name].map(item => item.gift_card_id)
                }
            })

            return uniqRetailerList.filter(retailer => {
                if (!searchKeyword) {
                    return true
                }

                const keywordArr = searchKeyword.toLowerCase().split(',')

                return keywordArr.some(keyword => retailer.name?.toLowerCase()?.includes(keyword))
            })
        }

        // has selectedGiftCard
        return selectedGiftCard.retailerList.filter(retailer => {
            return !searchKeyword || retailer.name.toLowerCase().includes(searchKeyword.toLowerCase())
        })
    }, [selectedGiftCard, searchKeyword, giftCardList])

    const hasNotOtherCard = useMemo(() => {
        const res = giftCardList.some(item => checkIsNotOtherGiftCard(item.gift_card_id))

        if (!res) {
            setSelectedGiftCard(giftCardList?.[0])
        }

        return res
    }, [giftCardList])

    useAsyncEffect(async () => {
        setSearchKeyword('')
        setGiftCardList([])
        setSelectedGiftCard(undefined)

        if (!giftCardPromotion) {
            return
        }

        setLoading(true)


        if (giftCardPromotion.platform?.toLowerCase() === 'coles') {
            const respData = await request({
                method: 'POST',
                url: '/api/v1/service/execute',
                data: {
                    service: 'rds.sql',
                    requestParams: [`
                        SELECT 
                            * 
                        FROM 
                            simpo_octopus.au_salefinder_catalogue_item a, 
                            simpo_octopus.au_salefinder_catalogue b 
                        WHERE 
                            a.catalogue_id = b.catalogue_id
                            AND b.platform = 'coles'
                            AND b.start_date = "${giftCardPromotion.start_date}"
                            AND b.end_date = "${giftCardPromotion.end_date}"
                            AND a.new_price IS NULL
                    `]
                }
            })

            setCatalogueItemDetail(respData?.[0])
        }

        const giftCardListRespData = await request({
            method: 'POST',
            url: '/api/v1/service/execute',
            data: {
                service: 'au.hotaudeals.getGiftCardPromotionDetail',
                requestParams: [giftCardPromotion.offer_text]
            }
        })

        setGiftCardList(giftCardListRespData.map((item: any) => ({
            ...item,
            retailerList: item.retailerList || []
        })))
        setLoading(false)
    }, [giftCardPromotion])

    if (loading) {
        return (
            <Spin size="large" spinning={loading} style={{ width: '100%' }}>
                <div style={{ height: 200 }} />
            </Spin>
        )
    }

    return (
        <div className="gift-card-promotion-detail-body-wrapper">

            {/* <Tabs
                className="responsive-background-secondary"
                activeKey={activeKey}
                tabList={[
                    { tab: 'Promotion detail', itemKey: 'promotion-detail' },
                    { tab: 'Applicable retailer', itemKey: 'applicable-retailer' },
                    { tab: 'Points calculator', itemKey: 'points-calculator', hidden: !giftCardPromotion?.offer_text?.toLowerCase()?.includes('point') },
                    { tab: <StoreLocator platform={giftCardPromotion?.platform || ''} />, itemKey: 'store-locator', hidden: !['coles', 'woolworths', 'big w'].includes(giftCardPromotion?.platform?.toLowerCase() || '') }
                ].filter((item: any) => !item.hidden)}
                onTabClick={(key) => {
                    if (key !== 'store-locator') {
                        setActiveKey(key)
                    }
                }}
            /> */}

            <GiftCardPoster giftCardPromotion={giftCardPromotion as any} giftCardList={giftCardList} />


            {!!giftCardPromotion?.promotion_detail && (
                <div>
                    <Divider />
                    <div className="font-weight-bold responsive-text section-header responsive-background-secondary" style={{ fontWeight: 700, letterSpacing: -1, fontSize: 22 }}>Promotion Detail</div>
                    <GiftCardSummary md={giftCardPromotion.promotion_detail} gift_card_promotion_auto_increment_id={giftCardPromotion.gift_card_promotion_auto_increment_id} />
                </div>
            )}

            {/* <div>
                {giftCardPromotion && giftCardPromotion.pic_url?.endsWith('.jpg') && (
                    <div style={{ padding: '6px 12px 0' }}>
                        <img src={giftCardPromotion.pic_url} width="100%" style={{ borderRadius: 4 }} />
                    </div>
                )}

                {!!catalogueItemDetail && catalogueItemDetail.description && (
                    <div className="font-weight-bold" dangerouslySetInnerHTML={{ __html: catalogueItemDetail.description || '' }}></div>
                )}
            </div> */}

            {!!giftCardPromotion?.platform && !!REWARD_PROGRAM_MAP[giftCardPromotion.platform.toLowerCase()] && giftCardPromotion.offer_text.toLowerCase().includes('point') && (
                <div style={{ marginTop: 16, marginBottom: 16 }}>
                    <Divider />
                    <PointsCalculator platform={giftCardPromotion?.platform} multiplier={20} />
                </div>
            )}

            {!!giftCardList.length && (
                <>
                    <Divider />
                    <div className="font-weight-bold responsive-text section-header responsive-background-secondary" style={{ fontWeight: 700, letterSpacing: -1, fontSize: 22 }}>Participating Retailers</div>
                    <div className="responsive-background">
                        <Tabs
                            className="responsive-background-secondary"
                            activeKey={selectedGiftCard?.gift_card_id || (hasNotOtherCard ? 'all' : giftCardList?.[0]?.gift_card_id)}
                            collapsible
                            onChange={(v) => setSelectedGiftCard(giftCardList.find(item => item.gift_card_id === v))}
                            style={isGiftCardDemo && !isMobile ? {} : { paddingTop: 12 }}
                        >
                            {hasNotOtherCard && (
                                <Tabs.TabPane itemKey="all" tab={(
                                    <FlexContainer className="font-weight-bold" alignItems="center" style={{ height: '100%' }}>
                                        All retailers
                                    </FlexContainer>
                                )} />
                            )}
                            {giftCardList.map(gc => (
                                <Tabs.TabPane key={gc.gift_card_id} itemKey={gc.gift_card_id} tab={(
                                    <FlexContainer>
                                        {/* <img src={gc.pic_url} height={24} /> */}
                                        <GiftCardRender pic_url={gc.pic_url} margin_offset={gc.margin_offset} height={24} />
                                    </FlexContainer>
                                )} />
                            ))}
                        </Tabs>

                        {selectedGiftCard && !checkIsNotOtherGiftCard(selectedGiftCard.gift_card_id || '') ? (
                            <Banner icon={<IconBulb />} closeIcon={null} fullMode={false} description={(
                                <FlexContainer className="font-weight-bold responsive-text" flexDirection="column" gap="4px">
                                    {selectedGiftCard.gift_card_id.startsWith('other') && (
                                        <span>{selectedGiftCard.name} Gift Card is a store-specific gift card.</span>
                                    )}

                                    {selectedGiftCard.gift_card_id.startsWith('pos') && (
                                        <span>{selectedGiftCard.name} Gift Card functions like cash and can be used at select categories of merchants.</span>
                                    )}

                                    {selectedGiftCard.gift_card_id.startsWith('universal') && (
                                        <span>{selectedGiftCard.name} Gift Card can be used at any merchants where {selectedGiftCard.name} is accepted.</span>
                                    )}

                                    
                                    <span>
                                        <a href={selectedGiftCard.detail_url || `https://www.google.com/search?btnI=1&q=${selectedGiftCard.name} Gift Card`} target="_blank">Click here for more details</a>
                                    </span>
                                </FlexContainer>
                            )} />


                        ) : (
                            <FlexContainer flexDirection="column" style={{ position: 'relative' }}>
                                <div className="responsive-background-secondary" style={isMobile ? { padding: '0 12px', position: 'sticky', top: 47, zIndex: 10, background: 'white', paddingBottom: 8 } : { paddingBottom: 8 }}>
                                    <Input placeholder="Search for retailer" onChange={debounce(setSearchKeyword, 500)} prefix={<IconSearch />} style={{ borderRadius: 16 }} />
                                </div>

                                <Table
                                    dataSource={filteredRetailerList as any}
                                    size="small"
                                    pagination={false}
                                    sticky={!isMobile ? { top: 32 } : {}}
                                    // style={isMobile ? {} : { maxHeight: 400, overflow: 'auto' }}
                                    columns={[
                                        {
                                            title: 'Retailer',
                                            className: 'retailer-name-wrapper',
                                            width: isMobile ? 100 : 140,
                                            fixed: true,
                                            render: (record: IGiftCardDetail["retailerList"][0]) => {
                                                return (
                                                    <FlexContainer alignItems="center" style={{ height: '100%' }}>
                                                        <img src={record.logo} height="40" width={isMobile ? 60 : 100} style={{
                                                            background: 'white',
                                                            objectFit: 'contain',
                                                            ...record.gift_card_id.startsWith('tcn') ? { transform: 'scale(1.5)' } : {}
                                                        }} />
                                                    </FlexContainer>
                                                )
                                            }
                                        },
                                        {
                                            title: ''
                                        },
                                        {
                                            title: 'In-store',
                                            width: 100,
                                            render: (record: IGiftCardDetail["retailerList"][0]) => {
                                                if (record.channel.includes('in-store')) {
                                                    return <IconTickCircle style={{ fontSize: 20, color: 'rgb(59,179,70)' }} />
                                                }
                                            }
                                        },
                                        {
                                            title: 'Online',
                                            width: 100,
                                            render: (record: IGiftCardDetail["retailerList"][0]) => {
                                                if (record.channel.includes('online')) {
                                                    return <IconTickCircle style={{ fontSize: 20, color: 'rgb(59,179,70)' }} />
                                                }
                                            }
                                        },
                                        {
                                            title: 'Swap',
                                            width: 100,
                                            render: (record: IGiftCardDetail["retailerList"][0]) => {
                                                if (record.channel.includes('exchange-card')) {
                                                    return <IconTickCircle style={{ fontSize: 20, color: 'rgb(59,179,70)' }} />
                                                }
                                            }
                                        },
                                        ...!selectedGiftCard ? [{
                                            width: 120,
                                            title: 'Options',
                                            render: (record: any) => {
                                                const eligibleGiftCardList = record.eligibleGiftCardList

                                                return (
                                                    <FlexContainer flexDirection="column" alignItems="flex-end">
                                                        {eligibleGiftCardList?.map((giftCardId: string) => {
                                                            const targetGiftCard = giftCardList.find(item => item.gift_card_id === giftCardId)

                                                            return (
                                                                <div key={giftCardId}>
                                                                    <Button size="small" theme="borderless" icon={<IconChevronRight />} iconPosition="right" style={{ padding: 0 }} onClick={() => {
                                                                        setSelectedGiftCard(targetGiftCard)
                                                                    }}>
                                                                        {targetGiftCard?.name}
                                                                    </Button>
                                                                </div>
                                                            )
                                                        })}
                                                    </FlexContainer>
                                                )
                                            }
                                        }] : []
                                    ]}
                                />
                            </FlexContainer>
                        )}


                    </div>
                </>
            )}
        </div>
    )
}

export default GiftCardPromotionDetailBody
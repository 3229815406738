import { Button, Form, Toast, Typography } from "@douyinfe/semi-ui"
import './index.less'
import { request } from "../../../../utils";
import { pick } from "lodash-es";
import { useContext, useState } from "react";
import { AppContext } from "../../../../App";

interface ISignUpFormProps {
    onLogin: () => void;
    onSignUp: () => void;
}

const SignUpForm = ({ onLogin, onSignUp }: ISignUpFormProps) => {
    const { setUserInfo } = useContext(AppContext)
    const [confirmLoading, setConfirmLoading] = useState(false)
    const [passwordInput, setPasswordInput] = useState('')

    const handleSubmit = async (v: any) => {
        setConfirmLoading(true)

        try {
            const respData = await request({
                method: 'POST',
                url: '/api/v1/service/execute',
                data: {
                    service: 'rds.insert',
                    requestParams: [
                        "simpo_octopus", "au_user", pick(v, ['email', 'password']), { ignore: true }
                    ]
                }
            })

            if (respData?.affectedRows) {
                Toast.success("Signup successfully")
                onSignUp()
            } else {
                Toast.error("This email address has already been registered")
            }

        } catch(err) {
            console.error(err)
        } finally {
            setConfirmLoading(false)
        }


    }

    return (
        <div className="signup-wrapper" style={{ padding: 24, background: 'white', height: '100%', overflow: 'auto' }}>

            <div>
                <h1>Sign up</h1>
                <p className="font-weight-bold">Sign up to your account to get the most out of HotAuDeals.</p>
            </div>

            <Form onSubmit={handleSubmit}>
                {({ formState, values, formApi }) => (
                    <>
                        <Form.Input 
                            rules={[{ required: true, message: 'Required' }]} 
                            size="large" 
                            field='email' 
                            label='Email' 
                            style={{ width: '100%' }} 
                            placeholder='Enter your email' 
                        />
                        <Form.Input
                            rules={[{ required: true, message: 'Required' }, { min: 8, message: 'Password must be at least 8 characters' }]} 
                            type="password" 
                            size="large" 
                            field='password' 
                            label='Password' 
                            style={{ width: '100%' }} 
                            placeholder='Enter your password'
                            onChange={setPasswordInput}
                        />
                        <Form.Input 
                            rules={[
                                { required: true, message: 'Required' }, 
                                { 
                                    validator: (__, v) => {
                                        if (v !== passwordInput) {
                                            return false
                                        }

                                        return true
                                    },
                                    message: 'Passwords do not match'
                                }
                            ]} 
                            type="password" 
                            size="large" 
                            field='confirm_password' 
                            label='Confirm Password' 
                            style={{ width: '100%' }} 
                            placeholder='Confirm your password'
                        />

                        <Form.Checkbox field='agree' noLabel>
                            <span className="font-weight-regular">I have read and agree to the terms of service</span>
                        </Form.Checkbox>
                        <div>
                            <Button loading={confirmLoading} size="large" disabled={!values.agree} htmlType='submit' theme="solid" block>
                                <span className="font-weight-bold">Sign up</span>
                            </Button>

                            <Typography.Paragraph type="tertiary" style={{ fontSize: 12, marginTop: 6 }}>
                                <span className="font-weight-regular">Have an account?</span>
                                <Button size="small" theme='borderless' style={{ padding: 0, marginLeft: 4, fontSize: 12 }} onClick={onLogin}>
                                    <span className="font-weight-regular">Log in</span>
                                </Button>
                            </Typography.Paragraph>

                            <Typography.Paragraph type="tertiary" style={{ fontSize: 12, marginTop: 0 }}>
                                <span className="font-weight-regular">Have problems?</span>
                                <Button size="small" theme='borderless' style={{ padding: 0, marginLeft: 4, fontSize: 12 }} onClick={() => {
                                    const recipient = "hotaudeals@icloud.com";
                                    const subject = `login issue`
                                    const body = ''
                                
                                    const mailtoLink = "mailto:" + recipient + "?subject=" + encodeURIComponent(subject) + "&body=" + encodeURIComponent(body);
                                
                                    window.open(mailtoLink);
                                }}>
                                    <span className="font-weight-regular">Report a problem</span>
                                </Button>
                            </Typography.Paragraph>
                        </div>
                    </>
                )}
            </Form>
        </div>
    )
}

export default SignUpForm
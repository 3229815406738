import { createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react"
import { IHotel } from "../../typings"
import { useAsyncEffect } from "ahooks"
import { request } from "../../utils"
import HotelMap from "./components/HotelMap"
import { AppContext } from "../../App"
import FlexContainer from "../../components/FlexContainer"
import { Button, Divider, Form, List, Radio, RadioGroup, Select, SideSheet, Table } from "@douyinfe/semi-ui"
import useKeyValuePairs from "../../hooks/useKeyValuePairs"
import { chain, debounce, groupBy, isEmpty, uniq, uniqBy } from "lodash-es"
import { MAPBOX_TOKEN } from "../RewardFlightsByPlatform/components/AirportMap"
import { ViewState } from "react-map-gl"
import { OptionProps } from "@douyinfe/semi-ui/lib/es/select/option"
import { FormApi } from "@douyinfe/semi-ui/lib/es/form"
import { IconChevronLeft, IconChevronRight, IconDelete } from "@douyinfe/semi-icons"
import dayjs from "dayjs"
import isSameOrBefore from "dayjs/plugin/isSameOrBefore"
import isSameOrAfter from "dayjs/plugin/isSameOrAfter"
import relativeTime from "dayjs/plugin/relativeTime"
import './index.less'
import { IconNavigation } from "@douyinfe/semi-icons-lab"

dayjs.extend(isSameOrAfter)
dayjs.extend(isSameOrBefore)
dayjs.extend(relativeTime)

export const RewardHotelsContext = createContext<{
    currency: string;
    date_range?: string[];
    display_price: string;

    exchangeRate: Record<string, number>;
    setExchangeRate: any
}>({
    currency: 'hotel_currency',
    date_range: [],
    display_price: 'STANDARD',

    exchangeRate: {},
    setExchangeRate: () => { }
})

const REWARD_HOTELS_SEARCH_HISTORY_LOCAL_STORAGE_KEY = '__reward_hotels_search_history__'

const RewardHotels = () => {
    const { kvPairs: hotelChainNameDict } = useKeyValuePairs('name', 'reward-hotels')
    const { kvPairs: currencyList } = useKeyValuePairs('generic', 'currency_list') as any

    const { windowHeight, navbarHeight, isMobile, isDarkMode } = useContext(AppContext)

    const [searchHistory, setSearchHistory] = useState<OptionProps[]>(JSON.parse(localStorage.getItem(REWARD_HOTELS_SEARCH_HISTORY_LOCAL_STORAGE_KEY) || '[]'))

    const [brandList, setBrandList] = useState<{ chain: string, brand: string }[]>([])
    const [pcFilterSectionExpand, setPcFilterSectionExpand] = useState(true)

    const [exchangeRate, setExchangeRate] = useState<Record<string, number>>({})
    const [locationSuggestionLoading, setLocationSuggestionLoading] = useState(false)
    const [locationSuggestionList, setLocationSuggestionList] = useState<any[]>(JSON.parse(localStorage.getItem(REWARD_HOTELS_SEARCH_HISTORY_LOCAL_STORAGE_KEY) || '[]').map((item: any) => ({
        place_name: item.label,
        geometry: {
            coordinates: item.value.split('||')
        }
    })))

    const [viewState, setViewState] = useState<ViewState>()
    const [hotelList, setHotelList] = useState<IHotel[]>([])
    const [validPropertyIdList, setValidPropertyIdList] = useState<string[]>([])

    const [filterSidesheetVisible, setFilterSidesheetVisible] = useState(false)

    const [loading, setLoading] = useState(true)

    const [filter, setFilter] = useState<Record<string, any>>({
        display_price: 'STANDARD'
    })

    const formRef = useRef<FormApi>()

    const filteredHotelList = useMemo(() => {

        const output = hotelList.filter(item => {
            if (!validPropertyIdList.includes(item.property_id)) {
                return false
            }

            if (isEmpty(filter)) {
                return true
            }

            if (filter.chain && filter.chain.length) {
                if (!filter.chain.includes(item.chain)) {
                    return false
                }
            }

            if (filter.brand && filter.brand.length) {
                if (!filter.brand.includes(item.brand)) {
                    return false
                }
            }

            if (filter.availability) {
                return true
            }

            return true
        })

        return output
    }, [hotelList, filter, validPropertyIdList])

    useAsyncEffect(async () => {
        const brandListRespData = await request({
            data: {
                service: 'rds.sql',
                requestParams: [`
                    SELECT DISTINCT brand, "marriott" AS chain FROM simpo_octopus.marriott_hotel
                    UNION
                    SELECT DISTINCT brand, "ihg" AS chain FROM simpo_octopus.ihg_hotel
                    UNION
                    SELECT DISTINCT brand, "hilton" AS chain FROM simpo_octopus.hilton_hotel 
                `]
            }
        })

        setBrandList(brandListRespData)
    }, [])

    useEffect(() => {
        handleSubmit()
    }, [filter])

    const handleSubmit = useCallback(async () => {
        setLoading(true)

        if (!hotelList?.length) {
            const sql = ['marriott', 'ihg', 'hilton'].map((chain: string) => {
                return `SELECT "${chain}" AS chain, pic_url, property_id, nick_name, brand, name, latitude, longitude, currency, opening_date, stars, number_of_reviews, country, location FROM simpo_octopus.${chain}_hotel`
            }).join(`\nUNION\n`)

            const respData = await request({
                data: {
                    service: 'rds.sql',
                    requestParams: [sql]
                }
            })

            setHotelList(respData)
        }

        const sql2 = ['marriott', 'ihg', 'hilton'].map((chain: string) => {
            return `
                SELECT 
                    DISTINCT property_id 
                FROM 
                    simpo_octopus.${chain}_hotel_rate 
                WHERE 
                    1 = 1
                    ${filter.availability === 'have_reward_nights' ? `AND rate_category = "REDEMPTION" AND price > 0` : ''}
                    ${Array.isArray(filter.date_range) && filter.date_range.length ? `AND date BETWEEN '${dayjs(filter.date_range[0]).format('YYYY-MM-DD')}' AND '${dayjs(filter.date_range[1]).format('YYYY-MM-DD')}'` : ""}
                `
        }).join('\nUNION\n')

        const respData2: any[] = await request({
            data: {
                service: 'rds.sql',
                requestParams: [sql2]
            }
        })

        setFilterSidesheetVisible(false)
        setValidPropertyIdList(respData2.map(item => item.property_id))
        setLoading(false)

        if (filter.location) {
            const [longitude, latitude] = filter.location.split('||').map((item: string) => parseFloat(item))
            setViewState({
                latitude,
                longitude,
                zoom: 12,
                bearing: 0,
                pitch: 0,
                padding: { top: 0, right: 0, bottom: 0, left: 0 },
            })

            const targetLocation = locationSuggestionList.find(location => {
                return location.geometry.coordinates?.join('||') === filter.location
            })

            if (targetLocation) {
                const output = uniqBy([
                    ...JSON.parse(localStorage.getItem(REWARD_HOTELS_SEARCH_HISTORY_LOCAL_STORAGE_KEY) || '[]'),
                    {
                        label: targetLocation.place_name,
                        value: targetLocation.geometry.coordinates?.join('||'),
                    }
                ], 'value')

                localStorage.setItem(REWARD_HOTELS_SEARCH_HISTORY_LOCAL_STORAGE_KEY, JSON.stringify(output))
                setSearchHistory(output)
            }
        }
    }, [locationSuggestionList, hotelList, filter])

    const handleSearch = async (keyword: string) => {
        if (!keyword) {
            return
        }

        setLocationSuggestionLoading(true)
        setLocationSuggestionList([])

        const response = await fetch(
            `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(keyword)}.json?types=place&access_token=${MAPBOX_TOKEN}`
        );
        const data = await response.json();

        setLocationSuggestionList(data?.features || [])
        setLocationSuggestionLoading(false)
    }

    const filterSection = (
        <FlexContainer style={{ width: isMobile ? '100%' : (pcFilterSectionExpand ? 320 : 0), flexShrink: 0, position: 'relative' }} flexDirection="column">
            {pcFilterSectionExpand && (
                <>
                    <div className="responsive-background-secondary" style={{ padding: isMobile ? 24 : 16, width: '100%', boxSizing: 'border-box', background: 'white', borderBottom: '1px solid var(--semi-color-border)' }}>
                        <div className="font-weight-black" style={{ fontSize: 20, letterSpacing: -0.5 }}>Reward Hotels Explorer</div>

                        <Form onSubmit={v => setFilter(prev => ({ ...prev, ...v }))} getFormApi={api => formRef.current = api}>
                            <Form.Select
                                label="Where to?"
                                field="location"
                                size="large"
                                style={{ width: '100%' }}
                                filter
                                remote
                                showArrow={false}
                                loading={locationSuggestionLoading}
                                onSearch={debounce(handleSearch, 1000)}
                                optionList={locationSuggestionList.map(item => {
                                    return {
                                        label: item.place_name,
                                        value: item.geometry.coordinates?.join('||')
                                    }
                                })}
                            />

                            <Form.DatePicker
                                size="large"
                                type="dateRange"
                                label="Travel Dates"
                                field="date_range"
                                dropdownClassName="reward-hotels-filter-date-picker-wrapper"
                                format="dd/MM/yyyy"
                                disabledDate={date => dayjs().isSameOrAfter(date)} style={{ width: '100%' }}
                                presetPosition="left"
                                presets={Array.from({ length: 12 }, (_, i) => {
                                    let start, end, text;

                                    if (i === 0) {
                                        start = dayjs().add(1, 'day'); // Tomorrow
                                        end = dayjs().endOf('month'); // End of the current month
                                    } else {
                                        start = dayjs().add(i, 'month').startOf('month');
                                        end = dayjs().add(i, 'month').endOf('month');
                                    }

                                    text = start.format('MMM YYYY');

                                    return {
                                        text,
                                        start: start.toDate(),
                                        end: end.toDate(),
                                    };
                                })}
                            />


                            <Button theme="solid" block size="large" loading={loading} htmlType="submit">Update search</Button>
                        </Form>
                    </div>

                    <div>
                        <div>
                            <Table
                                dataSource={searchHistory}
                                size='small'
                                sticky
                                style={{ maxHeight: windowHeight - navbarHeight - 262, overflow: 'auto' }}
                                columns={[
                                    {
                                        title: 'Recent search',
                                        dataIndex: 'label',
                                        render: (text: string, record: OptionProps) => {
                                            return (
                                                <FlexContainer justifyContent="space-between" alignItems="center" style={{ padding: '4px 0' }}>
                                                    <span>{text}</span>
                                                    <Button size="small" theme="borderless" type="danger" icon={<IconDelete />} onClick={(e) => {
                                                        e.stopPropagation()
                                                        const arr = searchHistory.filter(item => item.value !== record.value)
                                                        localStorage.setItem(REWARD_HOTELS_SEARCH_HISTORY_LOCAL_STORAGE_KEY, JSON.stringify(arr))
                                                        setSearchHistory(arr)
                                                    }} />
                                                </FlexContainer>
                                            )
                                        }
                                    }
                                ]}
                                pagination={false}
                                onRow={(record: any) => {
                                    return {
                                        className: 'cursor-pointer',
                                        onClick: () => {
                                            setLocationSuggestionList(JSON.parse(localStorage.getItem(REWARD_HOTELS_SEARCH_HISTORY_LOCAL_STORAGE_KEY) || '[]').map((item: any) => ({
                                                place_name: item.label,
                                                geometry: {
                                                    coordinates: item.value.split('||')
                                                }
                                            })))
                                            formRef.current?.setValue('location', record.value)
                                        }
                                    }
                                }}
                            />
                        </div>
                    </div>
                </>
            )}

            <FlexContainer
                className="responsive-background-secondary"
                alignItems="center"
                style={{
                    position: 'absolute',
                    top: 'calc(50% - 50px)',
                    background: 'white',
                    right: -15,
                    zIndex: 1,
                    height: 100,
                    boxSizing: 'border-box',
                    border: '1px solid var(--semi-color-border)',
                    borderLeft: 'none',
                    width: 14,
                    borderRadius: '0 50px 50px 0',
                    cursor: 'pointer'
                }}
                onClick={() => {
                    setPcFilterSectionExpand(prev => !prev)
                    setTimeout(() => {
                        window.dispatchEvent(new Event('resize'))
                    }, 0)
                }}
            >
                {pcFilterSectionExpand
                    ? <IconChevronLeft style={{ color: isDarkMode ? 'lightgrey' : 'grey', fontSize: 14 }} />
                    : <IconChevronRight style={{ color: isDarkMode ? 'lightgrey' : 'grey', fontSize: 14 }} />}
            </FlexContainer>
        </FlexContainer>
    )

    return (
        <RewardHotelsContext.Provider
            value={{
                currency: filter.currency,
                date_range: filter?.date_range?.map((date: any) => dayjs(date).format('YYYY-MM-DD')) || [],
                display_price: filter.display_price,
                exchangeRate,
                setExchangeRate
            }}
        >
            <div className="reward-hotels-wrapper">
                <FlexContainer style={{ width: '100%', height: windowHeight - navbarHeight }}>
                    {!isMobile && filterSection}

                    <FlexContainer flexDirection="column" style={{ width: '100%', flexGrow: 1, borderLeft: '1px solid var(--semi-color-border)' }}>
                        <FlexContainer style={{ padding: '8px 16px', borderBottom: '1px solid var(--semi-color-border)', overflowX: 'auto', overflowY: 'hidden' }} gap="8px">
                            <Select
                                prefix={<span className="font-weight-bold" style={{ fontSize: 14, paddingLeft: 12, paddingRight: 8, color: 'var(--semi-color-text-2)' }}>Price</span>}
                                value={filter.display_price}
                                style={{ flexShrink: 0, height: 30 }}
                                optionList={[
                                    {
                                        label: 'Standard price',
                                        value: 'STANDARD'
                                    },
                                    {
                                        label: 'Redemption price',
                                        value: 'REDEMPTION'
                                    },
                                    {
                                        label: 'Point value',
                                        value: 'POINT_VALUE'
                                    }
                                ]}
                                onChange={v => {
                                    setFilter(prev => ({
                                        ...prev,
                                        display_price: v as string[]
                                    }))
                                }}
                            />

                            <Select
                                prefix={<span className="font-weight-bold" style={{ fontSize: 14, paddingLeft: 12, paddingRight: 8, color: 'var(--semi-color-text-2)' }}>Hotel chain</span>}
                                multiple
                                placeholder="All"
                                style={{ flexShrink: 0, height: 30 }}
                                optionList={[
                                    ...Object.entries(hotelChainNameDict).map(([k, v]) => {
                                        return { label: v, value: k }
                                    }),
                                ]}
                                onChange={v => {
                                    setFilter(prev => ({
                                        ...prev,
                                        chain: v as string[]
                                    }))
                                }}
                            />

                            <Select
                                prefix={<span className="font-weight-bold" style={{ fontSize: 14, paddingLeft: 12, paddingRight: 8, color: 'var(--semi-color-text-2)' }}>Hotel brand</span>}
                                multiple
                                placeholder="All"
                                maxTagCount={2}
                                dropdownClassName="reward-hotels-filter-brand-wrapper"
                                style={{ flexShrink: 0, height: 30 }}
                                onChange={v => {
                                    setFilter(prev => ({
                                        ...prev,
                                        brand: v as string[]
                                    }))
                                }}
                            >
                                {Object.entries(groupBy(brandList, 'chain')).map(([chain, brandList]) => {
                                    return {
                                        label: hotelChainNameDict[chain],
                                        children: brandList.map(brand => {
                                            return {
                                                label: brand.brand,
                                                value: brand.brand
                                            }
                                        }),
                                    }
                                }).map((group, index) => (
                                    <Select.OptGroup label={group.label} key={`${index}-${group.label}`}>
                                        {group.children.map((option, index2) => (
                                            <Select.Option value={option.value} key={`${index2}-${option.label}`}>
                                                {option.label}
                                            </Select.Option>
                                        ))}
                                    </Select.OptGroup>
                                ))}
                            </Select>

                            <Select
                                prefix={<span className="font-weight-bold" style={{ fontSize: 14, paddingLeft: 12, paddingRight: 8, color: 'var(--semi-color-text-2)' }}>Availability</span>}
                                placeholder="All"
                                showClear
                                style={{ flexShrink: 0, height: 30 }}
                                optionList={[
                                    { label: 'Have reward nights', value: 'have_reward_nights' }
                                ]}
                                onChange={v => {
                                    setFilter(prev => ({
                                        ...prev,
                                        availability: v
                                    }))
                                }}
                            />

                            <Select
                                prefix={<span className="font-weight-bold" style={{ fontSize: 14, paddingLeft: 12, paddingRight: 8, color: 'var(--semi-color-text-2)' }}>Currency</span>}
                                defaultValue="hotel_currency"
                                style={{ flexShrink: 0, height: 30 }}
                                optionList={[
                                    { label: 'Hotel currency', value: 'hotel_currency' },
                                    ...Array.isArray(currencyList) ? currencyList.map((item: any) => {
                                        return {
                                            label: item.currency,
                                            value: item.currency
                                        }
                                    }) : []
                                ]}
                                onChange={v => {
                                    setFilter(prev => ({
                                        ...prev,
                                        currency: v
                                    }))
                                }} />
                        </FlexContainer>

                        <div style={{ position: 'relative', width: '100%', height: '100%' }}>

                            {isMobile && (
                                <FlexContainer style={{ position: 'absolute', top: 8, zIndex: 1, width: '100%', pointerEvents: 'none' }} justifyContent="center">
                                    <FlexContainer
                                        className="responsive-background"
                                        alignItems="center"
                                        style={{
                                            pointerEvents: 'auto',
                                            borderRadius: 32,
                                            padding: '4px 12px 4px 8px',
                                            border: '1px solid var(--semi-color-border)',
                                            background: 'white'
                                        }}
                                        gap="8px"
                                        onClick={() => {
                                            setFilterSidesheetVisible(true)
                                        }}
                                    >
                                        <IconNavigation />
                                        <div>
                                            <div style={{ fontSize: 14 }}>
                                                {locationSuggestionList.find(location => {
                                                    return location.geometry.coordinates?.join('||') === filter.location
                                                })?.place_name || 'Sydney, Australia'}
                                            </div>
                                        </div>
                                    </FlexContainer>
                                </FlexContainer>
                            )}

                            <HotelMap hotelList={filteredHotelList} viewState={viewState} />
                        </div>
                    </FlexContainer>

                </FlexContainer>

                <SideSheet
                    className="reward-hotels-filter-sidesheet-wrapper"
                    placement="bottom"
                    style={{ borderRadius: '12px 12px 0 0' }}
                    bodyStyle={{ padding: 0, overflow: 'auto' }}
                    visible={filterSidesheetVisible}
                    onCancel={() => setFilterSidesheetVisible(false)}
                >
                    {filterSection}
                </SideSheet>
            </div>
        </RewardHotelsContext.Provider>
    )
}

export default RewardHotels
import { Button, Divider, Modal, SideSheet, Spin, Tabs, Toast } from "@douyinfe/semi-ui"
import { SideSheetReactProps } from "@douyinfe/semi-ui/lib/es/sideSheet"
import { useAsyncEffect } from "ahooks";
import { useContext, useState } from "react";
import { IDeal } from "../../../../typings";
import algoliasearch from "algoliasearch"
import ProductCard from "../ProductCard";
import FlexContainer from "../../../../components/FlexContainer";
import { renderPageOnReady, request } from "../../../../utils";
import { AppContext } from "../../../../App";
import './index.less'
import { uniq } from "lodash-es";
import PriceHistoryPane from "../PriceHistoryPane";
import { IconAppCenter, IconDisc, IconHistory } from "@douyinfe/semi-icons";
import { isCataloguePage } from "../..";

const client = algoliasearch('A97US7BF85', '70a0214c44fee7a1ddda7f47b0babeaf');
const index = client.initIndex('simpo_octopus');


export interface ISimilarProductSidesheetProps extends SideSheetReactProps {
    platform?: string;
    product?: IDeal;
    mode?: string
}

const SimilarProductSidesheet = ({ platform, product, mode, ...sidesheetProps }: ISimilarProductSidesheetProps) => {
    const { isMobile, isInApp, COLOR_MAP, isDarkMode } = useContext(AppContext)

    const [loading, setLoading] = useState(false)
    const [hitList, setHitList] = useState<IDeal[]>([])

    const [variantList, setVariantList] = useState<IDeal[]>([])
    const [exactMatchItem, setExactMatchItem] = useState<IDeal>()

    useAsyncEffect(async () => {
        if (!product || !platform || !['coles', 'woolworths', 'coles (sneak peek)', 'woolworths (sneak peek)'].includes(platform)) return

        setExactMatchItem(undefined)
        setHitList([])
        setVariantList([])
        setLoading(true)

        // const elasticSearchResult = await index.search('', {
        //     similarQuery: product.name,
        //     hitsPerPage: 5,
        //     page: 0,
        //     getRankingInfo: true,
        //     filters: platform === 'coles' ? "platform:woolworths" : 'platform:coles'
        // })

        if (!isCataloguePage()) {
            const exactMatchRespData: any[] = await request({
                url: '/api/v1/service/execute',
                method: 'POST',
                data: {
                    service: 'rds.sql',
                    requestParams: [
                        platform === 'coles'
                            ? `SELECT * FROM simpo_octopus.au_gorcery_item_buying_option WHERE platform = "coles" AND criteria = "algolia_${product.sku_id}" AND status = 1`
                            : `SELECT * FROM simpo_octopus.au_gorcery_item_buying_option WHERE platform = "coles" AND criteria LIKE "algolia_%" AND status = 1 AND buying_options = "${product.sku_id}"`
                    ]
                }
            })

            if (exactMatchRespData && exactMatchRespData.length) {
                const skuId = platform === 'coles' ? exactMatchRespData[0].buying_options : exactMatchRespData[0].criteria.split('_')[1]

                const exactMatchDetailRespData = await request({
                    url: '/api/v1/service/execute',
                    method: 'POST',
                    data: {
                        service: 'rds.sql',
                        requestParams: [
                            `SELECT *, "${platform === 'coles' ? 'woolworths' : 'coles'}" AS platform FROM simpo_octopus.au_${platform === 'coles' ? 'woolworths' : 'coles'}_product_tmp WHERE sku_id = "${skuId}"`
                        ]
                    }
                })

                if (exactMatchDetailRespData && exactMatchDetailRespData.length) {
                    setExactMatchItem(exactMatchDetailRespData[0])
                }
            }

            const buyingOptionsRespData: any[] = await request({
                url: '/api/v1/service/execute',
                method: 'POST',
                data: {
                    service: 'rds.sql',
                    requestParams: [
                        `SELECT * FROM simpo_octopus.au_gorcery_item_buying_option WHERE platform = "${platform}" AND criteria NOT LIKE "algolia_%" AND buying_options LIKE "${platform === 'coles' ? '%' : ''}||${product.sku_id}||%"`
                    ]
                }
            })

            const skuIdList = uniq(buyingOptionsRespData.map(item => item.buying_options.split('||')).flat()).filter(skuId => skuId !== product.sku_id)

            if (skuIdList.length) {
                const variantListRespData = await request({
                    url: '/api/v1/service/execute',
                    method: 'POST',
                    data: {
                        service: 'rds.sql',
                        requestParams: [
                            `SELECT *, "${platform}" as platform FROM simpo_octopus.au_${platform}_product_tmp WHERE sku_id IN (${skuIdList.map(skuId => `"${skuId}"`).join(', ')})`
                        ]
                    }
                })

                setVariantList(variantListRespData.map((item: any) => {
                    item.criteria = buyingOptionsRespData.find(el => el.buying_options.includes(`||${item.sku_id}||`))?.criteria
                    return item
                }))
            }
        } else {
            const skuIdList = product.sku_id_list?.split(',') || []

            if (skuIdList.length) {
                const variantListRespData = await request({
                    url: '/api/v1/service/execute',
                    method: 'POST',
                    data: {
                        service: 'rds.sql',
                        requestParams: [
                            `SELECT *, "${product.platform}" as platform FROM simpo_octopus.au_${product.platform}_product_tmp WHERE sku_id IN (${skuIdList.map(skuId => `"${skuId}"`).join(', ')})`
                        ]
                    }
                })

                setVariantList(variantListRespData.map((item: IDeal) => {
                    return {
                        ...item,
                        new_price: product.new_price,
                        price: product.new_price,
                        unit_price: product.unit_price,
                        origin_price: product.origin_price,
                    }
                }))
            }
        }

        setLoading(false)
    }, [product, platform])

    const bodyWrapper = (
        <>
            {product && platform && (
                <div className="product-detail-wrapper similar-product-sidesheet-wrapper">
                    <style>{`
                        .similar-product-sidesheet-wrapper {
                            .semi-tabs-tab-active {
                                border-color: ${COLOR_MAP[platform]} !important;
                            }
                        }
                    `}</style>
                    {mode !== 'embedded' && (
                        <>
                            <div style={{ paddingTop: product.extra_data?.isActiveCatalogueItem || isCataloguePage() ? 0 : 0 }}>
                                <ProductCard
                                    showCatalogueItemLabel={isMobile}
                                    platform={product.platform}
                                    product={product}
                                    cardProps={{ style: { width: '100%', borderRadius: 0 }, bordered: false }}
                                    showSimpleOpenButton={isMobile && (!isCataloguePage() || ['aldi', 'costco'].includes(product.platform))}
                                    showOpenButton={!isMobile && (!isCataloguePage() || ['aldi', 'costco'].includes(product.platform))}
                                    showAddToListButton={!isCataloguePage()}
                                />
                            </div>
                            <Divider />
                        </>

                    )}

                    {['coles', 'woolworths'].includes(product.platform) && (
                        <div style={{ padding: 0 }}>
                            {loading ? (
                                <FlexContainer style={{ width: '100%', padding: '24px 0' }} justifyContent="center">
                                    <Spin spinning size="large" />
                                </FlexContainer>
                            ) : (
                                <Tabs defaultActiveKey={isCataloguePage() ? "catalogue-items" : "price-history"}>
                                    {/* <Tabs.TabPane tab="Similar products" itemKey="similar-products">
                                {renderPageOnReady(loading, (
                                    <FlexContainer flexDirection="column" gap="12px">
                                        {hitList.map((hit: any, index) => {
                                            return (
                                                <>
                                                    {index !== 0 && <Divider />}
                                                    <ProductCard
                                                        platform={hit.platform}
                                                        product={hit}
                                                        cardProps={{ style: { width: '100%' }, bordered: false }}
                                                        showSimpleOpenButton={isMobile}
                                                        showOpenButton={!isMobile}
                                                        showAddToListButton
                                                    />
                                                </>

                                            )
                                        })}
                                    </FlexContainer>
                                ))}
                            </Tabs.TabPane> */}

                                    {!!exactMatchItem && (
                                        <Tabs.TabPane tab={"Exact match"} itemKey="exact-match">
                                            <FlexContainer flexDirection="column">
                                                <ProductCard
                                                    platform={exactMatchItem.platform}
                                                    product={exactMatchItem}
                                                    cardProps={{ style: { width: '100%', borderRadius: 0 }, bordered: false }}
                                                    showSimpleOpenButton={isMobile}
                                                    showOpenButton={!isMobile}
                                                    showAddToListButton
                                                />
                                            </FlexContainer>
                                        </Tabs.TabPane>
                                    )}

                                    {!isCataloguePage() && mode !== 'embedded' && platform === 'woolworths' && !!variantList.length && (
                                        <Tabs.TabPane tab='You May Also Like' itemKey="buying-options">
                                            <FlexContainer flexDirection="column">
                                                {variantList.map((variant: any, index) => {
                                                    return (
                                                        <ProductCard
                                                            key={variant.sku_id}
                                                            platform={variant.platform}
                                                            product={variant}
                                                            cardProps={{ className: 'responsive-border', style: { width: '100%', borderRadius: 0, borderBottom: `1px solid rgba(28,31,35,.08)` }, bordered: false }}
                                                            showSimpleOpenButton={isMobile}
                                                            showOpenButton={!isMobile}
                                                            showAddToListButton
                                                        />
                                                    )
                                                })}
                                            </FlexContainer>
                                        </Tabs.TabPane>
                                    )}

                                    {!isCataloguePage() && mode !== 'embedded' && platform === 'coles' && variantList.some(item => item.criteria === 'varieties') && (
                                        <Tabs.TabPane tab={`${variantList.filter(item => item.criteria === 'varieties').length} Buying Options`} itemKey="variants">
                                            <FlexContainer flexDirection="column">
                                                {variantList.filter(item => item.criteria === 'varieties').map((variant: any, index) => {
                                                    return (
                                                        <ProductCard
                                                            key={variant.sku_id}
                                                            platform={variant.platform}
                                                            product={variant}
                                                            cardProps={{ className: 'responsive-border', style: { width: '100%', borderRadius: 0, borderBottom: `1px solid rgba(28,31,35,.08)` }, bordered: false }}
                                                            showSimpleOpenButton={isMobile}
                                                            showOpenButton={!isMobile}
                                                            showAddToListButton
                                                        />
                                                    )
                                                })}
                                            </FlexContainer>
                                        </Tabs.TabPane>
                                    )}

                                    {!isCataloguePage() && mode !== 'embedded' && platform === 'coles' && variantList.some(item => item.criteria === 'size') && (
                                        <Tabs.TabPane tab={`${variantList.filter(item => item.criteria === 'size').length} Sizes`} itemKey="sizes">
                                            <FlexContainer flexDirection="column">
                                                {variantList.filter(item => item.criteria === 'size').map((variant: any, index) => {
                                                    return (
                                                        <ProductCard
                                                            key={variant.sku_id}
                                                            platform={variant.platform}
                                                            product={variant}
                                                            cardProps={{ className: 'responsive-border', style: { width: '100%', borderRadius: 0, borderBottom: `1px solid rgba(28,31,35,.08)` }, bordered: false }}
                                                            showSimpleOpenButton={isMobile}
                                                            showOpenButton={!isMobile}
                                                            showAddToListButton
                                                        />

                                                    )
                                                })}
                                            </FlexContainer>
                                        </Tabs.TabPane>
                                    )}

                                    {!isCataloguePage() && (
                                        <Tabs.TabPane tab="Price history" itemKey="price-history">
                                            <PriceHistoryPane platform={platform} sku_id={product.sku_id as any} />
                                        </Tabs.TabPane>
                                    )}

                                    {isCataloguePage() && mode !== 'embedded' && (
                                        <Tabs.TabPane tab={`${variantList.length} Catalogue Items`} itemKey="catalogue-items">
                                            <FlexContainer flexDirection="column">
                                                {variantList.map((variant: any, index) => {
                                                    return (
                                                        <ProductCard
                                                            key={variant.sku_id}
                                                            platform={variant.platform}
                                                            product={variant}
                                                            cardProps={{ className: 'responsive-border', style: { width: '100%', borderRadius: 0, borderBottom: `1px solid rgba(28,31,35,.08)` }, bordered: false }}
                                                            showSimpleOpenButton={isMobile}
                                                            showOpenButton={!isMobile}
                                                            showAddToListButton
                                                        />

                                                    )
                                                })}
                                            </FlexContainer>
                                        </Tabs.TabPane>
                                    )}
                                </Tabs>
                            )}

                        </div>
                    )}

                    <FlexContainer
                        gap="16px"
                        flexDirection="column"
                        className="font-weight-bold"
                        style={{ fontSize: 13, padding: 24, textAlign: 'center', color: isDarkMode ? 'grey' : 'rgba(28, 31, 35, 0.6)' }}
                    >
                        <div>
                            All product names, product images, retailer names, trademarks, and registered trademarks are the property of their respective owners.
                        </div>
                        <div>
                            Prices may differ from those found in-store. In case of any errors, the in-store prices shall prevail.
                        </div>

                        <div>
                            <Button theme="borderless" style={{ fontSize: 13 }} onClick={() => {
                                const recipient = "hotaudeals@icloud.com";
                                const subject = `product issue: ${product.platform}_${product.sku_id}_${product.price}`
                                const body = ''

                                const mailtoLink = "mailto:" + recipient + "?subject=" + encodeURIComponent(subject) + "&body=" + encodeURIComponent(body);

                                window.open(mailtoLink);
                            }}>Report an issue</Button>
                        </div>
                    </FlexContainer>
                </div>

            )}
        </>
    )

    // if (!isInApp) {
    //     return (
    //         <Modal {...sidesheetProps} closable={false} footer={null} centered width={300}>
    //             <div className="font-weight-bold" style={{ fontSize: 20, letterSpacing: -1, lineHeight: 1.2, textAlign: 'center' }}>
    //                 Explore similar products, track price history, and more—exclusive features available in our handy app!
    //             </div>

    //             <div style={{ marginTop: 16, marginBottom: -8 }}>
    //                 <Button theme="solid" size="large" block onClick={() => {
    //                     Toast.warning("Our app is currently under review and will be available as soon as it's released.")
    //                 }}>Open in App</Button>
    //                 <Button size="large" block theme="borderless" style={{ marginTop: 6 }} onClick={sidesheetProps.onCancel}>Close</Button>
    //             </div>
    //         </Modal>
    //     )
    // }

    if (mode === 'embedded' || mode === 'tour') {
        return bodyWrapper
    }

    if (isMobile) {
        return (
            <SideSheet
                {...sidesheetProps}
                placement="bottom"
                bodyStyle={{ padding: 0, paddingBottom: isInApp ? 48 : 0 }}
                closable={false}
                headerStyle={{ padding: 0 }}
                style={{ borderRadius: '12px 12px 0 0' }}
                height={600}
            >
                {bodyWrapper}
            </SideSheet>
        )
    }

    return (
        <Modal {...sidesheetProps} title="Product detail" width="700px" footer={null}>
            {bodyWrapper}
        </Modal>
    )
}

export default SimilarProductSidesheet
import React, { lazy, Suspense } from 'react';

// Lazy load the Map component
const LazyMapComponent = lazy(() => import('react-map-gl'));

interface ILazyMapProps {
    children: React.ReactNode;
    // Extend with other MapProps as needed
    [key: string]: any;
}

const LazyMap = ({ children, onRef, ...mapProps }: ILazyMapProps) => {
    return (
        <Suspense fallback={<div>Loading Map...</div>}>
            <LazyMapComponent 
                ref={onRef} 
                {...{
                    ...mapProps,
                    projection: 'mercator'
                } as any}
            >
                {children}
            </LazyMapComponent>
        </Suspense>
    );
};

export default LazyMap;
import { Button, Form, Input, Pagination, Toast, Upload, useFormApi } from "@douyinfe/semi-ui"
import FlexContainer from "../../../components/FlexContainer"
import { createContext, useContext, useEffect, useMemo, useRef, useState } from "react"
import * as htmlToImage from 'html-to-image';
import download from 'downloadjs'
import { FormApi } from "@douyinfe/semi-ui/lib/es/form";
import SceneRender from "./components/SceneRender";
import './index.less'
import { FONT_FACE_STYLE_SHEET } from "./consts";
import { uniqueId } from "lodash-es";
import { AppContext } from "../../../App";

export const PosterContext = createContext({
    width: 0,
    height: 0,
    offset: 1,
    pageNum: 1,
    setTotalPageNum: (num: number) => {}
})

const LOCALSTORAGE_POSTER_FORM_DATE = 'poster_form_data'
const LOCALSTORAGE_POSTER_TEMPLATE_LIST = 'poster_template_list'

const MULTIPLIER = 5032 / 2796

const SIZE_TO_USER_CASE: Record<string, any> = {
    '1280,800': {
        borderRadius: 8,

        titleFontSize: 14,
        titleStyle: {
            fontWeight: 700
        },

        descriptionFontSize: 8,
        descriptionStyle: {
            width: '80%',
            lineHeight: 1.2,
            marginTop: 2,
        },

        imageBorderRadius: 2,


        zoomIn: 2,
    },

    '900,1200': {
        borderRadius: 1e-8,

        titleFontSize: 14,
        titleStyle: {
            fontWeight: 700
        },

        descriptionFontSize: 8,
        descriptionStyle: {
            width: '80%',
            lineHeight: 1.2,
            marginTop: 2,
        },

        imageBorderRadius: 2,


        zoomIn: 2,
    }
}

// dd2c00, ff5822, ffc205

const getBase64 = (file: any) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
};

const Poster = () => {

    const formRef = useRef<FormApi>()
    const downloadFlag = useRef(false)

    const { windowHeight, navbarHeight } = useContext(AppContext)

    const [pageNum, setPageNum] = useState(1)
    const [totalPageNum, setTotalPageNum] = useState(1)

    const taskIdRef = useRef(1)

    const [formData, setFormData] = useState<Record<string, any>>(JSON.parse(localStorage.getItem(LOCALSTORAGE_POSTER_FORM_DATE) || JSON.stringify({
        size: [1290, 2796].join(','),
        scene: '',
        background: '#dd2c01',
        color: 'white',
        title: 'Save',
        description: 'on groceries from your favourite supermarkets',
        scale: 4,
        url: '',
        text_padding: 36,
        image_padding: 40,
        bottom: -70,
    })))

    const parsedSize = useMemo(() => {
        return formData.size?.split(',')?.map((item: string) => Number(item))
    }, [formData])

    const offset = useMemo(() => {
        return 4 / formData.scale / 1.8
    }, [formData])

    useEffect(() => {
        const styleEl = document.createElement('style')
        styleEl.id = 'poster-font-face-style-sheet'
        styleEl.innerHTML = FONT_FACE_STYLE_SHEET

        document.head.appendChild(styleEl)
    }, [])

    useEffect(() => {
        localStorage.setItem(LOCALSTORAGE_POSTER_FORM_DATE, JSON.stringify(formData))

        if (formData.scale === 1 && downloadFlag.current) {
            const el: any = document.querySelector('.poster-container')

            const num = pageNum

            htmlToImage.toPng(el as any, { width: parsedSize[0] / MULTIPLIER, height: parsedSize[1] / MULTIPLIER })
                .then(function (dataUrl) {
                    download(dataUrl, `task_${taskIdRef.current}_page_${num}.png`);
                });

            setTimeout(() => {
                if (formData.mode === 'all' && totalPageNum > pageNum) {
                    setPageNum(pageNum + 1)
                    return
                }

                setFormData(prev => ({ ...prev, scale: formRef.current?.getValue('scale') }))
                downloadFlag.current = false
            }, 32)
        }
    }, [formData, totalPageNum, pageNum])

    const beforeUpload = async (file: any) => {
        if (file?.file?.fileInstance) {
            const base64 = await getBase64(file.file.fileInstance);
            setFormData(prev => ({ ...prev, url: base64 }))

            formRef.current?.setValue('url', base64)
        }
        return false; // Prevent the default upload behavior
    };

    return (
        <FlexContainer flexDirection="column" className="font-weight-bold" style={{ boxSizing: 'border-box', padding: '0 16px' }}>
            <div>
                <div className="font-weight-black responsive-text" style={{ padding: '8px 0', fontSize: 20, letterSpacing: -0.5 }}>
                    Poster Creator
                </div>
            </div>

            <FlexContainer gap="24px" style={{ padding: '0', paddingBottom: 16 }}>
                <Form 
                    className="responsive-background-secondary"
                    getFormApi={api => formRef.current = api} 
                    onValueChange={v => setFormData(prev => ({ ...prev, ...v }))} 
                    style={{ flexGrow: 1, height: windowHeight - navbarHeight - 46 - 16, overflow: 'auto', boxSizing: 'border-box', padding: '0 20px', border: '1px solid var(--semi-color-border)', borderRadius: 8, background: 'white' }} 
                    initValues={formData}
                >
                    <Form.RadioGroup field='size' label="Size" options={[
                        { label: '1290 x 2796 (iPhone 6.7")', value: [1290, 2796].join(',') },
                        { label: '1242 x 2208 (iphone 5.5")', value: [1242, 2208].join(',') },
                        { label: "1280 x 800 (Chrome Extension)", value: [1280, 800].join(',') },
                        { label: "900 x 1200 (小红书)", value: [900, 1200].join(',') }
                    ]} />

                    <Form.Select
                        style={{ width: '100%' }}
                        showClear
                        field="template"
                        label="Load from template"
                        optionList={(JSON.parse(localStorage.getItem(LOCALSTORAGE_POSTER_TEMPLATE_LIST) || '[]')).map((item: any) => {
                            return {
                                label: <span>{item.templateName}</span>,
                                value: JSON.stringify(item)
                            }
                        })}
                        onChange={v => {
                            formRef.current?.setValues(JSON.parse(v as string || '{}'))
                        }}
                    />

                    <Form.Select
                        style={{ width: '100%' }}
                        showClear
                        field="scene"
                        label="Scene"
                        optionList={[
                            { label: <span>Gift Card</span>, value: 'gift-card' },
                            { label: <span>Coles Catalogue</span>, value: 'coles-catalogue' },
                            { label: <span>Woolworths Catalogue</span>, value: 'woolworths-catalogue' },
                            { label: <span>Costco Catalogue</span>, value: 'costco-catalogue' },
                            { label: <span>Cashback</span>, value: 'cashback' }
                        ]}
                        onChange={v => {
                            if (!v) {
                                setFormData(prev => ({ ...prev, scene: '' }))
                            }
                        }}
                    />

                    <Form.Input field="title" label="Title" />

                    <Form.Input field="description" label="Description" />

                    <Form.Input field="background" label="Background color" />

                    <Form.Input field="color" label="Font color" />

                    <Form.InputNumber field="text_padding" label="Text padding top" />

                    <Form.Input field="url" label="Image URL" extraText={(
                        <Upload
                            action="" // Leave empty if not uploading to a server
                            beforeUpload={beforeUpload as any}
                            showUploadList={false}
                        >
                            <button>Click to Upload</button>
                        </Upload>
                    )} />

                    <Form.InputNumber field="image_padding" label="Image padding" />

                    <Form.InputNumber field="bottom" label="Image absolute bottom" />

                    <Form.InputNumber field="scale" label="Scale" min={0} />

                    <FlexContainer className="responsive-background-secondary" style={{ position: 'sticky', bottom: 0, background: 'white', padding: '16px 0' }}>
                        <Button theme="solid" style={{ marginRight: 8 }} onClick={() => {
                            downloadFlag.current = true
                            taskIdRef.current = uniqueId() as any
                            setFormData(prev => {
                                return { ...prev, scale: 1, mode: 'curr_page' }
                            })
                        }}>
                            Download this page only
                        </Button>

                        <Button theme="solid" style={{ marginRight: 8 }} onClick={() => {
                            downloadFlag.current = true
                            taskIdRef.current = uniqueId() as any
                            setFormData(prev => {
                                return { ...prev, scale: 1, mode: 'all' }
                            })
                        }}>
                            Download all
                        </Button>

                        <Button style={{ marginRight: 8 }} onClick={() => {
                            const templateName = prompt("Enter template name: ");

                            const arr = JSON.parse(localStorage.getItem(LOCALSTORAGE_POSTER_TEMPLATE_LIST) || '[]')
                            arr.push({ ...formData, url: '', templateName })
                            localStorage.setItem(LOCALSTORAGE_POSTER_TEMPLATE_LIST, JSON.stringify(arr))

                            Toast.success('Save template successfully')
                        }}>
                            Save as template
                        </Button>

                        <Button onClick={() => localStorage.removeItem(LOCALSTORAGE_POSTER_FORM_DATE)}>Clear form data</Button>
                    </FlexContainer>
                </Form>

                <div>
                    <FlexContainer
                        alignItems="center"
                        flexDirection="column"
                        className="poster-container"
                        style={{
                            flexShrink: 0,
                            overflow: 'hidden',
                            position: 'relative',
                            borderRadius: SIZE_TO_USER_CASE[formData.size]?.borderRadius || 40 * offset,
                            width: parsedSize[0] * offset / 4,
                            height: parsedSize[1] * offset / 4,
                            background: formData.background,
                            color: formData.color,
                        }}
                    >
                        {!!formData.title && !formData.scene && (
                            <div
                                style={{
                                    fontSize: (SIZE_TO_USER_CASE[formData.size]?.titleFontSize || 36) * offset,
                                    marginTop: formData.text_padding * offset, letterSpacing: -1,
                                    ...SIZE_TO_USER_CASE[formData.size]?.titleStyle || {}
                                }}
                                dangerouslySetInnerHTML={{ __html: formData.title }}
                            />
                        )}

                        {!!formData.description && !formData.scene && (
                            <div
                                style={{
                                    fontSize: (SIZE_TO_USER_CASE[formData.size]?.descriptionFontSize || 16) * offset,
                                    textAlign: 'center',
                                    letterSpacing: -0.5,
                                    ...SIZE_TO_USER_CASE[formData.size]?.descriptionStyle || {}
                                }}
                                dangerouslySetInnerHTML={{ __html: formData.description }}
                            />
                        )}

                        {!!formData.url && !formData.scene && (
                            <img src={formData.url} style={{ width: parsedSize[0] * offset / 4 - formData.image_padding * offset, objectFit: 'contain', position: 'absolute', bottom: formData.bottom * offset, borderRadius: (SIZE_TO_USER_CASE[formData.size]?.imageBorderRadius || 36) * offset }} />
                        )}

                        <PosterContext.Provider value={{
                            width: parsedSize[0],
                            height: parsedSize[1],
                            offset,
                            pageNum,
                            setTotalPageNum: setTotalPageNum as any
                        }}>
                            {formData.scene && <SceneRender scene={formData.scene} />}
                        </PosterContext.Provider>

                    </FlexContainer>

                    {totalPageNum > 1 && (
                        <FlexContainer justifyContent="center" style={{ marginTop: 4 }}>
                            <Pagination 
                                pageSize={12}
                                currentPage={pageNum}
                                total={totalPageNum*12}
                                onPageChange={setPageNum}
                            />
                        </FlexContainer>
                    )}
                </div>
            </FlexContainer>


        </FlexContainer>
    )
}

export default Poster
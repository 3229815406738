import { useContext } from "react"
import { AppContext } from "../../App"
import { Button } from "@douyinfe/semi-ui"
import FlexContainer from "../../components/FlexContainer"

const ContactUs = () => {
    const { isMobile } = useContext(AppContext)

    return (
        <FlexContainer className="responsive-text" justifyContent="center" style={{ width: '100%', height: window.innerHeight - 52, overflow: 'auto' }}>
            <div style={{ padding: 24, maxWidth: 1000 }}>
                <div style={{ fontWeight: 700, fontSize: 28, paddingBottom: 12, letterSpacing: -1 }}>Getting help</div>

                <FlexContainer className="font-weight-bold" flexDirection="column" gap="8px">
                    <div>
                        If you encounter any issues while using our platform, you can reach out to us by clicking the button below.
                    </div>

                    <div>
                        <Button theme="solid" block={isMobile} size="large" onClick={() => {
                            window.open("https://bytedance.sg.larkoffice.com/share/base/form/shrlgnXg7Mbm67i272N6Didhsod")
                        }}>Report an issue</Button>
                    </div>

                    <div>
                        Alternatively, if you have any other inquiries, please contact us at:
                    </div>

                    <div style={{ marginBottom: -8 }}>
                        David
                    </div>

                    <div>
                        <a href="mailto:hotaudeals@icloud.com">hotaudeals@icloud.com</a>
                    </div>

                    <div>
                        Thank you for using HotAuDeals.
                    </div>
                </FlexContainer>
            </div>
        </FlexContainer>
    )
}

export default ContactUs
import { Button, Divider, Form, Toast, Typography } from "@douyinfe/semi-ui"
import './index.less'
import { useContext, useEffect, useState } from "react"
import SignUpForm from "../SignUpForm"
import { CookieUtil, request } from "../../../../utils"
import { AppContext } from "../../../../App"
import { THIRD_PARTY_LOGIN_PAGE_URL_LOCAL_STORAGE_KEY } from "../../../../constants"

export interface ILoginFormProps {
    showSignInWithAppleOnly?: boolean;
    showSignInWithGoogleOnly?: boolean;
    onLogin?: () => void;
}

const LoginForm = ({ onLogin, showSignInWithAppleOnly, showSignInWithGoogleOnly }: ILoginFormProps) => {
    const { setUserInfo } = useContext(AppContext)

    const [isSignUp, setIsSignUp] = useState(false)
    const [appleLoading, setAppleLoading] = useState(false)
    const [googleLoading, setGoogleLoading] = useState(false)

    const [confirmLoading, setConfirmLoading] = useState(false)

    const [usePasswordLogin, setUsePasswordLogin] = useState(false)

    useEffect(() => {
        if (window.AppleID) {
            window.AppleID.auth.init({
                clientId: 'com.hotaudeals.webapp',
                scope: 'name email',
                redirectURI: 'https://www.hotaudeals.com/api/v1/apple/callback', // Your redirect URI
                state: 'optional_csrf_token', // CSRF protection token (optional)
                usePopup: false // Set to false to use redirects
            });
        }

        if (window.google) {
            const handleCredentialResponse = (response: any) => {
                // Extract the ID token
                const idToken = response.credential;
    
                // Create a form element
                const form = document.createElement('form');
                form.method = 'POST';
                form.action = '/api/v1/google/callback';
    
                // Create an input element for the ID token
                const input = document.createElement('input');
                input.type = 'hidden';
                input.name = 'credential'; // The name attribute should match what your backend expects
                input.value = idToken;
    
                // Append the input to the form
                form.appendChild(input);
    
                // Append the form to the body and submit it
                document.body.appendChild(form);
                form.submit();
            }

            window.google.accounts.id.initialize({
                client_id: '726079566624-t81crje29auqo9uossq99aiftajtl3b5.apps.googleusercontent.com',
                callback: handleCredentialResponse
            })
        }
    }, [])

    const handleSubmit = async (v: any) => {
        setConfirmLoading(true)

        try {
            const respData = await request({
                method: 'POST',
                url: '/api/v1/service/execute',
                data: {
                    service: 'rds.sql',
                    requestParams: [
                        `SELECT email FROM simpo_octopus.au_user WHERE email = '${v.email}' AND password = '${v.password}'`,
                    ]
                }
            })

            if (respData && respData.length) {
                Toast.success('Login successfully')
                setUserInfo(respData?.[0])
                onLogin?.()
            } else {
                Toast.error("Email and password do not match")
            }
        } catch (err) {
            console.error(err)
        } finally {
            setConfirmLoading(false)
        }

    }

    const handleSignInWithApple = () => {
        CookieUtil.set(THIRD_PARTY_LOGIN_PAGE_URL_LOCAL_STORAGE_KEY, window.location.href, { days: 1 })
        setAppleLoading(true)
        window.AppleID.auth.signIn()

        setTimeout(() => setAppleLoading(false), 5000)
    }

    const handleSignInWithGoogle = () => {
        CookieUtil.set(THIRD_PARTY_LOGIN_PAGE_URL_LOCAL_STORAGE_KEY, window.location.href, { days: 1 })
        setGoogleLoading(true)
        window.google.accounts.id.prompt()

        setTimeout(() => setGoogleLoading(false), 5000)
    }

    if (isSignUp) {
        return <SignUpForm onLogin={() => setIsSignUp(false)} onSignUp={() => setIsSignUp(false)} />
    }

    if (showSignInWithAppleOnly) {
        return (
            <Button
                size="large"
                block
                theme="solid"
                loading={appleLoading}
                icon={<img width="18" height="18" src="https://img.icons8.com/ios-filled/50/FFFFFF/mac-os.png" alt="mac-os" />}
                style={{ background: 'black', color: 'white', borderColor: 'grey' }}
                onClick={handleSignInWithApple}
            >
                <span className='font-weight-bold'>
                    Sign in with Apple
                </span>
            </Button>
        )
    }

    if (showSignInWithGoogleOnly) {
        return (
            <Button
                size="large"
                block
                theme="outline"
                loading={googleLoading}
                id="sign-in-with-google-btn"
                icon={<img width="18" height="18" src="https://img.icons8.com/color/50/google-logo.png" alt="google-logo"/>}
                style={{ color: 'black' }}
                onClick={handleSignInWithGoogle}
            >
                <span style={{ fontWeight: 400 }}>
                    Sign in with Google
                </span>
            </Button>
        )
    }

    return (
        <div className="login-wrapper" style={{ padding: 24, height: '100%', overflow: 'auto' }}>

            <div className="responsive-text">
                <h1>Log in</h1>
                <p className="font-weight-bold">Log in to your account to get the most out of HotAuDeals.</p>
            </div>

            <Button
                size="large"
                block
                theme="solid"
                loading={appleLoading}
                icon={<img width="18" height="18" src="https://img.icons8.com/ios-filled/50/FFFFFF/mac-os.png" alt="mac-os" />}
                style={{ background: 'black', color: 'white' }}
                onClick={handleSignInWithApple}
            >
                <span className='font-weight-bold'>
                    Sign in with Apple
                </span>
            </Button>

            <Button
                size="large"
                block
                theme="outline"
                id="sign-in-with-google-btn"
                loading={googleLoading}
                icon={<img width="18" height="18" src="https://img.icons8.com/color/50/google-logo.png" alt="google-logo"/>}
                style={{ color: 'black', marginTop: 8 }}
                onClick={handleSignInWithGoogle}
            >
                <span style={{ fontWeight: 400 }}>
                    Sign in with Google
                </span>
            </Button>

            {/* <Divider align="center" style={{ marginTop: 12 }}>
                <span className="font-weight-bold" style={{ color: 'grey', fontSize: 12, padding: '0 8px' }}>
                    Alternatively
                </span>
            </Divider>

            <Form onSubmit={handleSubmit}>
                {({ formState, values, formApi }) => (
                    <>
                        <Form.Input
                            size="large"
                            field='email'
                            label='Email'
                            style={{ width: '100%' }}
                            rules={[{ required: true, message: 'Required' }]}
                            placeholder='Enter your email'
                        />
                        <Form.Input
                            size="large"
                            field='password'
                            type="password"
                            label='Password'
                            rules={[{ required: true, message: 'Required' }]}
                            style={{ width: '100%' }}
                            placeholder='Enter your password'
                        />
                        <Form.Checkbox field='agree' noLabel>
                            <span className="font-weight-regular">I have read and agree to the terms of service</span>
                        </Form.Checkbox>
                        <div>
                            <Button size="large" disabled={!values.agree} htmlType='submit' theme="solid" block loading={confirmLoading}>
                                <span className="font-weight-bold">Log in</span>
                            </Button>

                            <Typography.Paragraph type="tertiary" style={{ fontSize: 12, marginTop: 6 }}>
                                <span className="font-weight-regular">Don't have an account?</span>
                                <Button size="small" theme='borderless' style={{ padding: 0, marginLeft: 4, fontSize: 12 }} onClick={() => setIsSignUp(true)}>
                                    <span className="font-weight-regular">Sign up</span>
                                </Button>
                            </Typography.Paragraph>

                            <Typography.Paragraph type="tertiary" style={{ fontSize: 12, marginTop: 0 }}>
                                <span className="font-weight-regular">Have problems?</span>
                                <Button size="small" theme='borderless' style={{ padding: 0, marginLeft: 4, fontSize: 12 }} onClick={() => {
                                    const recipient = "hotaudeals@icloud.com";
                                    const subject = `login issue`
                                    const body = ''

                                    const mailtoLink = "mailto:" + recipient + "?subject=" + encodeURIComponent(subject) + "&body=" + encodeURIComponent(body);

                                    window.open(mailtoLink);
                                }}>
                                    <span className="font-weight-regular">Report a problem</span>
                                </Button>
                            </Typography.Paragraph>
                        </div>
                    </>
                )}
            </Form> */}
        </div>
    )
}

export default LoginForm
export const EXAMPLE_PRODUCT = {
    "platform": "coles",
    "product": {
        "coles_product_auto_increment_id": 42172857,
        "uniq_composite_key": "5114390__2",
        "sku_id": "5114390",
        "barcode": null,
        "name": "Pepsi Light Caffeine Free No Sugar Cola Soft Drink Bottle | 1.25L",
        "description": "PEPSI SOFT DRINK:COLA:DIET CAFFEINE FREE:1.25 LITRE",
        "formatted_category": "Drinks",
        "category_level_one": "drinks",
        "category_level_two": "soft-drinks",
        "pic_url": "https://productimages.coles.com.au/productimages/5/5114390.jpg",
        "price": 2,
        "unit_price": "$1.60 per 1L",
        "meta_data": null,
        "origin_price": 2.4,
        "new_price": 2,
        "discount_rate": -20,
        "created_at": "2024-09-15T02:36:29.000Z",
        "updated_at": "2024-09-15T02:36:29.000Z",
        "sync_batch": 166,
        "extra_data": {
            "isLowestPrice": false,
            "lowest_price": 1.8,
            "isActiveCatalogueItem": true,
            "activeCatalogueItemDetail": {
                "start_date": "2024-09-11",
                "end_date": "2024-09-17",
                "salefinder_item_auto_increment_id": 48320,
                "platform": "coles",
                "catalogue_id": "56308",
                "item_id": "563081136",
                "name": "Pepsi, Solo or Sunkist Soft Drink 1.25 Litre",
                "description": "",
                "category": "Drinks",
                "pic_url": "https://dduhxx0oznf63.cloudfront.net/images/thumbs/ipad/563081136_f548161299c9425.jpg",
                "sku_id_list": "1167362,147494,148205,2765494,3271150,3530281,5037303,5037358,5080798,5114390,5441863,7714151,9855562",
                "origin_price": null,
                "new_price": 5,
                "lowest_price": 1.8,
                "discount_rate": null,
                "offer_text": "Save  $1.00 ",
                "unit_price": "$2.00 per litre",
                "created_at": "2024-09-09T07:11:45.000Z",
                "updated_at": "2024-09-11T14:31:31.000Z"
            }
        },
        "platform": "coles"
    },
    "exactMatchProduct": {
        "coles_sku_id": "5114390",
        "woolworths_sku_id": "40895",
        "woolworths_product_auto_increment_id": 35020085,
        "uniq_composite_key": "40895__1.8",
        "sku_id": "40895",
        "barcode": "9313820003504",
        "name": "Pepsi Light Caffeine Free No Sugar Cola Soft Drink Bottle 1.25l",
        "description": "A caffeine-free, no sugar soft drink with the same refreshing Pepsi cola taste. Pepsi Light is bound to be your go-to alternative when you want a caffeine-free cola. <br> <br><br> <br><br><br>Pepsi Light caffeine-free, has….no caffeine AND no sugar. It’s as simple as that. <br> <br><br><br>Pepsi Light is low in calories vs full sugar colas. And, in case you we’re wondering, it also has the bold taste of cola that’s comparable with full sugar cola soft drinks.  <br> <br><br><br>The 1.25L multi-serve Pepsi Light bottle is ideal for the fridge at home or for enjoying with family and friends at any social, entertaining occasion.  <br> <br><br><br>Unbeatable taste without the caffeine or sugar hit.<br> <br><br><br>No sugar<br><br>No caffeine",
        "formatted_category": "Drinks",
        "category_level_one": "drinks",
        "category_level_two": null,
        "category_level_three": null,
        "pic_url": "https://cdn0.woolworths.media/content/wowproductimages/large/040895.jpg",
        "price": 1.8,
        "unit_price": "$1.44 / 1L",
        "meta_data": null,
        "origin_price": null,
        "new_price": 1.8,
        "discount_rate": null,
        "created_at": "2024-09-15T02:39:28.000Z",
        "updated_at": "2024-09-15T02:39:28.000Z",
        "sync_batch": 225,
        "extra_data": {
            "isLowestPrice": true,
            "lowest_price": 1.8,
            "isActiveCatalogueItem": true,
            "activeCatalogueItemDetail": {
                "start_date": "2024-09-11",
                "end_date": "2024-09-17",
                "salefinder_item_auto_increment_id": 48676,
                "platform": "woolworths",
                "catalogue_id": "56321",
                "item_id": "563211120",
                "name": "Pepsi, Solo, Sunkist or Mountain Dew Soft Drink Varieties 1.25 Litre",
                "description": null,
                "category": "Drinks",
                "pic_url": "https://dduhxx0oznf63.cloudfront.net/images/thumbs/ipad/563211120_a306ef89ed282bc.jpg",
                "sku_id_list": "32673,7985,12011,35808,40895,727383,231664,335129,825111,69142,676277,676312,60754,430139,666089",
                "origin_price": 3,
                "new_price": 1.8,
                "lowest_price": 1.8,
                "discount_rate": -40,
                "offer_text": "40% off, Save  $1.20 ",
                "unit_price": "$1.44 per litre",
                "created_at": "2024-09-09T07:12:10.000Z",
                "updated_at": "2024-09-09T07:12:10.000Z"
            }
        },
        "platform": "woolworths"
    }
}
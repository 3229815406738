import { useContext, useEffect, useRef, useState } from "react"
import FlexContainer from "../../../components/FlexContainer"
import { IAirport } from "../../../typings"
import { useAsyncEffect } from "ahooks"
import { request } from "../../../utils"
import { Button, Form, SideSheet, Table, Tag } from "@douyinfe/semi-ui"
import { AppContext } from "../../../App"
import AirportNameRender from "../../RewardFlightsByPlatform/components/AirportNameRender"
import { IconSetting } from "@douyinfe/semi-icons"
import { FormApi } from "@douyinfe/semi-ui/lib/es/form"
import { uniq } from "lodash-es"
import { SideSheetPlaceholder } from "../AdminLayout"

const AirportManagement = () => {
    const { windowHeight, navbarHeight } = useContext(AppContext)

    const [loading, setLoading] = useState(true)
    const [airportList, setAirportList] = useState<IAirport[]>([])
    const [selectedAirport, setSelectedAirport] = useState<IAirport>()

    const [count, setCount] = useState(0)

    const formRef = useRef<FormApi>()

    useAsyncEffect(async () => {
        const respData = await request({
            data: {
                service: 'rds.sql',
                requestParams: [
                    `SELECT * FROM simpo_octopus.au_airport`
                ]
            }
        })

        setAirportList(respData)
        setCount(respData.length)
        setLoading(false)
    }, [])

    useEffect(() => {
        if (selectedAirport) {
            formRef.current?.setValues({
                ...selectedAirport,
            })
        }
    }, [selectedAirport])

    const columns = [
        {
            title: 'Region',
            dataIndex: 'region_name',
            filterMultiple: false,
            filters: uniq(airportList.map(airport => airport.region_name)).sort((a, b) => a.localeCompare(b)).map(region => ({
                text: region,
                value: region
            })),
            onFilter: (value: string, record: any) => {
                return record.region_name === value
            },
            width: 150
        },
        {
            title: 'Country',
            dataIndex: 'country_name',
            filterMultiple: false,
            filters: uniq(airportList.map(airport => airport.country_name)).sort((a, b) => a?.localeCompare(b)).map(country => ({
                text: country,
                value: country
            })),
            onFilter: (value: string, record: any) => {
                return record.country_name === value
            },
            width: 170,
        },
        {
            title: 'City, Airport',
            dataIndex: 'city_name',
            render: (text: string, record: IAirport) => {
                return (
                    <FlexContainer gap="4px">
                        <Tag>{record.airport_code}</Tag>
                        <div>{text}</div>
                    </FlexContainer>
                )
            }
        },
        {
            title: 'Action',
            width: 80,
            render: (record: IAirport) => {
                return (
                    <FlexContainer>
                        <Button theme="solid" type="tertiary" icon={<IconSetting />} style={{ borderRadius: 32 }} onClick={() => {
                            setSelectedAirport(record)
                        }} />
                    </FlexContainer>
                )
            }
        }
    ]

    return (
        <FlexContainer style={{ width: '100%' }}>
            <div style={{ flexGrow: 1, width: '100%' }}>
                <div className="font-weight-black" style={{ padding: '8px 16px', fontSize: 20, letterSpacing: -0.5 }}>
                    Airport Management ({count})
                </div>

                <Table
                    dataSource={airportList}
                    loading={loading}
                    columns={columns}
                    pagination={false}
                    sticky
                    rowKey="airport_code"
                    style={{ height: windowHeight - navbarHeight - 46, overflow: 'auto' }}
                    onChange={() => {
                        setTimeout(() => {
                            const el = Array.from(document.querySelectorAll('.semi-table-tbody .semi-table-row'))
                            setCount(el.length)
                        }, 100)
                    }}
                />

            </div>

            <SideSheetPlaceholder />

            <SideSheet title="Edit airport" visible={!!selectedAirport} onCancel={() => setSelectedAirport(undefined)} mask={false} footer={(
                <FlexContainer>
                    <Button theme="solid">Submit</Button>
                </FlexContainer>
            )}>
                <Form getFormApi={api => formRef.current = api}>
                    <Form.Input label="Region name" field="region_name" />
                    <Form.Input label="Region code" field="region_code" />
                    <Form.Input label="Country name" field="country_name" />
                    <Form.Input label="Country code" field="country_code" />
                    <Form.Input label="City name" field="city_name" />
                    <Form.Input label="Airport name" field="airport_name" />
                    <Form.Input label="Airport code" field="airport_code" />

                    <Form.Input label="Longitude" field="longitude" />
                    <Form.Input label="Latitude" field="latitude" />
                </Form>
            </SideSheet>
        </FlexContainer>
    )
}

export default AirportManagement
import { useAsyncEffect } from "ahooks"
import { useNavigate, useParams } from "react-router-dom"
import { renderText, request } from "../../utils"
import { useCallback, useContext, useEffect, useMemo, useState } from "react"
import { IGiftCardPromotion } from "../../typings"
import FlexContainer from "../../components/FlexContainer"
import { AppContext } from "../../App"
import { Banner, Button, Card, Spin, Tag, Toast } from "@douyinfe/semi-ui"
import GiftCardPromotionDetailBody from "../GiftCards/components/GiftCardPromotionDetailBody"
import dayjs from "dayjs"
import { capitalize } from "lodash-es"
import BrandExplorer from "../GiftCards/components/BrandExplorer"
import StoreLocator from "../GiftCards/components/StoreLocator"
import { IconArrowLeft, IconArrowUpRight, IconShareStroked } from "@douyinfe/semi-icons"
import './index.less'
import copy from "copy-to-clipboard"
import { GiftCardPromotionStatusRender } from "../GiftCards"
import GoogleAdContainer from "../../components/GoogleAdContainer"
import { initSeo } from "../../utils/seo"

const GiftCardDetail = () => {
    const { id } = useParams()
    const navigate = useNavigate()

    const { isMobile, navbarHeight, windowHeight, windowWidth, isDarkMode } = useContext(AppContext)

    const [loading, setLoading] = useState(true)
    const [giftCardPromotion, setGiftCardPromotion] = useState<IGiftCardPromotion>()
    const [historyOfferList, setHistoryOfferList] = useState<IGiftCardPromotion[]>([])

    useEffect(() => {
        if (!giftCardPromotion) {
            return
        }

        initSeo({
            title: `${giftCardPromotion.offer_text} @ ${giftCardPromotion.platform} | HotAuDeals`,
            description: `Offer available from ${dayjs(giftCardPromotion.start_date).format("DD/MM/YYYY")} to ${dayjs(giftCardPromotion.end_date).format('DD/MM/YYYY')} on all denominations of gift cards listed. Check out our retailer catalogue to know which gift card to buy at HotAuDeals.`
        })
    }, [giftCardPromotion])

    useAsyncEffect(async () => {
        setLoading(true)
        setGiftCardPromotion(undefined)
        setHistoryOfferList([])

        const respData = await request({
            method: 'POST',
            url: '/api/v1/service/execute',
            data: {
                service: 'rds.sql',
                requestParams: [
                    `SELECT * FROM simpo_octopus.au_gift_card_promotion WHERE gift_card_promotion_auto_increment_id = ${id}`
                ]
            }
        })

        if (respData.length) {
            const historyGiftCardPromotionList = await request({
                method: 'POST',
                url: '/api/v1/service/execute',
                data: {
                    service: 'rds.sql',
                    requestParams: [
                        `SELECT * FROM simpo_octopus.au_gift_card_promotion WHERE platform = "${respData[0].platform}" AND gift_card_promotion_auto_increment_id != "${id}" AND start_date >= "2024-07-01" AND is_validated = 1 ORDER BY created_at DESC, gift_card_promotion_auto_increment_id ASC LIMIT 10`
                    ]
                }
            })

            setHistoryOfferList(historyGiftCardPromotionList)
            setGiftCardPromotion(respData?.[0])
        } else {
            navigate('/gift-cards')
        }

        setLoading(false)
    }, [id])

    const showInOneColumn = useMemo(() => {
        if (isMobile) {
            return true
        }

        return windowWidth < 1048
    }, [isMobile, windowWidth])

    const handlePromotionDoubleClick = useCallback(async () => {
        // if (giftCardPromotion?.promotion_detail) {
        //     return
        // }

        Toast.info("Generating promotion summary")
        const respData = await request({
            url: '/api/v1/service/execute',
            method: 'post',
            data: {
                service: 'au.giftcard.summarizeGiftCardPromotion',
                requestParams: [giftCardPromotion?.gift_card_promotion_auto_increment_id]
            }
        })

        setGiftCardPromotion(respData)
        Toast.success("Promotion summary generated")
    }, [giftCardPromotion])

    if (!loading && !giftCardPromotion) {
        return <></>
    }

    return (
        <Spin spinning={loading} size="large">
            <FlexContainer justifyContent="center" style={{ padding: isMobile ? 12 : 24, maxHeight: windowHeight - navbarHeight, overflow: 'auto', boxSizing: 'border-box' }}>
                <FlexContainer style={{ maxWidth: 1000, width: '100%', height: '100%' }} flexDirection="column">
                    <div>
                        <Button theme="borderless" style={{ padding: 0 }} size="small" icon={<IconArrowLeft style={{ fontSize: 14 }} />} onClick={() => navigate('/gift-cards')}>
                            Back to gift cards promotions
                        </Button>
                    </div>
                    <FlexContainer style={{ marginBottom: 16 }} justifyContent="space-between" gap="24px">
                        <FlexContainer className="responsive-text" flexDirection="column">
                            <div style={{ fontWeight: 700, fontSize: 24, letterSpacing: -1, lineHeight: 1.2 }} onDoubleClick={handlePromotionDoubleClick}>{renderText(giftCardPromotion?.offer_text)}</div>
                            <FlexContainer gap="6px" alignItems="center" style={{ marginTop: 4 }}>
                                <div className="font-weight-bold" style={{ letterSpacing: -0.5, color: 'grey' }}>
                                    Valid from {dayjs(giftCardPromotion?.start_date).format('DD/MM')} to {dayjs(giftCardPromotion?.end_date).format('DD/MM/YYYY')}
                                </div>
                                {!!giftCardPromotion && <GiftCardPromotionStatusRender record={giftCardPromotion} />}
                            </FlexContainer>

                        </FlexContainer>

                        {!isMobile && (
                            <div>
                                <Button
                                    icon={<IconShareStroked style={{ fontSize: 20 }} />}
                                    type="tertiary"
                                    style={{ borderRadius: 100, width: 40, height: 40 }}
                                    onClick={() => {
                                        copy(window.location.href)
                                        Toast.success("Link copied to clipboard")
                                    }}
                                />
                            </div>
                        )}
                    </FlexContainer>
                    <FlexContainer gap={isMobile ? "12px" : "20px"} flexWrap={showInOneColumn ? 'wrap' : 'nowrap'}>
                        {isMobile && (
                            <style>{`
                                .gift-card-promotion-detail-body-wrapper .section-header {
                                    top: -12px !important;
                                }
                            `}</style>
                        )}
                        <FlexContainer
                            className='responsive-background-secondary'
                            style={{
                                width: '100%',
                                background: 'white',
                                borderRadius: 4,
                                padding: '0 20px 20px',
                                boxSizing: 'border-box',
                                height: 'fit-content',
                                flexGrow: 0,
                                ...showInOneColumn ? {} : { width: 720 }
                            }}
                        >
                            <GiftCardPromotionDetailBody giftCardPromotion={giftCardPromotion} />
                        </FlexContainer>

                        <FlexContainer flexDirection="column" gap={isMobile ? "12px" : "20px"} style={{ boxSizing: 'border-box', ...showInOneColumn ? { width: '100%' } : { width: 260, flexGrow: 0, flexShrink: 0 } }}>
                            {/* <FlexContainer className="responsive-background-secondary" flexDirection="column" gap="8px" style={{ borderRadius: '4px', background: 'white', width: '100%', height: 'fit-content', padding: 16, boxSizing: 'border-box' }}>
                                <div className="font-weight-black responsive-text" style={{ letterSpacing: -0.2, }}>Google Ads</div>

                                <GoogleAdContainer slot="1234567890" />
                            </FlexContainer> */}

                            <FlexContainer className="responsive-background-secondary" flexDirection="column" gap="8px" style={{ borderRadius: '4px', background: 'white', width: '100%', height: 'fit-content', padding: 16, boxSizing: 'border-box' }}>
                                <div className="font-weight-black responsive-text" style={{ letterSpacing: -0.2, }}>Getting Started</div>

                                <StoreLocator
                                    platform={giftCardPromotion?.platform || ''}
                                    btnProps={{
                                        block: true,
                                        theme: "outline",
                                        type: "tertiary",
                                        children: "Where to Buy?",
                                        className: "responsive-background",
                                        style: { height: 40, borderRadius: 6 }
                                        // icon: <IconArrowUpRight style={{ fontSize: 12, position: 'relative', top: 0 }} />,
                                        // iconPosition: 'right'
                                    }}
                                />
                                <BrandExplorer btnProps={{ block: true, type: "tertiary", theme: "outline", icon: null, children: 'Browser Retailer Catalog', className: "responsive-background", style: { height: 40, borderRadius: 6 } }} />
                            </FlexContainer>

                            <FlexContainer className="responsive-background-secondary" flexDirection="column" gap="8px" style={{ borderRadius: '4px', background: 'white', width: '100%', height: 'fit-content', padding: 16, boxSizing: 'border-box' }}>
                                <div className="font-weight-black responsive-text" style={{ letterSpacing: -0.2, }}>Acknowledgement</div>
                                <div className="font-weight-bold">
                                    <div style={{ color: 'var(--semi-color-text-2)', fontSize: 13 }}>
                                        Full credit goes to the following resources for providing details on this gift card promotion.
                                    </div>

                                    <ul style={{ paddingLeft: 20, margin: 0, marginTop: 6, fontSize: 13, color: isDarkMode ? 'white' : 'black' }}>
                                        <li>
                                            <Button size="small" style={{ padding: 0 }} theme="borderless" onClick={() => {
                                                window.open('https://ozbargain.com.au/')
                                            }}>
                                                OzBargain
                                            </Button>
                                        </li>
                                        {!giftCardPromotion?.uniq_composite_key.startsWith('ozbargain') && (
                                            <li style={{ marginTop: 2 }}>
                                                <Button size="small" style={{ padding: 0 }} theme="borderless" onClick={() => {
                                                    window.open('https://freepoints.com.au/')
                                                }}>
                                                    FreePoints
                                                </Button>
                                            </li>
                                        )}
                                        {['Coles', 'Woolworths'].includes(giftCardPromotion?.platform || '') && (
                                            <li style={{ marginTop: 2 }}>
                                                <Button size="small" style={{ padding: 0, textAlign: 'left' }} theme="borderless" onClick={() => {
                                                    if (giftCardPromotion?.platform === 'Coles') {
                                                        window.open('https://www.coles.com.au/catalogues')
                                                    } else {
                                                        window.open('https://www.woolworths.com.au/shop/catalogue')
                                                    }
                                                }}>
                                                    <div>
                                                        {giftCardPromotion?.platform} Catalogue
                                                    </div>
                                                </Button>
                                                <div style={{ fontSize: 12, color: 'var(--semi-color-text-2)', marginTop: -4 }}>
                                                    {dayjs(giftCardPromotion?.start_date).format('DD/MM')} - {dayjs(giftCardPromotion?.end_date).format('DD/MM')}
                                                </div>
                                            </li>
                                        )}
                                    </ul>
                                </div>

                                {/* <Banner 
                                    fullMode={false}
                                    closeIcon={null}
                                    icon={null}
                                    description={(
                                        
                                    )} 
                                /> */}
                            </FlexContainer>

                            {!!historyOfferList.length && (
                                <FlexContainer className="responsive-background-secondary" flexDirection="column" gap="8px" style={{ borderRadius: '4px', background: 'white', width: '100%', height: 'fit-content', padding: 16, boxSizing: 'border-box' }}>
                                    <FlexContainer alignItems="center" gap="6px" className="font-weight-black responsive-text" style={{ letterSpacing: -0.2 }}>
                                        History Offers at {giftCardPromotion?.platform}
                                        {/* <Tag style={{ background: COLOR_MAP[giftCardPromotion?.platform.toLowerCase() || ''] }}>{giftCardPromotion?.platform}</Tag> */}
                                    </FlexContainer>

                                    {historyOfferList.map(offer => {
                                        return (
                                            <div key={offer.gift_card_promotion_auto_increment_id} className="history-offer-card-wrapper" onClick={() => navigate(`/gift-cards/${offer.gift_card_promotion_auto_increment_id}`)}>
                                                <Card bodyStyle={{ padding: '8px 12px' }}>
                                                    <div className="font-weight-bold" style={{ letterSpacing: -0.2 }}>{renderText(offer.offer_text)}</div>
                                                    <div style={{ color: 'grey', fontSize: 12 }}>
                                                        Valid from {dayjs(offer?.start_date).format('DD/MM')} to {dayjs(offer?.end_date).format('DD/MM/YYYY')}
                                                    </div>
                                                    <GiftCardPromotionStatusRender record={offer} />
                                                </Card>
                                            </div>

                                        )
                                    })}
                                </FlexContainer>
                            )}

                        </FlexContainer>
                    </FlexContainer>
                </FlexContainer>
            </FlexContainer>
        </Spin>

    )
}

export default GiftCardDetail
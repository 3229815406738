import { useAsyncEffect } from "ahooks"
import { useParams } from "react-router-dom"
import { request } from "../../../../utils"
import { useMemo, useRef, useState } from "react"
import { loadScript } from "../../../../components/ArticleRender"
import dayjs from "dayjs"
import { Empty, Spin, Tag } from "@douyinfe/semi-ui"
import { ICashbackPromotionChange } from "../../../../typings"
import { IllustrationNoContent, IllustrationNoContentDark } from "@douyinfe/semi-illustrations"
import FlexContainer from "../../../../components/FlexContainer"
import relativeTime from "dayjs/plugin/relativeTime"
import { IconClock } from "@douyinfe/semi-icons"
import { capitalize } from "lodash-es"

dayjs.extend(relativeTime)

const HistoryCashbackRate = () => {
    const { merchant: encodedMerchantName } = useParams()

    const [loading, setLoading] = useState(true)
    const [historyList, setHistoryList] = useState<ICashbackPromotionChange[]>([])

    const chartRef = useRef<any>()

    const maxCashbackRate = useMemo(() => {
        if (historyList.length === 0) {
            return
        }

        const bestRate = Math.max(...historyList.map(history => parseFloat(history.new_max_cashback_rate)))

        const reversedHistoryList = [...historyList].reverse()
        const bestRateItem = reversedHistoryList.find(item => item.new_max_cashback_rate === `${bestRate}`)
        const bestRateItemIdx = reversedHistoryList.indexOf(bestRateItem as any)

        return {
            isPercentage: historyList?.[0]?.new_cashback_info?.[0]?.max_cashback_rate?.includes('%'),
            value: bestRate,
            fromNow: bestRateItemIdx === 0 ? 'Trending now' : capitalize(dayjs(bestRateItem?.created_at).fromNow())
        }
    }, [historyList])

    useAsyncEffect(async () => {
        setLoading(true)
        const decodedMerchantName = atob(encodedMerchantName as string)?.trim()

        if (!window.Chart) {
            await loadScript('https://cdn.jsdelivr.net/npm/chart.js')
        }

        const respData = await request({
            data: {
                service: 'rds.sql',
                requestParams: [
                    `SELECT * FROM simpo_octopus.au_cashback_promotion_change WHERE merchant_name = '${decodedMerchantName}'`
                ]
            }
        })

        setHistoryList(respData)

        if (respData?.length) {
            const isPercentageCashback = respData?.[0]?.new_cashback_info?.[0]?.max_cashback_rate?.includes('%')

            const labels: string[] = [];
            const data: number[] = [];

            // Loop through respData
            respData.forEach((item: any, index: number) => {
                const formattedDate = dayjs(item.created_at).format('YYYY-MM-DD');
                const cashbackRate = parseFloat(item.new_max_cashback_rate);

                // Check if it's the first item or if current value is different from the previous one
                if (index === 0 || cashbackRate !== data[data.length - 1]) {
                    labels.push(formattedDate);
                    data.push(cashbackRate);
                }
            });

            // Add the 'Now' label and the most recent cashback rate
            labels.push('Now');
            data.push(parseFloat(respData[respData.length - 1].new_max_cashback_rate));

            new window.Chart(chartRef.current, {
                type: 'line',
                data: {
                    labels,
                    datasets: [{
                        label: 'Cashback Rate',
                        data,
                        borderWidth: 1,
                        fill: true,
                        stepped: true,
                    }]
                },
                options: {
                    plugins: {
                        legend: {
                            display: false
                        },
                        tooltip: {
                            bodyFont: {
                                family: '"Poppins", sans-serif'   // Set font for tooltips
                            },
                            callbacks: {
                                // This callback customizes the tooltip label
                                label: function (tooltipItem: any) {
                                    let label = tooltipItem.dataset.label || '';

                                    if (label) {
                                        label += ': ';
                                    }

                                    if (isPercentageCashback) {
                                        label += tooltipItem.raw + '%'
                                    } else {
                                        label += '$' + tooltipItem.raw
                                    }

                                    return label;
                                }
                            }
                        },
                    },
                    scales: {
                        x: {
                            ticks: {
                                font: {
                                    family: '"Poppins", sans-serif'   // Set font for X-axis labels
                                }
                            }
                        },
                        y: {
                            ticks: {
                                font: {
                                    family: '"Poppins", sans-serif'   // Set font for Y-axis labels
                                },
                                callback: function (value: string) {
                                    if (isPercentageCashback) {
                                        return value + '%';
                                    } else {
                                        return '$' + value
                                    }
                                }
                            }
                        }
                    }
                },

            })
        }

        setLoading(false)
    }, [encodedMerchantName])


    return (
        <Spin spinning={loading} size="large">
            <FlexContainer justifyContent="space-between" style={{ padding: '0 8px' }}>
                <div className="font-weight-black responsive-text" style={{ letterSpacing: -0.2 }}>History Rates</div>

                {!!maxCashbackRate && (
                    <FlexContainer flexDirection="column" style={{ fontSize: 12 }} alignItems="flex-end">
                        <div>
                            Max cashback rate: {!maxCashbackRate.isPercentage && '$'}{maxCashbackRate.value}{maxCashbackRate.isPercentage && '%'}
                        </div>
                        <FlexContainer style={{ fontSize: 10, color: 'grey', marginTop: -2 }} gap="4px" alignItems="center">
                            <IconClock style={{ fontSize: 10 }} />
                            {maxCashbackRate.fromNow}
                        </FlexContainer>
                    </FlexContainer>
                )}

            </FlexContainer>

            <div key={encodedMerchantName} style={{ padding: '4px 12px 12px', display: historyList.length === 0 ? 'none' : 'inherit' }}>
                <canvas ref={chartRef} />
            </div>

            {!loading && !historyList.length && (
                <Empty
                    image={<IllustrationNoContent style={{ width: 130, height: 130 }} />}
                    darkModeImage={<IllustrationNoContentDark style={{ width: 130, height: 130 }} />}
                    description="Oops! No data yet."
                    style={{ paddingBottom: 24 }}
                />
            )}
        </Spin>

    )
}

export default HistoryCashbackRate
import { Config, Driver, driver, Popover } from "driver.js"
import { useEffect, useState } from "react"
import "driver.js/dist/driver.css";
import './index.less'
import useTour from "../../hooks/useTour";

export enum IPopoverActionEnum {
    CLICK_FIRST_CHILD
}

export interface IPopoverWithAction extends Popover {
    action?: IPopoverActionEnum
}

interface ITourContainer {
    children?: any
    tourId?: string
    popover: IPopoverWithAction;
    options?: Config;
    order?: number;
    style?: React.CSSProperties
}

const TourContainer = ({ children, tourId, popover, options, order = 1, style = {} }: ITourContainer) => {
    const { isVisited } = useTour(tourId)

    if (isVisited) {
        return children
    }

    return (
        <div data-tour-id={tourId} data-tour-popover={JSON.stringify(popover)} data-tour-options={JSON.stringify(options || {})} data-tour-order={order} style={style}>
            {children}
        </div>
    )
}

export default TourContainer